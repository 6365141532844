import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    ApiCar,
    ApiMaker,
    ApiProduct,
    ApiProductMountableCarModel,
    ApiProductVariant,
    ApiProductVariantsData,
    ApiVariantItem
} from "../../openapi";
import TableContainer from "@material-ui/core/TableContainer";
import {Link, useParams} from "react-router-dom";
import {Box, Grid} from "@material-ui/core";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import {makeStyles} from "@material-ui/core/styles";
import {AppBar, Button, Title, useDataProvider, useGetOne, useNotify, useRefresh} from "react-admin";
import {Field, Form} from 'react-final-form';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useProductVariantHooks from "./productVariantHooks";
import ProductVariantCsvImportComponent from "./ProductVariantCsvImportComponent";
import Radio from '@material-ui/core/Radio';

const useStyles2 = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        flexGrow: 1,
        overflow: "scroll"
    },
    table: {
        position: 'sticky',
        top: 0,
        border: "solid thin"
    },
    category: {
        backgroundColor: "#808080",
        color: "#fff",
        borderBottom: "solid thin #000",
        borderRight: "solid thin #000"
    },
    product: {
        backgroundColor: "#E4E4E4",
        borderBottom: "solid thin",
        borderRight: "solid thin"
    },
    row: {
        backgroundColor: "#E4E4E4",
        minWidth: "50px",
        top: 0,
        position: "sticky",
        zIndex: 1000,
        borderBottom: "solid thin",
        borderRight: "solid thin"
    },
    tableContainer: {
        overflowX: "initial"
    },
    column: {
        backgroundColor: "#fff",
        minWidth: "50px",
        left: 0,
        position: "sticky",
        zIndex: 998,
        borderBottom: "solid thin",
        borderRight: "solid thin"
    },
    child: {
        zIndex: 1,
        borderBottom: "solid thin",
        borderRight: "solid thin"
    },
    paper: {
        marginBottom: 30
    }
}));

interface ApiProductVariantsCarGradesExtend {
    checked: boolean;
    data: ApiProductVariant
}

interface ApiProductVariantsCarGradesDetails {
    carGradeName: string,
    variantItemName: string,
    checked: boolean,
    carGradeId: number,
    variantItemId: number,
    productId: number
}

interface ApiProductVariantsCheckBoxData {
    id: number,
    gradeId: number,
    name: string,
    checked: boolean,
    values: [{
        checked: boolean
        data: { carGradeId: number, productId: number, variantItemId: number }
    }]
}

interface ScreenNameProps {
    product: ApiProduct;
}

interface MountCheckBoxData {
    checkData: CarGradeIdProductId[],
    verticalCheckData: number[],
    horizontalCheckData: number[]
}

interface CarGradeIdProductId {
    carGradeId: number,
    productId: number,
    variantItemId: number
}

const scrollVertical = (): number => {
    return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
}

const scrollHorizontal = (): number => {
    return Math.max(window.pageXOffset, document.documentElement.scrollLeft, document.body.scrollLeft);
}

export default function ProductVariantsList(props: { basePath: string | undefined; }) {
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {id} = useParams<{ id: string }>();
    const {
        getMakers,
        getCarModels,
        getCars
    } = useProductVariantHooks({
        productId: Number(id),
    });
    const [data, setData] = useState<ApiProductVariantsData[]>()
    const [checkBoxData, setCheckBoxData] = useState<Array<ApiProductVariantsCheckBoxData>>([]);
    const [products, setProducts] = useState<ApiVariantItem[]>()
    const [productsTotal, setProductsTotal] = useState([{total: 0}])
    const [checkDataForm, setCheckDataForm] = useState<MountCheckBoxData>();
    const [productMountableCarModel, setProductMountableCarModel] = useState<ApiProductMountableCarModel[]>()
    const [makerList, setMakerList] = useState<ApiMaker[]>([]);
    const [carList, setCarList] = useState<ApiCar[]>();
    const [checkMakerId, setCheckMakerId] = useState<number | null>();
    const [checkCarId, setCheckCarId] = useState<number | null>();
    const [checkCarModelId, setCheckCarModelId] = useState<number | null>()
    const [vertical, setVertical] = useState(0);
    const [buttonAvailable, setButtonAvailable] = useState(true);

    const [horizontal, setHorizontal] = useState(0);

    const [dataSize, setDataSize] = useState(0);
    useEffect(() => {
        const fetchApiData = async () => {
            const carModels = await getCarModels()
            if (carModels.length !== 0) {
                setProductMountableCarModel(carModels);
            }
            const cars = await getCars()
            if (cars.length !== 0) {
                const apiCars: ApiCar[] = []
                cars.forEach((car) => {
                    carModels.forEach((carModel) => {
                        if (!apiCars.some(it => it.id === car.id)) {
                            if (car.id === carModel.car?.id) {
                                apiCars.push(car)
                            }
                        }
                    })
                })
                setCarList(apiCars)
            }

            const makers = await getMakers()
            if (makers.length !== 0) {
                setMakerList(makers)
            }

        };
        fetchApiData().then();
    }, []);

    useEffect(() => {
        const fetchApiData = async () => {
            if (checkCarModelId !== undefined) {
                if (checkCarModelId !== null) {
                    const productVariants = await dataProvider.getList('productVariants',
                        {
                            pagination: {page: 1, perPage: 10000},
                            sort: {field: 'id', order: 'asc'},
                            filter: {productId: id, carModelId: checkCarModelId},
                        });
                    const fetched = productVariants.data as ApiProductVariantsData[]
                    setDataSize(productVariants.data.length)
                    setData(fetched);
                } else {
                    alert("データの絞り込みに失敗しました。")
                }
            }
        };
        fetchApiData().then();
    }, [checkCarModelId])

    useEffect(() => {
        if (data) {
            let arrProductTotalTemp: { name: string, id: number }[] = [];
            let arrProductsTotal: { total: number, id: number }[] = [];
            let arrProducts: ApiVariantItem [] = [];
            let arrCarGradeName: { name: string, id: number }[] = [];
            let arrCarGrade: ApiProductVariantsCarGradesDetails [] = [];
            let initialCheckData: CarGradeIdProductId[] = [];
            let horizontalCheckData: number[] = [];
            let verticalCheckData: number[] = [];
            data.map((data, index) => {
                data!!.variant!!.variantClass!!.map((variantClass) => {
                    variantClass.carGrades!!.map((carGrade) => {
                        carGrade.variantItem!!.map((variantItem) => {
                            let index = arrProducts.findIndex(searchProduct => searchProduct.id === variantItem.id);
                            index === -1 && arrProducts.push({
                                name: variantItem.name,
                                checked: true,
                                id: variantItem.id !== undefined ? variantItem.id : 0,
                                price: variantItem.price !== undefined ? variantItem.price : 0,
                                cost: variantItem.cost !== undefined ? variantItem.cost : 0,
                                originalPrice: variantItem.originalPrice !== undefined ? variantItem.originalPrice : 0,
                                priceForPartsOnly: variantItem.priceForPartsOnly !== undefined ? variantItem.priceForPartsOnly : 0,
                                variantClassId: variantItem.variantClassId !== undefined ? variantItem.variantClassId : 0,
                                position: variantItem.position,
                            })

                            let totalIndex = arrProductTotalTemp.findIndex(searchProductTotal => searchProductTotal.id === variantItem.id);
                            totalIndex === -1 && arrProductTotalTemp.push({
                                name: variantItem.name,
                                id: variantItem.id !== undefined ? variantItem.id : 0
                            })

                            arrCarGrade.push({
                                carGradeName: carGrade?.carGrade?.name !== undefined ? carGrade?.carGrade?.name : '',
                                variantItemName: variantItem.name,
                                checked: variantItem.checked !== undefined ? variantItem.checked : false,
                                carGradeId: carGrade?.carGrade?.id !== undefined ? carGrade?.carGrade?.id : 0,
                                variantItemId: variantItem.id !== undefined ? variantItem.id : 0,
                                productId: parseInt(id)
                            })

                        })

                        let index = arrCarGradeName.findIndex(searchCarGrade => searchCarGrade.id === carGrade?.carGrade?.id);
                        index === -1 && arrCarGradeName.push({
                            name: carGrade?.carGrade?.name !== undefined ? `${carGrade.carGrade?.carModel?.carName} ${carGrade.carGrade?.carModel?.name} ${carGrade?.carGrade?.name}` : '',
                            id: carGrade?.carGrade?.id !== undefined ? carGrade?.carGrade?.id : 0
                        })
                    })
                })
                arrProductsTotal.push({
                    total: arrProductTotalTemp.length,
                    id: index
                })
                arrProductTotalTemp.splice(0, arrProductTotalTemp.length)
                setProductsTotal(arrProductsTotal);
                setProducts(arrProducts);

                let checkedData: ApiProductVariantsCheckBoxData [] = [];
                arrCarGradeName.map((item, index) => {
                    const arrCheckboxTemp: ApiProductVariantsCarGradesExtend [] = [];
                    arrCarGrade.map((carGradeItem, index1) => {
                        if (item.id === carGradeItem.carGradeId) {
                            arrCheckboxTemp.push({
                                checked: arrCarGrade[index1].checked,
                                data: {
                                    productId: arrCarGrade[index1].productId,
                                    carGradeId: arrCarGrade[index1].carGradeId,
                                    variantItemId: arrCarGrade[index1].variantItemId,
                                }
                            })
                        }
                    })
                    checkedData.push({
                        id: index,
                        gradeId: item.id,
                        name: item.name,
                        checked: true,
                        values: arrCheckboxTemp
                    } as unknown as ApiProductVariantsCheckBoxData);
                })
                if (dataSize - 1 === index) {
                    checkedData.map((data, index) => {
                        data.values.map((data1: { checked: boolean, data: CarGradeIdProductId; }, index1: number) => {
                            if (!data1.checked) {
                                checkedData[index].checked = false;
                                if (typeof arrProducts[index1] !== 'undefined')
                                    arrProducts[index1].checked = false;
                                setProducts(arrProducts);
                            } else {
                                initialCheckData.push(data1.data);
                            }
                        })
                    })
                    arrProducts.map((product) => {
                        if (product.checked) {
                            verticalCheckData.push(product.id!!);
                        }
                    })

                    checkedData.map((item) => {
                        if (item.checked) {
                            horizontalCheckData.push(item.id);
                        }
                    })
                }
                console.log('checkBoxData:  ' + JSON.stringify(checkedData))
                setCheckBoxData(checkedData)
                setCheckDataForm({
                    checkData: initialCheckData,
                    verticalCheckData: verticalCheckData,
                    horizontalCheckData: horizontalCheckData
                });
            })
        }
    }, [data])

    const checkedVertical = (productId: number, checked: string | undefined) => {
        let initialCheckData: CarGradeIdProductId[] = [];
        let verticalCheckData: number[] = [];
        if (products) {
            (products[productId].checked = checked !== 'true')
            const index1 = checkDataForm!!.verticalCheckData.indexOf(products[productId].id!!);
            products[productId].checked ?
                verticalCheckData.push(products[productId].id!!) : delete checkDataForm!!.verticalCheckData[index1];
        }
        checkBoxData.map((data) => {
            if (!data.checked) {
                data.values[productId].checked = checked !== 'true';
            }
        })
        checkBoxData.map((data) => {
            if (data.values[productId].checked) {
                initialCheckData.push(data.values[productId].data)
            } else {
                const index2 = checkDataForm!!.checkData.indexOf(data.values[productId].data);
                delete checkDataForm!!.checkData[index2];
            }
        })
        // @ts-ignore
        const uniqueCheckData = [...new Map([...checkDataForm!!.checkData, ...initialCheckData].map((o) => [o, o])).values()];
        const uniqueVerticalCheckData = Array.from(new Set([...checkDataForm!!.verticalCheckData, ...verticalCheckData]));
        setCheckDataForm({
            checkData: uniqueCheckData,
            verticalCheckData: uniqueVerticalCheckData,
            horizontalCheckData: checkDataForm!!.horizontalCheckData
        });
        setVertical(scrollVertical());
        setHorizontal(scrollHorizontal());
    }

    const checkedHorizontal = (row: number, checked: boolean) => {
        let initialCheckData: CarGradeIdProductId[] = [];
        let horizontalCheckData: number[] = [];
        products?.map((productsData, a) => {
            if (!productsData.checked) {
                checkBoxData[row].values[a].checked = !checked;
            }
        })
        checkBoxData[row].checked = !checked;
        const index1 = checkDataForm!!.horizontalCheckData.indexOf(checkBoxData[row].id);
        checkBoxData[row].checked ?
            horizontalCheckData.push(checkBoxData[row].id) : delete checkDataForm!!.horizontalCheckData[index1];
        checkBoxData[row].values.map((data) => {
            if (data.checked) {
                initialCheckData.push(data.data);
            } else {
                const index2 = checkDataForm!!.checkData.indexOf(data.data);
                delete checkDataForm!!.checkData[index2];
            }
        })
        // @ts-ignore
        const uniqueCheckData = [...new Map([...checkDataForm!!.checkData, ...initialCheckData].map((o) => [o, o])).values()];
        const uniqueHorizontalCheckData = Array.from(new Set([...checkDataForm!!.horizontalCheckData, ...horizontalCheckData]));
        setCheckDataForm({
            checkData: uniqueCheckData,
            verticalCheckData: checkDataForm!!.verticalCheckData,
            horizontalCheckData: uniqueHorizontalCheckData
        });
        setVertical(scrollVertical());
        setHorizontal(scrollHorizontal());
    };

    const checkedCheckbox = (row: number, checked: boolean, column: number) => {
        let initialCheckData: CarGradeIdProductId[] = [];
        checkBoxData[row].values[column].checked = !checked;
        if (checkBoxData[row].values[column].checked) {
            initialCheckData.push(checkBoxData[row].values[column].data);
        } else {
            const index2 = checkDataForm!!.checkData.indexOf(checkBoxData[row].values[column].data);
            delete checkDataForm!!.checkData[index2];
        }
        // @ts-ignore
        const uniqueCheckData = [...new Map([...checkDataForm!!.checkData, ...initialCheckData].map((o) => [o, o])).values()];
        setCheckDataForm({
            checkData: uniqueCheckData,
            verticalCheckData: checkDataForm!!.verticalCheckData,
            horizontalCheckData: checkDataForm!!.horizontalCheckData
        });
        setVertical(scrollVertical());
        setHorizontal(scrollHorizontal());
    };

    const save = async (values: any) => {
        setButtonAvailable(false);
        let data: CarGradeIdProductId[] = []
        if (values && values.checkData.length > 0) {
            values.checkData.map((item: CarGradeIdProductId) => {
                if (item)
                    data.push(item);
            });
        }
        console.log('SaveRequest:' + JSON.stringify({data}));
        await dataProvider.createMany('productVariants/' + id + '/' + checkCarModelId, {
            data: null,
        })
        await dataProvider.createMany('productVariants/' + id + '/' + checkCarModelId, {
            data: data,
        })
        setButtonAvailable(true);
        notify('notification.saved');
        refresh();
    };


    const ScreenName: React.FC<ScreenNameProps> = (props) => {
        return (
            <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        product={props.product}
                        isJNumberAndInset={false}
                    />
                </div>
            </AppBar>
        );
    }

    const RadioGroup = ({...props}) => {
        const onChange = (event: any) => {
            if (props.selected !== undefined && props.selected === props.choices.id) {
                props.selected(null)
            } else {
                props.setSelected(props.choices.id)
            }
        }
        if (!props.choices) {
            return null;
        }
        return (
            <>
                <FormControlLabel
                    htmlFor={`${props.carId}~${props.id}`}
                    key={props.id}
                    className={props.className}
                    onChange={onChange}
                    control={
                        <Radio
                            id={`${props.carId}~${props.id}`}
                            color="primary"
                            checked={props.selected === props.choices.id}
                        />
                    }
                    label={`${props.makerName!!}` + ' -> ' + `${props.carName!!}`}
                />
                <div>
                    <Grid container={true} className={'w-150 ml-100'} style={{backgroundColor: "#CCCCCC"}}>
                        {productMountableCarModel?.map((carModel, index) => {
                            if (props.carId === checkCarId) {
                                if (props.carId === carModel.car?.id) {
                                    return (
                                        <Grid item={true} xs={6}>
                                            <FormControlLabel
                                                htmlFor={`${carModel.id}~${index}`}
                                                key={index}
                                                className={'w-125'}
                                                onChange={() => {
                                                    if (carModel.carModelId === checkCarModelId) {
                                                        setCheckCarModelId(null)
                                                    } else {
                                                        setCheckCarModelId(carModel.carModelId)
                                                    }
                                                }}
                                                control={
                                                    <Radio
                                                        id={`${carModel.id}~${props.id}`}
                                                        color="primary"
                                                        checked={checkCarModelId === carModel.carModelId}
                                                    />
                                                }
                                                label={`${carModel.carModel?.name!!}`}
                                            />
                                        </Grid>
                                    );
                                }
                            }
                        })
                        }
                    </Grid>
                </div>
            </>
        )
    }

    const RadioGroupParent = ({...props}) => {
        if (!props.maker) {
            return null;
        }
        return (
            <div>
                <FormControlLabel
                    htmlFor={`${props.carId}~${props.id}`}
                    key={props.id}
                    className={props.className}
                    onClick={() => {
                        if (props.maker.id === checkMakerId) {
                            setCheckMakerId(null);
                        } else {
                            setCheckMakerId(props.maker.id);
                        }
                    }
                    }
                    control={
                        <Radio
                            id={`${props.carId}~${props.id}`}
                            color="primary"
                            checked={checkMakerId !== undefined ? checkMakerId === props.maker.id : false}
                        />
                    }
                    label={""}
                />
                <label>
                    <strong>{props.maker.name}</strong>
                </label>
                <Grid container={true}>
                    {
                        carList?.map((car) => {
                            if (checkMakerId === props.maker.id) {
                                if (car.makerId === props.maker.id) {
                                    return (
                                        <Grid item={true} xs={3}>
                                            <RadioGroup
                                                choices={car}
                                                carId={car.id}
                                                carName={car.name}
                                                makerName={car.makerName}
                                                source={'carId'}
                                                setSelected={setCheckCarId}
                                                selected={checkCarId}
                                                className={'ml-70'}
                                            />
                                        </Grid>
                                    );
                                }
                            }
                        })}
                </Grid>

            </div>
        )
    }

    const LinkProductVariantMountableInset = () => {
        {
            return (
                checkCarModelId !== undefined ?
                    <Button
                        component={Link}
                        label={'J数・インセット設定'}
                        variant="contained"
                        color={'secondary'}
                        to={{pathname: `/jNumberAndInsetRecommendation/list/` + id + `/` + checkCarModelId}}
                        className={'mr-30'}
                        style={{marginLeft: 60}}
                    />
                    :
                    <></>
            );
        }
    }

    const product = useGetOne('products', id).data as ApiProduct;

    const classes = useStyles2();

    window.scrollTo(horizontal, vertical);

    return (
        <Form initialValues={checkDataForm} onSubmit={save} render={({handleSubmit}) => (
            <Paper className={'p-1'}>
                <form onSubmit={handleSubmit}>
                    <ScreenName product={product}/>
                    <Title title="商品規格設定"/>
                    <Box className={classes.paper}>
                        <Grid container={true}>
                            <Grid item={true}>
                                <Button component={Link}
                                        color={'default'}
                                        label={'戻る'}
                                        variant="contained"
                                        to={{pathname: `/products/${product?.id}`}}
                                /> &nbsp;
                                {buttonAvailable &&
                                    <>
                                        <Button
                                            type="submit"
                                            size={'small'}
                                            label={'保存'}
                                            variant="contained"
                                        />
                                        <LinkProductVariantMountableInset/>
                                        <ProductVariantCsvImportComponent productId={Number(id)}/>
                                    </>
                                }
                            </Grid>
                        </Grid>

                    </Box>
                    <div className={'searchForm'}>
                        {makerList?.map((maker) => {
                            if (carList?.find((v: ApiCar) => v.makerId === maker.id)) {
                                return (
                                    <div className={'mb-30'}>
                                        <RadioGroupParent maker={maker}/>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <TableContainer component={Paper} classes={{root: classes.tableContainer}}>
                        <Table aria-label="simple table" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.category}>規格</TableCell>
                                    {data?.map((data, index) => (
                                        <TableCell key={index}
                                                   colSpan={productsTotal[0].total !== 0 ? productsTotal[index].total : 0}
                                                   align={'center'}
                                                   className={classes.product}>{data.variant?.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.category}>規格分類</TableCell>
                                    {
                                        data?.map((data, index1) => (
                                            data.variant?.variantClass?.map((variantClass) => (
                                                <TableCell
                                                    colSpan={variantClass.carGrades && variantClass.carGrades[index1]?.variantItem?.length}
                                                    align={'center'}
                                                    className={classes.product}>{variantClass.name}</TableCell>
                                            ))))
                                    }
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.category}>規格項目</TableCell>
                                    {products && products.map(({name, checked, id}, index) => (
                                        <TableCell key={index} align={'center'} className={classes.row}>
                                            <label>
                                                <Field
                                                    name={'verticalCheckData'}
                                                    value={id}
                                                    type={'checkbox'}
                                                    component={"input"}
                                                    onClick={() => {
                                                        checkedVertical(index, checked?.toString())
                                                    }}
                                                /> {name?.toString()}
                                            </label>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {checkBoxData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="right" className={classes.column}>
                                            <label>
                                                <Field
                                                    name={'horizontalCheckData'}
                                                    value={row.id}
                                                    type={'checkbox'}
                                                    component={"input"}
                                                    onClick={() => {
                                                        checkedHorizontal(index, row.checked)
                                                    }}
                                                /> {row.name}
                                                <a
                                                    className={'MuiButtonBase-root MuiButton-root MuiButton-contained RaButton-button-6 MuiButton-containedSizeSmall MuiButton-sizeSmall w-150'}
                                                    target={'_blank'}
                                                    href={`/noCombinationVariants/${product?.id}/${row.gradeId}`}
                                                >
                                                    設定不可規格
                                                </a>
                                            </label>
                                        </TableCell>
                                        {row[('values')].map((values: { checked: boolean, data: CarGradeIdProductId }, index1: number) => (
                                            <TableCell align="center" key={index1} className={classes.child}>
                                                <label>
                                                    <Field
                                                        name={'checkData'}
                                                        value={values.data}
                                                        type={'checkbox'}
                                                        component={"input"}
                                                        onClick={() => {
                                                            checkedCheckbox(index, values.checked, index1)
                                                        }}
                                                    /> {``}
                                                </label>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={'mt-30 btnPullRight'}>
                        <Button
                            type="submit"
                            size={'small'}
                            label={'保存'}
                            variant="contained"
                        />
                    </div>
                </form>
            </Paper>
        )}
        />
    );
}
