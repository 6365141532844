import React, {useRef, useState} from "react";
import {Link} from 'react-router-dom';
import {
    Button,
    Create,
    Edit,
    maxLength,
    minValue,
    NullableBooleanInput,
    number,
    required,
    SimpleForm,
    useDataProvider,
    useRecordContext,
    useRedirect,
    useRefresh,
    ReduxState,
} from "react-admin";
import {floatValidation} from "../../common/validate";
import {Grid, Typography} from '@material-ui/core';
import {TempFileData} from "../../modules/temporaryFileModule";
import {CompleteCarImageClass, ImageDirectory, ResourceNames} from '../../config/const';
import CompleteCarImage from "./CompleteCarImagesComponent";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {saveImages} from "../../common/image/databaseImageUtils";
import CarDetailField from "./CarDataFieldComponent";
import DefaultProduct, {DefaultProductFunctions} from "./DefaultProductComponent";
import {ApiCompleteCar} from "../../openapi";
import Price from "./PriceComponent";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {SelectableCarGradeFunctions} from "./SelectableCarGradeComponent";
import CompleteCarColorImagesField, {CompleteCarColorImagesFunctions} from "./CompleteCarColorImagesComponent";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";
import {getNameForScreenHeader} from "../../common/ScreenComponent";
import {DefaultVariantItem, DefaultVariantItemFunctions} from "./DefaultVariantItemComponent";
import {useSelector} from 'react-redux';

const CompleteCarsForm: React.FC = (props: any) => {
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const validateName = [required(), maxLength(50)];
    const validateNumber = [required(), number(), minValue(0), floatValidation];
    const record = useRecordContext();
    const [pathData1, setPathData1] = useState(new Array<TempFileData>());
    const [pathData2, setPathData2] = useState(new Array<TempFileData>());
    const [pathData3, setPathData3] = useState(new Array<TempFileData>());
    const [images1, setImages1] = useState<ListPhotosType[] | undefined>();
    const [images2, setImages2] = useState<ListPhotosType[] | undefined>();
    const [images3, setImages3] = useState<ListPhotosType[] | undefined>()

    const [selectedCarGradeIds, setSelectedCarGradeIds] = useState<number[]>();

    const defaultProductRef = useRef<DefaultProductFunctions>(null);
    const defaultVariantItemRef = useRef<DefaultVariantItemFunctions>(null);
    const selectableCarGradeRef = useRef<SelectableCarGradeFunctions>(null);
    const completeCarColorImagesRef = useRef<CompleteCarColorImagesFunctions>(null);

    const [calcPrice, setCalcPrice] = useState(0);
    const [open, setOpen] = useState(false);
    const [modifiedProducts, setModifiedProducts] = useState(false);

    const [productInitialValues, setProductInitialValues] = useState<number[]>();
    const [variantItemInitialValues, setVariantItemInitialValues] = useState<number[]>();

    const [defaultProductIds, setDefaultProductItemIds] = useState<number[]>();
    const [defaultVariantItemIds, setDefaultVariantItemIds] = useState<number[]>();
    const [selectAffectPriceVariantItemIds, setSelectAffectPriceVariantItemIds] = useState<number[]>();

    const loading = useSelector<ReduxState>(state => state.admin.loading > 0) as boolean | undefined;

    const handleSave = async ({...values}) => {
        setOpen(true);
        if (props.type === 'create') {
            const completeCars = await dataProvider.create('completeCars', {data: {...values}});

            let count = 1
            let counter = ++count;

            await afterSave(completeCars.data as ApiCompleteCar, counter, values);

        } else {
            delete values.set;
            const completeCars = await dataProvider.update('completeCars', {
                id: values.id,
                data: {...values},
                previousData: {id: values.id}
            });

            let counter = 0
            await afterSave(completeCars.data as ApiCompleteCar, counter, values);
        }
    };

    const afterSave = async (completeCar: ApiCompleteCar, counter: number, {...values}) => {
        await saveImages(dataProvider, ResourceNames.COMPLETE_CAR_IMAGE_RESOURCE, {
            completeCarImageClass: CompleteCarImageClass.COMPLETE_CAR_IMAGE_CLASS_1,
            completeCarId: completeCar.id,
            position: counter++,
        }, ImageDirectory.COMPLETE_CAR_IMAGE_CLASS_1 + completeCar.id + '/', pathData1, images1);

        await saveImages(dataProvider, ResourceNames.COMPLETE_CAR_IMAGE_RESOURCE, {
            completeCarImageClass: CompleteCarImageClass.COMPLETE_CAR_IMAGE_CLASS_2,
            completeCarId: completeCar.id,
            position: counter++,
        }, ImageDirectory.COMPLETE_CAR_IMAGE_CLASS_2 + completeCar.id + '/', pathData2, images2);

        await saveImages(dataProvider, ResourceNames.COMPLETE_CAR_IMAGE_RESOURCE, {
            completeCarImageClass: CompleteCarImageClass.COMPLETE_CAR_IMAGE_CLASS_3,
            completeCarId: completeCar.id,
            position: counter++,
        }, ImageDirectory.COMPLETE_CAR_IMAGE_CLASS_3 + completeCar.id + '/', pathData3, images3);

        await defaultProductRef.current?.save(completeCar.id!!, defaultProductIds!!);
        await defaultVariantItemRef.current?.save(completeCar.id!!, defaultProductIds!!, defaultVariantItemIds!!);
        await selectableCarGradeRef.current?.save(completeCar.id!!, values['selectableCarGrades'] as number[]);
        await completeCarColorImagesRef.current?.save(completeCar.id!!);

        redirect('/completeCars');
        refresh();
    }

    const fireCalc = () => {
        setCalcPrice(calcPrice + 1);
    }

    return (
        <SimpleForm
            {...props}
            submitOnEnter={false}
            save={handleSave}
            toolbar={<ActionToolbar disableSave={loading}/>}
        >
            <Grid container={true} className="mb-30">コンプリートカー管理 {'->'}{getNameForScreenHeader(props)}</Grid>
            <Grid container spacing={3} className={'w-100'}>
                <Grid item md={12} lg={3}>
                    <NullableBooleanInput
                        source='whetherPublic'
                        label={'公開/非公開'}
                        fullWidth={true}
                        falseLabel="非公開"
                        trueLabel="公開"
                        validate={[required()]}
                        className={'w-100'}
                    />

                    <TextInputForJapanese source="name" validate={validateName} label={'名称'} fullWidth={true}/>

                    <NumberInputCommon
                        source={'discountPrice'}
                        onChange={fireCalc}
                        label={'値引'}
                        fullWidth={true}
                    />
                    <NumberInputCommon source={'position'} label={'表示順序'} fullWidth={true}/>
                    <CarDetailField selectedCarGradesIds={selectedCarGradeIds}
                                    setSelectedCarGradesIds={setSelectedCarGradeIds}
                                    setCalcPrice={setCalcPrice}
                                    calcPrice={calcPrice}
                                    selectableCarGradeRef={selectableCarGradeRef}
                    />
                    <NullableBooleanInput
                        source='whetherNew'
                        label={'新着'}
                        fullWidth
                        falseLabel="いいえ"
                        className={'w-100'}
                        trueLabel="はい"
                        validate={[required()]}/>
                    <NullableBooleanInput
                        source='used'
                        label={'中古のみ'}
                        fullWidth
                        falseLabel="いいえ"
                        className={'w-100'}
                        trueLabel="はい"
                        validate={[required()]}/>
                    <TextInputForJapanese source="explanation" validate={[required()]} label={'説明分'} multiline rows={20}
                                          fullWidth/>
                </Grid>
                <Grid item md={8} lg={8} xl={8}>
                    <Grid container spacing={3} className={'ml-80'}>
                        <Grid item lg={12}>
                            <Grid container>
                                <DefaultProduct
                                    carGradeIds={selectedCarGradeIds}
                                    ref={defaultProductRef}
                                    completeCarId={props.id}
                                    setCalcPrice={setCalcPrice}
                                    calcPrice={calcPrice}
                                    productInitialValues={productInitialValues}
                                    setProductInitialValues={setProductInitialValues}
                                    defaultProductIds={defaultProductIds}
                                    setDefaultProductIds={setDefaultProductItemIds}
                                    open={open}
                                    setOpen={setOpen}
                                    modifiedProducts={modifiedProducts}
                                    setModifiedProducts={setModifiedProducts}
                                />
                                <Grid xs={12}>
                                    <DefaultVariantItem
                                        carGradeIds={selectedCarGradeIds}
                                        ref={defaultVariantItemRef}
                                        completeCarId={props.id}
                                        setCalcPrice={setCalcPrice}
                                        calcPrice={calcPrice}
                                        variantItemInitialValues={variantItemInitialValues}
                                        setVariantItemInitialValues={setVariantItemInitialValues}
                                        defaultVariantItemIds={defaultVariantItemIds}
                                        setDefaultVariantItemIds={setDefaultVariantItemIds}
                                        selectAffectPriceVariantItemIds={selectAffectPriceVariantItemIds}
                                        setSelectAffectPriceVariantItemIds={setSelectAffectPriceVariantItemIds}
                                        defaultProductIds={defaultProductIds}
                                        open={open}
                                        setOpen={setOpen}
                                        modifiedProducts={modifiedProducts}
                                    />
                                </Grid>
                                <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11}
                                      className={'mb-50 mt-30'}>
                                    <Price
                                        setCalcPrice={setCalcPrice}
                                        completeCarId={props.id}
                                        calcPrice={calcPrice}
                                        productInitialValues={productInitialValues}
                                        setProductInitialValues={setProductInitialValues}
                                        defaultVariantItemIds={defaultVariantItemIds}
                                        selectAffectPriceVariantItemIds={selectAffectPriceVariantItemIds}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={1} md={2} lg={2} xl={2}/>
                        <Grid item xs={11} sm={9} md={10} lg={10} xl={10}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography component={'h6'}>一覧用画像(2枚)</Typography>
                                    <CompleteCarImage
                                        completeCarImageClass={CompleteCarImageClass.COMPLETE_CAR_IMAGE_CLASS_1}
                                        dataProvider={dataProvider}
                                        completeCarId={props.id}
                                        pathData={pathData1}
                                        setPathData={setPathData1}
                                        images={images1}
                                        setImages={setImages1}
                                        maxFileCount={2}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography component={'h6'}>上部用画像 PC(無制限)</Typography>
                                    <CompleteCarImage
                                        completeCarImageClass={CompleteCarImageClass.COMPLETE_CAR_IMAGE_CLASS_2}
                                        dataProvider={dataProvider}
                                        completeCarId={props.id}
                                        pathData={pathData2}
                                        setPathData={setPathData2}
                                        images={images2}
                                        setImages={setImages2}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography component={'h6'}>スマホ(無制限)</Typography>
                                    <CompleteCarImage
                                        completeCarImageClass={CompleteCarImageClass.COMPLETE_CAR_IMAGE_CLASS_3}
                                        dataProvider={dataProvider}
                                        completeCarId={props.id}
                                        pathData={pathData3}
                                        setPathData={setPathData3}
                                        images={images3}
                                        setImages={setImages3}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CompleteCarColorImagesField
                                selectedCarGradesIds={selectedCarGradeIds}
                                setSelectedCarGradesIds={setSelectedCarGradeIds}
                                dataProvider={dataProvider}
                                completeCarId={props.id}
                                ref={completeCarColorImagesRef}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}


const ActionTopToolbar = ({...props}) => {
    return (
        <div className={'mb-30 '}>
            <Button
                component={Link}
                label={'戻る'}
                variant="contained"
                color={'default'}
                to={{pathname: "/completeCars"}}
            />
        </div>
    );
};

export const CompleteCarsFormEdit: React.FC = (props: any) => {
    return (
        <Edit
            {...props}
            mutationMode={'optimistic'}
            actions={<ActionTopToolbar props/>}
        >
            <CompleteCarsForm {...props} type={'update'}/>
        </Edit>
    )
}

export const CompleteCarsFormCreate: React.FC = (props: any) => {
    return (
        <Create
            {...props}
            actions={<ActionTopToolbar/>}
        >
            <CompleteCarsForm {...props} type={'create'}/>
        </Create>
    )
};


export default CompleteCarsForm;
