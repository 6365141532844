import React, {useState, useEffect} from 'react';
import {useGetOne} from 'react-admin';
import {SETTING} from '../../config/const';
import {ApiSetting, SettingApiFp} from '../../openapi';

const useSetting = () => {
    const [getSettings, setGetSettings] = useState<ApiSetting[]>();
    const setting = useGetOne(SETTING.resource, SETTING.id);
    const {getAllSetting} = SettingApiFp();
    const getAllDataForSetting = () => {
        const fn = async () => {
            const result = await getAllSetting();
            const {data} = await result();
            if (data) {
                setGetSettings(data.data)
            }
        }
        fn().then()
    }
    useEffect(getAllDataForSetting, [])
    return {
        getSettings,
        setting,
    }
}
export default useSetting;