import {DeleteWithConfirmIconButton} from '@react-admin/ra-editable-datagrid';
import React from "react";
import {Link} from 'react-router-dom';
import {Button, Datagrid, List, ReferenceField, TextField, TopToolbar, useRecordContext,} from 'react-admin';
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";


const ListActions: React.FC = () => {
    return (
        <>
            <TopToolbar>
                <Button
                    component={Link}
                    to={{
                        pathname: '/aeroSeries/create',
                    }}
                    label={'新規作成'}
                />
            </TopToolbar>
        </>
    );
};


const AeroSeriesListComponent: React.FC = (props: any) => {
    return (
        <>
            <div>
                <List
                    {...props}
                    actions={<ListActions/>}
                    hasCreate
                    empty={false}
                    sort={{field: 'position', order: 'ASC'}}
                    exporter={false}
                    bulkActionButtons={false}
                    perPage={PER_PAGE}
                    pagination={<MyPagination/>}
                >
                    <Datagrid>
                        <TextField source="name" label={"名称"}/>
                        <ReferenceField link={false} reference={"completeCars"} source={"completeCarId"}
                                        label={"対応コンプリートカー"}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <TextFieldWithCommas source="position" label={"表示順序"} textAlign={'right'}/>
                        <div className={'actionButtons'}>
                            <UrlLinkAeroSeriesBtn source="id"/>
                            <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={"削除しても宜しいでしょうか。"}
                                           className={'ml-30'}/>
                        </div>
                    </Datagrid>
                </List>
            </div>
        </>
    );
}

const UrlLinkAeroSeriesBtn = ({...source}) => {
    const record = useRecordContext(source);
    return (
        <Button
            label={'変更'}
            variant="contained"
            component={Link}
            to={{
                pathname: `/aeroSeries/${record.id}`,
                state: {record: {aeroSeriesId: record.id}}
            }}
            className={'mr-30'}
        />
    );
}

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};

export default AeroSeriesListComponent;