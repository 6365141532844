import {stringify} from 'query-string';
import {DataProvider, fetchUtils} from 'ra-core';
import {CreateManyParams} from "./config/const";
import {CreateParams, GetOneParams} from "react-admin";

const dataProvider = (apiUrl: any, httpClient = fetchUtils.fetchJson): DataProvider => ({
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        delete params.filter.dummyShowImage
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };

        let url = `${apiUrl}/${resource}?_filter=${encodeURIComponent(JSON.stringify(params.filter))}&${stringify(query)}`;

        return httpClient(url).then(({headers, json}) => {
            if (!json.hasOwnProperty("total")) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: json.data,
                total: parseInt(
                    json.total,
                    10
                ),
            };
        });
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
            data: json.data,
        }))
    },

    getMany: (resource, params) => {
        let query = {};
        if (Array.isArray(params.ids) && params.ids.length > 0) {
            query = {
                ids: params.ids,
            };
        } else {
            query = {
                id: params.ids,
            };
        }
        const url = `${apiUrl}/${resource}?_end=999999999&_filter=${encodeURIComponent(JSON.stringify(query))}`;
        return httpClient(url).then(({json}) => ({data: json.data}));
    },

    getManyNoParams: (resource: string) => {
        const url = `${apiUrl}/${resource}`;
        return httpClient(url).then(({json}) => ({data: json.data}));
    },

    getManyReference: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        let filter = {};
        if (params.id) {
            filter = {[params.target]: params.id};
        }
        if (params.filter) {
            filter = params.filter;
        }
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };

        const url = `${apiUrl}/${resource}?_filter=${encodeURIComponent(JSON.stringify(filter))}&${stringify(query)}`;
        return httpClient(url).then(({headers, json}) => {
            if (!json.hasOwnProperty("total")) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: json.data,
                total: parseInt(
                    json.total,
                    10
                ),
            };
        });
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json.data}))
    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({data: responses.map(({json}) => json.id)})),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => {
            if (json.total) {
                return {
                    // TODO : it asked to add id in the response, but it's not in the json so I added it.
                    // validateResponseFormat.js : 28
                    // if (fetchActionsWithRecordResponse.includes(type) &&
                    //    !response.data.hasOwnProperty('id')) {
                    //    logger("The response to '" + type + "' must be like { data: { id: 123, ... } }, but the received data does not have an 'id' key. The dataProvider is probably wrong for '" + type + "'");
                    //    throw new Error('ra.notification.data_provider_error');
                    // }
                    data: {id: json.id, ...params.data}
                }
            } else {
                {
                    return {
                        data: {...json.data},
                    }
                }
            }

        }),

    createMany: async (resource: string, params: CreateManyParams) =>
        httpClient(`${apiUrl}/${resource}/saveMany`, {
            method: 'POST',
            body: JSON.stringify(params),
        }).then(({json}) => ({
            data: {...json.data},
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json})),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({data: responses.map(({json}) => json.id)})),

    searchById: async (resource: string, params: GetOneParams) =>
        httpClient(`${apiUrl}/${resource}/exists/${params.id}`).then(({json}) => ({
            data: json,
        })),

    calculate: async (resource: string, params: CreateParams) =>
        httpClient(`${apiUrl}/${resource}/calculate`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({
            data: {...json.data},
        })),
});

export default dataProvider;
