import React, {useState} from "react";
import {SelectInput} from "react-admin";
import {useForm} from "react-final-form";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {RECOMMENDED_CAR_COST_CALCULATION_TYPE} from "../../config/const";
import {ApiCostCalculationType} from "../../openapi";

const ManuallyCostField: React.FC = () => {
    const form = useForm();
    const [costCalculationType, setCostCalculationType] = useState(form.getState().values['costCalculationType']);

    const checkCostCalculationType = () => {
        setCostCalculationType(form.getState().values['costCalculationType'])
    }

    return (
        <>
            <SelectInput source={'costCalculationType'} choices={RECOMMENDED_CAR_COST_CALCULATION_TYPE} label={'原価計算方法'}
                         onChange={checkCostCalculationType}/>
            {costCalculationType === ApiCostCalculationType.Manual &&
                <NumberInputCommon source="manuallyCost" label={'原価'}/>
            }
        </>
    )
}
export default ManuallyCostField;
