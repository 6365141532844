import React from "react";
import {Button} from "react-admin";

interface CloseButtonProps {
    label?: string;
    variant?: "contained" | "text" | "outlined" | undefined;
    color?: "default" | "inherit" | "primary" | "secondary" | undefined;
}

const defaultProps: CloseButtonProps = {
    label: '閉じる',
    variant: 'contained',
    color: 'default',
};

const CloseButton: React.FC<CloseButtonProps> = (props) => {
    return (
        <>
            <Button
                label={props.label}
                variant={props.variant}
                color={props.color}
                onClick={() => window.close()}
            />
        </>
    )
}

CloseButton.defaultProps = defaultProps;
export default CloseButton;
