import React from "react";
import {ApiProductVariantItemImageCombination, ApiVariantClass} from "../../openapi";
import {useDataProvider} from "react-admin";
import {MAX_FETCH_DATA_SIZE, PAGE_LOCATION} from "../../config/const";

export interface UrlInterface {
    pubUrl: string;
    pathUrl: string;
    imageId: number;
    productId: number;
}

export const useVariantImageHooks = () => {
    const dataProvider = useDataProvider();

    const getVariantClasses = async () => {
        const variantClasses = await dataProvider.getList('variantClasses',
            {
                pagination: {page: PAGE_LOCATION, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'id', order: 'asc'},
                filter: {affectImage: true},
            });
        const array: React.SetStateAction<ApiVariantClass[]> = [];
        variantClasses.data.map((data) => {
            array.push(data as ApiVariantClass);
        });
        return array;
    }

    const sortVariantClasses = (variantClasses: ApiVariantClass[]) => {
        return variantClasses.sort((a, b) => a.position!! > b.position!! ? 1 : -1)
    }

    const getAllProductVariantItemImageCombination = async (productId: string) => {
        const variantClasses = await dataProvider.getList('productVariantItemImageCombination',
            {
                pagination: {page: PAGE_LOCATION, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'id', order: 'asc'},
                filter: {productId: productId},
            });

        const array: React.SetStateAction<ApiProductVariantItemImageCombination[]> = [];
        variantClasses.data.map((data) => {
            array.push(data as ApiVariantClass);
        });
        return array;
    }

    return {
        getVariantClasses,
        sortVariantClasses,
        getAllProductVariantItemImageCombination,
    }

}

export default useVariantImageHooks;