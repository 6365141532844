import React, {Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState} from "react";
import {Box, Grid, Paper, Tab, Tabs} from "@material-ui/core";
import {useDataProvider} from "react-admin";
import {
    ApiProduct,
    ApiProductCarGradeMountSettingWithOtherData,
    ApiProductCarGradeMountSettingWithOtherDataCategoryParentCategories
} from "../../openapi";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {IdName} from "../../common/IdName";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {useStyles} from "../../common/styles";
import {Checkbox, FormControl, FormControlLabel, FormGroup} from "@mui/material";

export interface DefaultProductFunctions {
    save: (
        completeCarId: number,
        defaultProducts: number[],
    ) => void;
}

interface DefaultProductProps {
    carGradeIds?: number[];
    completeCarId?: number;
    setCalcPrice: Dispatch<SetStateAction<number>>;
    calcPrice: number;
    productInitialValues: number[] | undefined
    setProductInitialValues: Dispatch<SetStateAction<number[] | undefined>>;
    defaultProductIds: number[] | undefined
    setDefaultProductIds: Dispatch<SetStateAction<number[] | undefined>>;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modifiedProducts: boolean;
    setModifiedProducts: Dispatch<SetStateAction<boolean>>;
}

const useLocalStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

interface CategoryProducts extends ApiProductCarGradeMountSettingWithOtherDataCategoryParentCategories {
    choices?: Array<IdName>
}

export const DefaultProduct: React.ForwardRefRenderFunction<DefaultProductFunctions, DefaultProductProps> = (props, ref) => {
    const classes = useLocalStyles();
    const dataProvider = useDataProvider();
    const [productCarGradeMountSettingWithOtherData, setProductCarGradeMountSettingWithOtherData] = useState<ApiProductCarGradeMountSettingWithOtherData>()
    const [tabValue, setTabValue] = React.useState(0);

    const [categories, setCategories] = useState<CategoryProducts[]>()

    useImperativeHandle(ref, () => ({
        async save(completeCarId: number, defaultProducts: number[]) {
            const dataArray = new Array<{ productId: number, completeCarId: number }>()
            defaultProducts?.map((value) => {
                dataArray.push(
                    {
                        completeCarId: completeCarId,
                        productId: value,
                    }
                )
            })
            await dataProvider.create('completeCarDefaultProducts/' + completeCarId, {
                data: {
                    data: dataArray,
                    id: 1,
                }
            })
        }
    }));


    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    // handle change tab
    useEffect(() => {
        if (tabValue) {
            let data = productCarGradeMountSettingWithOtherData?.data?.find((d) => d.categoryParent?.id === tabValue)?.categoryParent?.categories as Array<CategoryProducts>;
            data = data.filter(function (x, i, self) {
                return self.indexOf(x) === i;
            });
            data?.map((category) => {
                const choices = new Array<IdName>();
                category.carGrades?.map((carGrade) => {
                    carGrade.products?.map((product) => {
                        if (choices.find((choice) => choice.id === product.id) === undefined) {
                            choices.push({
                                id: product.id!!,
                                name: product.name,
                            });
                        }
                    })
                })
                category.choices = choices;
            })

            setCategories(data);

        }
    }, [tabValue]);

    useEffect(() => {
        const awaitFn = async () => {
            if (props.carGradeIds === undefined)
                return;

            props.setOpen(true);
            const data = await dataProvider.getList('completeCarDefaultProducts', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'createdAt', order: 'ASC'},
                filter: {
                    carGradeIds: props.carGradeIds,
                    completeCarId: props.completeCarId,
                },
            });
            const fetched = data as ApiProductCarGradeMountSettingWithOtherData
            setProductCarGradeMountSettingWithOtherData(fetched);

            let initialValues = new Array<number>();
            fetched.data?.map((parent) => parent.categoryParent?.categories?.map((category) => category.carGrades?.map((carGrade) => carGrade.products?.map((product) => {
                if (product.checked)
                    initialValues.push(product.id!!);
            }))));
            initialValues = initialValues.filter(function (x, i, self) {
                return self.indexOf(x) === i;
            });
            props.setDefaultProductIds(initialValues);
            props.setProductInitialValues(initialValues);
            props.setOpen(false);
        }
        awaitFn().then();
    }, [props.carGradeIds, props.completeCarId])


    const findProductById = (productId: number): ApiProduct => {
        let selectProduct: ApiProduct = {
            name: '',
            productCategoryId: 0,
            backboneCoreSystemCode: '',
            onlyWithCar: false,
            price: 0,
            priceForPartsOnly: 0,
            cost: 0,
            originalPrice: 0,
            structuralProcedureFee: 0,
            alignmentAdjustingFee: 0,
            isSet: false,
            forNew: false,
            forUsed: false,
            position: 0,
            whetherPublic: false,
        }
        categories?.map((category) =>
            category?.carGrades?.map((carGrade) =>
                carGrade?.products?.forEach((product) => {
                    if (productId == product.id!!) {
                        selectProduct = product
                    }
                })
            )
        )
        return selectProduct
    }

    const onChangeCheckBox = (e: any) => {
        const selectProduct = findProductById(e.target.value)
        let productIds: number[] = []
        if (props.defaultProductIds !== undefined)
            productIds = props.defaultProductIds

        if (productIds.some(value => value == selectProduct.id!!)) {
            productIds.splice(productIds.indexOf(selectProduct.id!!, 0), 1);
        } else {
            productIds.push(selectProduct.id!!);
        }
        setCategories(categories)
        props.setDefaultProductIds(productIds)
        props.setCalcPrice(props.calcPrice + 1);
        props.setModifiedProducts(!props.modifiedProducts)
    }

    const globalClasses = useStyles();

    return (
        <div className={classes.root}>

            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                <div>デフォルト商品</div>
            </Grid>

            <Paper className={globalClasses.scrollableTab}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant={"scrollable"}
                    scrollButtons="auto"
                >
                    {productCarGradeMountSettingWithOtherData?.data?.map((completeCarDefaultProduct) => (
                        <Tab
                            label={completeCarDefaultProduct.categoryParent?.name}
                            value={completeCarDefaultProduct.categoryParent?.id}/>
                    ))}
                </Tabs>

                {categories?.map((category) => (
                    <>
                        <Box ml={2}>
                            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                                {category.name}
                            </Grid>

                            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11}>
                                <FormControl>
                                    <FormGroup>
                                        {
                                            category.choices?.map((choices) => {
                                                return (
                                                    <FormControlLabel value={choices.id} control={
                                                        <Checkbox
                                                            onChange={onChangeCheckBox}
                                                            checked={props.defaultProductIds?.some(productId => productId == choices.id)}
                                                        />}
                                                                      label={choices.name}/>
                                                )
                                            })
                                        }
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Box>
                    </>
                ))}

            </Paper>
        </div>

    )
}

export default forwardRef(DefaultProduct);
