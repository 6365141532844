import {DeleteWithConfirmIconButton, EditableDatagrid, EditRowButton, RowForm} from '@react-admin/ra-editable-datagrid';
import React, {useEffect, useState} from 'react';
import {
    AutocompleteInput,
    BooleanField,
    CreateButton,
    Filter,
    List,
    minValue,
    NullableBooleanInput,
    number,
    NumberInput,
    ReferenceField,
    required,
    SelectInput,
    TextField,
    TopToolbar,
    useDataProvider,
    useRecordContext,
} from 'react-admin';
import {floatValidation} from "../../common/validate";
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";
import {Box, Grid} from '@material-ui/core';
import {MyPagination, PER_PAGE} from '../../common/MyPagination';
import {ApiProductCategory} from '../../openapi';
import {TextInputForJapanese} from "../../common/TextInputForJapanese";

const ListActions = () => (
    <TopToolbar className={'mt-30'}>
        <CreateButton/>
    </TopToolbar>
);


export const ProductCategoriesListComponent: React.FC = (props: any) => {

    return (
        <List
            filters={<UserFilter/>}
            {...props}
            hasCreate={false}
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            className={'filterForBox'}
            pagination={<MyPagination/>}
            actions={<ListActions {...props}/>}
        >
            <EditableDatagrid
                undoable={true}
                createForm={<EditAndCreateForm/>}
                editForm={<EditAndCreateForm/>}
                noDelete={false}
                className={'listTable productsList'}
            >
                <TextField source="id" label={'商品カテゴリID'}/>
                <ReferenceField label="親カテゴリー"
                                source="parentId"
                                reference="productCategories"
                                link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField label="カテゴリ名" source="name"/>
                <BooleanField source="onlyWithCar" label={'車両購入のみ'}/>
                <BooleanField source="selectableMultiple" label={'複数選択可能'}/>
                <BooleanField source="withTaxByEnvironmentalPerformance" label={'環境性能割に加算'}/>
                <BooleanField source="suspension" label={'サスペンション'}/>
                <BooleanField source="suspensionOption" label={'サスペンションオプション'}/>
                <BooleanField source="wheel" label={'ホイール'}/>
                <BooleanField source="makerDealerOption" label={'メーカーディーラーオプション'}/>
                <BooleanField source="fullCustomization" label={'フルカスタム'}/>
                <TextFieldWithCommas source="position" label={'表示順序'}/>
                <div className={'actionButtons'}>
                    <EditDataBtn {...props} className={'mr-30'}/>
                    <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                   className={'ml-10'}/>
                </div>
            </EditableDatagrid>
        </List>
    )
};

const searcher = (parentIdArray: Array<ApiProductCategory>, findId: number) => {
    const found = parentIdArray.find(e => e.valueOf() === findId);
    return !!found;
}

const EditAndCreateForm: React.FC = (props: any) => {
    const validateNumber = [required(), number(), minValue(0), floatValidation];
    const [parentCategories] = useState(new Array<ApiProductCategory>());
    const [parentChoice, setParentChoice] = useState(new Array<ApiProductCategory>());
    const dataProvider = useDataProvider();

    useEffect(() => {
        const awaitFn = async () => {
            const productCategories = await dataProvider.getList('productCategories', {
                pagination: {page: 1, perPage: 10000},
                sort: {field: 'position', order: 'ASC'},
                filter: {},
            });

            const array: React.SetStateAction<ApiProductCategory[]> = [];
            productCategories.data.map((item) => {
                if (item.parentId) {
                    array.push(item.parentId);
                }
            })
            setParentChoice(array);
            productCategories.data.map((item) => {
                if (item.parentId === null) {
                    parentCategories.push(item as ApiProductCategory);
                }
            })
        }

        awaitFn().then(() => console.log(parentCategories));

    }, []);

    return (
        <RowForm {...props}>
            {props.id === 'new_record' ?
                <SelectInput className={'input-field'} label="親カテゴリー" source="parentId" choices={parentCategories}
                             allowEmpty={true}/> :
                searcher(parentChoice, Number(props.id)) ?
                    <SelectInput className={'input-field'} label="親カテゴリー" source="parentId"
                                 options={{disabled: true, readOnly: true}}/> :
                    <SelectInput className={'input-field'} label="親カテゴリー" source="parentId" choices={parentCategories}
                                 defaultValue={() => 1} allowEmpty={true}/>
            }
            <TextInputForJapanese source="name" validate={required()} className={'input-field'} label={'カテゴリ名'}/>
            <NullableBooleanInput validate={required()} className={'input-field'} label="車両購入のみ" source="onlyWithCar"/>
            <NullableBooleanInput validate={required()} className={'input-field'} label={'複数選択可能'}
                                  source="selectableMultiple"/>
            <NullableBooleanInput validate={required()} className={'input-field'} label={'環境性能割に加算'}
                                  source="withTaxByEnvironmentalPerformance"/>
            <NullableBooleanInput validate={required()} className={'input-field'} label={'サスペンション'}
                                  source="suspension"/>
            <NullableBooleanInput validate={required()} className={'input-field'} label={'サスペンションオプション'}
                                  source="suspensionOption"/>
            <NullableBooleanInput validate={required()} className={'input-field'} label={'ホイール'}
                                  source="wheel"/>
            <NullableBooleanInput validate={required()} className={'input-field big-width'} label={'メーカーディーラーオプション'}
                                  source="makerDealerOption"/>
            <NullableBooleanInput validate={required()} className={'input-field'} label={'フルカスタム'}
                                  source="fullCustomization"/>
            <NumberInput validate={validateNumber} className={'input-field'} label={'表示順序'} source="position"/>
        </RowForm>
    )
};

const UserFilter: React.FC = (props: any) => {
    const [parentCategories] = useState(new Array<ApiProductCategory>());
    const dataProvider = useDataProvider();

    useEffect(() => {
        const awaitFn = async () => {
            const productCategories = await dataProvider.getList('productCategories', {
                pagination: {page: 1, perPage: 10000},
                sort: {field: 'position', order: 'ASC'},
                filter: {onlyParent: true},
            });

            productCategories.data.map((item) => {
                parentCategories.push(item as ApiProductCategory);
            })
        }

        awaitFn().then(() => console.log(parentCategories));

    }, []);

    return (
        <Filter {...props}>
            {/*// @ts-ignore*/}
            <Box bgcolor="white" p={2} id='box' className={'filterBox'} mt={2} alwaysOn>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true}>
                        <AutocompleteInput label="親カテゴリー" choices={parentCategories} alwaysOn resettable={true}
                                           source="parentId"/>
                    </Grid>
                    <Grid item={true} spacing={2}>
                        <TextInputForJapanese label="カテゴリ名" source="name" className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true}>
                        <NullableBooleanInput label="車両購入のみ" source="onlyWithCar" className={'p-display-none w-150'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true}>
                        <NullableBooleanInput fullWidth={true} label="複数選択可能" source="selectableMultiple"
                                              className={'p-display-none w-150'} alwaysOn/>
                    </Grid>
                    <Grid item={true}>
                        <NullableBooleanInput label="環境性能割に加算される" source="withTaxByEnvironmentalPerformance"
                                              className={'p-display-none w-250'} alwaysOn/>
                    </Grid>
                    <Grid item={true}>
                        <NullableBooleanInput label="メーカーディーラーオプション" source="makerDealerOption"
                                              className={'p-display-none w-250'} alwaysOn/>
                    </Grid>
                    <Grid item={true}>
                        <NullableBooleanInput label="フルカスタム" source="fullCustomization"
                                              className={'p-display-none w-150'} alwaysOn/>
                    </Grid>
                </Grid>
            </Box>
        </Filter>
    )
};

const EditDataBtn = ({...source}) => {
    return (<div className={source.className}><EditRowButton {...source} /></div>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};

export default ProductCategoriesListComponent;
