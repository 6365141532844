import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        inlineHeader: {
            backgroundColor: "#E6E6E6",
            color: "#555555",
            width: '100%',
            padding: '10px',
        },
        scrollableTab: {
            display:'grid',
        },
    }),
);
