import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {List, useDataProvider, useNotify, useRedirect} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {ApiEcoCarTaxCut, ApiWeightTax} from '../../openapi';

const WeightTaxesList = (props: { name?: string; id?: number, basePath?: string }) => {
    const [dataEcoCarTaxCuts, setDataEcoCarTaxCuts] = useState(new Array<any>());
    const [dataWeightTaxes, setDataWeightTaxes] = useState(new Array<any>());
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    console.log(props)

    useEffect(() => {
        const fetchApiData = async () => {
            const ecoCarTaxCuts = await dataProvider.getList('ecoCarTaxCuts',
                {
                    pagination: {page: 1, perPage: 10000},
                    sort: {field: 'position', order: 'asc'},
                    filter: {},
                });
            const array: React.SetStateAction<ApiEcoCarTaxCut[]> = [];
            ecoCarTaxCuts.data.map((data) => {
                array.push(data as ApiEcoCarTaxCut);
            });
            setDataEcoCarTaxCuts(array);

            const weightTaxes = await dataProvider.getList('weightTaxes',
                {
                    pagination: {page: 1, perPage: 10000},
                    sort: {field: 'id', order: 'asc'},
                    filter: {},
                });
            const array1: React.SetStateAction<ApiWeightTax[]> = [];
            weightTaxes.data.map((data) => {
                array1.push(data as ApiWeightTax);
            });
            setDataWeightTaxes(array1);
        };
        fetchApiData().then();
    }, []);

    const save = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        console.log('PostRequest: ' + JSON.stringify(dataWeightTaxes));
        await dataProvider.create('weightTaxes', {
            data: {
                data: dataWeightTaxes,
            },
        })
        notify('notification.saved');
        redirect('list', props.basePath);
    };

    const inputChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: { id: string }, index: { index: string }, row: { row: string }) => {
        let objIndex = dataWeightTaxes.findIndex((obj => obj.id == id.id));
        dataWeightTaxes[objIndex].values[JSON.parse(index.index)] = event.target.value;
        console.log('ViewDataByRow: ' + JSON.stringify(dataWeightTaxes[JSON.parse(row.row)]))
    };

    return (
        <>
            <form onSubmit={save}>
                <List
                    {...props}
                    empty={false}
                    actions={false}
                    pagination={false}
                >
                    <>
                        <div className={'App'}><h2> エコカー減税適用 </h2></div>
                        <Paper className={'mt-15 b-shadow'}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left"> 重量</TableCell>
                                            {dataEcoCarTaxCuts.sort((a, b) => a.id > b.id ? 1 : -1)
                                                .map((row, index) => (
                                                    <TableCell align="left" key={index}> {row.name} </TableCell>
                                                ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataWeightTaxes.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left"> ~{row[('weight')] / 1000} </TableCell>
                                                {row[('values')].map((id: number, index1: number) => (
                                                    <TableCell align="right" key={index1}>
                                                        <div className={'d-flex'}>
                                                            <TextField
                                                                defaultValue={id}
                                                                className={'w-100 input-filled-basic'}
                                                                type="number"
                                                                inputProps={{min: 0, step: 1}}
                                                                variant="filled"
                                                                onChange={(event) => {
                                                                    inputChanged(event, {id: (row[('id')]).toString()},
                                                                        {index: (index1).toString()}, {row: (index).toString()})
                                                                }}/>
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                </List>
                <Grid container={true} className={'d-flex mt-15'} justify={'flex-end'}>
                    <Grid>
                        <Button variant="contained" href={'/ecoCarTaxCuts'}>
                            エコカー減税項目管理
                        </Button> &nbsp;
                        <Button type={"submit"} variant="contained" color="secondary">
                            保存
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>

    );
};
export default WeightTaxesList;
