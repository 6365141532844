import React, {useState} from 'react';
import {EditableDatagrid, EditRowButton, RowForm,} from '@react-admin/ra-editable-datagrid';
import {
    AutocompleteInput,
    Filter,
    List,
    minValue,
    number,
    NumberInput,
    ReferenceInput,
    required,
    TextField
} from 'react-admin';
import {floatValidation} from '../../common/validate';
import {Box, Grid} from '@material-ui/core';
import {TextFieldWithCommas} from '../../common/TextFieldWithCommas';
import {PER_PAGE} from "../../common/MyPagination";

const AutoMobileTaxesList: React.FC = (props: any) => {
    return (
        <List
            filters={<SearchFilterForm/>}
            {...props}
            hasCreate={false}
            empty={false}
            exporter={false}
            bulkActionButtons={false}
            pagination={false}
            sort={{field: 'id', order: 'ASC'}}
            perPage={PER_PAGE}
        >
            <EditableDatagrid
                undoable={true}
                editForm={<DataForm/>}
                noDelete={false}
                className={'listTable autoMobileTaxList'}
            >
                <TextField source="autoMobileTaxBracketName"
                           label={'排気量'}
                           sortable={false}
                />
                <TextFieldWithCommas
                    source="twelfthMonths"
                    label={'年額'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="elevenMonths"
                    label={'11か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="tenMonths"
                    label={'10か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="nineMonths"
                    label={'9か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="eightMonths"
                    label={'8か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="sevenMonths"
                    label={'7か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="sixMonths"
                    label={'6か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="fiveMonths"
                    label={'5か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="fourMonths"
                    label={'4か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="threeMonths"
                    label={'3か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="twoMonths"
                    label={'2か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <TextFieldWithCommas
                    source="oneMonths"
                    label={'1か月分'}
                    textAlign="right"
                    sortable={false}
                />
                <div className={'actionButtons'}>
                    <EditRowButton {...props} />
                </div>
            </EditableDatagrid>
        </List>
    );
}

const DataForm: React.FC = (props) => {
    const validateNumber = [required(), number(), minValue(0), floatValidation];
    return (
        <RowForm
            {...props}
        >
            <TextField source="autoMobileTaxBracketName"/>
            <NumberInput source="twelfthMonths" validate={validateNumber} label={'年額'}/>
            <NumberInput source="elevenMonths" validate={validateNumber} label={'11か月分'}/>
            <NumberInput source="tenMonths" validate={validateNumber} label={'10か月分'}/>
            <NumberInput source="nineMonths" validate={validateNumber} label={'9か月分'}/>
            <NumberInput source="eightMonths" validate={validateNumber} label={'8か月分'}/>
            <NumberInput source="sevenMonths" validate={validateNumber} label={'7か月分'}/>
            <NumberInput source="sixMonths" validate={validateNumber} label={'6か月分'}/>
            <NumberInput source="fiveMonths" validate={validateNumber} label={'5か月分'}/>
            <NumberInput source="fourMonths" validate={validateNumber} label={'4か月分'}/>
            <NumberInput source="threeMonths" validate={validateNumber} label={'3か月分'}/>
            <NumberInput source="twoMonths" validate={validateNumber} label={'2か月分'}/>
            <NumberInput source="oneMonths" validate={validateNumber} label={'1か月分'}/>
        </RowForm>
    )
};

const SearchFilterForm: React.FC = ({...props}) => {
    const [fieldDisabled, setFieldDisabled] = useState<boolean | number | null>(null);
    const [fieldSelected, setFieldSelected] = useState(1);
    {/*// @ts-ignore*/
    }
    const handleSearchOnce = (data: number) => {
        if (!isNaN(data)) {
            setFieldSelected(data)
            setFieldDisabled(!data ? null : data);
        } else {
            setFieldSelected(0)
            setFieldDisabled(null);
        }
    }
    const classRenderer = (choice: Record<any, any>) => {
        if (choice) {
            return choice.name ? `${choice.name}` : choice.name;
        }
    }
    return (
        <Filter {...props}>
            {/*// @ts-ignore*/}
            <Box bgcolor="white" p={5} id='box' className={'filterBox'} mt={2} alwaysOn>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true} lg={10}>
                        <ReferenceInput
                            label="区分"
                            source="autoMobileTaxClassId"
                            reference="autoMobileTaxClasses"
                            onChange={handleSearchOnce}
                            alwaysOn
                            allowEmpty
                            className={'p-display-none'}
                            sort={{field: 'id', order: 'ASC'}}
                        >
                            <AutocompleteInput optionText={classRenderer}/>
                        </ReferenceInput>
                    </Grid>
                </Grid>
            </Box>
        </Filter>
    )
};


export default AutoMobileTaxesList;