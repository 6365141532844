import React, {useEffect} from 'react';
import {EditableDatagrid, EditRowButton, RowForm,} from '@react-admin/ra-editable-datagrid';
import {List, minValue, number, ReferenceField, required, TextField, useRefresh} from 'react-admin';
import {floatValidation} from '../../common/validate';
import {Button, Container} from '@material-ui/core';
import {TextFieldWithCommas} from '../../common/TextFieldWithCommas';
import NumberInputCommon from "../../common/NumberInputCommonComponent";

const CarShippingFeesList: React.FC = (props: any) => {
    const refresh = useRefresh();
    useEffect(() => {
        refresh();
    });
    return (
        <Container>
            <div className={'mt-30 numberRight'}>
                <Button variant="contained" href="/carShippingPlaces">
                    納車場所管理
                </Button>
            </div>
            <List
                {...props}
                empty={false}
                exporter={false}
                bulkActionButtons={false}
                pagination={false}
            >
                <EditableDatagrid
                    undoable={true}
                    editForm={<DataForm/>}
                    noDelete={true}
                    className={'listTable'}
                >
                    <ReferenceField
                        label=""
                        source="carShippingPlaceId"
                        reference="carShippingPlaces"
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextFieldWithCommas
                        source="shippingFee"
                        label={'納車費用'}
                        textAlign="right"
                    />
                    <div className={'actionButtons'}>
                        <EditRowButton {...props} />
                    </div>
                </EditableDatagrid>
            </List>
        </Container>
    );
}

const DataForm: React.FC = (props) => {
    const validateNumber = [required(), number(), minValue(0), floatValidation];
    return (
        <RowForm
            {...props}
        >
            <ReferenceField
                label=""
                source="carShippingPlaceId"
                reference="carShippingPlaces"
                link={false}
            >
                <TextField source="name"/>
            </ReferenceField>
            <NumberInputCommon
                source="shippingFee"
                validate={validateNumber}
                label={'納車費用'}
            />
        </RowForm>
    )
};


export default CarShippingFeesList;
