import React from 'react';
import {EditButton, Show, SimpleShowLayout, Toolbar,} from 'react-admin';
import {Grid} from "@material-ui/core";
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";

const ActionToolbar = ({...props}) => (
    <Toolbar className={'actionButtons'}>
        <EditButton basePath={props.basePath} record={props.data}/>
    </Toolbar>
);

const GeneralChargesShow: React.FC = (props: any) => {
    return (
        <Show {...props} actions={<ActionToolbar/>}>
            <SimpleShowLayout>
                <div>
                    <Grid container spacing={3}>
                        <Grid item={true} lg={2} md={4} sm={4} xs={4}>
                            法廷費用
                        </Grid>
                        <Grid item={true} lg={10} md={8} sm={8} xs={8}>
                            <Grid container spacing={3}>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                    検査登録（届出）
                                </Grid>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                    <TextFieldWithCommas source="legalFeeInspectionRegistration" label={''}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                    車庫証明証紙代
                                </Grid>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                    <TextFieldWithCommas source="legalFeeGarageCertificatePaperFee" label={''}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                    下取車
                                </Grid>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                    <TextFieldWithCommas source="legalFeeTradeIn" label={''}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                    印紙税
                                </Grid>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                    <TextFieldWithCommas source="stampDuty" label={''}/>
                                </Grid>
                            </Grid> <Grid container spacing={3}>
                            <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                ナンバー代
                            </Grid>
                            <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                <TextFieldWithCommas source="numberFee" label={'ナンバー代'}/>
                            </Grid>
                        </Grid>
                        </Grid>
                        <Grid item={true} lg={2} md={4} sm={4} xs={4}>
                            代行費用
                        </Grid>
                        <Grid item={true} lg={10} md={8} sm={8} xs={8}>
                            <Grid container spacing={3}>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                    検査登録（届出）
                                </Grid>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                    <TextFieldWithCommas source="agentFeeInspectionRegistration" label={''}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                    車庫証明
                                </Grid>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                    <TextFieldWithCommas source="agentFeeGarageCertificatePaperFee" label={''}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                    下取車
                                </Grid>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                    <TextFieldWithCommas source="agentFeeTradeIn" label={''}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                    一般希望番号申請費
                                </Grid>
                                <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                    <TextFieldWithCommas source="selectiveNumberFee" label={'一般希望番号申請費'}/>
                                </Grid>
                            </Grid> <Grid container spacing={3}>
                            <Grid item={true} lg={3} md={6} sm={6} xs={6}>
                                製作車両移動費用
                            </Grid>
                            <Grid item={true} lg={3} md={6} sm={6} xs={6} className={'numberRight'}>
                                <TextFieldWithCommas source="carShippingFee" label={'製作車両移動費用'}/>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                </div>
            </SimpleShowLayout>
        </Show>
    );
}

export default GeneralChargesShow;
