import {DefaultProductFunctions, DefaultProductProps} from "./RecommendedCarsDefaultProductsList";
import React, {ForwardedRef, useEffect, useImperativeHandle, useState} from "react";
import {IdName} from "../../common/IdName";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {
    ApiProduct,
    ApiProductCarGradeMountSettingWithOtherData,
    ApiProductCarGradeMountSettingWithOtherDataCategoryParentCategories
} from "../../openapi";
import {useStyles} from "../../common/styles";
import {useDataProvider} from "react-admin";
import {makeStyles, Theme} from "@material-ui/core/styles";

const useLocalStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

interface RecommendedCarDefaultProductHookProps extends DefaultProductProps {
    ref: ForwardedRef<DefaultProductFunctions>
}

export interface CategoryProducts extends ApiProductCarGradeMountSettingWithOtherDataCategoryParentCategories {
    choices?: Array<IdName>
}

export const useRecommendedCarDefaultProductHooks = (props: RecommendedCarDefaultProductHookProps) => {
    const classes = useLocalStyles();
    const dataProvider = useDataProvider();
    const [productCarGradeMountSettingWithOtherData, setProductCarGradeMountSettingWithOtherData] = useState<ApiProductCarGradeMountSettingWithOtherData>()
    const [tabValue, setTabValue] = React.useState(0);
    const [categories, setCategories] = useState<CategoryProducts[]>()

    useImperativeHandle(props.ref, () => ({
        async save(recommendedCarId: number, defaultProductIds: number[]) {
            if (defaultProductIds.length === 0) {
                await dataProvider.create('recommendedCarDefaultProducts/' + recommendedCarId, {
                    data: {
                        data: undefined,
                        id: 1,
                    }
                })
                return;
            } else {
                const dataArray = new Array<{ productId: number, recommendedCarId: number }>()
                const selectedValues = defaultProductIds;
                if (!selectedValues || !selectedValues.length) {
                    return;
                }
                selectedValues.map((value) => {
                    dataArray.push(
                        {
                            recommendedCarId: recommendedCarId,
                            productId: value,
                        }
                    )
                })
                await dataProvider.create('recommendedCarDefaultProducts/' + recommendedCarId, {
                    data: {
                        data: dataArray,
                        id: 1,
                    }
                })
            }
        },
    }));


    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const loadTabValues = () => {
        (() => {
            if (tabValue) {
                const data = productCarGradeMountSettingWithOtherData?.data?.find((d) => d.categoryParent?.id === tabValue)?.categoryParent?.categories as Array<CategoryProducts>;
                data?.map((category) => {
                    const choices = new Array<IdName>();
                    category.carGrades?.map((carGrade) => {
                        carGrade.products?.map((product) => {
                            if (choices.find((choice) => choice.id === product.id) === undefined) {
                                choices.push({
                                    id: product.id!!,
                                    name: product.name,
                                });
                            }
                        })
                    })
                    category.choices = choices;
                })
                setCategories(data);
            }
        })();
    }

    const loadData = () => {
        (async () => {
            if (props.carGradeIds === undefined)
                return;
            props.setOpen(true);
            console.log(props)

            const data = await dataProvider.getList('recommendedCarDefaultProducts', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'createdAt', order: 'ASC'},
                filter: {
                    carGradeIds: [props.carGradeIds],
                    recommendedCarId: props.recommendedCarId
                },
            });

            let fetched = data as ApiProductCarGradeMountSettingWithOtherData
            setProductCarGradeMountSettingWithOtherData(fetched);

            const initialValues = new Array<number>();
            fetched.data?.map((parent) => parent.categoryParent?.categories?.map((category) => category.carGrades?.map((carGrade) => carGrade.products?.map((product) => {
                if (product.checked)
                    initialValues.push(product.id!!);
            }))));
            props.setProductInitialValues(initialValues);
            props.setOpen(false);
            props.setDefaultProductIds(initialValues);
        })();
    }

    useEffect(loadData, [props.carGradeIds, props.recommendedCarId])
    useEffect(loadTabValues, [tabValue, props.carGradeIds])

    const onChangeCheckBox = (e: any) => {
        props.setDefaultProductIds(e)
    }

    const findProductById = (productId: number): ApiProduct => {
        let apiProduct: ApiProduct = {
            name: "",
            productCategoryId: 1,
            backboneCoreSystemCode: "",
            onlyWithCar: false,
            price: 0,
            priceForPartsOnly: 0,
            cost: 0,
            originalPrice: 0,
            structuralProcedureFee: 0,
            alignmentAdjustingFee: 0,
            isSet: false,
            forNew: false,
            forUsed: false,
            position: 0,
            whetherPublic: false,
        }
        categories?.map((category) =>
            category.carGrades?.map((carGrade) =>
                carGrade.products?.forEach((product) => {
                    if (productId == product.id!!) {
                        apiProduct = product
                    }
                })
            )
        )
        return apiProduct
    }

    const onChangeRadioButton = async (e: any) => {
        const selectProduct = findProductById(e.target.value)
        let productIds: number[] = []
        let deletedProductId = 0
        if (props.defaultProductIds !== undefined)
            productIds = props.defaultProductIds

        categories?.map((category) => {
            if (category.choices !== null) {
                category.choices?.map((choice) => {
                    const product = findProductById(choice.id)
                    if (product.productCategoryId === selectProduct.productCategoryId) {
                        const index = productIds.indexOf(choice.id, 0)
                        if (index !== -1) {
                            deletedProductId = choice.id
                        }
                    }
                })
            }
        })

        if (selectProduct.id != null) {
            productIds.push(selectProduct.id)
            if (selectProduct.id !== deletedProductId) {
                const index = productIds.indexOf(deletedProductId, 0)
                if (index !== -1) {
                    productIds.splice(index, 1)
                }
            }
        }
        productIds = [...new Set(productIds)]

        setCategories(categories)
        props.setDefaultProductIds(productIds)
    }

    const remove = (product: ApiProduct) => {
        let productIds: number[] = []
        if (props.defaultProductIds !== undefined)
            productIds = props.defaultProductIds;
        const index = productIds.indexOf(product.id!!, 0)
        if (index !== -1) {
            productIds.splice(index, 1);
        }
        props.setDefaultProductIds(productIds);
    }

    const globalClasses = useStyles();

    return {
        classes,
        globalClasses,
        tabValue,
        productCarGradeMountSettingWithOtherData,
        categories,
        handleTabChange,
        onChangeCheckBox,
        onChangeRadioButton,
        remove,
    }
}
