import React from "react";
import {
    AutocompleteInput,
    BooleanField,
    Button,
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    TextField,
    useRecordContext,
} from "react-admin";
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";
import {Box, Grid} from "@material-ui/core";
import {DeleteWithConfirmIconButton} from "@react-admin/ra-editable-datagrid";
import {Link} from "react-router-dom";
import {MyPagination, PER_PAGE} from "../../common/MyPagination";

export const multipleSelectionMethods = [
    {id: '', name: '2本ずつ設定不可'},
    {id: 'leftAndRight', name: '左右で選択'},
    {id: 'frontAndRear', name: '前後で選択'}
];

const VariantClassesListComponent: React.FC<any> = (props) => {
    return (
        <List
            {...props}
            filters={<VariantFilter/>}
            hasCreate
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            className={'filterForBox'}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <Datagrid className={'productsList'}>
                <ReferenceField
                    label={'規格名'}
                    source="variantId"
                    reference="variants"
                    link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="name" label={'規格分類名'}/>
                <BooleanField source="affectJNumberAndInset" label={'J数・インセットに影響'}/>
                <BooleanField source="affectImage" label={'画像に影響'}/>
                <BooleanField source="suspension" label={'サスペンション'}/>
                <BooleanField source="affectPrice" label={'価格に影響'}/>
                <BooleanField source="wheelSize" label={'ホイールサイズ'}/>
                <MultipleSelectionMethodFieldForJapanese source="multipleSelectionMethod" label={'2本ずつ選択方法'}/>
                <TextFieldWithCommas source="position" label={'表示順序'} textAlign={'right'}/>
                <div className={'actionButtons'}>
                    <EditDataBtn {...props}/>
                    <DeleteDataBtn className={'ml-30 mr-30'} {...props} confirmTitle={'削除'}
                                   confirmContent={'削除しても宜しいでしょうか。'}/>
                    <UrlLinkVariantItemsBtn source="id"/>
                </div>
            </Datagrid>
        </List>
    );
};

const UrlLinkVariantItemsBtn = ({...source}) => {
    const record = useRecordContext();
    return (
        <Button
            label={'規格項目編集'}
            variant="contained"
            color={'default'}
            component={Link}
            to={{
                pathname: `/variantClasses/${record['id']}/show`
            }}
            className={'mr-30'}
        />
    );
};

const VariantFilter: React.FC<any> = (props) => {
    return (
        <Filter {...props}>
            <FilterForm {...props} alwaysOn/>
        </Filter>
    )
};

const FilterForm: React.FC<any> = (props) => {
    return (
        <Box bgcolor="white" p={2} id='box' className={'filterBox'} mt={2}>
            <Grid container={true} spacing={1} className={'w-100'}>
                <Grid item={true} xs={8}>
                    <ReferenceInput label={'規格名'} source="variantId" reference="variants">
                        <AutocompleteInput alwaysOn resettable={true}/>
                    </ReferenceInput>
                </Grid>
            </Grid>
        </Box>
    )
};

const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''}/>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};

const MultipleSelectionMethodFieldForJapanese = ({...source}) => {
    const record = useRecordContext();
    return (
        <>
            {record?.multipleSelectionMethod?.key !== null ?
                <span>{`${multipleSelectionMethods[multipleSelectionMethods.findIndex((item) => item.id.toString() === record.multipleSelectionMethod.key)].name.toString()}`}</span>
                :
                <span>2本ずつ設定不可</span>
            }
        </>
    )
};

export default VariantClassesListComponent;
