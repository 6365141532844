import React from "react";
import {SimpleForm, useDataProvider, useRedirect, useRefresh} from "react-admin";
import {ActionToolbar} from "../../common/ActionToolbar";
import {Grid} from "@material-ui/core";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {ApiRecommendedCarDefaultNonMasterProductToAdd} from "../../openapi";


interface RecommendedCarNonMasterDefaultProductFormProps extends Record<string, any> {
    recommendedCarId: number;
    id?: string;
}

const RecommendedCarNonMasterDefaultProductForm: React.FC<RecommendedCarNonMasterDefaultProductFormProps> = (props) => {
    const dataProvider = useDataProvider()
    const redirect = useRedirect();
    const refresh = useRefresh();
    const handleSave = async (values: ApiRecommendedCarDefaultNonMasterProductToAdd) => {
        const value: ApiRecommendedCarDefaultNonMasterProductToAdd = {
            ...values
        };
        if (props.id)
            value.id = Number(props.id);
        value.recommendedCarId = Number(props.recommendedCarId);

        await dataProvider.create(`recommendedCarDefaultNonMasterProducts/${props.recommendedCarId}`, {
            data: {
                ...values
            },
        })

        redirect(`/recommendedCars/${props.recommendedCarId}`);
        refresh();

    }

    return (
        <>
            <SimpleForm
                {...props}
                submitOnEnter={false}
                save={handleSave}
                toolbar={<ActionToolbar/>
                }
            >
                <Grid container={true}>
                    <Grid item={true}>
                        <TextInputForJapanese source={"name"} label={"商品名"} required={true}/>
                    </Grid>
                    <Grid item={true}>
                        <NumberInputCommon source={"price"} label={"売価"} required={true}/>
                    </Grid>
                    <Grid item={true}>
                        <NumberInputCommon source={"originalPrice"} label={"定価"} required={true}/>
                    </Grid>
                    <Grid item={true}>
                        <NumberInputCommon source={"cost"} label={"原価"} required={true}/>
                    </Grid>
                </Grid>


            </SimpleForm>
        </>
    )
}
export default RecommendedCarNonMasterDefaultProductForm
