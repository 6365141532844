import {DeleteWithConfirmIconButton} from '@react-admin/ra-editable-datagrid';
import React from 'react'
import {BooleanField, Datagrid, EditButton, List, TextField, useRecordContext} from 'react-admin';
import {MyPagination, PER_PAGE} from '../../common/MyPagination';

const BrandsList: React.FC<any> = (props: any) => {
    return (
        <div>
            <List
                {...props}
                hasCreate
                empty={false}
                sort={{field: 'position', order: 'ASC'}}
                exporter={false}
                bulkActionButtons={false}
                perPage={PER_PAGE}
                pagination={<MyPagination/>}
            >
                <Datagrid>
                    <TextField source="name" label={'名称'}/>
                    <TextField source="position" label={'表示順序'}/>
                    <TextField source="companyName" label={'会社名'}/>
                    <TextField source="postCode" label={'郵便番号'}/>
                    <TextField source="address1" label={'都道府県'}/>
                    <TextField source="address2" label={'市区町村'}/>
                    <TextField source="address3" label={'番地、建物・マンション名'}/>
                    <TextField source="tel" label={'電話番号'}/>
                    <TextField source="fax" label={'FAX'}/>
                    <BooleanField source="vrarva" label={'vrarva'}/>
                    <div className={'actionButtons'}>
                        <EditDataBtn {...props} />
                    </div>
                </Datagrid>
            </List>
        </div>
    )
};

const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''}/>);
};


export default BrandsList