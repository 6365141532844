import React, {Dispatch, SetStateAction} from "react";
import {DataProviderProxy} from "ra-core";
import SortableImageList from "../../common/image/SortableImageList";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {ResourceNames} from "../../config/const";
import {useCarColorImageHooks} from "./CarColorImageHooks";

export interface CarColorImageProps {
    carColorImageClass: number;
    carColorId?: number;
    dataProvider: DataProviderProxy;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData: TempFileData[];
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    images: ListPhotosType[] | undefined;
}

const CarColorImage: React.FC<CarColorImageProps> = (props) => {
    useCarColorImageHooks(props);
    return (
        <>
            <SortableImageList imageArray={props.images}
                               dataProvider={props.dataProvider}
                               resource={ResourceNames.CAR_COLOR_IMAGE_RESOURCE}
                               isUpdating={!!props.carColorId}
                               setPathData={props.setPathData}
                               pathData={props.pathData}
                               setImages={props.setImages}
                               imageSourceName={ResourceNames.CAR_COLOR_IMAGE_RESOURCE + props.carColorImageClass.toString()}
                               required={false}
            />
        </>
    );
}

export default CarColorImage
