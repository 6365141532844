import React from "react";
import {TextInput, useRecordContext} from "react-admin";
import {useForm} from "react-final-form";
import {Grid, Paper} from "@material-ui/core";

interface JNumberProps {
    jNumberOfFrontWheel: string | undefined;
    insetOfFrontWheel: string | undefined;
    jNumberOfRearWheel: string | undefined;
    insetOfRearWheel: string | undefined;
    displayAutoJNumber: boolean;
}

const JNumberAndInsetComponent: React.FC<JNumberProps> = (props) => {
    const form = useForm();

    if(props.displayAutoJNumber){
        form.change("jnumberOfFrontWheel", nullProps(props.jNumberOfFrontWheel));
        form.change("insetOfFrontWheel", nullProps(props.insetOfFrontWheel));
        form.change("jnumberOfRearWheel", nullProps(props.jNumberOfRearWheel));
        form.change("insetOfRearWheel", nullProps(props.insetOfRearWheel));
    }

    return (
        <>
            <Grid item spacing={3} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                <h3>J数・インセット</h3>
            </Grid>
            <Paper>
                <div className={'ml-20'}>
                    <Grid container>
                        <Grid item xs={6}>
                            <TextInput source={'jnumberOfFrontWheel'} label={'J数_前輪'}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source={'jnumberOfRearWheel'} label={'J数_後輪'}/>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <TextInput source={'insetOfFrontWheel'} label={'インセット_前輪'}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source={'insetOfRearWheel'} label={'インセット_後輪'}/>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </>
    );
}

const nullProps = (str: string | undefined | number) => {
    if (typeof str === 'number') {
        return str;
    }
    return '不明';
}

export default JNumberAndInsetComponent;