import {Box} from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from "@material-ui/core/styles";

const defaultProps = {
    imageURL: '',
    lg: 2,
    message: '削除しても宜しいでしょうか。',
};

interface BrandImageProps {
    imageURL: string;
    openImage: Function;
    id: number;
    imgDir: string;
    message?: string;
}

const useStyles = makeStyles(
    {
        image: {
            margin: '0.5rem',
            maxHeight: '10rem',
            background: 'rgba(0, 0, 0, 0.09)',
            maxWidth: '100%',
        },
        imageBox: {
            position: 'relative',
        },
        zoom: {
            display: 'center',
            position: 'absolute',
            top: '0%',
            left: '0%',
            color: 'blue',
        }
    },
);


const BrandsImages = (props: BrandImageProps) => {
    const classes = useStyles(props);
    return (
        <Box p={'5px'} px={1} className={classes.imageBox}>
            <img src={`${props.imageURL ?? ''}`} title="imageURL" className={classes.image} alt={'imageURL'}/>
            <IconButton className={classes.zoom}>
                <ZoomInIcon onClick={() => props.openImage(props.imageURL)}/>
            </IconButton>
        </Box>
    );
};

BrandsImages.defaultProps = defaultProps;

export default BrandsImages;
