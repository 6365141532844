import React, {Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState} from "react";
import {CheckboxGroupInput, ReferenceArrayInput, required, useDataProvider} from "react-admin";
import {ApiCompleteCarSelectableCarGrades} from "../../openapi";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

export interface SelectableCarGradeFunctions {
    save: (completeCarId: number, selectedValues: number[]) => void;
}

interface SelectableCarGradeProps {
    completeCarId?: number;
    filterCarGrade: { carModelId: number };
    setGradeIds: () => void;
    setLoadedFlag: Dispatch<SetStateAction<boolean>>
}

const SelectableCarGrade: React.ForwardRefRenderFunction<SelectableCarGradeFunctions, SelectableCarGradeProps> = (props, ref) => {
    const dataProvider = useDataProvider();
    const [initialValues, setInitialValues] = useState<number[]>();

    useImperativeHandle(ref, () => (
        {
            async save(completeCarId, selectedValues) {
                const dataArray = new Array<{ carGradeId: number, completeCarId: number }>();
                selectedValues.map((value) => {
                    dataArray.push(
                        {
                            completeCarId: completeCarId,
                            carGradeId: value,
                        }
                    )
                });
                await dataProvider.create(`completeCarSelectableCarGrades/${completeCarId}`, {
                    data: {
                        data: dataArray,
                        id: completeCarId,
                    }
                })
            }
        }
    ));

    useEffect(() => {

        const awaitFn = async () => {
            if (props.completeCarId === undefined) {
                return;
            }

            const data = await dataProvider.getList('completeCarSelectableCarGrades', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'createdAt', order: 'ASC'},
                filter: {
                    completeCarId: props.completeCarId,
                },
            });

            const fetched = data as ApiCompleteCarSelectableCarGrades
            const initialValue = new Array<number>();
            fetched.data?.map((d) => {
                initialValue.push(d.carGradeId);
            })
            setInitialValues(initialValue);
            props.setLoadedFlag(true);
        }
        awaitFn().then();

    }, [props.completeCarId])
    return (
        <>
            <ReferenceArrayInput
                validate={[required()]}
                source={'selectableCarGrades'}
                reference={'carGrades'}
                filter={props.filterCarGrade}
                psort={{field: 'position', order: 'ASC'}}
                initialValue={initialValues}
                label={'選択可能グレード'}
                onChange={props.setGradeIds}
            >
                <CheckboxGroupInput
                    optionValue={'id'}
                    optionName={'name'}
                />
            </ReferenceArrayInput>
        </>
    )
}

export default forwardRef(SelectableCarGrade);
