import {Box} from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const defaultProps = {
    imageURL: '',
    lg: 2,
    message: '削除しても宜しいでしょうか。',
};

interface ProductImageProps {
    imageURL: string;
    openImage: Function;
    deleteImage: Function;
    id: number;
    path: string;
    imgDir: string;
    thumbPath: string;
    productId: number;
    message?: string;
}

const useStyles = makeStyles(
    {
        image: {
            margin: '0.5rem',
            maxHeight: '10rem',
        },
        imageBox: {
            position: 'relative',
        },
        delete: {
            display: 'center',
            position: 'absolute',
            top: '0%',
            left: '70%',
            color: 'red',
        },
        zoom: {
            display: 'center',
            position: 'absolute',
            top: '0%',
            left: '0%',
            color: 'blue',
        }
    },
);


const ProductsImages = (props: ProductImageProps) => {
    const classes = useStyles(props);
    const deleteTarget = async () => {
        if (window.confirm(props.message)) {
            await props.deleteImage(props.id, props.path, props.imgDir, props.productId, props.thumbPath, props.imageURL);
        }
    }
    return (
        <Box p={'5px'} px={1} className={classes.imageBox}>
            <img src={`${props.imageURL}`} title="imageURL" className={classes.image} alt={'imageURL'}/>
            <IconButton className={classes.zoom}>
                <ZoomInIcon onClick={() => props.openImage(props.imageURL)}/>
            </IconButton>
            <IconButton className={classes.delete}>
                <HighlightOffIcon onClick={deleteTarget}/>
            </IconButton>
        </Box>
    );
};

ProductsImages.defaultProps = defaultProps;

export default ProductsImages;
