export class SimpleLogger {
    private loggerID = Math.random()
        .toString(32)
        .slice(2, 6);

    constructor(private prefix: string, private debug: boolean) {
    }

    public get log() {
        if (!this.debug) {
            return (any: any, p?: any) => {
            };
        }
        const boundLogFn: (any: any) => void = console.log.bind(
            console,
            this.getLogString()
        );
        return boundLogFn;
    }

    public get warn() {
        if (!this.debug) {
            return (any: any) => {
            };
        }
        const boundLogFn: (any: any) => void = console.warn.bind(
            console,
            this.getLogString()
        );
        return boundLogFn;
    }

    public get error() {
        if (!this.debug) {
            return (any?: any, p?: { csvValues: any[] }, error?: any) => {
            };
        }
        const boundLogFn: (any: any) => void = console.error.bind(
            console,
            this.getLogString()
        );
        return boundLogFn;
    }

    setEnabled(logging: boolean) {
        this.debug = logging;
    }

    private getLogString() {
        return `🌟 react-admin-import-csv:: ${this.prefix} [${this.loggerID}] `;
    }
}
