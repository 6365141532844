import React from "react";
import {
    AutocompleteInput,
    BooleanInput,
    maxLength,
    RadioButtonGroupInput,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    useRecordContext
} from "react-admin";
import {Grid} from "@material-ui/core";
import useCarGrade from "./carGradeHook";
import {CarGradesLabel} from "./CarGradesListComponent";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";
import {Box} from "@mui/material";
import useSetting from "../setting/SettingHooks";

const CarGradesForm: React.FC = (props: any) => {
    const record = useRecordContext();
    const validateName = [required(), maxLength(50)];
    const validateCarType = [required(), maxLength(40)];
    const {carModel, car, setForUsed, forUsed, validateFormCreation} = useCarGrade(record['carModelId'], record);

    const {setting} = useSetting() ?? {};

    return (
        <>
            {carModel && car &&
            <>
                <CarGradesLabel carModel={carModel}/>
                <SimpleForm
                    validate={validateFormCreation}
                    {...props}
                    className={'w-100'}
                    toolbar={<ActionToolbar/>}
                    submitOnEnter={false}
                >
                    <Grid container spacing={0} className={'w-100 mt-30'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <TextInputForJapanese source="name" validate={validateName} fullWidth label={'グレード名'}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={'w-100 mt-30'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <TextInputForJapanese
                                source="carType"
                                validate={validateCarType}
                                fullWidth label={'型式'}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <NumberInputCommon source={'price'} label={'売価'}/>
                        </Grid>
                        <span className={'mt-20 ml-42'}>中古車 売価</span>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'priceUsed1'}
                                               label={setting.data?.usedMileage1 ? setting.data.usedMileage1 : ''}
                                               isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'priceUsed2'}
                                               label={setting.data?.usedMileage2 ? setting.data.usedMileage2 : ''}
                                               isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'priceUsed3'}
                                               label={setting.data?.usedMileage3 ? setting.data.usedMileage3 : ''}
                                               isRequired={false}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <NumberInputCommon source={'cost'} label={'原価'}/>
                        </Grid>
                        <span className={'mt-20 ml-42'}>中古車 定価</span>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'originalPriceUsed1'}
                                               label={setting.data?.usedMileage1 ? setting.data.usedMileage1 : ''}
                                               isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'originalPriceUsed2'}
                                               label={setting.data?.usedMileage2 ? setting.data.usedMileage2 : ''}
                                               isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'originalPriceUsed3'}
                                               label={setting.data?.usedMileage3 ? setting.data.usedMileage3 : ''}
                                               isRequired={false}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <NumberInputCommon source={'originalPrice'} label={'定価'}/>
                        </Grid>
                        <span className={'mt-20 ml-42'}>中古車 原価</span>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'costUsed1'}
                                               label={setting.data?.usedMileage1 ? setting.data.usedMileage1 : ''}
                                               isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'costUsed2'}
                                               label={setting.data?.usedMileage2 ? setting.data.usedMileage2 : ''}
                                               isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={11} sm={3} md={2} lg={2} className={'ml-10'}>
                            <NumberInputCommon source={'costUsed3'}
                                               label={setting.data?.usedMileage3 ? setting.data.usedMileage3 : ''}
                                               isRequired={false}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <NumberInputCommon source={'position'} label={'表示順序'}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <NumberInputCommon
                                source={'displacement'}
                                label={'排気量'}
                                isDouble={true}
                            />
                        </Grid>
                        <span className={'mt-25 ml-5'}>L</span>
                        <span className={'d-flex mt-5'}>
                    <span className={'mt-20 ml-30'}>自動車税区分</span>
                    <span className={'ml-10'}>
                        <ReferenceInput
                            label=""
                            reference="autoMobileTaxClasses"
                            source="autoMobileTaxClassId"
                            validate={[required()]}
                            fullWidth
                            sort={{field: 'id', order: 'ASC'}}
                            perPage={MAX_FETCH_DATA_SIZE}
                        >
                            <RadioButtonGroupInput source="name"/>
                        </ReferenceInput>
                </span>
                    </span>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <ReferenceInput
                                label="環境性能割"
                                reference="taxByEnvironmentalPerformances"
                                source="taxByEnvironmentalPerformanceId"
                                validate={[required()]}
                                fullWidth
                                sort={{field: 'name', order: 'ASC'}}
                                perPage={MAX_FETCH_DATA_SIZE}
                            >
                                <AutocompleteInput source="name" resettable={true}
                                />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <ReferenceInput
                                label="エコカー減税"
                                reference="ecoCarTaxCuts"
                                source="ecoCarTaxCutId"
                                fullWidth
                                validate={[required()]}
                                perPage={MAX_FETCH_DATA_SIZE}
                                sort={{field: 'name', order: 'ASC'}}>
                                <AutocompleteInput source="name" resettable={true}/>
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid xs={11} sm={3} md={2} lg={2}>
                            <NumberInputCommon source={'weight'} label={'重量'}/>
                        </Grid>
                        <span className={'mt-25 ml-5'}>kg</span>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid xs={11} sm={3} md={2} lg={2}>
                            <NumberInputCommon source={'payload'} isRequired={false} label={'最大積載量'}/>
                        </Grid>
                        <span className={'mt-25 ml-5'}>kg</span>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <NumberInputCommon source={'deadline'} label={'納期'}/>
                        </Grid>
                        <span className={'mt-25 ml-5'}>日後</span>
                    </Grid>
                    <Grid container spacing={0} className={'w-100'}>
                        <Grid item xs={11} sm={3} md={2} lg={2}>
                            <ReferenceInput
                                label="自賠責保険料区分"
                                reference="carInsuranceClasses"
                                source="carInsuranceClassId"
                                validate={[required()]}
                                fullWidth
                                perPage={MAX_FETCH_DATA_SIZE}
                                sort={{field: 'name', order: 'ASC'}}>
                                <AutocompleteInput source="name" resettable={true}/>
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                    <TextInput source="carModelId"
                               defaultValue={`${carModel.id!!}`}
                               label={''}
                               disabled={true}
                               fullWidth
                               className={'displayNone'}
                    />
                    <Box display={'flex'}>
                        <BooleanInput
                            defaultValue={!record?.id ? true : record?.forNew}
                            parse={(val: boolean)=>val}
                            source="forNew"
                            label="新車"
                        />
                        <BooleanInput
                            defaultValue={!record?.id ? true : record?.forUsed}
                            parse={(val: boolean)=>{
                                setForUsed(val)
                                return val
                            }}
                            source="forUsed"
                            label="中古"
                        />
                    </Box>
                </SimpleForm>
            </>
            }
        </>
    );
};

export default CarGradesForm;
