const PaymentMethod = {
    cash:
        {
            value: 1,
            labelWithCar: '全額現金',
            labelPartsOnly: '全額現金',
        },
    fullLoan: {
        value: 2,
        labelWithCar: '全額オートローン',
        labelPartsOnly: '全額ローン',
    },
    someLoan: {
        value: 3,
        labelWithCar: '一部オートローン',
        labelPartsOnly: '一部ローン',
    },
    lease: {
        value: 4,
        labelWithCar: 'オートリース',
        labelPartsOnly: 'リース',
    },
};
export default PaymentMethod;