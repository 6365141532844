import {
    Create,
    Edit,
    maxLength,
    required,
    SimpleForm,
    useRedirect,
    useRefresh,
} from "react-admin";
import React from "react";
import Grid from '@material-ui/core/Grid';
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";

const validateName = [required(), maxLength(50)];
const validateBody = [required(), maxLength(1000)];
let typeStatus = true;

const TemplateForm = (props: any) => (
    <SimpleForm {...props}
                submitOnEnter={props.type !== 'create'}
                toolbar={<ActionToolbar isTemplates={true}/>}>
        {props.type === "create" ? (typeStatus = false) : (typeStatus = true)}
        <Grid container={true} className={'w-100'}>
            <Grid item={true} xs={12} sm={3} md={3} lg={2} alignItems={'center'} className={'d-flex'}>
                タイトル
            </Grid>
            <Grid item={true} xs={12} sm={8} md={8} lg={9} alignItems={'center'} className={'d-flex'}>
                <TextInputForJapanese
                    source={'name'}
                    validate={validateName}
                    record={props.record}
                    resource={props.resource}
                    basePath={props.basePath}
                    className={'sm-input'}
                    label={false}/>
            </Grid>
            <Grid item={true} xs={12} sm={3} md={3} lg={2} alignItems={'center'} className={'d-flex'}>
                備考
            </Grid>
            <Grid item={true} xs={12} sm={8} md={8} lg={9} alignItems={'center'} className={'d-flex'}>
                <TextInputForJapanese
                    source={'body'}
                    validate={validateBody}
                    record={props.record}
                    resource={props.resource}
                    basePath={props.basePath}
                    rows={'10'}
                    fullWidth={true}
                    multiline={true}
                    label={false}
                />
            </Grid>
        </Grid>
    </SimpleForm>
);

const ActionTopToolbar = () => (
    <div className={'mb-30'}>
        <br/>
    </div>
);

export const TemplateCreate: React.FC = (props: any) => {
    const redirect = useRedirect();
    const refresh = useRefresh();

    return (
        <Create actions={<ActionTopToolbar/>} {...props} onSuccess={() => {
            redirect('/templates');
            refresh();
        }}>
            <TemplateForm type="create"/>
        </Create>
    );
};

export const TemplateEdit: React.FC = (props: any) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    return (
        <Edit actions={<ActionTopToolbar/>} {...props} mutationMode={typeStatus && 'optimistic'}
              onSuccess={() => {
                  redirect('/templates');
                  refresh();
              }}>
            <TemplateForm type="edit"/>
        </Edit>
    );
};
