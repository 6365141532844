import {Button, FileField, FileInput, useRefresh} from "react-admin";
import React, {useEffect, useState} from "react";
import {useForm} from "react-final-form";
import Alert from '@material-ui/lab/Alert';
import {useExportHooks} from "./exportHooks";


interface VariantProductPriceProps {
    productId: number;
}

const VariantItemProductPriceCsvImportComponent: React.FC<VariantProductPriceProps> = (props) => {
    const form = useForm();
    const [importCsv, setImportCsv] = useState(false);
    const [error, setError] = useState<Array<string>>([]);
    const {
        handleUpload,
    } = useExportHooks({
        productId: props.productId,
        form: form,
        setImportCsv: setImportCsv,
        setError: setError,
    });

    return (
        <>
            {!importCsv &&
                <Button variant="contained"
                        color="default"
                        className={'ml-30'}
                        onClick={() => setImportCsv(true)}
                        label={'CSVインポート'}/>
            }
            {importCsv &&
                <FileInput source="files"
                           label="CSVインポート"
                           accept="text/csv"
                           onChange={handleUpload}>
                    <FileField source="src"
                               title="CSVインポート"/>
                </FileInput>
            }
            {error && error.length > 0 &&
                <Alert severity="error">
                    <ul>
                        {error.map((value) => (
                            <li>{value}</li>
                        ))}
                    </ul>
                </Alert>
            }
        </>
    );
}

export default VariantItemProductPriceCsvImportComponent;
