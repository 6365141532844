import {AuthProvider} from 'react-admin';
import {AUTH_KEY, ROLE_KEY} from "./config/const";
import jwtDecode from "jwt-decode";

const authProvider: AuthProvider = {
    checkAuth(params: any): Promise<void> {
        if (localStorage.getItem(AUTH_KEY)) {
            const {accessToken} = JSON.parse(localStorage.getItem(AUTH_KEY)!);
            if (accessToken && accessToken !== 'undefined') {
                return Promise.resolve()
            } else {
                return Promise.reject()
            }
        } else {
            return Promise.reject()
        }
    }, checkError(error: any): Promise<void> {
        const status = error.status
        if (status === 401 || status === 403) {
            localStorage.removeItem(AUTH_KEY)
            return Promise.reject()
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve()
    }, getPermissions(params: any): Promise<any> {
        const role = localStorage.getItem(ROLE_KEY);
        return role ? Promise.resolve(role) : Promise.reject();
    }, logout(params: any): Promise<void | false | string> {
        localStorage.removeItem(AUTH_KEY);
        localStorage.removeItem(ROLE_KEY);
        return Promise.resolve(undefined);
    },
    login: ({email, password}) => {
        const request = new Request(`${process.env.REACT_APP_API_BASE_PATH}/v1/login`, {
            method: 'POST',
            body: JSON.stringify({email, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((result) => {
                const {accessToken} = result;
                if (!accessToken) {
                    throw new Error('invalid login information');
                }
                const decodedToken = jwtDecode<{ roles: string, sub: string, iat: number, exp: number }>(accessToken);
                const {roles} = decodedToken;
                localStorage.setItem(AUTH_KEY, JSON.stringify(result));
                localStorage.setItem(ROLE_KEY, roles);
            });
    }
}

export default authProvider
