import {Identifier} from "react-admin";

export const replaceArrayElements = <T>(array: T[], targetId: number, sourceId: number) => {
    array[targetId] = [array[sourceId], array[sourceId] = array[targetId]][0];
    return array;
};

export interface PreviousData {
    index: number;
    previousPosition: number;
}

export interface ListPhotosType {
    id: Identifier;
    position: number;
    path: string;
    url: string;
    bigImageUrl?: string;
}

// only pass array to auto position the array(this is useful for deleting image)
// pass array, index, value when clicking onBeforeClick() and onAfterClick
// pass array, index, value, and prevData when position array using textField
export const arraySort = <T extends { position: number }>
(defaultArray: T[], index?: number, value?: number, prevData?: PreviousData) => {

    let array = new Array<T>();
    defaultArray.map((d) => array.push(d));
    if (prevData && prevData.previousPosition !== value) {
        array[index!].position = prevData.previousPosition;
    }
    value = value! > array.length ? array.length : value;
    value = value! < 1 ? 1 : value;
    const duplicateIndex = array.sort((a, b) => a.position - b.position).findIndex((item) => item.position === value);
    // this will run if sort buttons are pressed(onBeforeClick() or onAfterClick())
    // this will also run sometimes when user enters data in textField
    // if the value is greater or less than 1 the original sort
    if (duplicateIndex + 1 === index || duplicateIndex - 1 === index) {
        array[duplicateIndex].position = array[index].position;
        array[index].position = value!;
    }
    // this will run if user enters data in text field
    if (prevData && !(duplicateIndex + 1 === index || duplicateIndex - 1 === index)) {
        if (index! < duplicateIndex) {
            for (let i = duplicateIndex; i >= index!; i--) {
                array[i].position = array[i].position - 1 !== 0 ? array[i].position - 1 : 1;
            }
        } else {
            for (let i = duplicateIndex; i < index!; i++) {
                array[i].position = array[i].position + 1;
            }
        }
        array[index!].position = value!;
    }
    // this will run if the user will delete an image from sortableImageList
    if (!value && !index) {
        array = array.sort((a, b) => a.position - b.position);
        for (let i = 0; i < array.length; i++) {
            array[i].position = i + 1;
        }
    }
    return array.sort((a, b) => a.position - b.position);
};


export const reSort = <T extends { position: number }>(defaultArray: T[]) => {
    let array = new Array<T>();
    defaultArray.map((d) => array.push(d));
    array.map((a, i) => a.position = i + 1);
    return array;
};
