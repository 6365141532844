import {EditableDatagrid, RowForm} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {List, maxLength, minValue, number, required, TextField,} from 'react-admin';
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import NumberInputCommon from "../../common/NumberInputCommonComponent";

const CarShippingPlacesList: React.FC = (props: any) => {
    return (
        <List
            {...props}
            hasCreate
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <EditableDatagrid
                undoable={true}
                createForm={<DataForm/>}
                editForm={<DataForm/>}
                noDelete={false}
                className={'editableListTable'}
            >
                <TextField source="name" label={'納車場所名'}/>
                <TextField source="position" label={'表示順序'}/>
            </EditableDatagrid>
        </List>
    );
}
const DataForm: React.FC = (props) => {
    const validateName = [required(), maxLength(50)];
    const validateNumber = [required(), number(), minValue(0)];
    return (
        <RowForm {...props} >
            <TextInputForJapanese source="name" validate={validateName} label={'納車場所名'}/>
            <NumberInputCommon source="position" validate={validateNumber} label={'表示順序'}/>
        </RowForm>
    )
};

export default CarShippingPlacesList;
