import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import TableBody from "@material-ui/core/TableBody";
import {ApiCarGrade, ApiProduct, ApiProductVariantsAdmin, ApiVariantClass} from '../../openapi';
import {AppBar, Button, useDataProvider, useGetOne} from 'react-admin';
import {useParams} from "react-router-dom";
import {Form} from 'react-final-form';
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import JNumberAndInsetRecommendationListRecordComponent from "./JNumberAndInsetRecommendationListRecordComponent";
import useProductVariantHooks from "./productVariantHooks";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ReactPaginate from "react-paginate";
import Radio from '@material-ui/core/Radio';

interface ScreenNameProps {
    product: ApiProduct;
}

export const CheckFilledParameter = {
    type: ['お勧め入力済み', 'お勧め未入力', 'J数・インセット入力済み', 'J数・インセット未入力']
}

const JNumberAndInsetRecommendationListComponent: React.FC = () => {
    const dataProvider = useDataProvider()
    const [dataProductVariantAdmins, setDataProductVariantAdmins] = useState(new Array<ApiProductVariantsAdmin>())
    const [dataVariantClasses, setDataVariantClasses] = useState(new Array<ApiVariantClass>());
    const [carGrade, setCarGrade] = useState(new Array<ApiCarGrade>());
    const {productId, carModelId} = useParams<{ productId: string, carModelId: string }>();
    const [checkFilledParameter, setCheckFilledParameter] = useState<string>();
    const [checkCarGradeId, setCheckCarGradeId] = useState<number | null>();
    const [data, setData] = useState(new Array<ApiProductVariantsAdmin>());
    const [viewData, setViewData] = useState(new Array<ApiProductVariantsAdmin>());
    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [isCSVButtonHidden, setIsCSVButtonHidden] = useState(false);
    const {
        exportJNumberAndInsetRecommendationCsv,
        getVariantClasses,
        sortVariantClasses,
        handleSave,
    } = useProductVariantHooks({
        productId: Number(productId)
    });

    useEffect(() => {
        const fetchApiData = async () => {
            const variantClasses = await getVariantClasses()
            if (variantClasses.length !== 0) {
                setDataVariantClasses(variantClasses);
            }

            const carGrade = await dataProvider.getList('carGrades',
                {
                    pagination: {page: 1, perPage: 10000},
                    sort: {field: 'id', order: 'asc'},
                    filter: {carModelId: carModelId},
                });

            const fetchedCarGrades = carGrade.data as ApiCarGrade[]

            setCarGrade(fetchedCarGrades);
        };
        fetchApiData().then();
        setPerPage(10);
    }, []);

    useEffect(() => {
        const fetchApiData = async () => {
            setIsCSVButtonHidden(false)
            if (checkCarGradeId !== undefined) {
                if (checkCarGradeId !== null) {
                    setCheckFilledParameter("")
                    const productVariantsAdmins = await dataProvider.getList('productVariants/admin',
                        {
                            pagination: {page: 1, perPage: 10000},
                            sort: {field: 'id', order: 'asc'},
                            filter: {productId: productId, carGradeId: checkCarGradeId},
                        });
                    const fetchedAdmins = productVariantsAdmins.data as ApiProductVariantsAdmin[]
                    setDataProductVariantAdmins(fetchedAdmins);
                    setIsCSVButtonHidden(true)
                } else {
                    alert("データの絞り込みに失敗しました。")
                }
            }
        }
        fetchApiData().then();
    }, [checkCarGradeId])

    const handleCheckFilledRecommend = () => {
        if (dataProductVariantAdmins) {
            if (checkFilledParameter === CheckFilledParameter.type[0]) {
                setViewData(dataProductVariantAdmins.filter(productVariantAdmin => productVariantAdmin.recommendName != null && productVariantAdmin.recommendNote != null))
            } else if (checkFilledParameter === CheckFilledParameter.type[1]) {
                setViewData(dataProductVariantAdmins.filter(productVariantAdmin => productVariantAdmin.recommendName === null && productVariantAdmin.recommendNote === null))
            } else if (checkFilledParameter === CheckFilledParameter.type[2]) {
                setViewData(dataProductVariantAdmins.filter(productVariantAdmin => productVariantAdmin.jNumberOfFrontWheel !== null && productVariantAdmin.jNumberOfRearWheel !== null && productVariantAdmin.insetOfFrontWheel !== null && productVariantAdmin.insetOfRearWheel !== null))
            } else if (checkFilledParameter === CheckFilledParameter.type[3]) {
                setViewData(dataProductVariantAdmins.filter(productVariantAdmin => productVariantAdmin.jNumberOfFrontWheel === null && productVariantAdmin.jNumberOfRearWheel === null && productVariantAdmin.insetOfFrontWheel === null && productVariantAdmin.insetOfRearWheel === null))
            } else {
                setViewData(dataProductVariantAdmins);
            }
            setOffset(0)
        }
    }

    const handleChangeData = () => {
        if (viewData.length !== 0) {
            setData(viewData.slice(offset, offset + perPage))
        } else {
            setData(viewData)
        }
        setPageCount(Math.ceil(viewData.length / perPage));
    }

    useEffect(handleCheckFilledRecommend, [perPage, dataProductVariantAdmins, checkFilledParameter]);
    useEffect(handleChangeData, [offset, viewData])

    const returnTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const save = async (e: React.FormEvent<HTMLFormElement>) => {
        await handleSave(data!!, checkCarGradeId!!);
    }

    const csvExport = () => {
        exportJNumberAndInsetRecommendationCsv(checkCarGradeId!!)
    }

    const inputChanged = (value: { value: string }, id: { id: string }, columnName: string) => {
        let objIndex = data!!.findIndex((obj => obj.id!!.toString() === id.id));
        if (columnName === "jNumberOfFrontWheel") {
            data!![objIndex].jNumberOfFrontWheel = parseFloat(value.value);
        } else if (columnName === "jNumberOfRearWheel") {
            data!![objIndex].jNumberOfRearWheel = parseFloat(value.value);
        } else if (columnName === "insetOfFrontWheel") {
            data!![objIndex].insetOfFrontWheel = parseFloat(value.value);
        } else if (columnName === "insetOfRearWheel") {
            data!![objIndex].insetOfRearWheel = parseFloat(value.value);
        } else if (columnName === "recommendName") {
            data!![objIndex].recommendName = value.value
        } else if (columnName === "recommendNote") {
            data!![objIndex].recommendNote = value.value
        }

        console.log('ViewDataByRow: ' + JSON.stringify(data!![objIndex]))
    };

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * perPage) % dataProductVariantAdmins.length;
        setOffset(newOffset);
    };

    const ScreenName: React.FC<ScreenNameProps> = (props) => {
        return (
            <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        product={props.product}
                        isJNumberAndInset={true}
                    />
                </div>
            </AppBar>
        );
    }

    const product = useGetOne('products', productId).data as ApiProduct;

    const RadioFilledRecommend = ({...props}) => {
        return (
            <>
                <FormControlLabel
                    htmlFor={`${props.carId}~${props.id} 0`}
                    key={props.id + 0}
                    className={props.className}
                    control={
                        <Radio
                            id={`${props.carId}~${props.id} 0`}
                            color="primary"
                            checked={checkFilledParameter === CheckFilledParameter.type[0]}
                            onClick={() => {
                                setCheckFilledParameter(CheckFilledParameter.type[0])
                            }}
                        />
                    }
                    label={'お勧め入力済み'}
                />
                <FormControlLabel
                    htmlFor={`${props.carId}~${props.id} 1`}
                    key={props.id + 1}
                    className={props.className}
                    control={
                        <Radio
                            id={`${props.carId}~${props.id} 1`}
                            color="primary"
                            checked={checkFilledParameter === CheckFilledParameter.type[1]}
                            onClick={() => {
                                setCheckFilledParameter(CheckFilledParameter.type[1])
                            }}
                        />
                    }
                    label={'お勧め未入力'}
                />
                <FormControlLabel
                    htmlFor={`${props.carId}~${props.id} 2`}
                    key={props.id + 2}
                    className={props.className}
                    control={
                        <Radio
                            id={`${props.carId}~${props.id} 2`}
                            color="primary"
                            checked={checkFilledParameter === CheckFilledParameter.type[2]}
                            onClick={() => {
                                setCheckFilledParameter(CheckFilledParameter.type[2])
                            }}
                        />
                    }
                    label={'J数・インセット入力済み'}
                />
                <FormControlLabel
                    htmlFor={`${props.carId}~${props.id} 3`}
                    key={props.id + 3}
                    className={props.className}
                    control={
                        <Radio
                            id={`${props.carId}~${props.id} 3`}
                            color="primary"
                            checked={checkFilledParameter === CheckFilledParameter.type[3]}
                            onClick={() => {
                                setCheckFilledParameter(CheckFilledParameter.type[3])
                            }}
                        />
                    }
                    label={'J数・インセット未入力'}
                />
            </>
        );
    }

    const RadioGroupParent = ({...props}) => {
        if (!props.carGrade) {
            return null;
        }
        return (
            <div>
                <Grid container={true}>
                    <Grid item={true} xs={10}>
                        <FormControlLabel
                            htmlFor={`${props.carGrade.id}~${props.id}`}
                            key={props.id}
                            className={props.className}
                            onChange={() => {
                                if (props.carGrade.id === checkCarGradeId) {
                                    setCheckCarGradeId(null);
                                } else {
                                    setCheckCarGradeId(props.carGrade.id)
                                }
                            }}
                            control={
                                <Radio
                                    id={`${props.carGrade.id}~${props.id}`}
                                    color="primary"
                                    checked={checkCarGradeId === props.carGrade.id}
                                />
                            }
                            label={`${props.carGrade.name!!}`}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <Form onSubmit={save} render={({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
                <ScreenName product={product}/>
                <Grid container={true} className={'d-flex mt-15'}>
                    <Grid>
                        <Button label={'戻る'} color={'default'} variant="contained"
                                href={`/productVariants/${productId}`}/> &nbsp;
                        <Button label={'保存'} type={"submit"} variant="contained" color={"primary"}/> &nbsp;
                        {isCSVButtonHidden &&
                            <Button variant="contained" onClick={csvExport} color={'default'} label={'CSVエクスポート'}/>}
                    </Grid>
                </Grid>
                <Grid container={true} className={'d-flex mt-15'}>
                    {carGrade?.map((carGrade, index) => {
                        return (
                            <div className={'mb-30'}>
                                <RadioGroupParent carGrade={carGrade} id={index}/>
                            </div>
                        )
                    })}
                </Grid>
                <Grid container={true} className={'d-flex mt-15'}>
                    {checkCarGradeId !== undefined &&
                        <RadioFilledRecommend/>
                    }
                </Grid>
                <Paper className={'mt-15 b-shadow'}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">グレード名</TableCell>
                                    {sortVariantClasses(dataVariantClasses!!)
                                        .map((row: ApiVariantClass) => {
                                            return (
                                                <TableCell align="left" key={row.id}> {row.name} </TableCell>
                                            );
                                        })
                                    }
                                    <TableCell align="left">J数・インセット</TableCell>
                                    <TableCell align="left">お勧め情報</TableCell>
                                    <TableCell align="left">お勧め画像</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data!!.map((row, index) => {
                                    return (
                                        <JNumberAndInsetRecommendationListRecordComponent
                                            id={row.id!!}
                                            jNumberAndInsetAdvancedSettings={row.jNumberAndInsetAdvancedSettings!!}
                                            recommendName={row.recommendName}
                                            inputChanged={inputChanged}
                                            dataVariantClasses={dataVariantClasses!!}
                                            jNumberOfFrontWheel={row.jNumberOfFrontWheel!!}
                                            jNumberOfRearWheel={row.jNumberOfRearWheel!!}
                                            insetOfFrontWheel={row.insetOfFrontWheel!!}
                                            insetOfRearWheel={row.insetOfRearWheel!!}
                                            recommendNote={row.recommendNote}
                                            productId={Number(productId)}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <ReactPaginate className={"my-pagination"}
                                       pageCount={pageCount}
                                       breakLabel="..."
                                       nextLabel="次へ ->"
                                       onPageChange={handlePageClick}
                                       pageRangeDisplayed={3}
                                       previousLabel="<- 戻る"
                                       onClick={returnTop}
                        />
                    </TableContainer>
                </Paper>
                <Grid container={true} className={'d-flex mt-15'} justify={'flex-end'}>
                    <Grid>
                        <Button label={'保存'} type={"submit"} variant="contained" color="primary"/>
                    </Grid>
                </Grid>
            </form>
        )}/>
    );
};


export default JNumberAndInsetRecommendationListComponent;