import {DeleteWithConfirmIconButton,} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Datagrid, EditButton, List, ReferenceField, TextField, useRecordContext,} from 'react-admin';
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";
import {MyPagination, PER_PAGE} from "../../common/MyPagination";

const CarsList: React.FC = (props: any) => {
    return (
        <div>
            <List
                {...props}
                hasCreate
                empty={false}
                sort={{field: 'position', order: 'ASC'}}
                exporter={false}
                bulkActionButtons={false}
                perPage={PER_PAGE}
                pagination={<MyPagination/>}
            >
                <Datagrid>
                    <TextField source="name" label={''}/>
                    <ReferenceField
                        label="メーカー"
                        source="makerId"
                        reference="makers"
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextFieldWithCommas source="position" label={'表示順序'} textAlign={'right'}/>
                    <div className={'actionButtons'}>
                        <UrlLinkCarModelsBtn source="id"/>
                        <EditDataBtn {...props} />
                        <DeleteDataBtn className={'ml-30'} {...props} confirmTitle={'削除'}
                                       confirmContent={'削除しても宜しいでしょうか。'}/>
                    </div>
                </Datagrid>
            </List>
        </div>
    );
}

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};

const UrlLinkCarModelsBtn = ({...source}) => {
    const record = useRecordContext(source);
    return (
        <Button
            label={'モデル'}
            variant="contained"
            component={Link}
            to={{
                pathname: `/carModels/list/${record[source.source]}`
            }}
            className={'mr-30'}
        />
    );
}

const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''}/>);
};
export default CarsList;
