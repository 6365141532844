import {useDataProvider} from "react-admin";
import React, {useState} from "react";
import {ApiVariantClass} from "../../openapi/model/api-variant-class";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {ApiVariantItem} from "../../openapi";

const useVariantClasses = () => {
    const dataProviders = useDataProvider();
    const [variantClasses, setVariantClasses] = useState<ApiVariantClass>();
    const [variantItems, setVariantItems] = useState<ApiVariantItem[]>();

    const getByVariantClassesId = async (variantClassesId: number) => {
        const fetchedData = await dataProviders.getOne('variantClasses', {id: variantClassesId});
        const data = fetchedData.data as ApiVariantClass;
        setVariantClasses(data);
    }

    const getVariantItemsByVariantClassesId = async (variantClassesId: number) => {
        const variantItems = await dataProviders.getList('variantItems',{
            pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
            sort: {field: 'position', order: 'asc'},
            filter: {variantClassId: variantClassesId}
        });
        const array: React.SetStateAction<ApiVariantItem[]> = [];
        variantItems.data.map((data) => {
            array.push(data as ApiVariantItem);
        });
        setVariantItems(array);
    }


    return {
        variantClasses,
        getByVariantClassesId,
        variantItems,
        getVariantItemsByVariantClassesId
    }
}

export default useVariantClasses;
