import {Box} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import {SortBoxType} from './SortBox';
import {makeStyles} from "@material-ui/core/styles";
import {DataProviderProxy} from "react-admin";
import {deleteImage} from "./databaseImageUtils";
import {ListPhotosType} from "./arrayUtil";

const defaultProps = {
    imageURL: '',
    lg: 2,
    message: '削除しても宜しいでしょうか。',
};

export interface SortableImageProps {
    children: any;
    imageURL?: string;
    sortBoxProps?: SortBoxType;
    image?: string;
    className?: string;
    dataProvider: DataProviderProxy;
    resource: string;
    id: number;
    path: string;
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    imageArray: ListPhotosType[];
    message?: string;
    bigImageUrl?: string;
    setImageId?: Dispatch<SetStateAction<number | undefined>>;
    selectedImageId?: number;
    unsShowDelete?: boolean;
}


const useStyles = makeStyles(
    {
        image: {
            margin: '0.5rem',
            maxHeight: '10rem',
        },
        imageBox: {
            position: 'relative',
        },
        delete: {
            display: 'center',
            position: 'absolute',
            top: '0%',
            left: '70%',
            color: 'red',
        },
        zoom: {
            display: 'center',
            position: 'absolute',
            top: '0%',
            left: '0%',
            color: 'blue',
        },
        selected: {
            backgroundColor: "gray",
        }
    },
);


const SortableImage = (props: SortableImageProps) => {
    const classes = useStyles(props);
    const deleteTarget = async () => {
        if (window.confirm(props.message)) {
            await deleteImage(props.dataProvider, props.resource, props.id, props.path, props.setImages, props.imageArray);
        }
    }
    const openImage = () => {
        props.bigImageUrl ? window.open(props.bigImageUrl)
            : window.open(props.imageURL);
    }
    const setNote = () => {
        props.setImageId && props.setImageId(props.id);
    }

    return (
        <Box p={'5px'} px={1}
             className={classes.imageBox + " " + (props.selectedImageId === props.id ? classes.selected : '')}>
            <img src={props.imageURL} alt={''} className={classes.image} onClick={setNote}/>
            <IconButton className={classes.zoom}>
                <ZoomInIcon onClick={openImage}/>
            </IconButton>
            {!props.unsShowDelete &&
                <IconButton className={classes.delete}>
                <HighlightOffIcon onClick={deleteTarget}/>
            </IconButton>}
            {props.children}
        </Box>
    );
};

SortableImage.defaultProps = defaultProps;

export default SortableImage;
