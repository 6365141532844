import React, {useEffect} from "react";
import useVariantClasses from "../variantClasses/variantClassesHooks";
import {AppBar, Button, useDataProvider} from "react-admin";
import {ApiVariantClass} from "../../openapi";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import {Box, Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import VariantItemImagesSortableList from "./VariantItemImagesSortableList";
import {Form} from 'react-final-form';

const VariantItemImagesListComponent: React.FC<any> = (props) => {
    const dataProvider = useDataProvider();
    const {
        variantClasses,
        getByVariantClassesId,
        variantItems,
        getVariantItemsByVariantClassesId
    } = useVariantClasses();
    const fetchVariantClasses = () => {
        if (props.location.pathname.toString().split('/')[2]) {
            const variantClassesId = props.location.pathname.toString().split('/')[2];
            getByVariantClassesId(variantClassesId).then();
            getVariantItemsByVariantClassesId(variantClassesId).then();
        }
    }
    useEffect(fetchVariantClasses, []);
    return (
        <div>
            {variantClasses &&
                <>
                    <ScreenName variantClasses={variantClasses}/>
                    <Paper className={'mt-15 b-shadow w-70'}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"> 規格項目名</TableCell>
                                        <TableCell align="center">規格項目画像</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {variantItems && variantItems.map((variantItem, index) => {
                                        return (<>
                                            <TableRow>
                                                <TableCell align="left" style={{verticalAlign:'top'}} key={variantItem.id}>
                                                    {variantItem.name}
                                                </TableCell>
                                                <TableCell>
                                                    <Form onSubmit={() => {}} render={() => (
                                                        <form onSubmit={() => {
                                                        }}>
                                                            <VariantItemImagesSortableList
                                                                dataProvider={dataProvider}
                                                                variantItemId={variantItem.id}
                                                            />
                                                        </form>
                                                    )}/>
                                                </TableCell>
                                            </TableRow>
                                        </>);
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </>
            }
        </div>
    )
}

interface ScreenNameProps {
    variantClasses?: ApiVariantClass;
}

const ScreenName: React.FC<ScreenNameProps> = (props) => {
    return (
        <>
            {props.variantClasses && <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        variantClasses={props.variantClasses}
                        variantItemImages={true}
                    />
                </div>
            </AppBar>}
            <Box p={2} mt={2}>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true} spacing={2}>
                        <Button
                            component={Link}
                            variant="contained"
                            color={'default'}
                            to={{
                                pathname: `/variantClasses/${props.variantClasses?.id}/show`
                            }}
                            label={'戻る'}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}


export default VariantItemImagesListComponent;
