import React, {useEffect, useState} from 'react';
import {useDataProvider, useRefresh} from "react-admin";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import ProductVariantImage from "./ProductVariantImageComponent";
import {UrlInterface} from "./ProductVariantImageHooks";
import {Box, Grid} from "@material-ui/core";
import {saveImages} from "../../common/image/databaseImageUtils";
import {ImageDirectory, MAX_FETCH_DATA_SIZE, PAGE_LOCATION, ResourceNames, VALUE} from "../../config/const";
import {useForm} from "react-final-form";
import {useParams} from "react-router-dom";


const ProductVariantItemImageComponent: React.FC<any> = (props) => {
    const [pathData, setPathData] = useState(new Array<TempFileData>());
    const [images, setImages] = useState<ListPhotosType[] | undefined>();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const form = useForm();
    const {productId} = useParams<{ productId: string }>();

    const loadImages = () => {
        (async () => {
            let result: ListPhotosType[] = [];
            result[0] = {
                id: props.record.productVariantItemImage.id,
                position: props.record.productVariantItemImage.id,
                path: props.record.productVariantItemImage.path,
                url: props.record.productVariantItemImage.publicUrl ? props.record.productVariantItemImage.publicUrl : 'https://i.imgur.com/Ajy00Ig.png',
            };
            setImages(result);
        })();
    }

    const saveImage = () => {
        (async () => {
            if (pathData.length > VALUE.EMPTY) {
                const newDate = new Date().getTime()
                const pathName = pathData[0].filename.split('.')
                pathData[0].filename = pathName[0] + '_' + newDate + '.' + pathName[1];

                const dataUrl: UrlInterface = {
                    pubUrl: props.record.productVariantItemImage.publicUrl,
                    pathUrl: props.record.productVariantItemImage.path,
                    imageId: props.record.productVariantItemImage.id,
                    productId: Number(productId)
                }

                await saveImages(dataProvider, ResourceNames.PRODUCT_VARIANT_ITEM_IMAGES, {}, ImageDirectory.PRODUCT_VARIANT_ITEM_IMAGE, pathData, images,
                    undefined, undefined, undefined, undefined, undefined, undefined,
                    true, dataUrl
                );

                const data = await dataProvider.getList('productVariantItemImageCombination', {
                    pagination: {page: PAGE_LOCATION, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'createdAt', order: 'ASC'},
                    filter: {productId: productId},
                });

                const variant = data.data.filter(result => result.productVariantItemImage.id === props.record.productVariantItemImage.id);

                if (variant) {
                    let result: ListPhotosType[] = [];
                    result[0] = {
                        id: variant[0].productVariantItemImage.id,
                        position: variant[0].productVariantItemImage.id,
                        path: variant[0].productVariantItemImage.path,
                        url: variant[0].productVariantItemImage.publicUrl,
                    };
                    setImages(result);
                    setPathData([]);
                    refresh();
                }
                form.change('productVariantItemImages', null)
                refresh();
                setPathData([]);
            }
        })();
    }

    useEffect(loadImages, [props.record.id])
    useEffect(saveImage, [pathData])


    return (
        <Grid item={true} xs={10}>
            <Grid container={true} spacing={3}>
                <Box mt={2} ml={10}>
                    <ProductVariantImage
                        dataProvider={dataProvider}
                        variantItemId={+props.record.productVariantItemImage.id}
                        pathData={pathData}
                        setPathData={setPathData}
                        images={images}
                        setImages={setImages}
                        unShowDelete={true}
                    />
                </Box>
            </Grid>
        </Grid>
    )
};


export default ProductVariantItemImageComponent;