import {Dispatch, SetStateAction, useEffect} from "react";
import {convertDataForSortableImage} from "../../common/image/databaseImageUtils";
import {MAX_FETCH_DATA_SIZE, ResourceNames} from "../../config/const";
import {DataProviderProxy} from "react-admin";
import {ListPhotosType} from "../../common/image/arrayUtil";

interface Props {
    dataProvider: DataProviderProxy;
    variantItemId?: number;
    uploadedCounter: number;
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
}

const UseVariantItemImagesSavedHooks = (props: Props) => {
    const getImages = async () => {
        if (props.variantItemId) {
            const data = await convertDataForSortableImage(props.dataProvider,
                ResourceNames.VARIANT_ITEM_IMAGES,
                {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'position', order: 'ASC'},
                    filter: {
                        variantItemId: props.variantItemId
                    }
                }
            );
            props.setImages(data);
        }
    }

    const callGetImages = () => {
        getImages().then()
    }

    useEffect(callGetImages, [props.variantItemId, props.uploadedCounter])
}

export default UseVariantItemImagesSavedHooks;
