import {mergeTranslations, resolveBrowserLocale, TranslationMessages, useTranslate,} from 'react-admin';
import * as messages from "./i18n";
import polyglotI18nProvider from "ra-i18n-polyglot";

const customEnglishMessages: TranslationMessages = mergeTranslations(
    messages,
);

const defaultI18nProvider = polyglotI18nProvider(
    (locale) => customEnglishMessages,
    resolveBrowserLocale()
);

type UseTranslateFn = (key: string, args?: any) => string;

export const translateWrapper = (): UseTranslateFn => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const translateSystem = useTranslate();
    const translate = (key: string, args?: any): string => {
        args = args || {};
        args._ = ""; // Hack to stop throwing error
        const res = translateSystem(key, args);
        if (res) {
            return res;
        }
        return defaultI18nProvider.translate(key, args);
    };
    return translate;
};

