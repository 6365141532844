const kusCoreSystemUtil = () => {
    const productsUrl = () => {
        return process.env.REACT_APP_KUS_CORE_SYSTEM_URL + '/products';
    }

    return {
        productsUrl,
    }
}

export default kusCoreSystemUtil;
