import {DataProvider, Identifier, Translate} from "ra-core";
import {processCsvFile} from "./csv-extractor";
import {SimpleLogger} from "./SimpleLogger";
import {ValidateRowFunction} from "./config.interface";

function makeLogger(logging: boolean) {
    const logger = new SimpleLogger("import-controller", true);
    logger.setEnabled(logging);
    return logger;
}

export async function GetIdsColliding(
    logging: boolean,
    translate: Translate,
    dataProvider: DataProvider,
    csvValues: any[],
    resourceName: string,
): Promise<Identifier[]> {
    const logger = makeLogger(logging);
    const hasIds = csvValues.some((v) => v.id);
    if (!hasIds) {
        return [];
    }
    try {
        const csvIds: Identifier[] = csvValues.filter(v => !!v.id).map((v) => v.id);
        const recordsColliding = await dataProvider.getMany(resourceName, {
            ids: csvIds,
        });
        const newRecordsColliding = csvIds.map((id) => {
            return recordsColliding.data.find((d) => d.id === id);
        })
        return newRecordsColliding.map((r) => r!.id);
    } catch (error) {
        logger.error("GetIdsColliding", {csvValues}, error);
        throw translate("csv.parsing.collidingIds");
    }
}

export async function CheckCSVValidation(
    logging: boolean,
    translate: Translate,
    csvValues: any[],
    validateRow?: ValidateRowFunction
): Promise<void> {
    const logger = makeLogger(logging);
    if (!validateRow) {
        return;
    }
    try {
        await Promise.all(csvValues.map(validateRow));
    } catch (error) {
        logger.error("onFileAdded", {csvValues}, error);
        throw translate("csv.parsing.failedValidateRow");
    }
}

export async function GetCSVItems(
    logging: boolean,
    translate: Translate,
    file: File,
    parseConfig: any,
): Promise<any[]> {
    const logger = makeLogger(logging);
    let csvValues: any[] | undefined;
    try {
        csvValues = await processCsvFile(file, parseConfig);
        return csvValues || [];
    } catch (error) {
        if (csvValues) {
            logger.error("onFileAdded", {csvValues}, error);
        }
        throw translate("csv.parsing.invalidCsvDocument");
    }
}
