import React, {useCallback, useEffect, useState} from "react";
import {Grid, TableCell, TableRow} from "@material-ui/core";
import {Button, Create, useDataProvider, EditButton} from "react-admin";

import {RecommendedCarsProps} from './RecommendedCarsFormComponent'
import {Link} from "react-router-dom";
import GoBack from "../../common/GoBackComponent";
import RecommendedCarNonMasterDefaultProductForm from "./RecommendedCarNonMasterDefaultProductForm";
import Table from "@material-ui/core/Table";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {MAX_FETCH_DATA_SIZE} from '../../config/const'
import {ApiRecommendedCarDefaultNonMasterProduct} from "../../openapi";
import RecommendedCarNonMasterDefaultProductEditForm from "./RecommendedCarNonMasterDefaultProductEditForm";
import {EditRowButton} from "@react-admin/ra-editable-datagrid";

export const RecommendedCarsNonMasterDefaultProductList: React.FC<RecommendedCarsProps> = (props) => {
    const dataProvider = useDataProvider();
    const [nonMasterDefaultProduct, setNonMasterDefaultProduct] = useState(new Array<ApiRecommendedCarDefaultNonMasterProduct>());
    const [update, setUpdate] = useState(false)

    const loadData = () => {
        (async () => {
            const master = await dataProvider.getList('recommendedCarDefaultNonMasterProducts', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'createdAt', order: 'ASC'},
                filter: {recommendedCarId: props.recommendedCarId},
            });

            const array: React.SetStateAction<ApiRecommendedCarDefaultNonMasterProduct[]> = []
            master?.data.map((item) => {
                array.push(item as ApiRecommendedCarDefaultNonMasterProduct)
            })

            setNonMasterDefaultProduct(array)
            setUpdate(false)
        })();
    }

    useEffect(loadData, [update, props.recommendedCarId])

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Grid container={true} spacing={3}>
                        <Grid item={true}>
                            <h4 className={'inline'}>マスタ外商品</h4>
                        </Grid>
                        <Grid item={true}>
                            <Button
                                component={Link}
                                label={'作成'}
                                variant="contained"
                                color={'primary'}
                                to={{
                                    pathname: "/recommendedCarNonMasterDefaultProducts/create",
                                    state: {record: {recommendedCarId: props.recommendedCarId}},
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Table>
                        <TableRow>
                            <TableCell>商品名</TableCell>
                            <TableCell align={"right"}>売価</TableCell>
                            <TableCell align={"right"}>定価</TableCell>
                            <TableCell align={"right"}>原価</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                        <>
                            {nonMasterDefaultProduct.map((nonMasterDefaultProduct) => {
                                return (
                                    <>
                                        <TableRow>
                                            <TableCell>{nonMasterDefaultProduct.name}</TableCell>
                                            <TableCell
                                                align={"right"}>{nonMasterDefaultProduct.price.toLocaleString()}</TableCell>
                                            <TableCell
                                                align={"right"}>{nonMasterDefaultProduct.originalPrice.toLocaleString()}</TableCell>
                                            <TableCell
                                                align={"right"}>{nonMasterDefaultProduct.cost.toLocaleString()}</TableCell>
                                            <TableCell>
                                                <EditButton label={''} to={{
                                                    pathname: "/recommendedCarNonMasterDefaultProducts/" + Number(nonMasterDefaultProduct.id),
                                                    state: {
                                                        record: {...nonMasterDefaultProduct}
                                                    },
                                                }}/>
                                                &nbsp;
                                                <DeleteButton variant={"contained"} label={'削除'}
                                                              id={Number(nonMasterDefaultProduct.id)}
                                                              setUpdate={setUpdate}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            })
                            }
                        </>
                    </Table>
                </CardContent>
            </Card>
        </>
    )
}

const DeleteButton = ({...props}) => {
    const dataProvider = useDataProvider();
    const handleClick = useCallback(async () => {
        if (window.confirm('削除しても宜しいでしょうか。')) {
            await dataProvider.delete('recommendedCarDefaultNonMasterProducts', {
                id: props.id,
                previousData: {id: props.id},
            }).then(e => {
                props.setUpdate(true)
            });

        }
    }, []);

    return (
        <Button onClick={handleClick} style={{backgroundColor: '#ff0000'}} {...props} />
    );
}
const ActionTopToolbar = () => {
    return (
        <div className={'mb-30 '}>
            <GoBack/>
        </div>
    );
};

interface CreateProps {
    location?: any;
}

export const RecommendedCarNonMasterDefaultProductCreate: React.FC<CreateProps> = ({location, ...props}) => {
    return (
        <>
            <Create
                {...props}
                actions={<ActionTopToolbar/>}
            >
                <RecommendedCarNonMasterDefaultProductForm {...props}
                                                           recommendedCarId={location.state.record.recommendedCarId}/>
            </Create>
        </>
    )
}

export const RecommendedCarNonMasterDefaultProductEdit: React.FC<CreateProps> = ({location, ...props}) => {
    return (
        <>
            <Create
                {...props}
                actions={<ActionTopToolbar/>}
            >
                <RecommendedCarNonMasterDefaultProductEditForm {...props}
                                                               recommendedCarId={location.state.record.recommendedCarId}/>
            </Create>
        </>
    )
}

export default RecommendedCarsNonMasterDefaultProductList;

