import React, {useEffect, useState} from "react";
import {
    AppBar,
    AutocompleteInput,
    Button,
    Create,
    CreateProps,
    Edit,
    maxLength,
    minValue,
    NullableBooleanInput,
    number,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    useDataProvider,
    useRedirect,
    useRefresh,
} from "react-admin";
import {Box, Grid, Typography} from "@material-ui/core";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {ActionToolbar} from "../../common/ActionToolbar";
import useVariantClasses from "./variantClassesHooks";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import {Link} from "react-router-dom";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import VariantClassesImage from "./VariantClassesImagesComponent";
import {ApiVariantClass} from "../../openapi/model/api-variant-class";
import {saveImages} from "../../common/image/databaseImageUtils";
import {circularSize, ImageDirectory, ResourceNames} from "../../config/const";
import {useStyles} from "../../common/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {multipleSelectionMethods} from "./VariantClassesListComponent";
import {ApiVariantClassMultipleSelectionMethodKeyEnum} from "../../openapi";

const VariantClassesForm: React.FC<any> = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const validateText = [required()];
    const validateName = [required(), maxLength(50)];
    const validateNumber = [required(), number(), minValue(0)];
    const dataProvider = useDataProvider();

    const [pathData1, setPathData1] = useState(new Array<TempFileData>());
    const [images1, setImages1] = useState<ListPhotosType[] | undefined>();

    const [open, setOpen] = useState(false);

    const handleSave = async ({...values}) => {
        setOpen(true);
        if (values.multipleSelectionMethod === 'leftAndRight') {
            values.multipleSelectionMethod = {key: ApiVariantClassMultipleSelectionMethodKeyEnum.LeftAndRight}
        } else if (values.multipleSelectionMethod === 'frontAndRear') {
            values.multipleSelectionMethod = {key: ApiVariantClassMultipleSelectionMethodKeyEnum.FrontAndRear}
        }

        if (props.type === 'create') {
            const variantClass = await dataProvider.create('variantClasses', {data: {...values}});
            let count = 1
            let counter = ++count;
            await saveImages(dataProvider, ResourceNames.VARIANT_CLASSES_IMAGE_RESOURCE, {
                variantClassId: variantClass.data.id,
                position: counter++
            }, ImageDirectory.VARIANT_CLASS_IMAGE + variantClass.data.id + '/', pathData1, images1);

        } else {
            delete values.set;
            await dataProvider.update('variantClasses', {
                id: values.id,
                data: {...values},
                previousData: {id: values.id}
            });

            await saveImages(dataProvider, ResourceNames.VARIANT_CLASSES_IMAGE_RESOURCE, {
                variantClassId: props.id,
            }, ImageDirectory.VARIANT_CLASS_IMAGE + props.id + '/', pathData1, images1);

        }

        redirect(`/variantClasses`);
        refresh();
    };

    const ShowProgress: React.FC = () => {
        const classes = useStyles();
        return (
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" size={circularSize}/>
            </Backdrop>
        );
    }

    return (
        <>
            <ShowProgress/>
            <SimpleForm
                {...props}
                submitOnEnter={false}
                save={handleSave}
                toolbar={<ActionToolbar/>}>
                <div className={"w-100"}>
                    <Grid container={true}>
                        <Grid item={true} xs={2}>
                            <ReferenceInput
                                label={'規格名'}
                                source="variantId"
                                reference="variants"
                                validate={validateText}
                                fullWidth={true}>
                                <AutocompleteInput resettable={true}/>
                            </ReferenceInput>
                            <Grid container={true}>
                                <TextInputForJapanese
                                    source="name"
                                    validate={validateName}
                                    label={'規格分類名'}
                                    fullWidth={true}/>
                            </Grid>
                            <Grid container={true}>
                                <NumberInputCommon
                                    source={'position'}
                                    label={'表示順序'}
                                    validate={validateNumber}
                                    fullWidth={true}/>
                            </Grid>
                            <Grid container={true}>
                                <NullableBooleanInput
                                    source="affectJNumberAndInset"
                                    validate={validateText}
                                    label={'J数・インセットに影響'}
                                    fullWidth={true}
                                    className={"w-100"}/>
                            </Grid>
                            <Grid container={true}>
                                <NullableBooleanInput
                                    source="affectImage"
                                    validate={validateText}
                                    label={'画像に影響'}
                                    fullWidth={true}
                                    className={"w-100"}/>
                            </Grid>
                            <Grid container={true}>
                                <NullableBooleanInput
                                    source="suspension"
                                    validate={validateText}
                                    label={'サスペンション'}
                                    fullWidth={true}
                                    className={"w-100"}/>
                            </Grid>
                            <Grid container={true}>
                                <NullableBooleanInput
                                    source="affectPrice"
                                    validate={validateText}
                                    label={'価格に影響'}
                                    fullWidth={true}
                                    className={"w-100"}/>
                            </Grid>
                            <Grid container={true}>
                                <NullableBooleanInput
                                    source="wheelSize"
                                    validate={validateText}
                                    label={'ホイールサイズ'}
                                    fullWidth={true}
                                    className={"w-100"}/>
                            </Grid>
                            <Grid container={true}>
                                <TextInputForJapanese
                                    multiline
                                    source="explanation"
                                    validate={validateText}
                                    label={"説明文"}
                                    fullWidth={true}
                                    rows={10}/>
                            </Grid>
                            <Grid container={true}>
                                <SelectInput
                                    source="multipleSelectionMethod.key"
                                    label={'2本ずつ選択方法'}
                                    fullWidth={true}
                                    optionValue={'id'}
                                    choices={multipleSelectionMethods}
                                    className={"w-100"}/>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={10}>
                            <Grid container={true} spacing={3}>
                                <Box mt={2} ml={10}>
                                    <Typography variant={'h6'}>画像アップロード(無制限)</Typography>
                                    <VariantClassesImage
                                        dataProvider={dataProvider}
                                        variantClassesId={props.id}
                                        pathData={pathData1}
                                        setPathData={setPathData1}
                                        images={images1}
                                        setImages={setImages1}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </>
    );
};

interface ScreenNameProps {
    variantClasses?: ApiVariantClass;
}

const ScreenName: React.FC<ScreenNameProps> = (props) => {
    return (
        <>
            {props.variantClasses && <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        variantClasses={props.variantClasses}
                    />
                </div>
            </AppBar>}
            <Box>
                <Button
                    component={Link}
                    variant="contained"
                    color={'default'}
                    to={{
                        pathname: `/variantClasses`
                    }}
                    label={'戻る'}
                />
            </Box>
        </>
    );
}

interface VariantActionTopToolbarProps {
    variantClassesId?: number;
}

const ActionTopToolbar: React.FC<VariantActionTopToolbarProps> = (props: VariantActionTopToolbarProps) => {
    const {variantClasses, getByVariantClassesId} = useVariantClasses();
    const fetchVariantClasses = () => {
        if (props.variantClassesId) {
            getByVariantClassesId(props.variantClassesId).then();
        }
    }
    useEffect(fetchVariantClasses, []);

    return (
        <div className={'mb-30'}>
            <ScreenName variantClasses={variantClasses}/>
        </div>
    );
};

export const VariantClassesEditForm: React.FC<any> = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const onSuccess = () => {
        redirect('/variantClasses');
        refresh();
    };
    const transform = ({...data}) => {
        delete data.set;
        return data;
    };

    return (
        <Edit
            {...props}
            mutationMode={'optimistic'}
            onSuccess={onSuccess}
            actions={<ActionTopToolbar variantClassesId={props.id}/>}
            transform={transform}
        >
            <VariantClassesForm {...props} type={'update'}/>
        </Edit>
    );
};

export const VariantClassesCreateForm: React.FC<CreateProps> = (props) => {
    return (
        <Create
            {...props}
            actions={<ActionTopToolbar/>}
        >
            <VariantClassesForm {...props} type={'create'}/>
        </Create>
    );
};

