import {useEffect, useState} from "react";
import {ApiCar, ApiCarModel} from "../../openapi";
import {useDataProvider} from "react-admin";
import {Record} from "../../config/const";


const useCarGrade = (carModelId: number, record?: Record) => {
    const dataProvider = useDataProvider();
    const [carModel, setCarModel] = useState<ApiCarModel>()
    const [car, setCar] = useState<ApiCar>()
    const [forUsed, setForUsed] = useState<boolean>();
    useEffect(() => {
        const awaitFn = async () => {
            if (carModelId) {
                const fetchedCarModel = await getCarModel();
                setCarModel(fetchedCarModel);
                setCar(await getCar(fetchedCarModel));
            }
        }
        awaitFn().then();

    }, [carModelId])

    const getCarModel = async () => {
        const data = await dataProvider.getOne('carModels', {
            id: Number(carModelId)
        });
        return data.data as ApiCarModel;
    }

    const getCar = async (carModel: ApiCarModel) => {
        const data = await dataProvider.getOne('cars', {
            id: carModel.carId!!
        });
        return data.data as ApiCar;
    }

    const validateFormCreation = () => {
        const errors = {};
        return errors;
    }

    const getInitialDataForUsed = () => {
        if (record?.id) {
            setForUsed(record?.forUsed)
        }
    }

    useEffect(getInitialDataForUsed, [])

    return {
        carModelId,
        carModel,
        car,
        setForUsed,
        forUsed,
        validateFormCreation,
    }
}

export default useCarGrade;
