import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    ApiCarModel,
    ApiProductCarGradeMountSetting as ApiMountSettingsCarGrades,
    ApiProductCarGradeMountSettingWithOtherDataData as ApiMountSettingsData,
    ApiProductMountableCarModelForAdminCarModels as ApiMountSettingsProducts
} from "../../openapi";
import {AppBar, Button, Title, useDataProvider, useGetOne, useNotify, useRedirect} from "react-admin";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import {Link, useParams} from "react-router-dom";
import {Box} from "@material-ui/core";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import {makeStyles} from "@material-ui/core/styles";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";


const useStyles2 = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        flexGrow: 1,
        overflow: "scroll"
    },
    table: {
        position: 'sticky',
        top: 0,
        border: "solid thin"
    },
    category: {
        backgroundColor: "#808080",
        color: "#fff",
        borderBottom: "solid thin #000",
        borderRight: "solid thin #000"
    },
    product: {
        backgroundColor: "#E4E4E4",
        borderBottom: "solid thin",
        borderRight: "solid thin"
    },
    row: {
        backgroundColor: "#E4E4E4",
        minWidth: "50px",
        top: 0,
        position: "sticky",
        zIndex: 1000,
        borderBottom: "solid thin",
        borderRight: "solid thin"
    },
    tableContainer: {
        overflowX: "initial"
    },
    column: {
        backgroundColor: "#fff",
        minWidth: "50px",
        left: 0,
        position: "sticky",
        zIndex: 998,
        borderBottom: "solid thin",
        borderRight: "solid thin"
    },
    child: {
        zIndex: 1,
        borderBottom: "solid thin",
        borderRight: "solid thin"
    },
    paper: {
        marginBottom: 30
    }
}));

interface ApiMountSettingsCarGradesExtend {
    checked: boolean;
    data: ApiMountSettingsCarGrades
}

interface ApiMountSettingsCarGradesDetails {
    carGradeName: string,
    productName: string,
    checked: boolean,
    carGradeId: number,
    productId: number
}

interface ApiMountSettingsCheckBoxData {
    id: number,
    name: string,
    checked: boolean,
    values: [{
        checked: boolean
        data: { carGradeId: number, productId: number }
    }]
}

interface ScreenNameProps {
    carModel: ApiCarModel;
}

const scrollVertical = (): number => {
    return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
}

const scrollHorizontal = (): number => {
    return Math.max(window.pageXOffset, document.documentElement.scrollLeft, document.body.scrollLeft);
}

export default function MountSettingsList(props: { basePath: string | undefined; }) {
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {id} = useParams<{ id: string }>();
    const [data, setData] = useState<ApiMountSettingsData[]>()
    const [checkBoxData, setCheckBoxData] = useState<Array<ApiMountSettingsCheckBoxData>>([]);
    const [products, setProducts] = useState<ApiMountSettingsProducts[]>()
    const [productsTotal, setProductsTotal] = useState([{total: 0}])

    const [vertical, setVertical] = useState(0);

    const [horizontal, setHorizontal] = useState(0);

    useEffect(() => {
        const fetchApiData = async () => {
            const mountSettings = await dataProvider.getList('productCarGradeMountSettings',
                {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'id', order: 'asc'},
                    filter: {carModelId: id},
                });
            const fetched = mountSettings.data as ApiMountSettingsData[]
            setData(fetched);
        };
        fetchApiData().then();
    }, []);

    useEffect(() => {
        if (data) {
            let arrProductTotalTemp: { name: string, id: number }[] = [];
            let arrProductsTotal: { total: number, id: number }[] = [];
            let arrProducts: ApiMountSettingsProducts [] = [];
            let arrCarGradeName: { name: string, id: number }[] = [];
            let arrCarGrade: ApiMountSettingsCarGradesDetails [] = [];
            data.map((data, index) => {
                data.categoryParent?.categories?.map((categoryParent) => {
                    categoryParent.carGrades?.map((carGradeItem) => {
                        carGradeItem.products?.sort((a, b) => Number(a.position) - Number(b.position)).map((productItem) => {

                            let index = arrProducts.findIndex(searchProduct => searchProduct.id === productItem.id);
                            index === -1 && arrProducts.push({
                                name: productItem.name,
                                checked: true,
                                id: productItem.id !== undefined ? productItem.id : 0
                            })

                            let totalIndex = arrProductTotalTemp.findIndex(searchProductTotal => searchProductTotal.id === productItem.id);
                            totalIndex === -1 && arrProductTotalTemp.push({
                                name: productItem.name,
                                id: productItem.id !== undefined ? productItem.id : 0
                            })

                            arrCarGrade.push({
                                carGradeName: carGradeItem?.carGrade?.name !== undefined ? carGradeItem?.carGrade?.name : '',
                                productName: productItem.name,
                                checked: productItem.checked !== undefined ? productItem.checked : false,
                                carGradeId: carGradeItem?.carGrade?.id !== undefined ? carGradeItem?.carGrade?.id : 0,
                                productId: productItem.id !== undefined ? productItem.id : 0
                            })

                        })

                        let index = arrCarGradeName.findIndex(searchCarGrade => searchCarGrade.id === carGradeItem?.carGrade?.id);
                        index === -1 && arrCarGradeName.push({
                            name: carGradeItem?.carGrade?.name !== undefined ? carGradeItem?.carGrade?.name : '',
                            id: carGradeItem?.carGrade?.id !== undefined ? carGradeItem?.carGrade?.id : 0
                        })
                    })
                })
                arrProductsTotal.push({
                    total: arrProductTotalTemp.length,
                    id: index
                })
                arrProductTotalTemp.splice(0, arrProductTotalTemp.length)
            })
            setProductsTotal(arrProductsTotal);
            setProducts(arrProducts);

            let checkedData: ApiMountSettingsCheckBoxData [] = [];
            arrCarGradeName.map((item, index) => {
                const arrCheckboxTemp: ApiMountSettingsCarGradesExtend [] = [];
                arrCarGrade.map((carGradeItem, index1) => {
                    if (item.name === carGradeItem.carGradeName) {
                        arrCheckboxTemp.push({
                            checked: arrCarGrade[index1].checked,
                            data: {
                                productId: arrCarGrade[index1].productId,
                                carGradeId: arrCarGrade[index1].carGradeId
                            }
                        })
                    }
                })
                checkedData.push({
                    id: index,
                    name: item.name,
                    checked: true,
                    values: arrCheckboxTemp
                } as unknown as ApiMountSettingsCheckBoxData);
            })

            checkedData.map((data, index) => {
                data.values.map((data1: { checked: boolean; }, index1: number) => {
                    if (!data1.checked) {
                        checkedData[index].checked = false;
                        if (arrProducts[index1] !== undefined) {
                            arrProducts[index1].checked = false;
                        }
                        setProducts(arrProducts);
                    }
                })
            })

            console.log('checkBoxData:  ' + JSON.stringify(checkedData))
            setCheckBoxData(checkedData)

        }
    }, [data])

    const checkedVertical = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>, productId: number, checked: string | undefined) => {
        products && (products[productId].checked = checked !== 'true')
        setProducts(products);
        checkBoxData.map((data) => {
            if (!data.checked) {
                data.values[productId].checked = checked !== 'true';
            }
        })
        setCheckBoxData(checkBoxData);
        e.preventDefault();
        setVertical(scrollVertical());
        setHorizontal(scrollHorizontal());
        redirect('/mountSettings/' + id + '', props.basePath);
    }

    const checkedHorizontal = (e: React.MouseEvent<HTMLLabelElement>, row: number, checked: boolean) => {
        products?.map((productsData, a) => {
            if (!productsData.checked) {
                checkBoxData[row].values[a].checked = !checked;
            }
        })
        checkBoxData[row].checked = !checked;
        setCheckBoxData(checkBoxData);
        e.preventDefault();
        setVertical(scrollVertical());
        setHorizontal(scrollHorizontal());
        redirect('/mountSettings/' + id + '', props.basePath);
    };

    const checkedCheckbox = (e: React.MouseEvent<HTMLLabelElement>, row: number, checked: boolean, column: number) => {
        checkBoxData[row].values[column].checked = !checked;
        setCheckBoxData(checkBoxData)
        e.preventDefault();
        setVertical(scrollVertical());
        setHorizontal(scrollHorizontal());
        redirect('/mountSettings/' + id + '', props.basePath);
    };

    const save = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let data: { productId: number, carGradeId: number }[] = []
        checkBoxData.map((checkBoxItem, index) => {
            checkBoxItem.values.map((valuesData, index1) => {
                if (checkBoxData[index].values[index1].checked)
                    data.push({
                        productId: checkBoxData[index].values[index1].data.productId,
                        carGradeId: checkBoxData[index].values[index1].data.carGradeId
                    });
            })
        })
        console.log('SaveRequest:' + JSON.stringify({data}));
        await dataProvider.createMany('productCarGradeMountSettings/' + id + '', {
            data: data,
        })
        e.preventDefault();
        notify('notification.saved');
        redirect('/mountSettings/' + id + '', props.basePath);
    };


    const ScreenName: React.FC<ScreenNameProps> = (props) => {
        return (
            <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        carModel={props.carModel}
                    />
                    装着可・不可設定
                </div>
            </AppBar>
        );
    }

    const carModel = useGetOne('carModels', id).data as ApiCarModel;

    const classes = useStyles2();

    window.scrollTo(horizontal, vertical);
    return (
        <Paper className={'p-1'}>
            <ScreenName carModel={carModel}/>
            <Title title="マウント設定"/>
            <Box className={classes.paper}>
                <Button component={Link}
                        color={'default'}
                        label={'戻る'}
                        variant="contained"
                        to={{pathname: `/carModels/list/${carModel?.carId}`}}
                /> &nbsp;
                <Button
                    size={'small'}
                    label={'保存'}
                    variant="contained"
                    onClick={(event) => {
                        save(event).then(r => {
                            console.log(r)
                        })
                    }}
                />
            </Box>
            <TableContainer component={Paper} classes={{root: classes.tableContainer}}>
                <Table aria-label="simple table" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={1} className={classes.category}>商品カテゴリ</TableCell>
                            {data?.map((data, index) => (
                                <TableCell key={index}
                                           colSpan={productsTotal[0].total !== 0 ? productsTotal[index].total : 0}
                                           align={'center'}
                                           className={classes.product}>{data.categoryParent?.name}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={1} className={classes.category}>商品サブカテゴリ</TableCell>
                            {
                                data?.map((data, index1) => (
                                    data?.categoryParent?.categories?.map((c) => (
                                        <TableCell
                                            colSpan={c.carGrades && c.carGrades[index1]?.products?.length}
                                            align={'center'}
                                            className={classes.product}>{c.name}</TableCell>
                                    ))))
                            }
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={1} className={classes.category}>グレード/商品</TableCell>
                            {products && products.map(({name, checked, id}, index) => (
                                <TableCell key={index} align={'center'} className={classes.row}>
                                    <FormControlLabel
                                        value='1'
                                        checked={checked?.toString() !== 'false'}
                                        control={<Checkbox color="primary"/>}
                                        label={name?.toString()}
                                        onClick={(e) => {
                                            checkedVertical(e, index, checked?.toString())
                                        }}
                                        labelPlacement="top"
                                        style={{display: 'contents'}}
                                    />
                                </TableCell>))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {checkBoxData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="right" className={classes.column}>
                                    <FormControlLabel
                                        value='1'
                                        checked={row.checked}
                                        control={<Checkbox color="primary"/>}
                                        onClick={(e) => {
                                            checkedHorizontal(e, index, row.checked)
                                        }}
                                        style={{display: 'contents'}}
                                        label={row.name}
                                        labelPlacement="top"
                                    />
                                </TableCell>
                                {/*// @ts-ignore*/}
                                {row[('values')].map((values: { checked: boolean, data: { carGradeId: number, productId: number } }, index1: number) => (
                                    <TableCell align="center" key={index1} className={classes.child}>
                                        <FormControlLabel
                                            value='1'
                                            checked={values.checked}
                                            control={<Checkbox color="primary"/>}
                                            onClick={(e) => {
                                                checkedCheckbox(e, index, values.checked, index1)
                                            }}
                                            style={{display: 'contents', zIndex: 1}}
                                            label={''}
                                            labelPlacement="top"
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={'mt-30 btnPullRight'}>
                <Button
                    size={'large'}
                    label={'保存'}
                    variant="contained"
                    onClick={(event) => {
                        save(event).then(r => {
                            console.log(r)
                        })
                    }}
                />
            </div>
        </Paper>
    );
}
