export const getNameForScreenHeader = (props: any) => {
    let name = '新規作成';
    if (props.data && props.data.name) {
        name = props.data.name;
    }
    if (props.record && props.record.name) {
        name = props.record.name;
    }
    return name;
}
