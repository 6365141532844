import React, {useEffect} from 'react';
import {EditableDatagrid, EditRowButton, RowForm,} from '@react-admin/ra-editable-datagrid';
import {List, minValue, number, ReferenceField, required, TextField, useRefresh} from 'react-admin';
import {floatValidation} from '../../common/validate';
import {TextFieldWithCommas} from '../../common/TextFieldWithCommas';
import {TextInputForJapanese} from "../../common/TextInputForJapanese";

const RegistrationFeeList: React.FC = (props: any) => {
    const refresh = useRefresh();
    useEffect(() => {
        refresh();
    });
    return (
        <List
            {...props}
            empty={false}
            exporter={false}
            bulkActionButtons={false}
            pagination={false}
        >
            <EditableDatagrid
                undoable={true}
                editForm={<DataForm/>}
                noDelete={true}
                className={'listTable'}
            >
                <ReferenceField
                    label=""
                    source="numberRegistrationLocationId"
                    reference="numberRegistrationLocation"
                    link={false}
                >
                    <TextField source="name"/>
                </ReferenceField>
                <TextFieldWithCommas
                    source="registrationFee"
                    label={'登録手続費用'}
                    textAlign="right"
                />
                <div className={'actionButtons'}>
                    <EditRowButton {...props} />
                </div>
            </EditableDatagrid>
        </List>
    );
}

const DataForm: React.FC = (props) => {
    const validateNumber = [required(), number(), minValue(0), floatValidation];
    return (
        <RowForm
            {...props}
        >
            <ReferenceField
                label=""
                source="numberRegistrationLocationId"
                reference="numberRegistrationLocation"
                link={false}
            >
                <TextField source="name"/>
            </ReferenceField>
            <TextInputForJapanese
                source="registrationFee"
                validate={validateNumber}
                label={'登録手続費用'}
            />
        </RowForm>
    )
};


export default RegistrationFeeList;
