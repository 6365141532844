import {DeleteWithConfirmIconButton, EditableDatagrid, EditRowButton, RowForm} from '@react-admin/ra-editable-datagrid';
import React, {useEffect, useState} from 'react';
import {
    AutocompleteInput,
    CreateButton,
    Filter,
    List,
    minValue,
    number,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TextField,
    TopToolbar,
    useDataProvider,
    useRecordContext,
} from 'react-admin';
import {floatValidation} from "../../common/validate";
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";
import {Box, Grid} from '@material-ui/core';
import {MyPagination, PER_PAGE} from '../../common/MyPagination';
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {ApiCar} from "../../openapi";

const ListActions = () => (
    <TopToolbar className={'mt-30'}>
        <CreateButton/>
    </TopToolbar>
);

export const RecommendedCarsListComponent: React.FC<any> = (props) => {
    return (
        <List
            filters={<SearchFilter/>}
            {...props}
            hasCreate={true}
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            className={'filterForBox'}
            pagination={<MyPagination/>}
            actions={<ListActions {...props}/>}
        >
            <EditableDatagrid
                undoable={true}
                editForm={<EditAndCreateForm/>}
                noDelete={false}
                createForm={<EditAndCreateForm/>}
                className={'listTable productsList'}
            >
                <ReferenceField
                    label="関連元車種"
                    source="fromCarId"
                    reference="cars"
                    link={false}
                >
                    <TextField source={"name"}/>
                </ReferenceField>
                <ReferenceField
                    label="関連先車種"
                    source="toCarId"
                    reference="cars"
                    link={false}
                >
                    <TextField source={"name"}/>
                </ReferenceField>
                <TextFieldWithCommas source="displayAmount" label={'表示件数'}/>
                <TextFieldWithCommas source="position" label={'表示順序'}/>
                <div className={'actionButtons'}>
                    <EditDataBtn {...props} className={'mr-30'}/>
                    <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                   className={'ml-10'}/>
                </div>
            </EditableDatagrid>
        </List>
    );
};

const SearchFilter: React.FC<any> = (props) => {
    return (
        <Filter {...props}>
            <SearchForm {...props} alwaysOn/>
        </Filter>
    );
};

const SearchForm: React.FC<any> = (props) => {
    const modelRenderer = (choice: Record<any, any>) => choice.name ? `${choice.name}` : '';
    return (
        <Box bgcolor="white" p={2} id='box' className={'filterBox'} mt={2}>
            <Grid container={true} spacing={1} className={'w-100'} id={'carModelField'}>
                <Grid item={true} lg={2}>
                    <ReferenceInput
                        label="関連元車種"
                        source="fromCarId"
                        reference="cars"
                        alwaysOn
                        allowEmpty
                        className={'p-display-none w-150'}
                        perPage={MAX_FETCH_DATA_SIZE}
                        sort={{field: 'position', order: 'ASC'}}
                        resettable={true}
                        fullWidth={true}
                    >
                        <AutocompleteInput
                            optionText={modelRenderer}/>
                    </ReferenceInput>
                </Grid>
                <Grid item={true} lg={2}>
                    <ReferenceInput
                        label="関連先車種"
                        source="toCarId"
                        reference="cars"
                        alwaysOn
                        allowEmpty
                        className={'p-display-none w-150'}
                        perPage={MAX_FETCH_DATA_SIZE}
                        sort={{field: 'position', order: 'ASC'}}
                        resettable={true}
                        fullWidth={true}
                    >
                        <AutocompleteInput
                            optionText={modelRenderer}/>
                    </ReferenceInput>
                </Grid>
            </Grid>
        </Box>
    );
};

const EditAndCreateForm: React.FC = (props: any) => {
    const validateNumber = [required(), number(), minValue(0), floatValidation];
    const [cars, setCars] = useState<Array<ApiCar>>()
    const dataProvider = useDataProvider();

    const init = () => {
        const awaitFn = async () => {
            const result = new Array<ApiCar>();
            const cars = await dataProvider.getList('cars', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'position', order: 'ASC'},
                filter: {}
            });
            cars.data.map((data) => {
                result.push(data as ApiCar);
            });
            setCars(result);
        }
        awaitFn().then();
    }

    useEffect(init, []);

    return (
        <RowForm {...props}>
            <SelectInput className={'input-field'} label={'関連元車種'} source={'fromCarId'} choices={cars}/>
            <SelectInput className={'input-field'} label={'関連先車種'} source={'toCarId'} choices={cars}/>
            <NumberInput validate={validateNumber} className={'input-field'} label={'表示件数'} source="displayAmount"/>
            <NumberInput validate={validateNumber} className={'input-field'} label={'表示順序'} source="position"/>
        </RowForm>
    )
}
const EditDataBtn = ({...source}) => {
    return (<div className={source.className}><EditRowButton {...source} /></div>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};
export default RecommendedCarsListComponent;
