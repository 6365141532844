import React from 'react';
import {Box, Container, Grid, Table, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {AppBar, Button, SelectInput, useDataProvider} from "react-admin";
import GoBack from "../../common/GoBackComponent";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import useEstimate from "./estimateHook";
import {Link} from "react-router-dom";
import moment from "moment";
import {Customer, DEPOSIT_PAID_TYPE, ESTIMATE, ESTIMATE_PHASE} from "../../config/const";
import PaymentMethod from "../../config/PaymentMethod";
import {ApiEstimateProduct} from "../../openapi";
import {priceWithTax} from "../../common/priceUtil";
import {Form} from "react-final-form";
import Variant from "./Variant";

const EstimateLabel = (props: { estimateId: number }) => {
    return (
        <div>
            <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        estimateId={props.estimateId}
                    />
                </div>
            </AppBar>
        </div>
    );
};

const EstimatesId: React.FC = (props: any) => {
    const {
        estimate,
        handleExportData,
        handleCopyEstimate,
        doExport,
        recommendedCar,
        getUsedMileageByUsedCarType,
        staff,
    } = useEstimate(props.id);

    const dataProvider = useDataProvider();

    console.log(estimate, 'estimate');
    const onSubmit = async (values: any) => {
        if (estimate) {
            estimate.depositPaid = values.depositPaid
            const products = estimate?.estimateProducts
            delete estimate?.estimateProducts;
            const estimateVariantItemProductPrices = estimate.estimateVariantItemProductPrices;
            estimate.estimateVariantItemProductPrices = undefined;
            const estimateVariantItems = estimate.estimateVariantItems;
            estimate.estimateVariantItems = undefined;

            await dataProvider.update('estimates', {
                id: props.id,
                data: {...estimate},
                previousData: {id: props.id}
            }).then();
            estimate.estimateProducts = products;
            estimate.estimateVariantItems = estimateVariantItems;
            estimate.estimateVariantItemProductPrices = estimateVariantItemProductPrices;

        }
    }

    const SubscribeInformation = () => {
        return (
            <>
                {estimate?.customer?.subscribeInformation === true && '受信する'}
                {estimate?.customer?.subscribeInformation === false && '受信しない'}
            </>
        );
    }

    return (
        <>
            <Form
                onSubmit={onSubmit}
                render={({handleSubmit, ...inputProps}) => (
                    <form onSubmit={handleSubmit}>
                        {estimate &&
                            <Container maxWidth="xl">
                                <Grid container={true}>
                                    <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                        <EstimateLabel estimateId={props.id}/>
                                    </Grid>
                                    <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                        <div className={'float-right mt-30 mb-30'}>
                                            {estimate.hasEstimatePdf &&
                                                <a className={"MuiButtonBase-root MuiButton-root MuiButton-contained RaButton-button-6 ml-10 MuiButton-containedSizeSmall MuiButton-sizeSmall"}
                                                   href={`${process.env.REACT_APP_API_BASE_PATH}/v1/estimates/${estimate.id}/estimatePdf`}
                                                   target={'_blank'}>見積書</a>
                                            }
                                            {estimate.hasOrderPdf &&
                                                <a className={"MuiButtonBase-root MuiButton-root MuiButton-contained RaButton-button-6 ml-10 MuiButton-containedSizeSmall MuiButton-sizeSmall"}
                                                   href={`${process.env.REACT_APP_API_BASE_PATH}/v1/estimates/${estimate.id}/orderPdf`}
                                                   target={'_blank'}>注文書</a>
                                            }
                                            <ChangeDownloadFlag
                                                {...props}
                                                label={'未対応に戻す'}
                                                className={'f-16'}
                                                record={estimate}
                                            />
                                            <Button
                                                label={'保存'}
                                                variant="contained"
                                                type="submit"
                                                className={'ml-10 create'}
                                            />

                                            {doExport(estimate) && <Button
                                                label={'CSV出力'}
                                                className={'ml-10 f-16'}
                                                onClick={() => handleExportData(estimate)}
                                            />}
                                            <Button {...props} variant="contained" color={'default'} label={'コピー'}
                                                    className={'ml-10'} onClick={handleCopyEstimate}/>
                                            <GoBack className={'ml-20'}/>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container={true}>
                                    <Grid item={true} xs={10} sm={10} md={8} lg={4} className={'mb-30'}>
                                        <Grid container={true} className={'borderGrid'} spacing={1}>
                                            <Grid item={true} xs={4}>
                                                見積番号
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.estimateCode}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                見積日時
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.estimateDate ? moment(estimate.estimateDate).format("YYYY/MM/DD HH:mm:ss") : ''}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                担当スタッフ
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {staff ? staff.name : ''}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                コンプリートカー
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.completeCarName}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                フェーズ
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.phase === ESTIMATE_PHASE.ORDERED.value ? ESTIMATE_PHASE.ORDERED.label : ESTIMATE_PHASE.NOT_ORDER.label}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                顧客名
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && `${estimate.customer.lastName} ${estimate.customer.firstName}`}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                ふりがな
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && `${estimate.customer.lastNameRuby} ${estimate.customer.firstNameRuby}`}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                生年月日
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer?.birthday ? moment(estimate.customer?.birthday).format("YYYY/MM/DD") : ''}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                種別
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {Customer.type[estimate.customer?.customerType || 1]}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                会社名
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && estimate.customer.companyName}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                郵便番号
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && estimate.customer.postalCode}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                都道府県
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && estimate.customer.address1}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                市区町村
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && estimate.customer.address2}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                番地
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && estimate.customer.address3}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                建物・マンション名
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && estimate.customer.address4}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                電話番号
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.customer && estimate.customer.phoneNumber1}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                メールアドレス
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate?.customer && estimate?.customer?.email !== '' ? estimate?.customer?.email : estimate?.customer?.emailOld}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                情報配信
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                <SubscribeInformation/>
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                ナンバー登録地
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.numberRegistrationLocationName}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                納車場所
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.carShippingPlaceName}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                お支払い方法
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {Object.entries(PaymentMethod).map((object) => {
                                                    if (estimate.completeCarId) {
                                                        if (object[1].value === estimate?.paymentMethod)
                                                            return <>{object[1].labelWithCar}</>;
                                                    } else {
                                                        if (object[1].value === estimate?.paymentMethod)
                                                            return <>{object[1].labelPartsOnly}</>;
                                                    }
                                                })}
                                            </Grid>
                                            {(estimate?.paymentMethod === PaymentMethod.fullLoan.value || estimate?.paymentMethod === PaymentMethod.someLoan.value) &&
                                                <>
                                                    <Grid item={true} xs={4}>
                                                        ローン審査状況
                                                    </Grid>
                                                    <Grid item={true} xs={8}>
                                                        {estimate.oricoLoanStatus ? "仮審査済み" : "仮審査未実行"}
                                                    </Grid>
                                                    {!estimate.oricoLoanStatus &&
                                                        <>
                                                            <Grid item={true} xs={4}>
                                                            </Grid>
                                                            <Grid item={true} xs={8}>
                                                                {estimate.oricoLoanUrl}
                                                            </Grid>
                                                        </>
                                                    }
                                                </>
                                            }
                                            <Grid item={true} xs={4}>
                                                消費税率
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.consumptionTax}%
                                            </Grid>

                                            <Grid item={true} xs={4}>
                                                手付金支払い方法
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.depositPaymentMethod === 'creditCard' ? 'クレジットカード' : '現金'}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                手付金支払い済
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                <SelectInput
                                                    fullWidth
                                                    source="depositPaid"
                                                    choices={DEPOSIT_PAID_TYPE}
                                                    translateChoice={false}
                                                    optionValue="id"
                                                    optionText="text"
                                                    initialValue={estimate.depositPaid}
                                                    label={''}
                                                />
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                希望ナンバー
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.desiredNumber ? <>あり</> : <>なし</>}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                下取車
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.tradeIn ? <>あり</> : <>なし</>}
                                            </Grid>
                                            {estimate.recommendedCarId &&
                                                <>
                                                    <Grid item={true} xs={4}>
                                                        特選車
                                                    </Grid>
                                                    <Grid item={true} xs={8}>
                                                        <Button
                                                            component={Link}
                                                            label={estimate.recommendedCarId?.toLocaleString()}
                                                            to={
                                                                {
                                                                    pathname: `/recommendedCars/${estimate.recommendedCarId}`
                                                                }
                                                            }
                                                            variant={'contained'}
                                                            color={'default'}
                                                        />
                                                    </Grid>
                                                </>
                                            }


                                        </Grid>

                                        <Grid container={true}>
                                            <Grid item={true}>
                                                <h4>下取り情報</h4>
                                            </Grid>
                                        </Grid>
                                        <Grid container={true} className={'borderGrid'} spacing={1}>
                                            <Grid item={true} xs={4}>
                                                車種
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.tradeInCar}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                グレード
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.tradeInCarGrade}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                年式
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.tradeInModelYear}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                走行距離
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {Number(estimate.tradeInMileage).toLocaleString()}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                ボディーカラー
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.tradeInCarColor}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                修復歴の有無
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                                {estimate.tradeInHistoryOfRepairs ? 'あり' : 'なし'}
                                            </Grid>
                                            <Grid item={true} xs={4}>
                                                セールスポイント
                                            </Grid>
                                            <Grid item={true} xs={8}>
                                <pre>
                                {estimate.salesPoint}
                                </pre>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item={true} xs={8}>
                                        <Box ml={2}>
                                            <Grid container={true}>
                                                <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                                    <Grid container={true}>
                                                        <Grid item={true} xs={2} sm={2} md={3} lg={3}>
                                                            <div>見積合計金額</div>
                                                        </Grid>
                                                        <Grid item={true} xs={4} sm={4} md={3} lg={3}>
                                                            <div>{estimate && Number(estimate.totalFee).toLocaleString()}</div>
                                                        </Grid>
                                                        <Grid item={true} xs={2} sm={2} md={3} lg={3}>
                                                            <div>原価合計金額</div>
                                                        </Grid>
                                                        <Grid item={true} xs={4} sm={4} md={3} lg={3}>
                                                            <div>{estimate && Number(estimate.totalCost).toLocaleString()}</div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                                    <Grid container={true}>
                                                        <Grid item={true} xs={2} sm={2} md={3} lg={3}>
                                                            <div>諸費用合計金額</div>
                                                        </Grid>
                                                        <Grid item={true} xs={4} sm={4} md={3} lg={3}>
                                                            <div>{estimate && Number(estimate.totalExpenses).toLocaleString()}</div>
                                                        </Grid>
                                                        <Grid item={true} xs={2} sm={2} md={3} lg={3}>
                                                            <div>粗利</div>
                                                        </Grid>
                                                        <Grid item={true} xs={4} sm={4} md={3} lg={3}>
                                                            <div>{estimate && Number((estimate.totalFee || 0) - (estimate.totalCost || 0)).toLocaleString()}</div>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <TableContainer className={'mt-15'}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>項目</TableCell>
                                                            <TableCell>選択内容</TableCell>
                                                            <TableCell className={"text-right"}>見積金額</TableCell>
                                                            <TableCell className={"text-right"}>売価</TableCell>
                                                            <TableCell className={"text-right"}>原価</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>車種・モデル・グレード</TableCell>
                                                            <TableCell>
                                                                {estimate &&
                                                                    <>
                                                                        <span> {estimate.carName1 ?? ""}</span>
                                                                        <span> {estimate.carModelName ?? ""}</span>
                                                                        <span> {getUsedMileageByUsedCarType(estimate)}</span>
                                                                    </>
                                                                }
                                                            </TableCell>
                                                            {(estimate.simulationFlow === ESTIMATE.SIMULATION_FLOW.COMPLETE_CAR || ESTIMATE.SIMULATION_FLOW.USED_CAR) &&
                                                                <>
                                                                    <TableCell
                                                                        className={"text-right"}>{estimate.completeCarOrderPrice?.toLocaleString()}</TableCell>
                                                                    <TableCell
                                                                        className={"text-right"}>
                                                                        {estimate && estimate.completeCarId !== null &&
                                                                            Number(estimate.carGradePrice).toLocaleString()
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={"text-right"}>
                                                                        {estimate && estimate.completeCarId != null &&
                                                                            Number(estimate.carGradeCost).toLocaleString()
                                                                        }
                                                                    </TableCell>
                                                                </>
                                                            }
                                                            {estimate.simulationFlow === ESTIMATE.SIMULATION_FLOW.ORIGINAL &&
                                                                <>
                                                                    <TableCell
                                                                        className={"text-right"}>
                                                                        {
                                                                            priceWithTax((estimate.carGradePrice || 0), estimate.consumptionTax || 0).toLocaleString()
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={"text-right"}>
                                                                        {
                                                                            estimate.carGradePriceWithTax?.toLocaleString()
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={"text-right"}>
                                                                        {
                                                                            estimate.carGradeCostWithTax?.toLocaleString()
                                                                        }
                                                                    </TableCell>
                                                                </>
                                                            }
                                                        </TableRow>
                                                        {recommendedCar !== undefined &&
                                                            <TableRow>
                                                                <TableCell>特選車名</TableCell>
                                                                <TableCell>
                                                                    {recommendedCar?.name}
                                                                </TableCell>
                                                                <TableCell className={"text-right"}>
                                                                    {recommendedCar.totalPriceWithTax.toLocaleString()}
                                                                </TableCell>
                                                                <TableCell className={"text-right"}>
                                                                    {recommendedCar.totalPriceWithTax.toLocaleString()}
                                                                </TableCell>
                                                                <TableCell className={"text-right"}>
                                                                    {recommendedCar.totalCost.toLocaleString()}
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                        {(estimate.completeCarId !== null || estimate.simulationFlow === ESTIMATE.SIMULATION_FLOW.ORIGINAL) &&
                                                            <TableRow>
                                                                <TableCell>カラー</TableCell>
                                                                <TableCell>{estimate && estimate.carColorName}</TableCell>
                                                                <TableCell
                                                                    className={"text-right"}>{estimate && Number(estimate.carColorOrderPrice).toLocaleString()}</TableCell>
                                                                <TableCell
                                                                    className={"text-right"}>{estimate && Number(estimate.carColorPrice).toLocaleString()}</TableCell>
                                                                <TableCell
                                                                    className={"text-right"}>{estimate && Number(estimate.carColorCost).toLocaleString()}</TableCell>
                                                            </TableRow>
                                                        }
                                                        {estimate && estimate.estimateProducts?.map((product: ApiEstimateProduct) =>
                                                            <TableRow>
                                                                <TableCell>{product.manual ? '追加パーツ' : product.productCategoryName}</TableCell>
                                                                <TableCell>{product.productName}</TableCell>
                                                                <TableCell
                                                                    className={"text-right"}>{product.free ? 0 : product.orderPrice?.toLocaleString()}</TableCell>
                                                                <TableCell
                                                                    className={"text-right"}>
                                                                    {(estimate.completeCarId || estimate.simulationFlow === ESTIMATE.SIMULATION_FLOW.ORIGINAL || estimate.simulationFlow === ESTIMATE.SIMULATION_FLOW.RECOMMENDED_CAR) ?
                                                                        product.free ? 0 : Number(product.productPrice).toLocaleString()
                                                                        :
                                                                        product.free ? 0 : Number(product.productPriceForPartsOnly).toLocaleString()
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    className={"text-right"}>{product.free ? 0 : Number(product.productCost).toLocaleString()}</TableCell>
                                                            </TableRow>
                                                        )}
                                                        <TableRow>
                                                            <TableCell>合計</TableCell>
                                                            <TableCell>&nbsp;</TableCell>
                                                            <TableCell className={"text-right"}>
                                                                {
                                                                    (
                                                                        (estimate.simulationFlow === ESTIMATE.SIMULATION_FLOW.ORIGINAL ? priceWithTax((estimate.carGradePrice || 0), estimate.consumptionTax || 0) : 0) +
                                                                        (estimate.completeCarOrderPrice || 0) +
                                                                        (estimate.carColorOrderPrice || 0) +
                                                                        (estimate?.estimateProducts?.reduce((sum, product) => product.orderPrice || 0 + sum, 0) || 0)
                                                                    ).toLocaleString()
                                                                }
                                                            </TableCell>
                                                            <TableCell className={"text-right"}>
                                                                {estimate.simulationFlow === ESTIMATE.SIMULATION_FLOW.COMPLETE_CAR &&
                                                                    ((estimate.carGradePrice || 0) + (estimate.carColorPrice || 0) + (estimate?.estimateProducts?.reduce((sum, product) => product.productPrice || 0 + sum, 0) || 0)).toLocaleString()
                                                                }
                                                                {estimate.simulationFlow === ESTIMATE.SIMULATION_FLOW.PARTS_ONLY &&
                                                                    ((estimate?.estimateProducts?.reduce((sum, product) => product.productPriceForPartsOnly || 0 + sum, 0) || 0)).toLocaleString()
                                                                }
                                                                {estimate?.simulationFlow === ESTIMATE.SIMULATION_FLOW.ORIGINAL &&
                                                                    (
                                                                        (estimate.carGradePriceWithTax || 0) +
                                                                        (estimate.carColorPriceWithTax || 0) +
                                                                        (estimate?.estimateProducts?.reduce((sum, product) => product.productPrice || 0 + sum, 0) || 0)
                                                                    ).toLocaleString()
                                                                }
                                                            </TableCell>
                                                            <TableCell className={"text-right"}>
                                                                {estimate.completeCarId !== null ?
                                                                    ((estimate?.carGradeCost || 0) + (estimate?.carColorCost || 0) + (estimate?.estimateProducts?.reduce((sum, product) => product.productCost || 0 + sum, 0) || 0)).toLocaleString()
                                                                    :
                                                                    ((estimate?.estimateProducts?.reduce((sum, product) => product.productCost || 0 + sum, 0) || 0)).toLocaleString()
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                        <Variant estimate={estimate}/>
                                    </Grid>
                                </Grid>
                                <Grid container={true} className={'mb-50 mt-15'}>
                                    <Grid item={true} xs={9} sm={10} md={10} lg={11}/>
                                    <Grid item={true} xs={3} sm={2} md={2} lg={1}>
                                        <ChargesButton estimateId={props.id}/>
                                    </Grid>
                                </Grid>

                            </Container>
                        }
                    </form>
                )}
            />
        </>
    );
};

const ChargesButton = (props: { estimateId: number }) => {
    return (
        <Button
            label={'諸費用'}
            variant="contained"
            color={'default'}
            component={Link}
            to={{
                pathname: `/estimatesCharges/${props.estimateId}/show`,
            }}
        />
    );
};

const ChangeDownloadFlag = ({...source}) => {
    const dataProvider = useDataProvider();
    const handleClick = () => {
        source.record.downloadFlag = false;
        delete source.record.estimateProducts;
        const params = {
            id: source.id,
            data: {...source.record},
            previousData: {id: source.id},
        }
        params.data.estimateVariantItemProductPrices = undefined
        params.data.estimateVariantItems = undefined
        dataProvider.update('estimates', params).then(() => {
            window.location.reload();
        })
    }
    if (source.record.downloadFlag) {
        return (
            <Button onClick={handleClick} className={source.className} size={'small'} label={source.label}/>
        )
    } else {
        return <></>
    }
}

export default EstimatesId;
