import React, {useEffect} from "react";
import {DeleteWithConfirmIconButton} from "@react-admin/ra-editable-datagrid";
import {
    AppBar,
    Button,
    Datagrid,
    EditButton,
    ReferenceManyField,
    TextField,
    TopToolbar,
    useRecordContext
} from "react-admin";
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas"
import useVariantClasses from "../variantClasses/variantClassesHooks";
import {ApiVariantClass} from "../../openapi/model/api-variant-class";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import {Box, Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import TextFieldWithMultiline from "../../common/TextFieldWithMultiline";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";


interface ListActionProps {
    variantClassId: number;
}

const ListActions: React.FC<ListActionProps> = (props) => {
    return (
        <TopToolbar>
            <Button
                component={Link}
                to={{
                    pathname: '/variantItems/create',
                    state: {record: {variantClassId: props.variantClassId}}
                }}
                label={'作成'}
            />
        </TopToolbar>
    );
};

const VariantItemsListComponent: React.FC<any> = (props) => {
    const {variantClasses, getByVariantClassesId} = useVariantClasses();
    const fetchVariantClasses = () => {
        if (props.location.pathname.toString().split('/')[2]) {
            getByVariantClassesId(props.location.pathname.toString().split('/')[2]).then();
        }
    }
    useEffect(fetchVariantClasses, []);
    return (
        <div>
            {variantClasses &&
                <>
                    <ScreenName variantClasses={variantClasses}/>
                    <ListActions variantClassId={variantClasses.id!!}/>
                    <ReferenceManyField
                        perPage={MAX_FETCH_DATA_SIZE}
                        reference="variantItems"
                        target="variantClassesId"
                        filter={{variantClassId: props.id}}
                        addLabel={false}
                        sort={{field: 'position', order: 'ASC'}}
                        fullWidth
                    >
                        <Datagrid>
                            <TextField source="name" label={'規格項目名'}/>
                            <TextFieldWithMultiline source="explanation" label={'説明'}/>
                            {variantClasses.suspension &&
                                <TextFieldWithCommas source="lowdown" label={'ローダウン量'} textAlign={'right'}/>
                            }
                            {variantClasses.wheelSize &&
                                <TextFieldWithCommas source="inches" label={'サイズ'} textAlign={'right'}/>
                            }
                            {variantClasses.wheelSize &&
                                <TextFieldWithCommas source="tireRatio" label={'タイヤ比率'} textAlign={'right'}/>
                            }
                            <TextFieldWithCommas source="price" label={'売価'} textAlign={'right'}/>
                            <TextFieldWithCommas source="cost" label={'原価'} textAlign={'right'}/>
                            <TextFieldWithCommas source="priceForPartsOnly" label={'部品売価'} textAlign={'right'}/>
                            <TextFieldWithCommas source="originalPrice" label={'定価'} textAlign={'right'}/>
                            <TextFieldWithCommas source="position" label={'表示順序'} textAlign={'right'}/>
                            <div className={'actionButtons'}>
                                <EditDataBtn {...props} className={'mr-30'}/>
                                <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                               redirect={`/variantClasses/${variantClasses?.id}/show`}/>
                            </div>
                        </Datagrid>
                    </ReferenceManyField>
                </>
            }
        </div>
    )
}

interface ScreenNameProps {
    variantClasses?: ApiVariantClass;
}

const ScreenName: React.FC<ScreenNameProps> = (props) => {
    return (
        <>
            {props.variantClasses && <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        variantClasses={props.variantClasses}
                        variantItems={true}
                    />
                </div>
            </AppBar>}
            <Box p={2} mt={2}>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true}>
                        <Button
                            component={Link}
                            variant="contained"
                            color={'default'}
                            to={{
                                pathname: `/variantClasses`
                            }}
                            label={'戻る'}
                        />
                    </Grid>
                    <Grid item={true} spacing={2}>
                        <Button
                            component={Link}
                            variant="contained"
                            color={'default'}
                            to={{
                                pathname: `/variantItemImages/${props.variantClasses?.id}`
                            }}
                            label={'規格項目画像編集'}
                            className={'mr-30'}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}


const BASE_PATH = '/variantItems';
const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''} basePath={BASE_PATH}/>);

};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record}
                                         resource={'variantItems'}
                                         mutationMode={'undoable'}
                                         basePath={BASE_PATH}
                                         redirect={`/variantClasses/${record.variantClassId}/show`}
    />);
};

export default VariantItemsListComponent;
