import React, {Dispatch, SetStateAction} from "react";
import {DataProviderProxy} from "ra-core";
import {useCompleteCarImageHooks} from "./completeCarImageHooks";
import SortableImageList from "../../common/image/SortableImageList";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {ResourceNames} from "../../config/const";

export interface CompleteCarImageProps {
    completeCarImageClass: number;
    completeCarId?: number;
    dataProvider: DataProviderProxy;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData: TempFileData[];
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    images: ListPhotosType[] | undefined;
    maxFileCount?: number;
}

const CompleteCarImage: React.FC<CompleteCarImageProps> = (props) => {
    useCompleteCarImageHooks(props);
    return (
        <>
            <SortableImageList imageArray={props.images}
                               dataProvider={props.dataProvider}
                               resource={ResourceNames.COMPLETE_CAR_IMAGE_RESOURCE}
                               isUpdating={!!props.completeCarId}
                               setPathData={props.setPathData}
                               pathData={props.pathData}
                               setImages={props.setImages}
                               imageSourceName={ResourceNames.COMPLETE_CAR_IMAGE_RESOURCE + props.completeCarImageClass.toString()}
                               maxFileCount={props.maxFileCount}
            />
        </>
    );
}

export default CompleteCarImage
