import React, {
    ChangeEvent,
    Dispatch,
    forwardRef,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import {Grid, TableCell, TableRow} from "@material-ui/core";
import {ApiEstimate, ApiEstimates} from "../../openapi";
import {ESTIMATE_PHASE, MAX_FETCH_DATA_SIZE} from "../../config/const";
import {useDataProvider} from "react-admin";
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import {IdName} from "../../common/IdName";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import MenuItem from "@material-ui/core/MenuItem";


export interface RecommendedCarsEstimateListFunctions {
    save: (
        recommendedCarId: number,
        estimateList: ApiEstimate[] | undefined
    ) => void;
}

interface EstimateProps {
    setEstimateList: Dispatch<SetStateAction<ApiEstimate[] | undefined>>;
    recommendedCarId?: number;
}


export const RecommendedCarsEstimateList: React.ForwardRefRenderFunction<RecommendedCarsEstimateListFunctions, EstimateProps> = (props, ref) => {
    const [estimate, setEstimate] = useState<ApiEstimates>()
    const [estimated, setEstimated] = useState<ApiEstimate[]>()
    const dataProvider = useDataProvider();
    const [userChoices, setUserChoices] = useState<IdName[]>()


    useImperativeHandle(ref, () => ({
        async save(recommendedCarId: number, estimateList: ApiEstimate[] | undefined) {
            estimateList?.map(async (data) => {
                delete data.estimateProducts
                delete data.customer
                const params = {
                    id: Number(data.id),
                    data: {...data},
                    previousData: {id: Number(data.id)},
                }
                await dataProvider.update('estimates', params)
            })

        },

    }));

    const loadData = () => {
        (async () => {
            if (props.recommendedCarId == undefined || props.recommendedCarId == 0)
                return;

            const data = await dataProvider.getList('estimates', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'createdAt', order: 'ASC'},
                filter: {
                    recommendedCarId: props.recommendedCarId,
                    phase: ESTIMATE_PHASE.ORDERED.value,
                },
            });
            const fetched = data as ApiEstimates
            setEstimate(fetched)
        })();
    }
    const choices = () => {
        (async () => {
            const user = await dataProvider.getList('users', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'createdAt', order: 'asc'},
                filter: {},
            });

            const choices = new Array<IdName>();
            user.data.map(data => {
                choices.push({
                    id: Number(data.id!!),
                    name: data.name,
                });
            })

            setUserChoices(choices)
            loadData();
        })();
    }

    useEffect(choices, [props.recommendedCarId])

    const checkedCheckbox = async (e: React.MouseEvent<HTMLLabelElement>, estimates: ApiEstimate, id: number) => {
        if (e.currentTarget.id === 'advancePaymentPaid') {
            estimates.advancePaymentPaid = !estimates.advancePaymentPaid
        }
        if (e.currentTarget.id === 'orderContacted') {
            estimates.orderContacted = !estimates.orderContacted
        }
        if (e.currentTarget.id === 'contractedStatusContacted') {
            estimates.contractedStatusContacted = !estimates.contractedStatusContacted
        }
        if (e.currentTarget.id === 'inNegotiation') {
            estimates.inNegotiation = !estimates.inNegotiation
        }
        if (e.currentTarget.id === 'sold') {
            estimates.sold = !estimates.sold
        }

        const array: React.SetStateAction<ApiEstimate[]> = []
        estimated?.map((item: ApiEstimate) => {
            array.push(item)
        })
        estimate?.data?.map((item: ApiEstimate) => {
            array.push(item)
        })

        const newArray = array.filter(item => item.id !== estimates.id)

        newArray.push(estimates as ApiEstimate)

        newArray.map(data => {
            if (data.id !== id && e.currentTarget.id === 'sold') {
                data.sold = false;
            }
            if (data.id !== id && e.currentTarget.id === 'inNegotiation') {
                data.inNegotiation = false;
            }
        })
        setEstimated(newArray)
        props.setEstimateList(newArray)
    };

    const changeName = async (estimates: ApiEstimate, e: ChangeEvent<{ name?: string, value: unknown }>, id: number) => {
        estimates.userId = Number(e.target.value)

        const array: React.SetStateAction<ApiEstimate[]> = []
        estimated?.map((item: ApiEstimate) => {
            array.push(item)
        })

        const newArray = array.filter(item => item.id !== estimates.id)

        newArray.push(estimates as ApiEstimate)
        setEstimated(newArray)
        props.setEstimateList(newArray)
    }

    return (
        <Grid>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>見積番号</TableCell>
                        <TableCell>見積日</TableCell>
                        <TableCell>顧客名</TableCell>
                        <TableCell>都道府県</TableCell>
                        <TableCell>担当者</TableCell>
                        <TableCell>受注連絡済</TableCell>
                        <TableCell>手付金支払済</TableCell>
                        <TableCell>入金済</TableCell>
                        <TableCell>(未)成約連絡済</TableCell>
                        <TableCell>商談中</TableCell>
                        <TableCell>売却</TableCell>
                    </TableRow>
                    <>
                        {estimate?.data?.map((recommendedCarsEstimate, index) => {
                            return (
                                <>
                                    {recommendedCarsEstimate && userChoices &&
                                        <TableRow>
                                            <TableCell>
                                                <a className={"MuiButtonBase-root MuiButton-root MuiButton-contained RaButton-button-6 ml-10 MuiButton-containedSizeSmall MuiButton-sizeSmall"}
                                                   target={'_blank'}
                                                   href={`/estimates/${recommendedCarsEstimate.id}/show`}
                                                >
                                                    {recommendedCarsEstimate.id}
                                                </a>
                                            </TableCell>
                                            <TableCell>{moment(recommendedCarsEstimate.estimateDate).format("YYYY/MM/DD HH:mm")}</TableCell>
                                            <TableCell>{recommendedCarsEstimate.customer?.lastName!}{recommendedCarsEstimate.customer?.firstName!}</TableCell>
                                            <TableCell>{recommendedCarsEstimate.addressee1}</TableCell>
                                            <TableCell key={recommendedCarsEstimate.id}>
                                                <FormControlLabel
                                                    id={'userId'}
                                                    htmlFor={`${props.recommendedCarId}~${recommendedCarsEstimate.id}`}
                                                    key={recommendedCarsEstimate.id}
                                                    control={
                                                        <Select
                                                            color={"primary"}
                                                            defaultValue={recommendedCarsEstimate.userId}
                                                            value={recommendedCarsEstimate.userId || ""}
                                                            onChange={(e) => changeName(recommendedCarsEstimate, e, recommendedCarsEstimate.id!)}
                                                        >
                                                            {userChoices.map((userChoice) => (
                                                                <MenuItem
                                                                    value={userChoice.id}>{userChoice.name}</MenuItem>
                                                            ))
                                                            })
                                                        </Select>

                                                    }
                                                    label={''}
                                                    labelPlacement="top"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    id={'orderContacted'}
                                                    htmlFor={`${props.recommendedCarId}~${recommendedCarsEstimate.id}`}
                                                    key={recommendedCarsEstimate.id}
                                                    checked={recommendedCarsEstimate.orderContacted}
                                                    control={
                                                        <Checkbox
                                                            id={'orderContacted'}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={''}
                                                    labelPlacement="top"
                                                    onClick={(e) => {
                                                        checkedCheckbox(e, recommendedCarsEstimate, recommendedCarsEstimate.id!).then()
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {recommendedCarsEstimate.depositPaid ? '✓' : ''}
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    id={'advancePaymentPaid'}
                                                    htmlFor={`${props.recommendedCarId}~${recommendedCarsEstimate.id}`}
                                                    key={recommendedCarsEstimate.id}
                                                    checked={recommendedCarsEstimate.advancePaymentPaid}
                                                    control={
                                                        <Checkbox
                                                            id={'advancePaymentPaid'}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={''}
                                                    labelPlacement="top"
                                                    onClick={(e) => {
                                                        checkedCheckbox(e, recommendedCarsEstimate, recommendedCarsEstimate.id!).then()
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    id={'contractedStatusContacted'}
                                                    htmlFor={`${props.recommendedCarId}~${recommendedCarsEstimate.id}`}
                                                    key={recommendedCarsEstimate.id}
                                                    checked={recommendedCarsEstimate.contractedStatusContacted}
                                                    control={
                                                        <Checkbox
                                                            id={'contractedStatusContacted'}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={''}
                                                    labelPlacement="top"
                                                    onClick={(e) => {
                                                        checkedCheckbox(e, recommendedCarsEstimate, recommendedCarsEstimate.id!).then()
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    id={'inNegotiation'}
                                                    htmlFor={`${props.recommendedCarId}~${recommendedCarsEstimate.id}`}
                                                    key={recommendedCarsEstimate.id}
                                                    checked={recommendedCarsEstimate.inNegotiation}
                                                    control={
                                                        <input
                                                            type="radio"
                                                            id={'inNegotiation'}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={''}
                                                    labelPlacement="top"
                                                    onClick={(e) => {
                                                        checkedCheckbox(e, recommendedCarsEstimate, recommendedCarsEstimate.id!).then()
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    id={'sold'}
                                                    htmlFor={`${props.recommendedCarId}~${recommendedCarsEstimate.id}`}
                                                    key={recommendedCarsEstimate.id}
                                                    checked={recommendedCarsEstimate.sold}
                                                    control={
                                                        <input
                                                            type="radio"
                                                            id={'sold'}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={''}
                                                    labelPlacement="top"
                                                    onClick={(e) => {
                                                        checkedCheckbox(e, recommendedCarsEstimate, recommendedCarsEstimate.id!).then()
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    }
                                </>
                            );
                        })}
                    </>
                </TableBody>
            </Table>
        </Grid>
    );
}

export default forwardRef(RecommendedCarsEstimateList);
