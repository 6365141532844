import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'

import ReactCrop, {centerCrop, Crop, makeAspectCrop, PercentCrop, PixelCrop} from 'react-image-crop'
import canvasPreview from "./CanvasPreview";

import 'react-image-crop/dist/ReactCrop.css'
import {Grid} from "@material-ui/core";
import {ImageInput, required, Validator} from "react-admin";
import {maxFileSize} from "../../config/const";
import {convertFilesToTempFileData} from "../../common/fileUtils";
import {TempFileData} from "../../modules/temporaryFileModule";
import {useForm} from "react-final-form";

interface ImageCroppingFieldProps<T> {
    sourceName: string;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    imageArray: T;
    required?: boolean;
    setFlag?: Dispatch<SetStateAction<boolean>>;
}

const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

type ImageCroppingFieldList<T = any> = ImageCroppingFieldProps<T>;

const RecommendedCarsImageCroppingField: React.FC<ImageCroppingFieldList> = (props) => {
    const form = useForm();
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(1 / 1)
    const [acceptedFiles, setAcceptedFiles] = useState(new Array<File>());
    const values = form.getState().values;
    const [validations, setValidations] = useState(Array<Validator>());
    const [validationCounter, setValidationCounter] = useState(0);

    const onSelectFile = async (files: File) => {
        setAcceptedFiles([]);
        if (files) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setImgSrc(reader.result && reader.result.toString() || ''),
            )
            reader.readAsDataURL(files)
        }
    }

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (aspect) {
            setAspect(1);
            const {width, height} = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    const load = () => {
        const t = setTimeout(() => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                makeClientCrop(completedCrop).then();
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        }, 100);

        return () => {
            clearTimeout(t)
        };
    }

    useEffect(load, [completedCrop, scale, rotate]);

    const handleValidation = () => {
        const currentValidations = [];
        // create
        if (!values['id']) {
            if (props.required !== false)
                currentValidations.push(required());
            setValidations(currentValidations);
            setValidationCounter(1);
        }
        if (props.imageArray) {
            if (props.required !== false && (!props.imageArray || props.imageArray.length === 0)) {
                currentValidations.push(required());
            }
            setValidations(currentValidations);
            setValidationCounter(validationCounter + 1);
        }
    }

    useEffect(handleValidation, [props.imageArray, previewCanvasRef.current]);

    const onCropChange = (pixelCrop: PixelCrop, percentCrop: PercentCrop) => {
        setCrop(percentCrop);
        makeClientCrop(pixelCrop).then();
    }

    const onCropComplete = (crop: PixelCrop) => {
        setCompletedCrop(crop);
        makeClientCrop(crop).then();
    };

    const makeClientCrop = async (crop: PixelCrop) => {
        if (imgRef.current && crop.width && crop.height) {
            await getCroppedImg(
                imgRef.current,
                crop
            );
        }
    }

    const getCroppedImg = (image: HTMLImageElement, crop: PixelCrop) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = 1;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            throw new Error('No 2d context')
        }
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    try {
                        setAcceptedFiles([]);
                        let files: File[] = [], min = 1, max = 100;
                        let random = min + (Math.random() * (max - min));
                        const newImage = new File([blob], `thumbnail${random * 2}.jpeg`, {type: 'image/jpeg'});
                        files.push(newImage);
                        setAcceptedFiles(files);
                    } catch (e) {
                        console.log('Error creating new file!');
                    }
                    const fileUrl = window.URL.createObjectURL(blob);
                    resolve(fileUrl);
                },
                'image/jpeg',
                1
            );
        });
    }

    const handleFileConversion = () => {
        console.log('acceptedFiles', acceptedFiles)
        const t = setTimeout(async () => {
            if (acceptedFiles.length) {
                await convertFilesToTempFileData(props.setPathData, acceptedFiles, props.setFlag)
                    .then(() => console.log('Done!!!'));
            }
        }, 1000);

        return () => {
            clearTimeout(t)
        }
    };

    useEffect(handleFileConversion, [acceptedFiles]);

    return (
        <>
            <ImageInput
                source={props.sourceName}
                accept="image/*"
                label=""
                multiple={false}
                maxSize={maxFileSize}
                onChange={(files: File) => onSelectFile(files)}
                validate={validations}
                className={'cropImgInput'}
                children={
                    <>
                        {imgSrc && (
                            <ReactCrop
                                crop={crop}
                                ruleOfThirds={true}
                                onChange={onCropChange}
                                onComplete={onCropComplete}
                                aspect={aspect}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        )}
                        <Grid>
                            {completedCrop && (
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                    }}
                                />
                            )}
                        </Grid>
                    </>
                }
            />
        </>
    )
}
export default RecommendedCarsImageCroppingField;
