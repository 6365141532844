import {EditableDatagrid, EditRowButton, RowForm} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {
    AutocompleteInput,
    List,
    maxLength,
    minValue,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    TextField,
    useNotify,
} from 'react-admin';
import {floatValidation} from '../../common/validate';
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

const CarInsurancesList: React.FC = (props: any) => {
    return (
        <List
            {...props}
            hasCreate={false}
            empty={false}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <EditableDatagrid
                undoable={true}
                editForm={<DataForm/>}
                noDelete={false}
                className={'listTable'}
            >
                <ReferenceField
                    label="科率区分/月数"
                    source="carInsuranceClassId"
                    reference="carInsuranceClasses"
                    link={false}
                >
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="months37" label={'37ヶ月分'}/>
                <TextField source="months36" label={'36ヶ月分'}/>
                <TextField source="months25" label={'25ヶ月分'}/>
                <TextField source="months24" label={'24ヶ月分'}/>
                <TextField source="months13" label={'13ヶ月分'}/>
                <TextField source="months12" label={'12ヶ月分'}/>
                <div className={'actionButtons'}>
                    <EditRowButton/>
                </div>
            </EditableDatagrid>
        </List>
    );
}

const DataForm: React.FC = (props) => {
    const notify = useNotify();
    const validateName = [required(), maxLength(50)];
    const validateNumber = [required(), minValue(0), floatValidation];
    return (
        <RowForm {...props}>
            <ReferenceInput
                label="科率区分/月数"
                source="carInsuranceClassId"
                reference="carInsuranceClasses"
                perPage={MAX_FETCH_DATA_SIZE}
                validate={validateName}
            >
                <AutocompleteInput source="name" disabled={true}/>
            </ReferenceInput>
            <NumberInput source="months37" validate={validateNumber} label={'37ヶ月分'}/>
            <NumberInput source="months36" validate={validateNumber} label={'36ヶ月分'}/>
            <NumberInput source="months25" validate={validateNumber} label={'25ヶ月分'}/>
            <NumberInput source="months24" validate={validateNumber} label={'24ヶ月分'}/>
            <NumberInput source="months13" validate={validateNumber} label={'13ヶ月分'}/>
            <NumberInput source="months12" validate={validateNumber} label={'12ヶ月分'}/>
        </RowForm>
    )
};

export default CarInsurancesList;
