import React from "react";
import {MenuItem, MultiLevelMenu,} from "@react-admin/ra-navigation";
import {usePermissions} from "react-admin";
import {ROLES} from "../../config/const";

const MenuBar: React.FC = () => {
    const {permissions} = usePermissions();
    return (
        <MultiLevelMenu variant="categories">
            {permissions === ROLES.ADMIN &&
                <>
                    <MenuItem
                        name="車種"
                        to="/cars"
                        exact
                        label="車種"
                        // icon={<DashboardIcon />}
                    />
                    <MenuItem
                        name="商品カテゴリ"
                        to="/productCategories"
                        exact
                        label="商品カテゴリ"
                        // icon={<DashboardIcon />}
                    />
                    {/* The empty filter is required to avoid falling back to the previously set filter */}
                    <MenuItem
                        name="商品"
                        to="/products"
                        label="商品"
                        // icon={<PeopleIcon />}
                    />
                    <MenuItem
                        className='margin'
                        name="コンプリートカー"
                        to="/completeCars"
                        exact
                        label="コンプリートカー"
                        // icon={<SettingsIcon />}
                    />
                </>
            }
            <MenuItem
                name="見積"
                to="/estimates"
                exact
                label="見積"
                // icon={<DashboardIcon />}
            />
            {permissions === ROLES.ADMIN &&
                <>
                    <MenuItem
                        name="テンプレート"
                        to="/templates"
                        exact
                        label="テンプレート"
                        // icon={<DashboardIcon />}
                    />
                    <MenuItem
                        name="諸費用"
                        to="/taxByEnvironmentalPerformances"
                        exact
                        label="諸費用"
                        // icon={<DashboardIcon />}
                    >
                        {/* CardContent to get consistent spacings */}
                        {/* Note that we must wrap our MenuItem components in a Menu */}
                        <MenuItem
                            name="自動車税"
                            to={'/autoMobileTaxes'}
                            label="自動車税"
                        />
                        <MenuItem
                            name="環境性能割"
                            to={'/taxByEnvironmentalPerformances?filter={"type":"環境性能割"}'}
                            label="環境性能割"
                        />
                        <MenuItem
                            name="重量税"
                            to={'/weightTaxes'}
                            label="重量税"
                        />
                        <MenuItem
                            name="自賠責保険料"
                            to={'/carInsurances?filter={"type":"自賠責保険料"}'}
                            label="自賠責保険料"
                        />
                        <MenuItem
                            name="登録手続費用"
                            to={'/registrationFees'}
                            label="登録手続費用"
                        />
                        <MenuItem
                            name="納車費用"
                            to={'/carShippingFees'}
                            label="納車費用"
                        />
                        <MenuItem
                            name="諸費用全般設定"
                            to={'/generalCharges/1/show'}
                            label="諸費用全般設定"
                        />
                    </MenuItem>
                    <MenuItem
                        name="全般設定"
                        to="/taxByEnvironmentalPerformances"
                        exact
                        label="全般設定"
                        // icon={<DashboardIcon />}
                    >
                        <MenuItem
                            name="設定"
                            to={'/setting/1/show?filter={"type":"設定"}'}
                            label="設定"
                        />
                        <MenuItem
                            name="消費税設定"
                            to={'/consumptionTaxes'}
                            label="消費税設定"
                        />
                    </MenuItem>
                    <MenuItem
                        name="エアロシリーズ管理"
                        to={'/aeroSeries'}
                        exact
                        label="エアロシリーズ管理"
                    />
                </>
            }

            <MenuItem
                name="特選車"
                to={'/recommendedCars'}
                exact
                label="特選車"
            />
            {permissions === ROLES.ADMIN &&
                <>
                    <MenuItem
                        name="関連特選車"
                        to={'/recommendedCarRelatedCarSettings'}
                        exact
                        label="関連特選車"
                    />

                    <MenuItem
                        name="店舗"
                        to={'/shops'}
                        exact
                        label="店舗"
                    />
                    <MenuItem
                        name="スタッフ"
                        to={'/users'}
                        exact
                        label="スタッフ"
                    />
                    <MenuItem
                        name="規格分類管理"
                        to={'/variantClasses'}
                        exact
                        label="規格分類管理"
                    />
                </>

            }
            <MenuItem
                name="ブランド管理"
                to={'/brands'}
                exact
                label="ブランド管理"
            />
        </MultiLevelMenu>
    );
};

export default MenuBar
