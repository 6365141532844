import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import useProductVariantHooks from "./productVariantHooks";
import {ApiVariantItem} from "../../openapi";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const ProductSuspensionVariantItems = ({...props}) => {

    const [suspensionVariantClassItems, setSuspensionVariantClassItems] = useState(new Array<ApiVariantItem>());

    const {
        getSuspensionVariantClassItems
    } = useProductVariantHooks({
        productId: props.productId,
    });

    const fetchData = () => {
        const fetchApiData = async () => {
            const variantClassesItems = await getSuspensionVariantClassItems()
            setSuspensionVariantClassItems(variantClassesItems);
        };
        fetchApiData().then();
    }

    useEffect(fetchData, []);

    const onChangeHandleSelectedValue = (id?: String) => {
        props.handleSelectedSuspensionVariantItems(id);
    }

    return (
        <>
            <Box mt={2}>
                <Grid>
                    <Typography variant="h6" id="tableTitle" component="div">サスペンション設定</Typography>
                </Grid>
                <Grid>
                    {suspensionVariantClassItems && props.savedSuspensionVariantItems !== undefined && suspensionVariantClassItems.map((data) => {
                        return (
                            <FormControlLabel
                                htmlFor={`${data.id}`}
                                key={data.id}
                                onChange={() => onChangeHandleSelectedValue(`${data.id}`)}
                                control={
                                    <Checkbox
                                        id={`${data.id}`}
                                        color="primary"
                                        {...props.savedSuspensionVariantItems.includes(`${data.id}`) ? {defaultChecked: true} : {defaultChecked: false}}
                                    />
                                }
                                label={`${data.name}`}
                            />
                        )
                    })}
                </Grid>
            </Box>
        </>
    );
}

export default ProductSuspensionVariantItems;
