import {ApiCar, ApiCarGrade, ApiCarModel, ApiProduct} from "../openapi";
import React from "react";
import {Link} from 'react-router-dom';
import {Button} from "react-admin";
import {ApiVariantClass} from "../openapi/model/api-variant-class";

interface BreadcrumbTrailProps {
    carModel?: ApiCarModel;
    carGrade?: ApiCarGrade;
    car?: ApiCar;
    estimateId?: number;
    product?: ApiProduct;
    isJNumberAndInset?: boolean;
    isVariantItemProductPrice?: boolean;
    variantClasses?: ApiVariantClass;
    variantItems?: boolean;
    variantItemImages?: boolean;
}

const BreadcrumbTrail: React.FC<BreadcrumbTrailProps> = (props) => {
    console.log(props);
    return (
        <>
            {props.carModel &&
                <>
                    <Button
                        component={Link}
                        label={props.carModel?.carName}
                        to={
                            {
                                pathname: `/cars/`
                            }
                        }
                        variant={'contained'}
                        color={'secondary'}
                    />
                    - &gt;
                </>
            }
            {props.car &&
                <>
                    <Button
                        label={props.car?.name}
                        component={Link}
                        to={{
                            pathname: `/cars/`
                        }}
                        variant={'contained'}
                        color={'secondary'}
                    />
                    - &gt;
                </>
            }
            {props.carModel &&
                <>
                    <Button label={props.carModel?.name}
                            component={Link}
                            to={{
                                pathname: `/carModels/list/${props.carModel?.carId}`
                            }}
                            variant={'contained'}
                            color={'secondary'}
                    />
                    - &gt;
                </>
            }
            {props.carGrade && !props.product &&
                <>
                    <Button label={props.carGrade?.name}
                            component={Link}
                            to={{
                                pathname: `/carModels/${props.carModel?.id}/show`
                            }}
                            variant={'contained'}
                            color={'secondary'}
                    />
                    - &gt;
                </>
            }
            {props.estimateId &&
                <>
                <span>
                    見積管理
                </span>
                    - &gt;
                    <span>
                    {props.estimateId}
                </span>
                </>
            }
            {props.product && !props.carGrade && !props.isJNumberAndInset && !props.isVariantItemProductPrice &&
                <>
                    <span>
                        {props.product.name}
                    </span>
                    - &gt;
                    <span>
                        商品規格設定
                    </span>
                </>
            }
            {props.product && props.carGrade &&
                <>
                    <span>
                        {props.product.name}
                    </span>
                    - &gt;
                    <span>
                        {props.carGrade.carModel?.carName} {props.carGrade.carModel?.name}
                    </span>
                    - &gt;
                    <span>
                        {props.carGrade.name}
                    </span>
                    - &gt;
                    <span>

                    </span>
                </>
            }
            {props.product && props.isJNumberAndInset &&
                <>
                    <span>
                        {props.product.name}
                    </span>
                    - &gt;
                    <span>
                        J数・インセット設定管理
                    </span>
                </>
            }
            {props.product && props.isVariantItemProductPrice &&
                <>
                    <span>
                        {props.product.name}
                    </span>
                    - &gt;
                    <span>
                        規格項目価格設定
                    </span>
                </>
            }
            {props.variantClasses &&
                <>
                    <span>
                        {props.variantClasses?.name}
                    </span>
                    - &gt;
                    <span>
                        {props.variantItems ? `規格項目管理` : props.variantItemImages ? '商品規格項目画像編集' : `規格分類編集`}
                    </span>
                </>
            }
        </>
    );

}

export default BreadcrumbTrail;
