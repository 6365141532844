import {
    DeleteWithConfirmIconButton,
    EditableDatagrid,
    EditRowButton,
    RowForm,
} from '@react-admin/ra-editable-datagrid';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    AppBar,
    AutocompleteInput,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    TextField,
    useGetOne,
    useRecordContext,
} from 'react-admin';
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import {ApiCarGrade, ApiNoCombinationVariant, ApiProduct} from "../../openapi";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import CloseButton from "../../common/CloseButton";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import NoCombinationVariantCsvImportComponent from "./NoCombinationVariantCsvImportComponent";

const BASE_PATH = '/noCombinationVariants';
const RESOURCE = 'noCombinationVariants';

interface ScreenNameProps {
    product: ApiProduct;
    carGrade: ApiCarGrade;
}

const ScreenName: React.FC<ScreenNameProps> = (props) => {
    return (
        <AppBar {...props} title={'foo'}>
            <div className={'app-bar-title'}>
                <BreadcrumbTrail
                    product={props.product}
                    carGrade={props.carGrade}
                />
                商品不可規格設定
            </div>
        </AppBar>
    );
}

const NoCombinationVariantsList: React.FC = (props: any) => {
    const {productId, carGradeId} = useParams<{ productId: string, carGradeId: string }>();
    const carGrade = useGetOne('carGrades', Number(carGradeId)).data as ApiCarGrade;
    const product = useGetOne('products', Number(productId)).data as ApiProduct;
    const baseRoute = `${BASE_PATH}/${productId}/${carGradeId}`;

    return (
        <>
            <ScreenName carGrade={carGrade} product={product}/>
            <div className={'mt-30'}>
                <CloseButton/>
                <NoCombinationVariantCsvImportComponent productId={Number(productId)}
                                                        carGradeId={Number(carGradeId)}/>
            </div>
            <List
                {...props}
                hasCreate
                empty={false}
                sort={{field: 'id', order: 'ASC'}}
                exporter={false}
                bulkActionButtons={false}
                filter={{carGradeId: Number(carGradeId), productId: Number(productId)}}
                basePath={baseRoute}
                resource={RESOURCE}
            >
                <EditableDatagrid
                    undoable={true}
                    createForm={<NoCombinationVariantsForm product={product} carGrade={carGrade}/>}
                    editForm={<NoCombinationVariantsForm product={product} carGrade={carGrade}/>}
                    noDelete={false}
                    className={'listTable productsList'}
                >
                    <ReferenceField
                        reference={'carGrades'}
                        source={'carGradeId'}
                        label={'グレード'}
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        reference={'products'}
                        source={'productId'}
                        label={'商品'}
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        reference={'variantItems'}
                        source={'variantItemId1'}
                        label={'選択不可規格1'}
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        reference={'variantItems'}
                        source={'variantItemId2'}
                        label={'選択不可規格2'}
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        reference={'variantItems'}
                        source={'variantItemId3'}
                        label={'選択不可規格3'}
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        reference={'variantItems'}
                        source={'variantItemId4'}
                        label={'選択不可規格4'}
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        reference={'variantItems'}
                        source={'variantItemId5'}
                        label={'選択不可規格5'}
                        link={false}
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <div className={'actionButtons'}>
                        <EditDataBtn {...props} basePath={baseRoute} label={''} resource={RESOURCE}/>
                        <DeleteDataBtn className={'ml-70'} {...props} basePath={baseRoute} confirmTitle={'削除'}
                                       confirmContent={'削除しても宜しいでしょうか。'}/>
                    </div>
                </EditableDatagrid>
            </List>
        </>
    );
};

const NoCombinationVariantsForm: React.FC<any> = (props) => {
    const [validateName, setValidate] = useState([required()]);
    const [isValidated, setIsValidated] = useState<number>(0);
    const handleTransform = (data: ApiNoCombinationVariant) => {
        if (!data.variantItemId1 && (data.variantItemId2 || data.variantItemId3 || data.variantItemId4 || data.variantItemId5)) {
            if (data.variantItemId2) {
                data.variantItemId1 = data.variantItemId2
                delete data.variantItemId2
            } else if (data.variantItemId3) {
                data.variantItemId1 = data.variantItemId3
                delete data.variantItemId3
            } else if (data.variantItemId4) {
                data.variantItemId1 = data.variantItemId4
                delete data.variantItemId4
            } else if (data.variantItemId5) {
                data.variantItemId1 = data.variantItemId5
                delete data.variantItemId5
            }
        }
        const createData = props.id === 'new_record' ? {
            ...data,
            productId: props.product.id,
            carGradeId: props.carGrade.id
        } : null;
        console.log('createData', createData)
        setIsValidated(0);
        return props.id === 'new_record' ? {...createData} : {...data};
    };
    const handleItemsChanged = () => {
        setIsValidated(isValidated + 1);
    };
    const validated = () => {
        setValidate([]);
    };
    useEffect(validated, [isValidated]);

    return (
        <RowForm
            {...props}
            submitOnEnter={false}
            transform={handleTransform}
        >
            {props.id !== 'new_record' &&
                <ReferenceField
                    reference={'carGrades'}
                    source={'carGradeId'}
                    label={'グレード'}
                    link={false}
                >
                    <TextField source="name"/>
                </ReferenceField>}
            {props.id !== 'new_record' &&
                <ReferenceField
                    reference={'products'}
                    source={'productId'}
                    label={'商品'}
                    link={false}
                >
                    <TextField source="name"/>
                </ReferenceField>}
            {props.id === 'new_record' &&
                <TextInputForJapanese disabled label={'グレード'} source="carGradeName"
                                      defaultValue={props.carGrade && props.carGrade.name}>
                    <NumberInput source={"carGradeId"} defaultValue={props.carGrade && props.carGrade.id}/>
                </TextInputForJapanese>
            }
            {props.id === 'new_record' &&
                <TextInputForJapanese disabled source="productName" label={'商品'}
                                      defaultValue={props.product && props.product.name}>
                    <NumberInput source={"productId"} defaultValue={props.product && props.product.id}/>
                </TextInputForJapanese>
            }
            <ReferenceInput
                sort={{field: 'variantClassId', order: 'ASC'}}
                perPage={MAX_FETCH_DATA_SIZE}
                source="variantItemId1"
                reference="variantItems"
                label={"選択不可規格1"}
                filter={{productId: props.product.id, carGradeId: props.carGrade.id}}
                validate={required()}
                onChange={handleItemsChanged}
            >
                <AutocompleteInput optionText="name" resettable={true}/>
            </ReferenceInput>
            <ReferenceInput
                sort={{field: 'variantClassId', order: 'ASC'}}
                perPage={MAX_FETCH_DATA_SIZE}
                source="variantItemId2"
                reference="variantItems"
                label={"選択不可規格2"}
                filter={{productId: props.product.id, carGradeId: props.carGrade.id}}
                validate={validateName}
                onChange={handleItemsChanged}
            >
                <AutocompleteInput optionText="name" resettable={true}/>
            </ReferenceInput>
            <ReferenceInput
                sort={{field: 'variantClassId', order: 'ASC'}}
                perPage={MAX_FETCH_DATA_SIZE}
                source="variantItemId3"
                reference="variantItems"
                label={"選択不可規格3"}
                filter={{productId: props.product.id, carGradeId: props.carGrade.id}}
                validate={validateName}
                onChange={handleItemsChanged}
            >
                <AutocompleteInput optionText="name" resettable={true}/>
            </ReferenceInput>
            <ReferenceInput
                sort={{field: 'variantClassId', order: 'ASC'}}
                perPage={MAX_FETCH_DATA_SIZE}
                source="variantItemId4"
                reference="variantItems"
                label={"選択不可規格4"}
                filter={{productId: props.product.id, carGradeId: props.carGrade.id}}
                validate={validateName}
                onChange={handleItemsChanged}
            >
                <AutocompleteInput optionText="name" resettable={true}/>
            </ReferenceInput>
            <ReferenceInput
                sort={{field: 'variantClassId', order: 'ASC'}}
                perPage={MAX_FETCH_DATA_SIZE}
                source="variantItemId5"
                reference="variantItems"
                label={"選択不可規格5"}
                filter={{productId: props.product.id, carGradeId: props.carGrade.id}}
                validate={validateName}
                onChange={handleItemsChanged}
            >
                <AutocompleteInput optionText="name" resettable={true}/>
            </ReferenceInput>
        </RowForm>
    )
};

const EditDataBtn = ({...source}) => {
    return (<EditRowButton {...source} />);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}
                                         redirect={`/noCombinationVariants/${record['productId']}/${record['carGradeId']}`}
                                         resource={RESOURCE}/>);
};

export default NoCombinationVariantsList;
