import React, {useState} from "react";
import useNoCombinationVariantHooks from "./noCombinationVariantHooks";
import {Button, FileField, FileInput, useNotify, useRefresh} from "react-admin";
import Alert from "@material-ui/lab/Alert";
import {Form} from 'react-final-form';

interface NoCombinationVariantCsvImportComponentProps {
    productId: number;
    carGradeId: number;
}

const NoCombinationVariantCsvImportComponent: React.FC<NoCombinationVariantCsvImportComponentProps> = (props: any) => {

    const [importCsv, setImportCsv] = useState(false);
    const [error, setError] = useState<Array<string>>([])
    const notify = useNotify();
    const refresh = useRefresh();

    const {handleUpload, exportNoCombinationVariantCsv} = useNoCombinationVariantHooks({
        productId: props.productId,
        carGradeId: props.carGradeId,
        setImportCsv: setImportCsv,
        setError: setError,
    });

    const save = async () => {
        notify('CSVインポート完了しました。');
        refresh();
    }
    return (
        <>
            {!importCsv &&
                <Button variant="contained" color="secondary" className={'ml-30'}
                        onClick={() => setImportCsv(true)} label={'CSVインポート'}/>
            }
            {importCsv &&
                <Form initialValues={importCsv} onSubmit={save} render={({handleSubmit}) => (
                    <FileInput source="files" label="CSVインポート" accept="text/csv" onChange={handleUpload}>
                        <FileField source="src" title="CSVインポート"/>
                    </FileInput>

                )}
                />
            }
            {error && error.length > 0 &&
                <Alert severity="error">
                    <ul>
                        {error.map((value) => (
                            <li>{value}</li>
                        ))}
                    </ul>
                </Alert>
            }
            <Button variant="contained" onClick={exportNoCombinationVariantCsv} color={'primary'} label={'CSVエクスポート'}
                    className={'ml-10'}/>
        </>
    );
}

export default NoCombinationVariantCsvImportComponent;
