import React, {useEffect, useState} from 'react';
import {AppBar, useDataProvider,} from "react-admin";
import ProductVariantItemImageComponent from "./ProductVariantItemImageComponent";
import {Form} from 'react-final-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {ApiProductVariantItemImageCombination, ApiVariantClass} from "../../openapi";
import useVariantImageHooks from "./ProductVariantImageHooks";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import GoBack from "../../common/GoBackComponent";
import {MAX_FETCH_DATA_SIZE, PAGE_LOCATION} from "../../config/const";
import {useParams} from "react-router-dom";

const ScreenName: React.FC = (props) => {
    return (
        <AppBar {...props} title={'foo'}>
            <div className={'app-bar-title'}>
                規格項目画像編集
            </div>
        </AppBar>
    );
}

const ProductVariantItemImagesList: React.FC<any> = (props) => {

    const {
        getVariantClasses,
        sortVariantClasses,
        getAllProductVariantItemImageCombination,
    } = useVariantImageHooks();
    const [dataVariantClasses, setDataVariantClasses] = useState(new Array<ApiVariantClass>());
    const [dataVariantCombo, setDataVariantCombo] = useState(new Array<ApiProductVariantItemImageCombination>());
    const dataProvider = useDataProvider();
    const {productId} = useParams<{ productId: string }>();

    const loadData = () => {
        (async () => {
            await createData();
            const variantClasses = await getVariantClasses()
            const variantCombo = await getAllProductVariantItemImageCombination(productId);
            setDataVariantClasses(variantClasses);
            setDataVariantCombo(variantCombo)
        })();
    }

    const createData = async () => {
        try {
            await dataProvider.getList('productVariantItemImages/' + productId, {
                pagination: {page: PAGE_LOCATION, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'id', order: 'asc'},
                filter: {},
            });
        } catch (e) {
            console.log(e)
        }

        const variantCombo = await getAllProductVariantItemImageCombination(productId);
        const variantClasses = await getVariantClasses();
        setDataVariantClasses(variantClasses);
        setDataVariantCombo(variantCombo)
    };

    useEffect(loadData, [])

    return (
        <>
            <ScreenName/>
            <Box bgcolor="none" p={5} id='box' className={'searchForm-sizeLarge rm-padding-left'} mt={2}>
                <Grid container={true} spacing={1}>
                    <GoBack/>
                </Grid>
            </Box>
            <Paper className={'mt-15 b-shadow w-70'}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {sortVariantClasses(dataVariantClasses!!)
                                    .map((row: ApiVariantClass) => {
                                        return (
                                            <TableCell align="left" key={row.id}> {row.name} </TableCell>);
                                    })}
                                <TableCell className={'w-30'} align="center">画像</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataVariantCombo!!.map((row, index) => {
                                return (<>
                                    <TableRow>
                                        {sortVariantClasses(dataVariantClasses!!).map((variant: ApiVariantClass) => {
                                            const data = row.variantItems?.find(element => element.variantClassId === variant.id)
                                            if (data) {
                                                return (
                                                    <TableCell align="left" key={data.id}>
                                                        {data.name}
                                                    </TableCell>);
                                            } else {
                                                return (
                                                    <TableCell align="left"> </TableCell>);
                                            }
                                        })}
                                        <TableCell>
                                            <ImageData record={row} productId={productId}/>
                                        </TableCell>
                                    </TableRow>
                                </>);
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>);
};

const ImageData = ({...record}) => {
    const save = async (e: React.FormEvent<HTMLFormElement>) => {
        console.log('save')
    }

    return (<>
        <Form onSubmit={save} render={({handleSubmit}) => (<form onSubmit={handleSubmit}>
            <ProductVariantItemImageComponent {...record}/>
        </form>)}/>
    </>);
};

export default ProductVariantItemImagesList;