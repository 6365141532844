import {EditableDatagrid,} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {List, TextField,} from 'react-admin';
import TextFieldWithMultiline from '../../common/TextFieldWithMultiline';
import {TemplateEdit} from "./TemplatesFormComponent";
import {MyPagination, PER_PAGE} from "../../common/MyPagination";

const TemplatesList: React.FC = (props: any) => {
    return (
        <List
            {...props}
            hasCreate
            empty={false}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <EditableDatagrid
                undouble
                editForm={<TemplateEdit/>}
                noDelete={false}
                className={'editableListTable'}
            >
                <TextField source="name" label={'タイトル'}/>
                <TextFieldWithMultiline source="body" label={'備考'}/>
            </EditableDatagrid>
        </List>
    );
};

export default TemplatesList;
