import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CategoryProducts} from "./RecommendedCarDefaultProductHooks";
import {ApiProduct} from "../../openapi";
import {Grid} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

interface RecommendedCarDefaultProductRadioFieldProps {
    category?: CategoryProducts;
    defaultProductIds: number[] | undefined;
    onChangeRadioButton: (e: any) => void;
    remove: (product: ApiProduct) => void;
}

const RecommendedCarDefaultProductRadioField: React.FC<RecommendedCarDefaultProductRadioFieldProps> = (props: RecommendedCarDefaultProductRadioFieldProps) => {
    const [value, setValue] = useState<number | undefined | null>();
    const handleValue = () => {
        props.category?.carGrades?.map((carGrade) => {
            carGrade.products?.map((product) => {
                if (props.defaultProductIds?.find((defaultProductId) => defaultProductId === product.id) !== undefined) {
                    setValue(product.id)
                }
            });
        });
    }

    useEffect(handleValue, [props.category, props.defaultProductIds]);

    const handleRemove = (product: ApiProduct) => {
        props.remove(product);
        setValue(null);
    }

    return (
        <>
            <RadioGroup
                name="controlled-radio-buttons-group"
                value={value}
                onClick={props.onChangeRadioButton}
            >
                {props.category?.carGrades?.map((carGrade) => {
                    return (
                        carGrade.products?.map((product) => {
                            return (
                                <>
                                    <Grid container={true}>
                                        <Grid item={true}>
                                            <FormControlLabel
                                                value={product.id}
                                                control={
                                                    <Radio checked={value === product.id}/>
                                                }
                                                label={product.name}/>
                                        </Grid>
                                        <Grid item={true}>
                                            {value && value === product.id &&
                                                <DeleteIcon onClick={() => handleRemove(product)} color={"error"}/>
                                            }
                                        </Grid>
                                    </Grid>

                                </>
                            )
                        })
                    )
                })
                }
            </RadioGroup>
        </>
    );
}

export default RecommendedCarDefaultProductRadioField;
