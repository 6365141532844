import {EditableDatagrid} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {
    AutocompleteInput,
    BooleanField,
    CreateButton,
    EditButton,
    Filter,
    FunctionField,
    List,
    minValue,
    NullableBooleanInput,
    ReferenceField,
    ReferenceInput,
    TextField,
    TopToolbar,
    useRecordContext,
} from 'react-admin';
import {floatValidation} from "../../common/validate";
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";
import {Box, Grid} from '@material-ui/core';
import {MyPagination, PER_PAGE} from '../../common/MyPagination';
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

const ListActions = () => (
    <TopToolbar className={'mt-30'}>
        <CreateButton/>
    </TopToolbar>
);

export const RecommendedCarsListComponent: React.FC<any> = (props) => {
    const modelRender = (choice: any) => choice.name ? `${choice.carName}・${choice.name}` : null;
    return (
        <List
            filters={<SearchFilter/>}
            {...props}
            hasCreate={false}
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            className={'filterForBox'}
            pagination={<MyPagination/>}
            actions={<ListActions {...props}/>}
        >
            <EditableDatagrid
                undoable={true}
                editForm={<SearchFilter/>}
                noDelete={false}
                className={'listTable productsList'}
            >
                <div className={'actionButtons'}>
                    <EditDataBtn {...props} label={''}/>
                </div>
                <TextField label="名称" source="name" className={'ellipsis-600 inline-block'}/>
                <ReferenceField
                    label="車種・モデル"
                    source="carModelId"
                    reference="carModels"
                    className={'p-display-none w-150'}
                    sortBy={"carGrade.carModelId"}
                    link={false}
                >
                    <FunctionField render={modelRender}/>
                </ReferenceField>
                <TextField
                    label="車台番号"
                    source="chassis"
                    className={'p-display-none w-150'}
                />
                <ReferenceField
                    label="店舗"
                    source="shopId"
                    reference="shops"
                    className={'p-display-none w-150'}
                    link={false}
                >
                    <TextField source={'name'}/>
                </ReferenceField>
                <BooleanField source="whetherPublic" label={'公開/非公開'}/>
                <FunctionField sortable={false} label={'売価'} render={() =>
                    <TextFieldWithCommas source="totalPriceWithTax"/>
                }/>
                <TextFieldWithCommas source="targetPrice" label={'予定売価'}/>

                <BooleanField source="sold" label={'売却確定'}/>
                <TextFieldWithCommas source="orderCount" label={'オーダー数'}/>
                <TextFieldWithCommas source="position" label={'表示順序'}/>

            </EditableDatagrid>
        </List>
    );
};

const SearchFilter: React.FC<any> = (props) => {
    return (
        <Filter {...props}>
            <SearchForm {...props} alwaysOn/>
        </Filter>
    );
};

const SearchForm: React.FC<any> = (props) => {
    const publicChoices = [{id: 1, name: '公開'}, {id: 0, name: '非公開'}];
    const soldChoices = [{id: 1, name: '売却済み'}, {id: 0, name: '未売却'}];
    const validateNumber = [minValue(0), floatValidation];
    const modelRenderer = (choice: Record<any, any>) => choice.name ? `${choice.id} ${choice.carName}・${choice.name}` : '';
    return (
        <Box bgcolor="white" p={2} id='box' className={'filterBox'} mt={2}>
            <Grid container={true} spacing={1} className={'w-100'}>
                <Grid item={true} lg={2}>
                    <TextInputForJapanese label="名称" fullWidth={true} source="name" className={'p-display-none'}
                                          type={'text'}
                                          alwaysOn/>
                </Grid>
                <Grid item={true} lg={2} id={'publicField'}>
                    <AutocompleteInput label="公開/非公開" fullWidth={true} choices={publicChoices} alwaysOn
                                       resettable={true}
                                       source="whetherPublic"/>
                </Grid>
                <Grid item={true} lg={2} id={'soldField'}>
                    <AutocompleteInput label="売却確定" fullWidth={true} choices={soldChoices} alwaysOn
                                       resettable={true}
                                       source="sold"/>
                </Grid>
                <Grid item={true} lg={2}>
                    <NullableBooleanInput fullWidth={true} label="要連絡" source="requiredContact"
                                          className={'p-display-none w-100'} alwaysOn/>
                </Grid>
                <Grid item={true} lg={2}>
                    <NullableBooleanInput fullWidth={true} label="総合サイトトップに表示" source="showCompanySite"
                                          className={'p-display-none w-100'} alwaysOn/>
                </Grid>
            </Grid>
            <Grid container={true} spacing={1} className={'w-100'} id={'carModelField'}>
                <Grid item={true} lg={2}>
                    <ReferenceInput
                        label="車種・モデル"
                        source="carModelId"
                        reference="carModels"
                        alwaysOn
                        allowEmpty
                        className={'p-display-none w-150'}
                        perPage={MAX_FETCH_DATA_SIZE}
                        sort={{field: 'id', order: 'ASC'}}
                        resettable={true}
                        fullWidth={true}
                    >
                        <AutocompleteInput
                            optionText={modelRenderer}/>
                    </ReferenceInput>
                </Grid>
                <Grid item={true} lg={2}>
                    <TextInputForJapanese source="totalPriceWithTaxFrom" validate={validateNumber} type={'number'}
                                          label={'売価〜'}
                                          fullWidth={true}
                                          className={'p-display-none'} alwaysOn/>
                </Grid>
                <Grid item={true} lg={2}>
                    <TextInputForJapanese source="totalPriceWithTaxTo" validate={validateNumber} type={'number'}
                                          label={'～売価'}
                                          fullWidth={true}
                                          className={'p-display-none'} alwaysOn/>
                </Grid>
                <Grid item={true} lg={2}>
                    <TextInputForJapanese source="orderCountFrom" validate={validateNumber} type={'number'}
                                          label={'オーダー数〜'}
                                          fullWidth={true}
                                          className={'p-display-none'} alwaysOn/>
                </Grid>
                <Grid item={true} lg={2}>
                    <TextInputForJapanese source="orderCountTo" validate={validateNumber} type={'number'}
                                          label={'～オーダー数'}
                                          fullWidth={true}
                                          className={'p-display-none'} alwaysOn/>
                </Grid>
            </Grid>
        </Box>
    );
};

const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''}/>);
};

export default RecommendedCarsListComponent;
