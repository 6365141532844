import React, {useEffect, useState} from "react";
import {DataProviderProxy} from "ra-core";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import SortableImageList from "../../common/image/SortableImageList";
import {ImageDirectory, ResourceNames} from "../../config/const";
import useVariantItemImagesSavedHooks from "./useVariantItemImagesSavedHooks";
import {useRefresh} from "react-admin";
import {useForm} from "react-final-form";
import {saveImages} from "../../common/image/databaseImageUtils";


export interface VariantItemImagesSortableListProps {
    variantItemId?: number;
    variantItemImageId?: number;
    dataProvider: DataProviderProxy;
    maxFileCount?: number;
    uploadedCounter?: number;
}

const VariantItemImagesSortableList: React.FC<VariantItemImagesSortableListProps> = (props) => {
    const [pathData, setPathData] = useState(new Array<TempFileData>());
    const [images, setImages] = useState<ListPhotosType[] | undefined>();
    const [uploadedCounter, setUploadedCounter] = useState(0);
    const refresh = useRefresh();
    const form = useForm();

    const saveImagePathData = () => {
        const fn = async () => {
            await saveImages(props.dataProvider, ResourceNames.VARIANT_ITEM_IMAGES, {
                position: images?.length,
                variantItemId: props.variantItemId
            }, ImageDirectory.VARIANT_ITEM_IMAGES + props.variantItemId + '/', pathData, images)
            setUploadedCounter(uploadedCounter + 1);
            setPathData([]);
            form.change(ResourceNames.VARIANT_ITEM_IMAGES + `${props.variantItemId}`, undefined);
            refresh();
        }
        if (pathData.length > 0)
            fn().then();
    }

    useEffect(saveImagePathData, [pathData])

    useVariantItemImagesSavedHooks({
        dataProvider: props.dataProvider,
        variantItemId: props.variantItemId,
        uploadedCounter: uploadedCounter,
        setImages: setImages,
    });

    return (
        <>
            <SortableImageList
                imageArray={images}
                dataProvider={props.dataProvider}
                resource={ResourceNames.VARIANT_ITEM_IMAGES}
                isUpdating={!!props.variantItemId}
                setPathData={setPathData}
                pathData={pathData}
                setImages={setImages}
                imageSourceName={ResourceNames.VARIANT_ITEM_IMAGES + `${props.variantItemId}`}
                maxFileCount={props.maxFileCount}
                required={false}
            />
        </>
    );
}

export default VariantItemImagesSortableList;
