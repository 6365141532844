import React from 'react';
import {DeleteWithConfirmIconButton,} from '@react-admin/ra-editable-datagrid';
import {
    AppBar,
    Button,
    Create,
    Datagrid,
    Edit,
    EditButton,
    ReferenceField,
    ReferenceManyField,
    TextField,
    TopToolbar,
    useRecordContext,
    useRedirect,
    useRefresh,
} from 'react-admin';
import {TextFieldWithCommas, TextFieldWithCommaUnit} from '../../common/TextFieldWithCommas';
import useCarGrade from "./carGradeHook";
import {ApiCarModel} from "../../openapi";
import CarGradesForm from "./CarGradeFormComponent";
import {Box} from "@material-ui/core";
import {Link} from 'react-router-dom';
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import GoBack from "../../common/GoBackComponent";

const BASE_PATH = '/carGrades'

interface ListActionProps {
    carModelId: number;
}

const ListActions: React.FC<ListActionProps> = (props) => {
    return (
        <TopToolbar>
            <Button
                component={Link}
                to={{
                    pathname: '/carGrades/create',
                    state: {record: {carModelId: props.carModelId}}
                }}
                label={'作成'}
            />
        </TopToolbar>
    );
};

const CarGradesList: React.FC = (props: any) => {
    const {carModel, car} = useCarGrade(props.id);

    return (
        <>
            {carModel && car &&
            <>
                <Box mt={2}>
                    <div className={'w-100 d-flex'}>
                        <CarGradesLabel carModel={carModel}/>
                        <CarModelButton carId={carModel.carId as number}/>
                    </div>
                </Box>
                <ListActions carModelId={carModel.id!!}/>
                <Box mt={2}>
                    <ReferenceManyField
                        reference="carGrades"
                        target="carModelId"
                        filter={{carModelId: props.id}}
                        addLabel={false}
                        sort={{field: 'position', order: 'ASC'}}
                        fullWidth
                    >
                        <Datagrid>
                            <TextField source="name" label={'グレード名'}/>
                            <TextField source="carType" label={'型式'}/>
                            <TextFieldWithCommas source="price" label={'売価'} textAlign={'right'}/>
                            <TextFieldWithCommas source="cost" label={'原価'} textAlign={'right'}/>
                            <TextFieldWithCommas source="originalPrice" label={'定価'} textAlign={'right'}/>
                            <TextFieldWithCommas source="position" label={'表示順序'} textAlign={'right'}/>
                            <TextFieldWithCommaUnit unit="L" source="displacement" label={'排気量'} textAlign={'right'}/>
                            <ReferenceField
                                label="環境性能割"
                                source="taxByEnvironmentalPerformanceId"
                                reference="taxByEnvironmentalPerformances"
                                link={false}
                            >
                                <TextField source="name"/>
                            </ReferenceField>
                            <ReferenceField
                                label="エコカー減税"
                                source="ecoCarTaxCutId"
                                reference="ecoCarTaxCuts"
                                link={false}
                            >
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextFieldWithCommaUnit unit="日後" source="deadline" label={'納期'} textAlign={'right'}/>
                            <div className={'actionButtons'}>
                                <CarColorButton source="id"/>
                                <EditDataBtn {...props} />
                                <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                               className={'ml-30'}/>
                            </div>
                        </Datagrid>
                    </ReferenceManyField>
                </Box>
            </>
            }

        </>
    );
};

const ReturnBack = () => {
    return (
        <Box mb={2}>
            <GoBack/>
        </Box>
    );
}
export const CarGradesLabel = (props: { carModel: ApiCarModel }) => {
    return (
        <div>
            <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        carModel={props.carModel}
                    />
                    グレード管理
                </div>
            </AppBar>
        </div>
    );
};


export const CarGradesEdit: React.FC = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const onSuccess = (response: any) => {
        redirect(`/carModels/${response.data.carModelId}/show`);
        refresh();
    };
    return (
        <Edit
            actions={<ReturnBack/>}
            onSuccess={onSuccess}
            undoable={false}
            {...props}
        >
            <CarGradesForm {...props}/>
        </Edit>
    )
};

interface CreateProps {
    location?: any;
}

export const CarGradesCreate: React.FC<CreateProps> = ({location, ...props}) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const onSuccess = () => {
        redirect(`/carModels/${location.state.record['carModelId']}/show`);
        refresh();
    };
    return (
        <Create
            record={(location.state && location.state.record)}
            location={location}
            {...props}
            onSuccess={onSuccess}
            actions={<ReturnBack/>}
        >
            <CarGradesForm {...props}/>
        </Create>
    )
};

const CarModelButton = (props: { carId: number }) => {
    return (
        <div className={'ml-30'}>
            <Button
                label={'戻る'}
                variant="contained"
                color={'default'}
                component={Link}
                to={{
                    pathname: `/carModels/list/${props.carId}`,
                }}
            />
        </div>
    );
};

const CarColorButton = ({...source}) => {
    const record = useRecordContext();
    return (
        <Button label={'カラー'}
                component={Link}
                to={{
                    pathname: `/carGrades/${record[source.source]}/show`
                }}
                className={'mr-30'}
        />
    );
};

const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''} basePath={BASE_PATH}/>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source}
                                         record={record}
                                         resource={'carGrades'}
                                         mutationMode={'undoable'}
                                         basePath={BASE_PATH}
                                         redirect={`/carModels/${record.carModelId}/show`}
    />);
};

export default CarGradesList;
