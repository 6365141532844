import {JNumberAndInsetRecommendationImageProps} from "./JNumberAndInsetRecommendationImagesComponent";
import {useEffect} from "react";
import {MAX_FETCH_DATA_SIZE, ResourceNames} from "../../config/const";
import {convertDataForSortableImage} from "../../common/image/databaseImageUtils";

export const useJNumberAndInsetRecommendationImageHooks = (props: JNumberAndInsetRecommendationImageProps) => {

    useEffect(() => {
        getImages().then()
    }, [props.jNumberAndInsetRecommendationId, props.uploadedCounter])

    const getImages = async () => {
        if (props.jNumberAndInsetRecommendationId) {
            convertDataForSortableImage(props.dataProvider,
                ResourceNames.J_NUMBER_AND_INSET_RECOMMENDATION_IMAGE_RESOURCE,
                {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'position', order: 'ASC'},
                    filter: {
                        jNumberAndInsetRecommendationId: props.jNumberAndInsetRecommendationId,
                    }
                }
            ).then((result) => {
                props.setImages(result);
            });
        }
    }

}
