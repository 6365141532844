import {useEffect, useState} from 'react'
import {Record, useDataProvider, useRedirect, useRefresh} from 'react-admin';
import {uploadBrandsImageToFirebase} from '../../common/image/databaseImageUtils';
import {TempFileData} from '../../modules/temporaryFileModule';
import {deleteFileFromUrl, listFiles} from '../../utils/upload';


const useBrandHooks = ({...props}) => {

    const [logoPathUrl, setLogoPathUrl] = useState<string>();
    const [logoPathSpUrl, setLogoPathSpUrl] = useState<string>();
    const [stampPathUrl, setStampPathUrl] = useState<string>();
    const [logoPathSuspensionUrl, setLogoPathSuspensionUrl] = useState<string>();
    const [logoPathData, setLogoPathData] = useState<Array<TempFileData>>([]);
    const [logoPathSpData, setLogoPathSpData] = useState<Array<TempFileData>>([]);
    const [logoPathSuspensionData, setLogoPathSuspensionData] = useState<Array<TempFileData>>([]);
    const [stampPathData, setStampPathData] = useState<Array<TempFileData>>([]);

    const [brandsImgId, setBrandsImgId] = useState<any>();
    const [brandsImgStampPath, setBrandsImgStampPath] = useState('');
    const [brandsImgLogoPath, setBrandsImgLogoPath] = useState('');
    const [brandsImgLogoPathSp, setBrandsImgLogoPathSp] = useState('');
    const [brandsImgLogoPathSuspension, setBrandsImgLogoPathSuspension] = useState('');
    const [brandsImgPaths, setBrandsImgPaths] = useState('');

    const [opens, setOpens] = useState(false);
    const redirect = useRedirect();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const uploadAndUpdateImage = async (data: Record) => {
        if (logoPathData.length > 0 || stampPathData.length || logoPathSpData.length > 0 || logoPathSuspensionData.length > 0) {
            const brands = await uploadBrandsImageToFirebase(logoPathData, logoPathSpData, stampPathData, logoPathSuspensionData);
            if (brands.logoPath) {
                setLogoPathUrl('')
                await deleteImgData(brandsImgLogoPath)
                await dataProvider.update('brands', {
                    id: data.id,
                    data: {
                        ...data,
                        logoPath: brands.logoPath,
                    },
                    previousData: {id: data.id}
                });
                const {data: updater} = await dataProvider.getOne('brands', {id: props.id});
                const imgPathDirectory = updater.logoPath.split('/')[0] + '/' + updater.logoPath.split('/')[1]
                const listOfImg = await listFiles(imgPathDirectory);
                listOfImg && await Promise.all(listOfImg.map(async (item: any) => {
                    if (item) {
                        const imageUrl = await item.getDownloadURL();
                        updater.logoPath !== '' && setLogoPathUrl(imageUrl);
                    }
                }));
            }

            if (brands.logoPathSp) {
                setLogoPathSpUrl('')
                await deleteImgData(brandsImgLogoPathSp)
                await dataProvider.update('brands', {
                    id: data.id,
                    data: {
                        ...data,
                        logoPathSp: brands.logoPathSp,
                    },
                    previousData: {id: data.id}
                });
                const {data: updater} = await dataProvider.getOne('brands', {id: props.id});
                const imgPathDirectory = updater.logoPathSp.split('/')[0] + '/' + updater.logoPathSp.split('/')[1]
                const listOfImg = await listFiles(imgPathDirectory);
                listOfImg && await Promise.all(listOfImg.map(async (item: any) => {
                    if (item) {
                        const imageUrl = await item.getDownloadURL();
                        updater.logoPathSp !== '' && setLogoPathSpUrl(imageUrl);
                    }
                }));
            }

            if (brands.stampPath) {
                setStampPathUrl('')
                await deleteImgData(brandsImgStampPath)
                await dataProvider.update('brands', {
                    id: data.id,
                    data: {
                        ...data,
                        stampPath: brands.stampPath,
                    },
                    previousData: {id: data.id}
                });
                const {data: updater} = await dataProvider.getOne('brands', {id: props.id});
                const imgPathDirectory = updater.stampPath.split('/')[0] + '/' + updater.stampPath.split('/')[1]
                const listOfImg = await listFiles(imgPathDirectory);
                listOfImg && await Promise.all(listOfImg.map(async (item: any) => {
                    if (item) {
                        const imageUrl = await item.getDownloadURL();
                        updater.stampPath !== '' && setStampPathUrl(imageUrl);
                    }
                }));
            }

            if (brands.logoPathSuspension) {
                setLogoPathSuspensionUrl('')
                await deleteImgData(brandsImgLogoPathSuspension)
                await dataProvider.update('brands', {
                    id: data.id,
                    data: {
                        ...data,
                        logoPathSuspension: brands.logoPathSuspension,
                    },
                    previousData: {id: data.id}
                });
                const {data: updater} = await dataProvider.getOne('brands', {id: props.id});
                const imgPathDirectory = updater.logoPathSuspension.split('/')[0] + '/' + updater.logoPathSuspension.split('/')[1]
                const listOfImg = await listFiles(imgPathDirectory);
                listOfImg && await Promise.all(listOfImg.map(async (item: any) => {
                    if (item) {
                        const imageUrl = await item.getDownloadURL();
                        updater.logoPathSuspension !== '' && setLogoPathSuspensionUrl(imageUrl);
                    }
                }));
            }
        }
    }

    const getImagesTypeUpdate = () => {
        const fn = async () => {
            if (props.type === 'update') {
                const {data} = await dataProvider.getOne('brands', {id: props.id});
                if (data && data.logoPath && data.logoPathSp && data.stampPath) {
                    if (data.id === Number(props.id)) {
                        setBrandsImgId(data.id);
                        setBrandsImgLogoPath(data.logoPath);
                        setBrandsImgLogoPathSp(data.logoPathSp);
                        setBrandsImgStampPath(data.stampPath);
                        setBrandsImgLogoPathSuspension(data.logoPathSuspension);
                        const logoPathDirectory = data.logoPath?.split('/')[0] + '/' + data.logoPath?.split('/')[1]
                        const logoPathSpDirectory = data.logoPathSp?.split('/')[0] + '/' + data.logoPathSp?.split('/')[1]
                        const stampPathDirectory = data.stampPath?.split('/')[0] + '/' + data.stampPath?.split('/')[1];
                        const logoPathSuspensionDirectory = data.logoPathSuspension?.split('/')[0] + '/' + data.logoPathSuspension?.split('/')[1];
                        setBrandsImgPaths('brands/');
                        const listOfLogos = await listFiles(logoPathDirectory);
                        listOfLogos && await Promise.all(listOfLogos.map(async (item: any) => {
                            if (item) {
                                const imageUrl = await item.getDownloadURL();
                                data.logoPath !== '' && setLogoPathUrl(imageUrl);
                            }
                        }));
                        const listOfLogosSp = await listFiles(logoPathSpDirectory);
                        listOfLogosSp && await Promise.all(listOfLogosSp.map(async (item: any) => {
                            if (item) {
                                const imageUrl = await item.getDownloadURL();
                                data.logoPathSp !== '' && setLogoPathSpUrl(imageUrl);
                            }
                        }));
                        const listOfStamps = await listFiles(stampPathDirectory);
                        listOfStamps && await Promise.all(listOfStamps.map(async (item: any) => {
                            if (item) {
                                const imageUrl = await item.getDownloadURL();
                                data.stampPath !== '' && setStampPathUrl(imageUrl);
                            }
                        }));
                        const listOfLogoPathSuspension = await listFiles(logoPathSuspensionDirectory);
                        listOfLogoPathSuspension && await Promise.all(listOfLogoPathSuspension.map(async (item: any) => {
                            if (item) {
                                const imageUrl = await item.getDownloadURL();
                                data.logoPathSuspension !== '' && setLogoPathSuspensionUrl(imageUrl);
                            }
                        }));
                        await uploadAndUpdateImage(data);
                    }
                }
            }
        }
        fn().then();
    }
    useEffect(getImagesTypeUpdate, [props.type, logoPathData, stampPathData, logoPathSpData, logoPathSuspensionData])

    const openImage = (imageURL: string) => {
        window.open(imageURL);
    }

    const handleSave = async ({...values}) => {
        setOpens(true);
        if (props.type === 'create') {
            const brands = await uploadBrandsImageToFirebase(logoPathData, logoPathSpData, stampPathData, logoPathSuspensionData);
            await dataProvider.create('brands', {
                data: {
                    ...values,
                    logoPath: brands.logoPath,
                    stampPath: brands.stampPath,
                    logoPathSp: brands.logoPathSp,
                    logoPathSuspension: brands.logoPathSuspension,
                }
            });
            redirect('/brands');
            refresh();
        } else {
            const brands = await uploadBrandsImageToFirebase(logoPathData, logoPathSpData, stampPathData, logoPathSuspensionData);
            await dataProvider.update('brands', {
                id: values.id,
                data: {
                    ...values,
                    logoPath: brands.logoPath ? brands.logoPath : brandsImgLogoPath,
                    stampPath: brands.stampPath ? brands.stampPath : brandsImgStampPath,
                    logoPathSp: brands.logoPathSp ? brands.logoPathSp : brandsImgLogoPathSp,
                    logoPathSuspension: brands.logoPathSuspension ? brands.logoPathSuspension : brandsImgLogoPathSuspension,
                },
                previousData: {id: values.id}
            });
            redirect('/brands');
            refresh();
        }
    }

    const handleUpload = async (acceptedFiles: File, field: string) => {
        let directory = '';
        if (acceptedFiles) {
            const reader = new FileReader();
            let fileBinary: string | ArrayBuffer | null | undefined = null;
            reader.onload = async (item) => {
                fileBinary = item.target?.result;
                if (directory === '' || directory === undefined) {
                    directory = `brands/`;
                }
                if (field === 'logoPath') {
                    setLogoPathData(new Array({
                        dir: directory,
                        fileBinary: fileBinary,
                        filename: 'logoPath',
                        fileType: acceptedFiles.type
                    }))
                }
                if (field === 'stampPath') {
                    setStampPathData(new Array({
                        dir: directory,
                        fileBinary: fileBinary,
                        filename: 'stampPath',
                        fileType: acceptedFiles.type
                    }));
                }
                if (field === 'logoPathSp') {
                    setLogoPathSpData(new Array({
                        dir: directory,
                        fileBinary: fileBinary,
                        filename: 'logoPathSp',
                        fileType: acceptedFiles.type
                    }));
                }
                if (field === 'logoPathSuspension') {
                    setLogoPathSuspensionData(new Array({
                        dir: directory,
                        fileBinary: fileBinary,
                        filename: 'logoPathSuspension',
                        fileType: acceptedFiles.type
                    }));
                }
            }
            reader.readAsDataURL(acceptedFiles);
        }
    }

    const deleteImgData = async (imgDir: string) => {
        const list = await listFiles(imgDir);
        await Promise.all(list.map(async (item: any) => {
            if (item) {
                const imageUrl = await item.getDownloadURL();
                imageUrl && await deleteFileFromUrl(imageUrl);
            }
        }));
    }

    return {
        handleUpload,
        logoPathUrl,
        stampPathUrl,
        handleSave,
        opens,
        openImage,
        brandsImgId,
        brandsImgStampPath,
        brandsImgLogoPath,
        brandsImgPaths,
        logoPathSpUrl,
        brandsImgLogoPathSp,
        logoPathSuspensionUrl,

    }
}

export default useBrandHooks
