import {defaultTheme} from "react-admin";
import {ThemeOptions} from "@react-admin/ra-navigation/src/multi-level-menu/theme";

export const theme: ThemeOptions = {
    ...defaultTheme,
    overrides: {
        RaSidebar: {
            drawerPaper: {
                paddingRight: 16,
                width: '130px',
            },
            fixed:{
                position: 'absolute'
            }
        },
        RaMenuItem: {
            nestedList: {
                paddingLeft: '1px',
            },
            link: {
                color: '#FEFEFE',
            }
        },
        MuiListItemText: {
            primary: {
                fontSize: '0.75rem'
            }
        }
    },
};

export default theme
