import MountSettingsList from "../../components/mountSettings/MountSettingsListComponent";
import * as React from "react";
import {Route} from 'react-router-dom';
import CarModelsList from "../../components/carModels/CarModelsListComponent";
import ProductVariantsList from "../../components/products/ProductVariantsListComponent";
import JNumberAndInsetRecommendationListComponent
    from "../../components/products/JNumberAndInsetRecommendationListComponent";
import NoCombinationVariantsList from "../../components/noCombinationVariants/NoCombinationVariantsListComponent";
import VariantItemProductPriceComponent from "../../components/products/VariantItemProductPriceComponent";
import ProductVariantItemImagesList
    from "../../components/productVariantItemImages/ProductVariantItemImagesListComponent";
import VariantItemImagesListComponent from "../../components/variantItemImages/VariantItemImagesListComponent";

const mountSettingRoute = (
    <Route path="/mountSettings/:id" component={MountSettingsList}/>
);
const carModelListRoute = (
    <Route exact path="/carModels/list/:carId" component={CarModelsList}/>
);
const productVariantMountableByInsetListRoute = (
    <Route path="/jNumberAndInsetRecommendation/list/:productId/:carModelId" component={JNumberAndInsetRecommendationListComponent}/>
);
const productVariantsListRoute = (
    <Route path="/productVariants/:id" component={ProductVariantsList}/>
);
const noCombinationVariantsListRoute = (
    <Route path="/noCombinationVariants/:productId/:carGradeId" component={NoCombinationVariantsList}/>
);
const variantItemProductPrice = (
    <Route path="/variantItemProductPrice/:productId" component={VariantItemProductPriceComponent}/>
)
const productVariantItemImageCombinationListRoute = (
    <Route path="/productVariantItemImageCombination/:productId" component={ProductVariantItemImagesList}/>
)
const variantItemImagesListRoute = (
    <Route path="/variantItemImages/:variantClassId" component={VariantItemImagesListComponent}/>
)
const customRoutes = [
    mountSettingRoute,
    carModelListRoute,
    productVariantsListRoute,
    productVariantMountableByInsetListRoute,
    noCombinationVariantsListRoute,
    variantItemProductPrice,
    productVariantItemImageCombinationListRoute,
    variantItemImagesListRoute
];

export default customRoutes;
