import React, {Dispatch, SetStateAction} from "react";
import {DataProviderProxy} from "ra-core";
import {useCarImageHooks} from "./carImageHooks";
import SortableImageList from "../../common/image/SortableImageList";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {ResourceNames} from "../../config/const";

const defaultProps = {
    showSortBox: true,
};

export interface CarImageProps {
    carImageClass: number;
    carId?: number;
    dataProvider: DataProviderProxy;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData: TempFileData[];
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    images: ListPhotosType[] | undefined;
    showSortBox?: boolean;
    maxFileCount?: number;
}

const CarImage: React.FC<CarImageProps> = (props) => {
    useCarImageHooks(props);
    return (
        <>
            <SortableImageList imageArray={props.images}
                               dataProvider={props.dataProvider}
                               resource={ResourceNames.CAR_IMAGE_RESOURCE}
                               isUpdating={!!props.carId}
                               setPathData={props.setPathData}
                               pathData={props.pathData}
                               setImages={props.setImages}
                               imageSourceName={ResourceNames.CAR_IMAGE_RESOURCE + props.carImageClass.toString()}
                               showSortBox={props.showSortBox}
                               maxFileCount={props.maxFileCount}
            />
        </>
    );
}

CarImage.defaultProps = defaultProps;

export default CarImage
