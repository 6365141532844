import React from "react";
import {useProductsHooks} from "./productsHooks";
import {useListContext} from "react-admin";

interface ProductThumbnailProps {
    record: { id: number };
}

const ProductThumbnail: React.FC<ProductThumbnailProps> = (props: ProductThumbnailProps) => {
    const {filterValues} = useListContext();
    const {thumbnailUrl} = useProductsHooks({id: props.record.id, type: 'update'})
    return (
        <>
            {filterValues.dummyShowImage ?
                <img src={thumbnailUrl} width={'120px'} className={'ml-10'}/>
                :
                null}
        </>
    );
}

export default ProductThumbnail;
