import React, {Dispatch, SetStateAction} from "react";
import {DataProviderProxy} from "ra-core";
import SortableImageList from "../../common/image/SortableImageList";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {ResourceNames} from "../../config/const";
import {useVariantClassesImageHooks} from "./VariantClassesImageHooks";

export interface VariantClassesProps {
    variantClassesId?: number;
    dataProvider: DataProviderProxy;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData: TempFileData[];
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    images: ListPhotosType[] | undefined;
}

const VariantClassesImage: React.FC<VariantClassesProps> = (props) => {
    console.log(props)
    useVariantClassesImageHooks(props);
    return (
        <>
            <SortableImageList imageArray={props.images}
                               dataProvider={props.dataProvider}
                               resource={ResourceNames.VARIANT_CLASSES_IMAGE_RESOURCE}
                               isUpdating={!!props.variantClassesId}
                               setPathData={props.setPathData}
                               pathData={props.pathData}
                               setImages={props.setImages}
                               imageSourceName={ResourceNames.VARIANT_CLASSES_IMAGE_RESOURCE}
            />
        </>
    );
}

export default VariantClassesImage;
