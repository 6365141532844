import firebase from 'firebase';
import 'firebase/storage';

const Config = () => {
    return {
        firebase: {
            apiKey: process.env.REACT_APP_FB_DEV_APP_ID,
            authDomain: process.env.REACT_APP_FB_DEV_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FB_DEV_DATABASE_URL,
            projectId: process.env.REACT_APP_FB_DEV_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FB_DEV_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FB_DEV_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FB_DEV_APP_ID
        }
    }
};

const firebaseConfig = Config().firebase;

function initApp() {
    return firebase.initializeApp(firebaseConfig)
}

export const firebaseApp = !firebase.apps.length ?
    initApp() :
    firebase.app()

function getTimestamp(): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.now();
}

export const firebaseStorage = firebaseApp.storage();
export const auth = firebase.auth();
export const firestoreApp = firebaseApp.firestore();
