import React, {Dispatch, forwardRef, SetStateAction} from "react";
import {Box, Grid, Paper, Tab, Tabs} from "@material-ui/core";
import {CheckboxGroupInput} from "react-admin";
import {useForm} from "react-final-form";
import {FormControl} from "@mui/material";
import {useRecommendedCarDefaultProductHooks} from "./RecommendedCarDefaultProductHooks";
import RecommendedCarDefaultProductRadioField from "./RecommendedCarDefaultProductRadioField";

export interface DefaultProductFunctions {
    save: (
        recommendedCarId: number,
        defaultProducts: number[],
    ) => void;
}

export interface DefaultProductProps {
    carGradeIds?: number;
    recommendedCarId?: number;
    productInitialValues: number[] | undefined
    setProductInitialValues: Dispatch<SetStateAction<number[] | undefined>>;
    setSelectedCarGradeId: Dispatch<SetStateAction<number | undefined>>;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    defaultProductIds: number[] | undefined
    setDefaultProductIds: Dispatch<SetStateAction<number[] | undefined>>;
}

export const RecommendedCarsDefaultProductsList: React.ForwardRefRenderFunction<DefaultProductFunctions, DefaultProductProps> = (props, ref) => {
    const form = useForm();
    const savedValues = form.getState().values;
    props.setSelectedCarGradeId(savedValues['carGradeId'])

    const {
        classes,
        globalClasses,
        tabValue,
        productCarGradeMountSettingWithOtherData,
        categories,
        handleTabChange,
        onChangeCheckBox,
        onChangeRadioButton,
        remove,
    } = useRecommendedCarDefaultProductHooks({
        ref: ref,
        carGradeIds: props.carGradeIds,
        recommendedCarId: props.recommendedCarId,
        productInitialValues: props.productInitialValues,
        setProductInitialValues: props.setProductInitialValues,
        setSelectedCarGradeId: props.setSelectedCarGradeId,
        open: props.open,
        setOpen: props.setOpen,
        defaultProductIds: props.defaultProductIds,
        setDefaultProductIds: props.setDefaultProductIds
    })

    return (
        <div className={classes.root}>

            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                <h3>装着済商品</h3>
            </Grid>

            <Paper className={globalClasses.scrollableTab}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant={"scrollable"}
                    scrollButtons="auto"
                >
                    {productCarGradeMountSettingWithOtherData?.data?.filter((completeCarDefaultProduct) => completeCarDefaultProduct.categoryParent?.makerDealerOption !== true)?.map((completeCarDefaultProduct) => (
                        <Tab
                            label={completeCarDefaultProduct.categoryParent?.name}
                            value={completeCarDefaultProduct.categoryParent?.id}/>
                    ))}
                </Tabs>

                {categories?.filter((category) => category.makerDealerOption !== true)?.map((category) => (
                    <>
                        <Box ml={2}>
                            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                                {category.name}
                            </Grid>

                            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11}>
                                {category.selectableMultiple
                                    ?
                                    <CheckboxGroupInput
                                        source={"defaultProducts"}
                                        label={''}
                                        choices={category.choices}
                                        initialValue={props.productInitialValues}
                                        onChange={onChangeCheckBox}
                                    />
                                    :
                                    <FormControl>
                                        <RecommendedCarDefaultProductRadioField
                                            defaultProductIds={props.defaultProductIds}
                                            onChangeRadioButton={onChangeRadioButton}
                                            category={category}
                                            remove={remove}
                                        />
                                    </FormControl>
                                }

                            </Grid>
                        </Box>
                    </>
                ))}

            </Paper>
        </div>

    )
}

export const RecommendedCarsDefaultProducts = forwardRef(RecommendedCarsDefaultProductsList);
