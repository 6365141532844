import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import Email from '@material-ui/icons/Email';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import {useLogin} from 'react-admin';
import {Box, Typography} from '@material-ui/core';

const Login: React.FC = () => {
    const login = useLogin();

    const [email, setEmail] = useState('');
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [hasError, setHasError] = useState(false);

    const handleChange = (prop: string) => (event: { target: { value: any; }; }) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        login({email: email, password: values.password}).catch(() => setHasError(true));
    };
    return (
        <div id={'login-form'}>
            <Grid className={'flex h-100'}>
                <Card className={'w-30'}>
                    <CardActions>
                        <Grid className={'w-100 h-28 flex'}>
                            <form onSubmit={handleSubmit}>
                                <Grid spacing={2} container={true} className={'w-100 flex'}>
                                    <Grid item={true} xs={10} sm={11} md={12} lg={12} className={'flex fw-bolder'}>
                                        ログイン
                                    </Grid>
                                    <Grid item={true} xs={10} sm={7} md={7} lg={7} className={'flex'}>
                                        <TextField value={email}
                                                   onChange={(e) => {
                                                       setEmail(e.target.value)
                                                   }}
                                                   fullWidth={true} label="メールアドレス" variant="outlined"
                                                   InputProps={{
                                                       startAdornment: <InputAdornment
                                                           position="start"><Email/></InputAdornment>
                                                   }}/>
                                    </Grid>
                                    <Grid item={true} xs={10} sm={7} md={7} lg={7} className={'flex'}>
                                        <TextField
                                            fullWidth={true}
                                            label="パスワード"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    {values.showPassword ?
                                                        <Visibility onClick={handleClickShowPassword}
                                                                    style={{cursor: 'pointer'}}/> :
                                                        <VisibilityOff
                                                            onClick={handleClickShowPassword}
                                                            style={{cursor: 'pointer'}}/>}
                                                </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={10} sm={7} md={7} lg={7} className={'flex'}>
                                        <Grid container={true}>
                                            <Grid item={true} xs={12}>
                                                <Button fullWidth={true}
                                                        variant="contained"
                                                        color="primary"
                                                        type={'submit'}
                                                        size="large">
                                                    ログイン
                                                </Button>
                                            </Grid>
                                            <Grid item={true} xs={12}>
                                                <Box mt={2}>
                                                    {hasError &&
                                                    <Typography color="error">メールアドレスもしくはパスワードが間違っています。</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </div>
    );
};

export default Login;
