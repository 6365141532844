import React, {useEffect, useState} from "react";
import {CarColorImageClass, circularSize, ImageDirectory, ResourceNames} from '../../config/const';
import CarColorImage from "./CarColorsImagesComponent";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {saveImages} from "../../common/image/databaseImageUtils";
import {
    BooleanInput,
    Create,
    CreateProps,
    Edit,
    maxLength,
    required,
    SimpleForm,
    TextInput,
    useDataProvider,
    useRecordContext,
    useRedirect,
    useRefresh,
} from "react-admin";
import {Box, Grid, Typography} from '@material-ui/core';
import useCarColor from "./carColorHooks";
import {CarColorsLabel} from "./CarColorsListComponent";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useStyles} from "../../common/styles";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";
import CarColorSuspensionImageComponent from "./CarColorSuspensionImageComponent";

const CarColorsForm: React.FC<any> = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const validateName = [required(), maxLength(50)];
    const dataProvider = useDataProvider();

    const [pathData1, setPathData1] = useState(new Array<TempFileData>());
    const [pathData2, setPathData2] = useState(new Array<TempFileData>());
    const [pathDataSuspensionImage, setPathDataSuspensionImage] = useState(new Array<TempFileData>());
    const [images1, setImages1] = useState<ListPhotosType[] | undefined>()
    const [images2, setImages2] = useState<ListPhotosType[] | undefined>()
    const [imagesSuspensionImage, setImagesSuspensionImage] = useState<ListPhotosType[] | undefined>()

    const record = useRecordContext();
    const {carGrade, getByCarGradeId} = useCarColor();
    useEffect(() => {
        getByCarGradeId(record['carGradeId']).then();
    }, [])

    const [open, setOpen] = useState(false);

    const handleSave = async ({...values}) => {
        setOpen(true);
        if (props.type === 'create') {
            const carColors = await dataProvider.create('carColors', {data: {...values}});
            let count = 1
            let counter = ++count;
            await saveImages(dataProvider, ResourceNames.CAR_COLOR_IMAGE_RESOURCE, {
                carColorImageClass: CarColorImageClass.CAR_COLOR_IMAGE_CLASS_1,
                carColorId: carColors.data.id,
                position: counter++
            }, ImageDirectory.CAR_COLOR_IMAGE_CLASS_1 + carColors.data.id + '/', pathData1, images1);

            await saveImages(dataProvider, ResourceNames.CAR_COLOR_IMAGE_RESOURCE, {
                carColorImageClass: CarColorImageClass.CAR_COLOR_IMAGE_CLASS_2,
                carColorId: carColors.data.id,
                position: counter++
            }, ImageDirectory.CAR_COLOR_IMAGE_CLASS_2 + carColors.data.id + '/', pathData2, images2);
            await saveImages(dataProvider, ResourceNames.CAR_COLOR_SUSPENSION_IMAGE_RESOURCE, {
                    carColorId: carColors.data.id,
                    position: counter++
                }, ImageDirectory.CAR_COLOR_SUSPENSION_IMAGE + carColors.data.id + '/', pathDataSuspensionImage, imagesSuspensionImage,
                undefined, undefined, undefined, undefined, undefined, undefined,
                true
            );
        } else {
            delete values.set;
            await dataProvider.update('carColors', {
                id: values.id,
                data: {...values},
                previousData: {id: values.id}
            });

            await saveImages(dataProvider, ResourceNames.CAR_COLOR_IMAGE_RESOURCE, {
                carColorImageClass: CarColorImageClass.CAR_COLOR_IMAGE_CLASS_1,
                carColorId: props.id,
            }, ImageDirectory.CAR_COLOR_IMAGE_CLASS_1 + props.id + '/', pathData1, images1);

            await saveImages(dataProvider, ResourceNames.CAR_COLOR_IMAGE_RESOURCE, {
                carColorImageClass: CarColorImageClass.CAR_COLOR_IMAGE_CLASS_2,
                carColorId: props.id,
            }, ImageDirectory.CAR_COLOR_IMAGE_CLASS_2 + props.id + '/', pathData2, images2);

            await saveImages(dataProvider, ResourceNames.CAR_COLOR_SUSPENSION_IMAGE_RESOURCE, {
                    carColorId: props.id,
                }, ImageDirectory.CAR_COLOR_SUSPENSION_IMAGE + props.id + '/', pathDataSuspensionImage, imagesSuspensionImage,
                undefined, undefined, undefined, undefined, undefined, undefined,
                true
            );

        }

        redirect(`/carGrades/${values.carGradeId}/show`);
        refresh();
    };

    const ShowProgress: React.FC = () => {
        const classes = useStyles();
        return (
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" size={circularSize}/>
            </Backdrop>
        );
    }

    return (
        <>
            <ShowProgress/>
            {carGrade &&
            <SimpleForm
                {...props}
                submitOnEnter={false}
                save={handleSave}
                toolbar={<ActionToolbar/>}>
                <div className={"w-100"}>
                    <Grid container={true}>
                        <Grid item={true} xs={2}>
                            <TextInput source="carGradeId"
                                       className={"displayNone"}
                                       label={''}
                                       disabled={true}
                                       alwaysOn
                                       defaultValue={carGrade.id}
                            />
                            <Grid container={true}>
                                <TextInputForJapanese source="name" validate={validateName} label={'カラー'}/>
                            </Grid>
                            <Grid container={true}>
                                <NumberInputCommon
                                    source={'price'}
                                    label={'売価'}
                                    fullWidth={false}/>
                            </Grid>
                            <Grid container={true}>
                                <NumberInputCommon
                                    source={'cost'}
                                    label={'原価'}
                                    fullWidth={false}
                                />
                            </Grid>
                            <Grid container={true}>
                                <TextInputForJapanese source="color1" validate={validateName} label={'色1'}/>
                            </Grid>
                            <Grid container={true}>
                                <TextInputForJapanese source="color2" validate={validateName} label={'色2'}/>
                            </Grid>
                            <Box display={'flex'}>
                                <BooleanInput
                                    defaultValue={!record?.id ? true : record?.forNew}
                                    parse={(val: boolean)=>val}
                                    source="forNew"
                                    label="新車"
                                />
                                <BooleanInput
                                    defaultValue={!record?.id ? true : record?.forUsed}
                                    parse={(val: boolean)=>val}
                                    source="forUsed"
                                    label="中古"
                                />
                            </Box>
                            <Grid container={true}>
                                <NumberInputCommon
                                    source={'position'}
                                    label={'表示順序'}
                                    fullWidth={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={10}>
                            <Grid container={true} spacing={3}>
                                <Box mt={2}>
                                    <Typography variant={'h6'}>上部画像(PC用画像)(無制限)</Typography>
                                    <CarColorImage
                                        carColorImageClass={CarColorImageClass.CAR_COLOR_IMAGE_CLASS_1}
                                        dataProvider={dataProvider}
                                        carColorId={props.id}
                                        pathData={pathData1}
                                        setPathData={setPathData1}
                                        images={images1}
                                        setImages={setImages1}
                                    />
                                </Box>
                            </Grid>
                            <Grid container={true} spacing={3}>
                                <Box mt={2}>
                                    <Typography variant={'h6'}>上部画像(スマホ用画像)(無制限)</Typography>
                                    <CarColorImage
                                        carColorImageClass={CarColorImageClass.CAR_COLOR_IMAGE_CLASS_2}
                                        dataProvider={dataProvider}
                                        carColorId={props.id}
                                        pathData={pathData2}
                                        setPathData={setPathData2}
                                        images={images2}
                                        setImages={setImages2}
                                    />
                                </Box>
                            </Grid>
                            <Grid container={true} spacing={3}>
                                <Box mt={2}>
                                    <Typography variant={'h6'}>足回りシミュレーション用画像(1枚)</Typography>
                                    <CarColorSuspensionImageComponent images={imagesSuspensionImage}
                                                                      dataProvider={dataProvider}
                                                                      setPathData={setPathDataSuspensionImage}
                                                                      pathData={pathDataSuspensionImage}
                                                                      setImages={setImagesSuspensionImage}
                                                                      carColorId={props.id}
                                                                      required={false}/>

                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>

            }
        </>
    )
}

interface ActionTopToolbarProps {
    carGradeId?: number;
    carColorId?: number;
}

const ActionTopToolbar: React.FC<ActionTopToolbarProps>
    = (props) => {
    const {carModel, carGrade, getByCarGradeId, getByCarColorId} = useCarColor();
    useEffect(() => {
        if (props.carColorId) {
            getByCarColorId(props.carColorId).then();
        }
        if (props.carGradeId) {
            getByCarGradeId((props.carGradeId)).then();
        }
    }, [])

    return (
        <div className={'mb-30'}>
            {carGrade && carModel &&
            <CarColorsLabel carModel={carModel} carGrade={carGrade}/>
            }
        </div>
    );
};


export const CarColorsEditForm: React.FC = (props: any) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const onSuccess = () => {
        redirect('/carColors');
        refresh();
    };
    const transform = ({...data}) => {
        delete data.set;
        return data
    };

    return (
        <Edit
            {...props}
            mutationMode={'optimistic'}
            onSuccess={onSuccess}
            actions={<ActionTopToolbar carColorId={props.id}/>}
            transform={transform}
        >
            <CarColorsForm {...props} type={'update'}/>
        </Edit>
    )
}

interface CreateState {
    record: {
        carGradeId: number
    };
}

export const CarColorsCreateForm: React.FC<CreateProps> = (props) => {
    const state = props.location?.state as CreateState;
    return (
        <>
            {state && state.record &&
            <Create
                {...props}
                actions={<ActionTopToolbar carGradeId={state.record.carGradeId}/>}
            >
                <CarColorsForm {...props} type={'create'}/>
            </Create>
            }
        </>
    )
}
