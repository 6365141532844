import React from 'react';
import {Edit, required, SimpleForm, useRedirect, useRefresh} from 'react-admin';
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";
import {Grid} from '@mui/material';

const SettingEdit: React.FC = (props: any) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const validateText = [required()];
    const onSuccess = () => {
        redirect('/setting/1/show');
        refresh();
    };
    return (
        <Edit {...props} onSuccess={onSuccess} mutationMode={'optimistic'}>
            <SimpleForm submitOnEnter={false} toolbar={<ActionToolbar isSettings={true}/>}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <TextInputForJapanese source="usedMileage1"
                                              validate={validateText}
                                              label={"中古車走行距離1"}
                                              fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInputForJapanese source="usedMileage2"
                                              validate={validateText}
                                              label={"中古車走行距離2"}
                                              fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInputForJapanese source="usedMileage3"
                                              validate={validateText}
                                              label={"中古車走行距離3"}
                                              fullWidth={true}
                        />
                    </Grid>
                </Grid>
                <TextInputForJapanese multiline source="vehiclePurchaseAgreement" validate={validateText}
                                      label={"ご注文同意事項(車両も購入(HTMLタグ)"}
                                      fullWidth={true} rows={10}/>
                <TextInputForJapanese multiline source="partsOnlyPurchaseAgreement" validate={validateText}
                                      label={"ご注文同意事項(部品のみ購入(HTMLタグ)"}
                                      fullWidth={true} rows={10}/>
            </SimpleForm>
        </Edit>
    );
};

export default SettingEdit;
