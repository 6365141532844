import {maxValue, minValue, number, required, TextInput} from "react-admin";
import React from "react";
import {floatValidation, lengthNumberValidation} from "./validate";
import {TextInputProps} from "ra-ui-materialui/lib/input/TextInput";

interface NumberInputCommonProps extends TextInputProps {
    source: string;
    className?: string;
    isRequired?: boolean;
    label?: string;
    fullWidth?: boolean;
    isDouble?: boolean;
    allowNegative?: boolean;
    max?: number;
    minLength?: boolean;
}

const NumberInputCommon: React.FC<NumberInputCommonProps> = (
    {
        source,
        className = 'input-filled-basic',
        isRequired = true,
        label = '',
        fullWidth = true,
        isDouble = false,
        allowNegative = false,
        max = 999999999999999,
        minLength = false,
        ...props
    }
) => {
    const validateNumber = [number()];
    if (isRequired) {
        validateNumber.push(required());
    }
    if (!isDouble) {
        validateNumber.push(floatValidation);
    }
    if (!allowNegative) {
        validateNumber.push(minValue(0))
    }
    if (max) {
        validateNumber.push(maxValue(max))
    }
    if(!minLength) {
        validateNumber.push(lengthNumberValidation);
    }
    return (
        <TextInput
            source={source}
            type={allowNegative ? 'text' : 'number'}
            className={className}
            validate={validateNumber}
            label={label}
            fullWidth={fullWidth}
            {...props}
        />
    );
}


export default NumberInputCommon
