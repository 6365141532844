import {DeleteWithConfirmIconButton,} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {
    AppBar,
    Button,
    Datagrid,
    EditButton,
    List,
    TextField,
    TopToolbar,
    useGetOne,
    useRecordContext,
} from 'react-admin';
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import {ApiCar} from "../../openapi";

const BASE_PATH = '/carModels';
const RESOURCE = 'carModels';


interface ScreenNameProps {
    car: ApiCar;
}

const ScreenName: React.FC<ScreenNameProps> = (props) => {
    return (
        <AppBar {...props} title={'foo'}>
            <div className={'app-bar-title'}>
                <BreadcrumbTrail
                    car={props.car}
                />
                モデル管理
            </div>
        </AppBar>
    );
}

interface ListActionProps {
    carId: number;
}

const ListActions: React.FC<ListActionProps> = (props) => {
    return (
        <>
            <TopToolbar>
                <Button
                    component={Link}
                    to={{
                        pathname: '/carModels/create',
                        state: {record: {carId: props.carId}}
                    }}
                    label={'作成'}
                />
            </TopToolbar>
        </>
    );
};

const CarModelsList: React.FC = (props: any) => {
    const {carId} = useParams<{ carId: string }>();
    const car = useGetOne('cars', Number(carId)).data as ApiCar;

    return (
        <>
            <ScreenName car={car}/>
            <div className={'mt-30'}>
                <Button
                    label={'戻る'}
                    variant="contained"
                    color={"default"}
                    href={'/cars'}
                />
            </div>
            <List
                {...props}
                actions={<ListActions carId={Number(carId)}/>}
                empty={false}
                sort={{field: 'position', order: 'ASC'}}
                exporter={false}
                bulkActionButtons={false}
                filter={{carId: Number(carId)}}
                basePath={BASE_PATH}
                resource={RESOURCE}
            >
                <Datagrid>
                    <TextField source="name" label={'モデル名'}/>
                    <TextFieldWithCommas source="position" label={'表示順序'} textAlign={'right'}/>
                    <div className={'actionButtons'}>
                        <UrlLinkCarGradesBtn source="id"/>
                        <LinkMountSetting/>
                        <EditDataBtn {...props}/>
                        <DeleteDataBtn className={'ml-30'} {...props} confirmTitle={'削除'}
                                       confirmContent={'削除しても宜しいでしょうか。'}/>
                    </div>
                </Datagrid>
            </List>
        </>
    );
};

const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''} basePath={BASE_PATH} resource={RESOURCE}/>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (
        <DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}
                                     redirect={`/carModels/list/${record['carId']}`}
                                     basePath={BASE_PATH} resource={RESOURCE}
        />);
};

const UrlLinkCarGradesBtn = ({...source}) => {
    const record = useRecordContext();
    return (
        <Button
            label={'グレード'}
            variant="contained"
            component={Link}
            to={{
                pathname: `/carModels/${record['id']}/show`
            }}
            className={'mr-30'}
        />
    );
};

const LinkMountSetting = () => {
    const record = useRecordContext();
    return (
        <Button
            component={Link}
            label={'装着可・不可設定'}
            variant="contained"
            to={{pathname: `/mountSettings/${record['id']}`}}
            className={'mr-30'}
        />
    );
}


export default CarModelsList;
