import React from "react";
import {useField} from "react-final-form";
import {TextInput} from "react-admin";
import InputChangeUtil from "../utils/InputChangeUtil";
import {TextInputProps} from "ra-ui-materialui/lib/input/TextInput";


export const TextInputForJapanese: React.FC<TextInputProps> = (props) => {
    const field = useField(props.source);
    const handleBlur = () => {
        const converted = InputChangeUtil().changeKanaAndHalfWidth(field.input.value);
        field.input.onChange(converted);
    }

    return (
        <TextInput
            onBlur={handleBlur}
            {...props}
        />
    )
};

