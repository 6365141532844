import {Backdrop, CircularProgress, Grid} from '@mui/material';
import React from 'react';
import './Brands.css';
import {
    BooleanInput,
    Button,
    Create,
    Edit,
    ImageField,
    ImageInput,
    Link,
    minValue,
    number,
    required,
    SimpleForm
} from 'react-admin';
import {ActionToolbar} from '../../common/ActionToolbar';
import NumberInputCommon from '../../common/NumberInputCommonComponent';
import {useStyles} from '../../common/styles';
import {TextInputForJapanese} from '../../common/TextInputForJapanese';
import {circularSize, maxFileSize} from '../../config/const';
import useBrandHooks from './BrandsHooks';
import BrandsImages from './BrandsImages';

const BrandsForm: React.FC<any> = (props) => {
    const validateText = [required()];
    const validateNumber = [required(), number(), minValue(0)];

    const {
        handleUpload, logoPathUrl, stampPathUrl, handleSave, opens, openImage, brandsImgPaths,
        logoPathSpUrl,
        logoPathSuspensionUrl,
    } = useBrandHooks(props);

    const ShowProgress: React.FC = () => {
        const classes = useStyles();
        return (
            <Backdrop className={classes.backdrop} open={opens}>
                <CircularProgress color="inherit" size={circularSize}/>
            </Backdrop>
        );
    }

    return (
        <>
            <ShowProgress/>
            <SimpleForm
                {...props}
                submitOnEnter={false}
                save={handleSave}
                opens={opens}
                toolbar={<ActionToolbar/>}>

                <div className={"brands w-100"}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} lg={3} md={6}>
                            <label>{
                                props.type === 'update' ? 'ブランド管理 編集' : 'ブランド管理 作成'
                            }</label>
                            <TextInputForJapanese source="name" label={'ブランド名'} validate={validateText}
                                                  fullWidth={true}/>
                            <NumberInputCommon source="position" label={'表示順序'} validate={validateNumber}
                                               fullWidth={true}/>
                            <TextInputForJapanese source="companyName" label={'会社名'} validate={validateText}
                                                  fullWidth={true}/>
                            <TextInputForJapanese source="postCode" label={'郵便番号'} validate={validateText}
                                                  fullWidth={true}/>
                            <TextInputForJapanese source="address1" label={'都道府県'} validate={validateText}
                                                  fullWidth={true}/>
                            <TextInputForJapanese source="address2" label={'市区町村'} validate={validateText}
                                                  fullWidth={true}/>
                            <TextInputForJapanese source="address3" label={'番地、建物・マンション名'} validate={validateText}
                                                  fullWidth={true}/>
                            <TextInputForJapanese source="tel" label={'電話番号'} validate={validateText}
                                                  fullWidth={true}/>
                            <TextInputForJapanese source="fax" label={'FAX'} validate={validateText} fullWidth={true}/>
                            <BooleanInput source="vrarva" label={'vrarva'} fullWidth={true}/>
                        </Grid>
                        <Grid item lg={9} md={6}>
                            <label>画像アップロード</label>

                            <Grid container spacing={2}>
                                <Grid item lg={3}>
                                    <ImageInput
                                        source="logoPath"
                                        accept="image/*"
                                        label="ロゴ画像"
                                        maxSize={maxFileSize}
                                        onChange={(files: File) => handleUpload(files, 'logoPath')}
                                        validate={props.type === 'update' || logoPathUrl ? [] : [required()]}
                                    >
                                        <ImageField source="logoPath" title="logoPathImg"/>
                                    </ImageInput>

                                    {(logoPathUrl) ?
                                        <BrandsImages
                                            imageURL={logoPathUrl}
                                            openImage={openImage}
                                            id={props.id}
                                            imgDir={brandsImgPaths}
                                        /> : null
                                    }
                                </Grid>

                                <Grid item lg={3}>
                                    <ImageInput
                                        source="logoPathSp"
                                        accept="image/*"
                                        label="ロゴ画像(SP)"
                                        maxSize={maxFileSize}
                                        onChange={(files: File) => handleUpload(files, 'logoPathSp')}
                                        validate={props.type === 'update' || logoPathSpUrl ? [] : [required()]}
                                    >
                                        <ImageField source="logoPath" title="logoPathImg"/>
                                    </ImageInput>

                                    {(logoPathSpUrl) ?
                                        <BrandsImages
                                            imageURL={logoPathSpUrl}
                                            openImage={openImage}
                                            id={props.id}
                                            imgDir={brandsImgPaths}
                                        /> : null
                                    }
                                </Grid>


                                <Grid item lg={3}>
                                    <ImageInput
                                        source="stampPath"
                                        accept="image/*"
                                        label="社印画像"
                                        maxSize={maxFileSize}
                                        onChange={(files: File) => handleUpload(files, 'stampPath')}
                                        validate={props.type === 'update' || stampPathUrl ? [] : [required()]}
                                    >
                                        <ImageField source="stampPath" title="thumbnailImg"/>
                                    </ImageInput>

                                    {
                                        (stampPathUrl) ? <BrandsImages
                                            imageURL={stampPathUrl}
                                            openImage={openImage}
                                            id={props.id}
                                            imgDir={brandsImgPaths}
                                        /> : null
                                    }
                                </Grid>

                                <Grid item lg={3}>
                                    <ImageInput
                                        source="logoPathSuspension"
                                        accept="image/*"
                                        label="サスペンションページ用画像"
                                        maxSize={maxFileSize}
                                        onChange={(files: File) => handleUpload(files, 'logoPathSuspension')}
                                        validate={props.type === 'update' || logoPathSuspensionUrl ? [] : [required()]}
                                    >
                                        <ImageField source="logoPathSuspension" title="logoPathSuspensionImg"/>
                                    </ImageInput>

                                    {
                                        (stampPathUrl) ? <BrandsImages
                                            imageURL={logoPathSuspensionUrl}
                                            openImage={openImage}
                                            id={props.id}
                                            imgDir={brandsImgPaths}
                                        /> : null
                                    }
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </>
    )
}

const ActionTopToolbar = ({...props}) => (
    <div className={'mb-30'}>
        <Button
            component={Link}
            label={'戻る'}
            variant="contained"
            color={'default'}
            to={{pathname: "/brands"}}
        />
    </div>
);
export const BrandsCreate: React.FC = (props: any) => {
    return (
        <Create
            {...props}
            actions={<ActionTopToolbar/>}
        >
            <BrandsForm {...props} type={'create'}/>
        </Create>
    )
}
export const BrandsEdit: React.FC = (props: any) => {
    return (
        <Edit
            {...props}
            mutationMode={'optimistic'}
            actions={<ActionTopToolbar/>}
        >
            <BrandsForm {...props} type={'update'}/>
        </Edit>
    )
}


export default BrandsForm;
