import React, {useEffect, useState} from 'react';
import {DeleteWithConfirmIconButton, EditableDatagrid, EditRowButton, RowForm} from '@react-admin/ra-editable-datagrid';
import {DateField, List, useDataProvider, useNotify, useRecordContext, useRedirect, useRefresh} from 'react-admin';
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import moment from "moment";
import {ja} from "date-fns/locale";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {Container} from "@material-ui/core";

const ConsumptionTaxesList: React.FC = (props: any) => {

    return (
        <Container className={'smallForm'}>
            <List
                {...props}
                hasCreate
                empty={false}
                sort={{field: 'start', order: 'DESC'}}
                exporter={false}
                bulkActionButtons={false}
                perPage={PER_PAGE}
                pagination={<MyPagination/>}
            >
                <EditableDatagrid
                    undoable={true}
                    createForm={<DataForm {...props} type={'create'}/>}
                    editForm={<DataForm {...props} type={'update'}/>}
                    noDelete={false}
                    className={'listTable'}
                >
                    <DateField source="start" label={'開始日'} locales={"fr-CA"}/>
                    <PercentageTextField source="rate" label={'税率'} textAlign={'right'}/>
                    <div className={'actionButtons'}>
                        <EditRowButton/>
                        <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                       className={'ml-30'}/>
                    </div>
                </EditableDatagrid>
            </List>
        </Container>
    );
};

const DataForm: React.FC = (props: any) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>();
    const [dateError, setDateError] = useState(false);
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleSave = async ({...data}) => {
        const newDate = new Date(moment(selectedDate).format('YYYY-MM-DD'));
        if (newDate.toString() !== 'Invalid Date') {
            if (props.type === 'create') {
                await dataProvider.create('consumptionTaxes', {data: {...data, start: newDate}});
                redirect('/consumptionTaxes');
                refresh();
                notify('作成しました');
            } else {
                delete data.set;
                await dataProvider.update('consumptionTaxes', {
                    id: data.id,
                    data: {...data, start: newDate},
                    previousData: {id: data.id}
                });
                redirect('/consumptionTaxes');
                notify('更新しました');
            }
        } else {
            setDateError(true)
        }
    };
    return (
        <RowForm {...props} save={handleSave} submitOnEnter={false}>
            <CustomDatePicker source="start" label={'開始日'} selectedDate={selectedDate} dates={dateError}
                              setSelected={setSelectedDate}/>
            <NumberInputCommon
                source="rate"
                label={'税率'}
                fullWidth={false}
                isRequired={true}
                max={1000}
            />
        </RowForm>
    )
};

const CustomDatePicker = ({...props}) => {
    useEffect(() => {
        if (props.record && props.record.hasOwnProperty('start')) {
            // console.log('before', props.record.start)
            const formatted = new Date(moment(props.record.start).format('YYYY-MM-DD'));
            // console.log('after', formatted)
            props.setSelected(formatted);
        }
    }, [props.record]);
    const handleDateChange = (date: Date | null) => {
        props.setSelected(date);
    };
    return (
        <MuiPickersUtilsProvider locale={ja} utils={DateFnsUtils}>
            <KeyboardDatePicker
                {...props}
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                name={props.source}
                id="date-picker-inline"
                value={props.selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                invalidDateMessage="日付が不正です"
                error={props.dates !== false}
                helperText={props.dates === false ? '' : '必須'}
            />
        </MuiPickersUtilsProvider>
    )
};

const PercentageTextField = ({...source}) => {
    const record = useRecordContext();
    return (<span className={'numberRight'}>{`${record && (record[source.source]).toLocaleString()} %`}</span>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};

export default ConsumptionTaxesList;
