import {SharedDialogWrapper} from "../../importcsv/components/SharedDialogWrapper";
import {Box, List} from "@material-ui/core";
import {SharedDialogButton} from "../../importcsv/components/SharedDialogButton";
import {Clear} from "@material-ui/icons";
import React from "react";

interface ErrorProps {
    handleClose: () => any;
    open: boolean;
    errorMessages: string[];
}

export const ProductCSVErrorModal = (props: ErrorProps) => {
    const {
        handleClose,
        open,
        errorMessages,
    } = props;
    return (
        <SharedDialogWrapper
            title={'csvデータのエラー。'}
            open={open}
            handleClose={handleClose}
        >
            <div className={'productModal'}>
                <p>{Array.isArray(errorMessages) && errorMessages.join(`\n`)}</p>
                <List className={'flexContainer ml-250'}>
                    <Box m={1}>
                        <SharedDialogButton
                            onClick={handleClose}
                            icon={<Clear htmlColor="#3a88ca"/>}
                            label={'キャンセル'}
                        />
                    </Box>
                </List>
            </div>
        </SharedDialogWrapper>
    )
}