import React, {Dispatch, SetStateAction} from "react";
import {Grid} from "@material-ui/core";
import {DataProviderProxy} from "ra-core";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {useRecommendedCarImageHooks} from "./recommendedCarHook";
import {RecommendedCarImageClass, ResourceNames} from "../../config/const";
import SortableImageList from "../../common/image/SortableImageList";

interface RecommendedCarImageProps {
    recommendedCarId?: number;
    dataProvider: DataProviderProxy;
    setPathData1: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData1: TempFileData[];
    setPathData2: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData2: TempFileData[];
    setPathCrop: Dispatch<SetStateAction<Array<TempFileData>>>;
    setImages1: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    setImages2: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    images1: ListPhotosType[] | undefined;
    images2: ListPhotosType[] | undefined;
    maxFileCount1?: number;
    maxFileCount2?: number;
    setImageId?: Dispatch<SetStateAction<number | undefined>>;
    recommendedImageId?: number;
    setThumbnailUpdating?: Dispatch<SetStateAction<boolean>>;
    setBigImageUpdating?: Dispatch<SetStateAction<boolean>>;
    updateRefresh: number;
}

const RecommendedCarsImagesComponent: React.FC<RecommendedCarImageProps> = (props) => {
    useRecommendedCarImageHooks({
        recommendedCarId: props.recommendedCarId,
        recommendedCarImageClass1: RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_1,
        recommendedCarImageClass2: RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_2,
        dataProvider: props.dataProvider,
        setImages1: props.setImages1,
        setImages2: props.setImages2,
        refresh: props.updateRefresh,
    });
    return (
        <Grid item xs={11} sm={9} md={10} lg={10} xl={10} className={'mt-20'}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h3>一覧画像(2枚)</h3>
                    <SortableImageList imageArray={props.images1}
                                       dataProvider={props.dataProvider}
                                       resource={ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE}
                                       isUpdating={!!props.recommendedCarId}
                                       setPathData={props.setPathData1}
                                       pathData={props.pathData1}
                                       setImages={props.setImages1}
                                       imageSourceName={ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE + RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_1.toString()}
                                       maxFileCount={props.maxFileCount1}
                                       required={true}
                    />
                </Grid>
                <h3>詳細画像(無制限)</h3>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SortableImageList imageArray={props.images2}
                                       dataProvider={props.dataProvider}
                                       resource={ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE}
                                       isUpdating={!!props.recommendedCarId}
                                       setPathData={props.setPathData2}
                                       pathData={props.pathData2}
                                       setImages={props.setImages2}
                                       imageSourceName={ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE + RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_2.toString()}
                                       maxFileCount={props.maxFileCount2}
                                       multipleUpload={false}
                                       required={true}
                                       setImageId={props.setImageId}
                                       setPathCrop={props.setPathCrop}
                                       imageId={props.recommendedImageId}
                                       setFlagCrop={props.setThumbnailUpdating}
                                       setFlagBig={props.setBigImageUpdating}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default RecommendedCarsImagesComponent;
