import React from "react";
import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {
    AutocompleteInput,
    BooleanField,
    email,
    Filter,
    List,
    maxLength,
    minLength,
    minValue,
    NullableBooleanInput,
    number,
    PasswordInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TextField,
    useDataProvider,
    useRecordContext
} from "react-admin";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {Box, Grid} from "@material-ui/core";

export const roles = [{id: 'ADMIN', name: '管理者'}, {id: 'SALES', name: '営業部'}, {id: 'USER', name: '一般ユーザー'}];

const UsersListComponent: React.FC = (props: any) => {
    return (
        <List
            filters={<UsersFilter/>}
            {...props}
            hasCreate
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            className={'filterForBox'}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <EditableDatagrid
                undoable={true}
                createForm={<DataForm/>}
                editForm={<DataForm/>}
                noDelete={false}
                className={'editableListTable'}
            >
                <TextField source="name" label={'名称'}/>
                <TextField source="email" label={'email'}/>
                <RoleFieldForJapanese source="role" label={'権限'}/>
                <ReferenceField
                    label={'所属店舗'}
                    source="shopId"
                    reference="shops"
                    link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                <BooleanField source="unavailable" label={'辞職・休職'}/>
                <TextField source="position" label={'表示順序'}/>
                <PasswordField source="encryptedPassword" label={'パスワード'}/>
            </EditableDatagrid>
        </List>
    )
};

const DataForm: React.FC = (props: any) => {
    const dataProvider = useDataProvider();
    const validateName = [required(), maxLength(50)];
    const validateEmail = [required(), email()];
    const validatePassword = props.id === 'new_record' ?
        [required(), minLength(6)] : [minLength(6)];
    const validateNumber = [required(), number(), minValue(0)];
    const handleFormat = (value: any) => {
        if (value)
            return (props.id && props.id !== 'new_record') && value === props.record.encryptedPassword ?
                '' : value;
    };
    const handleTransform = async (record: any) => {
        if (record.id) {
            const {data} = await dataProvider.getOne('users', {id: Number(record.id)});
            const newData = !record.encryptedPassword && data ?
                {
                    ...record,
                    encryptedPassword: data.encryptedPassword
                }
                :
                {
                    ...record,
                };
            return {
                ...newData,
            };
        } else {
            return {
                ...record,
            };
        }
    };
    return (
        <RowForm
            {...props}
            submitOnEnter={false}
            className={'userForm'}
            transform={handleTransform}
        >
            <TextInputForJapanese source="name" validate={validateName} label={'名称'}/>
            <TextInputForJapanese source="email" validate={validateEmail} label={'email'}/>
            <SelectInput source="role" choices={roles} validate={required()} label={'権限'}/>
            <ReferenceInput label={'所属店舗'} source="shopId" reference="shops">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <NullableBooleanInput source="unavailable" validate={required()} label={'辞職・休職'}/>
            <TextInputForJapanese source="position" validate={validateNumber} label={'表示順序'}/>
            <PasswordInput source="encryptedPassword" validate={validatePassword} format={handleFormat}
                           label={'パスワード'}/>
        </RowForm>
    )
};

const UsersFilter: React.FC = (props: any) => {
    return (
        <Filter {...props}>
            {/*// @ts-ignore*/}
            <Box bgcolor="white" p={2} id='box' className={'filterBox'} mt={2} alwaysOn>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true}>
                        <TextInputForJapanese label="名称" source="name" className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} spacing={2}>
                        <AutocompleteInput label="権限" choices={roles} alwaysOn resettable={true}
                                           source="role"/>
                    </Grid>
                    <Grid item={true}>
                        <ReferenceInput label={'所属店舗'} source="shopId" reference="shops">
                            <AutocompleteInput alwaysOn resettable={true}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item={true}>
                        <NullableBooleanInput fullWidth={true} label="辞職・休職" source="unavailable"
                                              className={'p-display-none w-150'} alwaysOn/>
                    </Grid>
                </Grid>
            </Box>
        </Filter>
    )
};

const PasswordField = ({...source}) => {
    const record = useRecordContext();
    return (
        <span>{`${record && record[source.source].toString().replace(record[source.source], Array(record[source.source].length).join("*")).substring(0, 6)}`}</span>
    )
};

const RoleFieldForJapanese = ({...source}) => {
    const record = useRecordContext();
    return (
        <span>{`${record && roles[roles.findIndex((item) => item.id.toString() === record[source.source].toString())].name.toString()}`}</span>
    )
};

export default UsersListComponent;