import React, {useEffect, useState} from 'react';
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {useForm} from "react-final-form";
import {useDataProvider} from "react-admin";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {ApiProduct, ApiVariantItem, ApiVariantItemProductPrice} from "../../openapi";

interface RecommendedPriceProps {
    defaultVariantItemIds: number[] | undefined;
    productInitialValues: number[] | undefined;
    selectAffectPriceVariantItemIds: number[] | undefined;
}

const RecommendedPrice: React.FC<RecommendedPriceProps> = (props) => {
    const form = useForm();
    const dataProvider = useDataProvider();
    const [salePriceState, setSalePriceState] = useState(0);
    const [costState, setCostState] = useState(0);
    const savedValues = form.getState().values;
    const recommendedCarId = savedValues['id'];
    const defaultProductIds = savedValues['defaultProducts'] as number[] | undefined;

    const fetchPricing = () => {
        const awaitFn = async () => {
            let salePrice = 0;
            let cost = 0;

            const getVariantItemProductPriceCombinations = async (id: number) => {
                const result = await dataProvider.getManyNoParams('variantItemProductPriceCombinations/' + Number(id))
                return result.data as Array<ApiVariantItemProductPrice>;
            }

            const extracted = async (ids: number[]) => {
                const productsRawData = await dataProvider.getList('products', {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'createdAt', order: 'ASC'},
                    filter: {
                        ids: ids
                    }
                });

                const productsData = productsRawData.data as ApiProduct[]

                await Promise.all(productsData.map(async (product) => {
                    const variantItemProductPriceCombinationsList = await getVariantItemProductPriceCombinations(product.id as number);
                    variantItemProductPriceCombinationsList.map((variantItemProductPriceCombination) => {
                        if (props.selectAffectPriceVariantItemIds?.length === variantItemProductPriceCombination.variantItem?.length) {
                            if (props.selectAffectPriceVariantItemIds?.every((selectVariantItemId) => {
                                return variantItemProductPriceCombination?.variantItem?.find((variantItem) => variantItem.id === selectVariantItemId)
                            })) {
                                salePrice += variantItemProductPriceCombination.price ? variantItemProductPriceCombination.price * 4 : 0;
                                cost += variantItemProductPriceCombination.cost ? variantItemProductPriceCombination.cost * 4 : 0;
                            }
                        }
                    })
                }))
            }

            if (defaultProductIds && defaultProductIds.length) {
                await extracted(defaultProductIds);
            } else if (props.productInitialValues && props.productInitialValues.length) {
                const uniqueArray = Array.from(new Set(props.productInitialValues));
                await extracted(uniqueArray);
            }

            let variantPrice = 0;
            let variantCost = 0;

            if (+recommendedCarId && props.defaultVariantItemIds) {
                await Promise.all(props.defaultVariantItemIds!.map(async (id) => {
                    const variantItemRaw = await dataProvider.getOne('variantItems', {id: id});
                    const variantItem = variantItemRaw.data as ApiVariantItem;

                    variantPrice += variantItem.price ? variantItem.price * 4 : 0;
                    variantCost += variantItem.cost ? variantItem.cost * 4 : 0;
                }))
            }
            // totalVariantItemPrice sum(recommendedCarDefaultVariantItems.price) + variantItemProductPrice.price
            setSalePriceState(salePrice + variantPrice);
            setCostState(variantCost + variantCost);
        }
        awaitFn().then();
    }

    useEffect(fetchPricing, [props.defaultVariantItemIds]);
    form.change('totalVariantItemPrice', salePriceState);

    return (
        <>
            <NumberInputCommon source="totalVariantItemPrice" label={'規格項目売価合計'} disabled={true}/>
        </>
    )
}

export default RecommendedPrice;