import React, {Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDataProvider} from "react-admin";
import {DataProviderProxy} from "ra-core";
import CompleteCarColorImage, {CompleteCarColorImageFunctions} from "./CarColorImagesField";
import {ApiCarGrade} from "../../openapi";
import {Grid, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import {useStyles} from "../../common/styles";

export interface CompleteCarColorImagesProps {
    selectedCarGradesIds: number[] | undefined;
    setSelectedCarGradesIds: Dispatch<SetStateAction<number[] | undefined>>;
    dataProvider: DataProviderProxy;
    completeCarId?: number;
}

export interface CompleteCarColorImagesFunctions {
    save: (completeCarId: number) => Promise<void>;
}

const CompleteCarColorImagesField: React.ForwardRefRenderFunction<CompleteCarColorImagesFunctions, CompleteCarColorImagesProps> = (props, ref) => {
    const dataProvider = useDataProvider();
    const [carGrade, setCarGrade] = useState(new Array<ApiCarGrade>());
    const [tabValue, setTabValue] = React.useState(0);
    let imagesRefs = useRef(new Array<CompleteCarColorImageFunctions | null>());
    const classes = useStyles();

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (props.completeCarId) {
            const awaitFn = async () => {
                const defaultCarGrade = await dataProvider.getOne('completeCars', {
                    id: props.completeCarId!!
                });
                setTabValue(Number(defaultCarGrade?.data.defaultCarGradeId));
            }
            awaitFn().then();
        }
    }, [props.completeCarId]);

    useEffect(() => {
        const awaitFn = async () => {

            const listCarGrade = await dataProvider.getList('carGrades', {
                pagination: {page: 1, perPage: 10000},
                sort: {field: 'position', order: 'ASC'},
                filter: {},
            });

            const filteredCarGrades = props.selectedCarGradesIds?.map((id) => {
                return listCarGrade.data.find((d) => d.id === id);
            })

            const array: React.SetStateAction<ApiCarGrade[]> = []
            const unique = filteredCarGrades?.filter((v, i, a) => a.indexOf(v) === i);
            unique?.map((item) => {
                array.push(item as ApiCarGrade)
            })

            array.sort((a, b) => {
                return a.position - b.position
            });

            setCarGrade(array);
        }

        if (props.selectedCarGradesIds) {
            awaitFn().then();
        }
    }, [props.selectedCarGradesIds])

    useImperativeHandle(ref, () => ({
        async save(completeCarId: number) {
            await Promise.all(imagesRefs.current?.map(async (imageRef) => {
                        await imageRef?.save(completeCarId);
                    }
                )
            )
        }
    }));

    imagesRefs.current = [];
    return (
        <>
            <Paper className={classes.scrollableTab}>
                <Tabs value={tabValue} onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                >
                    {carGrade?.map((carGradeData) => (
                        <Tab
                            label={carGradeData.name}
                            value={carGradeData.id}/>
                    ))}
                </Tabs>

                {carGrade.map((carGradeData) => {
                    return (
                        <>
                            <Grid container style={{display: tabValue !== carGradeData.id ? 'none' : 'block'}}>
                                {carGradeData.carColors?.map((item) =>
                                    <>
                                        <Typography className={classes.inlineHeader}>{item.name+'(無制限)'}</Typography>
                                        <CompleteCarColorImage
                                            dataProvider={dataProvider}
                                            completeCarId={props.completeCarId}
                                            carColorId={item.id}
                                            ref={(element) => imagesRefs.current.push(element)}
                                        />
                                    </>
                                )
                                }
                            </Grid>
                        </>
                    );
                })}


            </Paper>
        </>
    )
}

export default forwardRef(CompleteCarColorImagesField);
