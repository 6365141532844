import React, {useEffect} from "react";
import {SharedDialogWrapper} from "./SharedDialogWrapper";
import {SharedLoader} from "./SharedLoader";
import {translateWrapper} from "../translateWrapper";
import {Box, List} from "@material-ui/core";
import {Clear, Done} from "@material-ui/icons";
import {SharedDialogButton} from "./SharedDialogButton";

interface ImportCsvDialogStrategyProps {
    disableImportOverwrite: boolean;
    resourceName: string;
    fileName: string;
    count: number;
    handleClose: () => any;
    handleReplace: () => any;
    handleSkip: () => any;
    handleAskDecide: () => any;
    open: boolean;
    isLoading: boolean;
    idsConflicting: string[];
}

interface MessageState {
    title: string;
    subTitle: string;
    loadingTxt: string;
    labelSkip: string;
    labelReplace: string;
    labelDecide: string;
    messageHtml: string;
}

export const ImportCsvDialogStrategy = (props: ImportCsvDialogStrategyProps) => {
    const {
        count,
        disableImportOverwrite,
        resourceName,
        fileName,
        handleClose,
        handleReplace,
        open,
        isLoading,
        idsConflicting,
    } = props;
    const [messages, setMessages] = React.useState({} as MessageState);
    const translate = translateWrapper();

    useEffect(() => {
        setMessages({
            title: translate("csv.dialogImport.title", {
                resource: resourceName,
            }),
            subTitle: translate("csv.dialogCommon.subtitle", {
                count: count,
                fileName: fileName,
                resource: resourceName,
            }),
            loadingTxt: translate("csv.loading"),
            labelSkip: translate("csv.dialogImport.buttons.skipAllConflicts"),
            labelReplace: translate("csv.dialogImport.buttons.replaceAllConflicts"),
            labelDecide: translate("csv.dialogImport.buttons.letmeDecide"),
            messageHtml: translate("csv.dialogCommon.conflictCount", {
                resource: resourceName,
                conflictingCount: Array.isArray(idsConflicting) && idsConflicting.join(', '),
            }),
        });
    }, [count, resourceName, fileName, idsConflicting]);

    return (
        <SharedDialogWrapper
            title={messages.title}
            open={open}
            handleClose={handleClose}
        >
            {isLoading && <SharedLoader loadingTxt={messages.loadingTxt}></SharedLoader>}
            {idsConflicting && idsConflicting.length > 0 && !isLoading && (
                <div>
                    <p
                        style={{fontFamily: "sans-serif", margin: "0"}}
                        dangerouslySetInnerHTML={{
                            __html: messages.messageHtml,
                        }}
                    ></p>
                    <List className={'flexContainer ml-150'}>
                        <Box m={1}>
                            <SharedDialogButton
                                onClick={handleClose}
                                icon={<Clear htmlColor="#3a88ca"/>}
                                label={messages.labelSkip}
                            />
                        </Box>
                        <Box m={1}>
                            <SharedDialogButton
                                disabled={disableImportOverwrite}
                                onClick={handleReplace}
                                icon={<Done htmlColor="#29c130"/>}
                                label={messages.labelReplace}
                            />
                        </Box>
                    </List>
                </div>
            )}
        </SharedDialogWrapper>
    );
};
