import {convertDataForSortableImage} from "../../common/image/databaseImageUtils";
import {CompleteCarColorImageProps} from "./CarColorImagesField";
import {MAX_FETCH_DATA_SIZE, ResourceNames} from "../../config/const";
import {Dispatch, SetStateAction, useEffect} from "react";
import {ListPhotosType} from "../../common/image/arrayUtil";

interface CompleteCarColorImageHooksProps extends CompleteCarColorImageProps {
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
}

export const useCompleteCarColorImageHooks = (props: CompleteCarColorImageHooksProps) => {

    useEffect(() => {
        if (props.completeCarId && props.carColorId) {
            const awaitFn = async () => {
                await getImages()
            };
            awaitFn().then();
        }
    }, [props.completeCarId, props.carColorId]);


    const getImages = async () => {
        if (props.completeCarId && props.carColorId) {
            const result = await convertDataForSortableImage(props.dataProvider,
                ResourceNames.COMPLETE_CAR_COLOR_IMAGE_RESOURCE,
                {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'position', order: 'ASC'},
                    filter: {
                        completeCarId: props.completeCarId,
                        carColorId: props.carColorId,
                    }
                }
            )
            props.setImages(result);
        }
    }
}
