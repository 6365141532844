import React, {useCallback, useEffect, useState} from "react";
import {Button, useDataProvider, useRedirect, useRefresh} from "react-admin";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {ApiAeroSeriesProducts} from "../../openapi";
import {Grid, TableCell, TableRow} from "@material-ui/core";

export interface AeroSeriesProductListFunctions {
    save: (
        aeroSeriesId: number,
        aeroSeriesProducts: number[],
    ) => void;
}

interface AeroSeriesProductProps {
    aeroSeriesId?: number;
}

export const AeroSeriesProductList: React.FC<AeroSeriesProductProps> = (props) => {
    const dataProvider = useDataProvider();
    const [aeroSeriesProducts, setAeroSeriesProducts] = useState<ApiAeroSeriesProducts>()

    useEffect(() => {
        const awaitFn = async () => {
            if (props.aeroSeriesId == undefined || props.aeroSeriesId == 0)
                return;

            const data = await dataProvider.getList('aeroSeriesProducts', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'createdAt', order: 'ASC'},
                filter: {
                    aeroSeriesId: props.aeroSeriesId,
                },
            });
            const fetched = data as ApiAeroSeriesProducts
            setAeroSeriesProducts(fetched)
        }
        awaitFn().then();
    }, [props.aeroSeriesId])

    return (
        <Grid>
            <TableRow>
                <TableCell align="right">商品ID</TableCell>
                <TableCell>商品カテゴリ</TableCell>
                <TableCell>商品名</TableCell>
                <TableCell></TableCell>
            </TableRow>
            <>
                {aeroSeriesProducts?.data?.map((aeroSeriesProduct) => {
                    return (
                        <>
                            <TableRow>
                                <TableCell>{aeroSeriesProduct.productId}</TableCell>
                                <TableCell>{aeroSeriesProduct.productCategoryName}</TableCell>
                                <TableCell>{aeroSeriesProduct.productName}</TableCell>
                                <TableCell>
                                    <DeleteBtn variant={"contained"} label={'削除'}
                                               id={Number(aeroSeriesProduct.id)}
                                               aeroSeriesId={Number(aeroSeriesProduct.aeroSeriesId)}/>
                                </TableCell>
                            </TableRow>
                        </>
                    );
                })}
            </>
        </Grid>
    );
}

const DeleteBtn = ({...props}) => {
    const dataProvider = useDataProvider();

    const refresh = useRefresh();
    const redirect = useRedirect();
    const handleClick = useCallback(async () => {
        if (window.confirm('削除しても宜しいでしょうか。')) {
            await dataProvider.delete('aeroSeriesProducts', {
                id: props.id,
                previousData: {id: props.id},
            }).then(e => {
                console.log(e)
            });
            window.location.reload()
            redirect('/aeroSeries/' + props.aeroSeriesId.toString());
            refresh();
        }
    }, []);

    return (
        <Button onClick={handleClick} style={{backgroundColor: '#ff0000'}} {...props} />
    );
}

export default AeroSeriesProductList;