import React, {Dispatch, SetStateAction} from "react";
import {DataProviderProxy} from "ra-core";
import {useJNumberAndInsetRecommendationImageHooks} from "./JNumberAndInsetRecommendationImageHooks";
import SortableImageList from "../../common/image/SortableImageList";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {ResourceNames} from "../../config/const";

export interface JNumberAndInsetRecommendationImageProps {
    jNumberAndInsetRecommendationId?: number;
    dataProvider: DataProviderProxy;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData: TempFileData[];
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    images: ListPhotosType[] | undefined;
    maxFileCount?: number;
    id: number;
    uploadedCounter: number;
}

const JNumberAndInsetRecommendationImage: React.FC<JNumberAndInsetRecommendationImageProps> = (props) => {
    useJNumberAndInsetRecommendationImageHooks(props);
    return (
        <>
            <SortableImageList imageArray={props.images}
                               dataProvider={props.dataProvider}
                               resource={ResourceNames.J_NUMBER_AND_INSET_RECOMMENDATION_IMAGE_RESOURCE}
                               isUpdating={!!props.jNumberAndInsetRecommendationId}
                               setPathData={props.setPathData}
                               pathData={props.pathData}
                               setImages={props.setImages}
                               imageSourceName={ResourceNames.J_NUMBER_AND_INSET_RECOMMENDATION_IMAGE_RESOURCE + props.id.toString()}
                               maxFileCount={props.maxFileCount}
                               required={false}
            />
        </>
    );
}

export default JNumberAndInsetRecommendationImage
