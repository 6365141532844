import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {arraySort, ListPhotosType, PreviousData} from "./arrayUtil";
import {DataProviderProxy} from "ra-core";
import {SortBoxType} from "./SortBox";

interface ActionType {
    type: 'before' | 'after' | 'change';
}

export const useSortingHooks = <T extends ListPhotosType>
(images: T[],
 dataProvider: DataProviderProxy,
 resource: string,
 setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>,
 callback?: (mode: string, index?: number) => void,
) => {
    const [prevData, setPrevData] = useState<PreviousData>();
    const [positionChanged, setPositionChanged] = useState<boolean>(false);

    useEffect(() => {
        if (!positionChanged)
            return;
        if (images) {
            images.map((image, index) => {
                image.position = index + 1;
                const params = {
                    id: image.id,
                    data: {...image},
                    previousData: {id: image.id}
                };
                dataProvider.update(resource, params).then();
            });
            setPositionChanged(false);
        }
    }, [images, positionChanged])

    const sortActions = (mode: ActionType['type'], index: number, value?: number) => {
        setPositionChanged(true);
        let sortedData: ListPhotosType[];
        switch (mode) {
            case 'before':
                sortedData = arraySort(images, index, images[index].position - 1);
                setImages(sortedData);
                break;
            case 'after':
                sortedData = arraySort(images, index, images[index].position + 1);
                setImages(sortedData);
                break;
            case 'change':
                value = Number(value) > images.length ? images.length : Number(value);
                value = isNaN(value) ? 1 : value;
                if (!prevData) {
                    setPrevData({index, previousPosition: images[index].position});
                }
                images[index].position = value;
                setImages(images);
                break;
            default:
                break;
        }
        if (callback && mode !== 'change') {
            callback(mode, index);
        }
    };

    const sortBoxProps: SortBoxType = {
        onBeforeClick: (index: number) => {
            sortActions('before', index);
        },
        onAfterClick: (index: number) => {
            sortActions('after', index);
        },
        onChange: (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
            sortActions('change', index, Number(e.target.value));
        },
    };

    useEffect(() => {
        const timedExecution = setTimeout(() => {
            if (prevData) {
                if (callback) {
                    callback('change');
                }
                setPrevData(undefined);
            }
        }, 450);
        return () => clearTimeout(timedExecution);
    }, [images, prevData]);

    return {sortActions, sortBoxProps};
};
