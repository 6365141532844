import {DeleteWithConfirmIconButton, EditableDatagrid, EditRowButton, RowForm} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {List, maxLength, minValue, number, required, TextField, useRecordContext,} from 'react-admin';
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import NumberInputCommon from "../../common/NumberInputCommonComponent";

const EcoCarTaxCutsList: React.FC = (props: any) => {
    return (
        <List
            {...props}
            hasCreate
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <EditableDatagrid
                undoable={true}
                createForm={<DataForm/>}
                editForm={<DataForm/>}
                noDelete={false}
                className={'listTable'}
            >
                <TextField source="name" label={'エコカー減税適用項目名'}/>
                <TextField source="position" label={'表示順序'}/>
                <div className={'actionButtons'}>
                    <EditRowButton/>
                    <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                   className={'ml-30'}/>
                </div>
            </EditableDatagrid>
        </List>
    );
}

const DataForm: React.FC = (props) => {
    const validateName = [required(), maxLength(50)];
    const validateNumber = [required(), number(), minValue(0)];
    return (
        <RowForm {...props} >
            <TextInputForJapanese source="name" validate={validateName} label={'エコカー減税適用項目名'}/>
            <NumberInputCommon source="position" validate={validateNumber} label={'表示順序'}/>
        </RowForm>
    )
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};

export default EcoCarTaxCutsList;
