import React from 'react';
import {Edit, minValue, number, required, SimpleForm, useRedirect, useRefresh,} from 'react-admin';
import {floatValidation} from '../../common/validate';
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";

const GeneralChargesEdit: React.FC = (props: any) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const validateNumber = [required(), minValue(0), number(), floatValidation];
    const onSuccess = () => {
        redirect('/generalCharges/1/show');
        refresh();
    };
    return (
        <Edit
            {...props}
            mutationMode={'optimistic'}
            onSuccess={onSuccess}>

            <SimpleForm submitOnEnter={false} toolbar={<ActionToolbar isGeneralCharges={true}/>}>
                <label>法定費用</label>
                <TextInputForJapanese source="legalFeeInspectionRegistration" validate={validateNumber}
                                      label={'検査登録（届出）'}/>
                <TextInputForJapanese source="legalFeeGarageCertificatePaperFee" validate={validateNumber}
                                      label={'車庫証明証紙代'}/>
                <TextInputForJapanese source="legalFeeTradeIn" validate={validateNumber} label={'下取車'}/>
                <TextInputForJapanese source="stampDuty" validate={validateNumber} label={'印紙税'}/>
                <TextInputForJapanese source="numberFee" validate={validateNumber} label={'ナンバー代'}/>
                <label>代行費用</label>
                <TextInputForJapanese source="agentFeeInspectionRegistration" validate={validateNumber}
                                      label={'検査登録（届出）'}/>
                <TextInputForJapanese source="agentFeeGarageCertificatePaperFee" validate={validateNumber}
                                      label={'車庫証明'}/>
                <TextInputForJapanese source="agentFeeTradeIn" validate={validateNumber} label={'下取車'}/>
                <TextInputForJapanese source="selectiveNumberFee" validate={validateNumber} label={'一般希望番号申請費'}/>
                <TextInputForJapanese source="carShippingFee" validate={validateNumber} label={'製作車両移動費用'}/>
            </SimpleForm>
        </Edit>
    );
}

export default GeneralChargesEdit;
