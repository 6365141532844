import {Grid, Typography} from '@material-ui/core';
import SortableImage from './SortableImage';
import SortBox from './SortBox';
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {ListPhotosType} from "./arrayUtil";
import {maxFileSize, RecommendedCarImageClass, ResourceNames} from "../../config/const";
import {DataProviderProxy, ImageField, ImageInput, required, Validator} from "react-admin";
import {useSortingHooks} from "./sortingHooks";
import {TempFileData} from "../../modules/temporaryFileModule";
import {convertFilesToTempFileData} from "../fileUtils";
import {useForm} from 'react-final-form';
import RecommendedCarsImageCroppingField from "../../components/recommendedCars/RecommendedCarsImageCroppingField";
import {NoteField} from "../../components/recommendedCars/RecommendedCarsFormComponent";
import ZoomInIcon from "@material-ui/icons/ZoomIn";

const defaultProps = {
    showSortBox: true,
    disabled: false,
    multipleUpload: true,
};

interface SortableImageListProps<T> {
    imageArray: T;
    className?: string;
    setCarColorImageClass?: number;
    dataProvider: DataProviderProxy;
    resource: string;
    isUpdating: boolean;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData: TempFileData[];
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    imageSourceName: string;
    showSortBox?: boolean;
    required?: boolean;
    disabled?: boolean;
    maxFileCount?: number;
    multipleUpload?: boolean;
    setImageId?: Dispatch<SetStateAction<number | undefined>>;
    setPathCrop?: Dispatch<SetStateAction<Array<TempFileData>>>;
    imageId?: number;
    setFlagCrop?: Dispatch<SetStateAction<boolean>>;
    setFlagBig?: Dispatch<SetStateAction<boolean>>;
    unShowDelete?: boolean;
}

type SortableImageList<T = any> = SortableImageListProps<T>;

const SortableImageList = (props: SortableImageList) => {
    const {sortBoxProps} = useSortingHooks(props.imageArray, props.dataProvider, props.resource, props.setImages);
    const form = useForm();
    const recommendedImagesClass2 = ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE + RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_2.toString();

    const clearImage = useCallback(() => {
        form.change(props.imageSourceName, undefined);
    }, [form]);

    const overFileCount = (uploadFileSize: number) => {
        if (!props.maxFileCount)
            return 0;
        let savedSize = 0;
        if (props.imageArray) {
            savedSize = props.imageArray.length;
        }
        return savedSize + uploadFileSize - props.maxFileCount;
    }

    const handleUpload = async (acceptedFiles: File[]) => {
        if (overFileCount(acceptedFiles.length) > 0 && props.imageSourceName !== recommendedImagesClass2) {
            alert('アップロード可能なファイルは' + props.maxFileCount + '個までです。');
            clearImage();
            return;
        }
        await convertFilesToTempFileData(props.setPathData, acceptedFiles);
    }

    const handleSingleUpload = async (acceptedFiles: File) => {
        const files: File[] = [];
        files.push(acceptedFiles);
        if (overFileCount(files.length) > 0 && props.imageSourceName !== recommendedImagesClass2) {
            alert('アップロード可能なファイルは' + props.maxFileCount + '個までです。');
            clearImage();
            return;
        }
        await convertFilesToTempFileData(props.setPathData, files, props.setFlagBig);
    }

    const values = form.getState().values;
    const [validations, setValidations] = useState(Array<Validator>());
    const [validationCounter, setValidationCounter] = useState(0);
    useEffect(() => {
        const currentValidations = [];
        if (props.imageArray) {
            if (props.required !== false && (!props.imageArray || props.imageArray.length === 0)) {
                currentValidations.push(required());
            }
            setValidations(currentValidations);
            setValidationCounter(validationCounter + 1);
        }
        // create
        if (!values['id']) {
            if (props.required !== false)
                currentValidations.push(required());
            setValidations(currentValidations);
            setValidationCounter(1);
        }
    }, [props.imageArray]);

    return (
        <Grid container={true}>
            {props.imageArray != undefined && props.imageSourceName !== recommendedImagesClass2 && props.imageArray.map((item: ListPhotosType, index: number) =>
                <SortableImage
                    key={index}
                    imageURL={item.url}
                    className={props.className}
                    dataProvider={props.dataProvider}
                    resource={props.resource}
                    id={item.id as number}
                    path={item.path}
                    setImages={props.setImages}
                    imageArray={props.imageArray}
                    bigImageUrl={item.bigImageUrl}
                    setImageId={props.setImageId}
                    unsShowDelete={props.unShowDelete}
                >
                    {
                        (props.showSortBox && props.imageArray.length > 1) &&
                        <SortBox sortBoxProps={sortBoxProps} value={item.position} index={index}/>
                    }
                </SortableImage>)
            }
            {props.setPathCrop && props.imageSourceName === recommendedImagesClass2 &&
                <Grid item={true} xs={3} lg={4}>
                    <Typography component={'h6'}>サムネイル</Typography>
                    <RecommendedCarsImageCroppingField
                        imageArray={props.imageArray}
                        setPathData={props.setPathCrop}
                        sourceName={'recommendCarCropImages'}
                        required={true}
                        setFlag={props.setFlagCrop}/>
                </Grid>
            }
            {(props.maxFileCount === undefined || props.imageArray == undefined || props.imageArray?.length < props.maxFileCount || props.imageSourceName === recommendedImagesClass2) && !props.disabled && validationCounter &&
                <Grid item={true} xs={3} lg={4}>
                    {props.imageSourceName === recommendedImagesClass2 &&
                        <Typography component={'h6'}>拡大画像</Typography>}
                    <ImageInput
                        source={props.imageSourceName}
                        accept="image/*"
                        label=""
                        multiple={props.multipleUpload}
                        maxSize={maxFileSize}
                        onChange={props.multipleUpload ? (files: File[]) => handleUpload(files) : (files: File) => handleSingleUpload(files)}
                        options={{disabled: props.disabled}}
                        validate={validations}
                        className={props.imageSourceName === recommendedImagesClass2 ? 'cropImgInput' : ''}
                    >
                        <ImageField source={props.imageSourceName} title="pathImg"/>
                    </ImageInput>
                </Grid>
            }
            {props.imageSourceName === recommendedImagesClass2 &&
                <Grid item={true} xs={3} lg={4}>
                    <NoteField {...props} imageId={props.imageId}/>
                </Grid>
            }
            {props.imageArray != undefined && props.imageSourceName === recommendedImagesClass2 && props.imageArray.map((item: ListPhotosType, index: number) =>
                <SortableImage
                    key={index}
                    imageURL={item.url}
                    className={props.className}
                    dataProvider={props.dataProvider}
                    resource={props.resource}
                    id={item.id as number}
                    path={item.path}
                    setImages={props.setImages}
                    imageArray={props.imageArray}
                    bigImageUrl={item.bigImageUrl}
                    setImageId={props.setImageId}
                    selectedImageId={props.imageId}
                >
                    {
                        props.showSortBox &&
                        <SortBox sortBoxProps={sortBoxProps} value={item.position} index={index}/>
                    }
                </SortableImage>)
            }
            <Grid xs={12}>
                {props.setImageId &&
                    <Typography component={"p"}> <ZoomInIcon/>で拡大画像が表示されます
                    </Typography>
                }
            </Grid>
        </Grid>
    );
};

SortableImageList.defaultProps = defaultProps;

export default SortableImageList;
