import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    BooleanInput,
    Button,
    Create,
    Edit,
    FormDataConsumer,
    maxLength,
    minValue,
    number,
    RadioButtonGroupInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    useDataProvider,
    useRedirect,
    useRefresh,
} from 'react-admin';
import {Box, Grid, Typography} from '@material-ui/core';
import {CarImageClass, ImageDirectory, MAX_FETCH_DATA_SIZE, ResourceNames} from '../../config/const';
import {floatValidation} from "../../common/validate";
import CarImage from "./CarImagesComponent";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {saveImages} from "../../common/image/databaseImageUtils";
import CarFormSelectTemplate from "./CarFormNoteSelectTemplate";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {getNameForScreenHeader} from "../../common/ScreenComponent";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";

const CarsForm: React.FC = (props: any) => {
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const validateName = [required(), maxLength(50)];
    const validateNumber = [required(), number(), minValue(0), floatValidation];
    const validateNumberNotRequired = [number(), minValue(0), floatValidation];
    const [pathData1, setPathData1] = useState(new Array<TempFileData>());
    const [images1, setImages1] = useState<ListPhotosType[] | undefined>()
    const [pathData2, setPathData2] = useState(new Array<TempFileData>());
    const [images2, setImages2] = useState<ListPhotosType[] | undefined>()
    const [pathData3, setPathData3] = useState(new Array<TempFileData>());
    const [images3, setImages3] = useState<ListPhotosType[] | undefined>()
    const [opens, setOpens] = React.useState(false);

    const caImageClass1 = async (data: Record<any, any>) => {
        await saveImages(dataProvider, ResourceNames.CAR_IMAGE_RESOURCE, {
            carImageClass: CarImageClass.CAR_IMAGE_CLASS_1,
            carId: data.id,
        }, ImageDirectory.CAR_IMAGE_CLASS_1 + data.id + '/', pathData1, images1);
    }

    const carImageClass2 = async (data: Record<any, any>) => {
        await saveImages(dataProvider, ResourceNames.CAR_IMAGE_RESOURCE, {
            carImageClass: CarImageClass.CAR_IMAGE_CLASS_2,
            carId: data.id,
        }, ImageDirectory.CAR_IMAGE_CLASS_2 + data.id + '/', pathData2, images2);
    }

    const carImageClass3 = async (data: Record<any, any>) => {
        await saveImages(dataProvider, ResourceNames.CAR_IMAGE_RESOURCE, {
            carImageClass: CarImageClass.CAR_IMAGE_CLASS_3,
            carId: data.id,
        }, ImageDirectory.CAR_IMAGE_CLASS_3 + data.id + '/', pathData3, images3);
    }

    const handleSave = async ({...values}) => {
        setOpens(true);
        if (props.type === 'create') {
            const cars = await dataProvider.create('cars', {data: {...values}});

            await caImageClass1(cars.data);
            await carImageClass2(cars.data);
            await carImageClass3(cars.data);

            redirect('/cars');
            refresh();
        } else {
            delete values.set;

            const cars = await dataProvider.update('cars', {
                id: values.id,
                data: {...values},
                previousData: {id: values.id}
            });

            await caImageClass1(cars.data);
            await carImageClass2(cars.data);
            await carImageClass3(cars.data);

            redirect('/cars');
            refresh();
        }
    };

    const CarLabel = (props: any) => {
        const CarName = () => {
            return (
                <>
                    <TextField source="name"/>
                </>
            );
        }
        return (
            <div {...props} >
                <CarName/>
            </div>
        );
    }


    return (
        <SimpleForm
            {...props}
            save={handleSave}
            submitOnEnter={false}
            toolbar={<ActionToolbar
                opens={opens}
            />}
        >
            <div className={"w-100"}>
                <Grid container={true}>
                    <Grid item={true} lg={3} md={6}>
                        <Grid container={true} className="mb-30">{'車種管理 -> '}{getNameForScreenHeader(props)}</Grid>
                        <Grid container={true}>
                            <TextInputForJapanese source="name" label={'車種名'} validate={validateName} fullWidth={true}
                            />
                        </Grid>
                        <Grid container={true}>
                            <TextInputForJapanese source="name2" label={'車種名2'} validate={validateName}
                                                  fullWidth={true}/>
                        </Grid>
                        <Grid container={true}>
                            <ReferenceInput
                                label="メーカー"
                                source="makerId"
                                reference="makers"
                                perPage={MAX_FETCH_DATA_SIZE}
                                sort={{field: 'id', order: 'ASC'}}
                            >
                                <SelectInput source="name" fullWidth={true}/>
                            </ReferenceInput>
                        </Grid>
                        <CarFormSelectTemplate/>
                        <Grid container={true}>
                            <TextInputForJapanese source="note" label={'備考'} multiline={true} rows={10}
                                                  fullWidth={true}
                                                  validate={[required()]}/>
                        </Grid>
                        <Grid container={true}>
                            <TextInputForJapanese source="orderingNote" label={'注文時注意事項'} multiline={true}
                                                  rows={10} fullWidth={true}
                            />
                        </Grid>
                        <Grid container={true}>
                            <NumberInputCommon className={'input-filled-basic'} source="position" label={'表示順序'}
                                               isRequired={true}/>
                        </Grid>
                        <Grid container={true}>
                            <ReferenceInput
                                label="ブランド"
                                reference="brands"
                                source="brandId"
                                validate={[required()]}
                                fullWidth
                                sort={{field: 'id', order: 'ASC'}}
                                perPage={MAX_FETCH_DATA_SIZE}
                            >
                                <RadioButtonGroupInput source="name"/>
                            </ReferenceInput>
                        </Grid>
                        <Grid container={true}>
                            <BooleanInput
                                defaultValue={props.record.id ? props.record.showOriginalFlow : true}
                                parse={(val: boolean) => val}
                                source="showOriginalFlow"
                                label="ONE My Orderに表示"
                            />
                        </Grid>
                    </Grid>
                    <Grid item={true} lg={3} md={5}>
                        <Box ml={2}>
                            <Grid container={true} className="mb-30">{'リサイクル費用'}</Grid>
                            <Grid container={true}>
                                <NumberInputCommon className={'input-filled-basic'} source="shredderDustFee"
                                                   label={'シュレッダーダスト料金'} isRequired={true} defaultValue={0}/>
                            </Grid>
                            <Grid container={true}>
                                <NumberInputCommon className={'input-filled-basic'} source="airbagFee"
                                                   label={'エアバッグ類料金'} isRequired={true} defaultValue={0}/>
                            </Grid>
                            <Grid container={true}>
                                <NumberInputCommon className={'input-filled-basic'} source="freonFee" label={'フロン類料金'}
                                                   isRequired={true} defaultValue={0}/>
                            </Grid>
                            <Grid container={true}>
                                <NumberInputCommon className={'input-filled-basic'} source="informationManagementFee"
                                                   label={'情報管理料金'} isRequired={true} defaultValue={0}/>
                            </Grid>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} lg={8} md={12}>
                                    <TextInputForJapanese source="freeRecyclingTitle1" label={'個別リサイクル項目名1'}
                                                          type={"text"}
                                                          defaultValue={''}
                                                          fullWidth={true}/>
                                </Grid>
                                <Grid item={true} lg={4} md={12}>
                                    <NumberInputCommon className={'input-filled-basic'} source="freeRecyclingFee1"
                                                       label={'金額'} defaultValue={0}
                                                       validate={validateNumberNotRequired}/>
                                </Grid>
                            </Grid>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} lg={8} md={12}>
                                    <TextInputForJapanese source="freeRecyclingTitle2" label={'個別リサイクル項目名2'}
                                                          type={"text"}
                                                          defaultValue={''}
                                                          fullWidth={true}/>
                                </Grid>
                                <Grid item={true} lg={4} md={12}>
                                    <NumberInputCommon className={'input-filled-basic'} source="freeRecyclingFee2"
                                                       label={'金額'} defaultValue={0}
                                                       validate={validateNumberNotRequired}/>
                                </Grid>
                            </Grid>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} lg={8} md={12}>
                                    <TextInputForJapanese source="freeRecyclingTitle3" label={'個別リサイクル項目名3'}
                                                          type={"text"}
                                                          defaultValue={''}
                                                          fullWidth={true}/>
                                </Grid>
                                <Grid item={true} lg={4} md={12}>
                                    <NumberInputCommon className={'input-filled-basic'} source="freeRecyclingFee3"
                                                       label={'金額'} defaultValue={0}
                                                       validate={validateNumberNotRequired}/>
                                </Grid>
                            </Grid>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} lg={9}>
                                    <label>リサイクル預託金合計</label>
                                </Grid>
                                <Grid item={true} lg={3}>
                                    <Box mr={3}>
                                        <FormDataConsumer {...props}>
                                            {({formData}) => (
                                                <label
                                                    className="numberRight">{Number(parseInt(formData.shredderDustFee) + parseInt(formData.airbagFee) + parseInt(formData.freonFee) + parseInt(formData.informationManagementFee) + parseInt(formData.freeRecyclingFee1) + parseInt(formData.freeRecyclingFee2) + parseInt(formData.freeRecyclingFee3)).toLocaleString()}</label>
                                            )}
                                        </FormDataConsumer>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container={true}>
                                <NumberInputCommon className={'input-filled-basic'} source="recyclingFundManagementFee"
                                                   label={'リサイクル資金管理料'} defaultValue={0} isRequired={true}/>
                            </Grid>
                            <Grid container={true} spacing={2} className="mb-30">
                                <Grid item={true} lg={9}>
                                    <label>リサイクル計</label>
                                </Grid>
                                <Grid item={true} lg={3}>
                                    <Box mr={3}>
                                        <FormDataConsumer {...props}>
                                            {({formData}) => (
                                                <label
                                                    className="numberRight">{Number(parseInt(formData.shredderDustFee) + parseInt(formData.airbagFee) + parseInt(formData.freonFee) + parseInt(formData.informationManagementFee) + parseInt(formData.freeRecyclingFee1) + parseInt(formData.freeRecyclingFee2) + parseInt(formData.freeRecyclingFee3) + parseInt(formData.recyclingFundManagementFee)).toLocaleString()}</label>
                                            )}
                                        </FormDataConsumer>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container={true}>
                                <NumberInputCommon
                                    source="usedCarMaintenanceFee"
                                    label="中古車整備費用"
                                    isRequired={false}
                                    validate={validateNumberNotRequired}
                                />
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item={true} md={6}>
                        <Box ml={2}>
                            <Typography component={'h6'}>一覧用画像(2枚)</Typography>
                            <CarImage
                                carImageClass={CarImageClass.CAR_IMAGE_CLASS_1}
                                dataProvider={dataProvider}
                                carId={props.id}
                                pathData={pathData1}
                                setPathData={setPathData1}
                                images={images1}
                                setImages={setImages1}
                                showSortBox={true}
                                maxFileCount={2}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box ml={2} mt={2}>
                    <Typography component={'h5'}>上部用画像(無制限)</Typography>
                    <Box ml={2} mt={1}>
                        <Typography component={'h6'}>PC用画像</Typography>
                        <CarImage
                            carImageClass={CarImageClass.CAR_IMAGE_CLASS_2}
                            dataProvider={dataProvider}
                            carId={props.id}
                            pathData={pathData2}
                            setPathData={setPathData2}
                            images={images2}
                            setImages={setImages2}
                        />
                    </Box>
                    <Box ml={2}>
                        <Typography component={'h6'}>スマホ用画像</Typography>
                        <CarImage
                            carImageClass={CarImageClass.CAR_IMAGE_CLASS_3}
                            dataProvider={dataProvider}
                            carId={props.id}
                            pathData={pathData3}
                            setPathData={setPathData3}
                            images={images3}
                            setImages={setImages3}
                        />
                    </Box>
                </Box>
            </div>
        </SimpleForm>
    );
};

const ActionTopToolbar = ({...props}) => (
    <div className={'mb-30'}>
        <Button
            component={Link}
            label={'戻る'}
            variant="contained"
            color={'default'}
            to={{pathname: "/cars"}}
        />
    </div>
);
export const CarsCreate: React.FC = (props: any) => {
    return (
        <Create
            {...props}
            actions={<ActionTopToolbar/>}
        >
            <CarsForm {...props} type={'create'}/>
        </Create>
    )
}
export const CarsEdit: React.FC = (props: any) => {
    return (
        <Edit
            {...props}
            mutationMode={'optimistic'}
            actions={<ActionTopToolbar/>}
        >
            <CarsForm {...props} type={'update'}/>
        </Edit>
    )
}

export default CarsForm
