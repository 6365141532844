import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {ReferenceInput, required, SelectInput} from "react-admin";
import {useForm} from "react-final-form";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

export interface CarDetailFieldProps {
    selectedCarGradesIds: number | undefined;
    setSelectedCarGradesIds: Dispatch<SetStateAction<number | undefined>>;
}

const CarDetailField: React.FC<CarDetailFieldProps> = (props) => {

    const form = useForm();
    const savedValues = form.getState().values;

    let savedCarId = 0;
    let savedCarModelId = 0;
    let savedCarGradeId = 0;
    if (savedValues['carId']) {
        savedCarId = savedValues['carId'];
    }
    if (savedValues['carModelId']) {
        savedCarModelId = savedValues['carModelId'];
    }
    if (savedValues['carGradeId']) {
        savedCarGradeId = savedValues['carGradeId'];
    }

    const [filterCarModel, setFilterCarModel] = useState({carId: savedCarId});
    const [filterCarGrade, setCarFilterCarGrade] = useState({carModelId: savedCarModelId});
    const [filterCarColor, setFilterCarColor] = useState({carGradeId: savedCarGradeId});
    const [loadedFlag, setLoadedFlag] = useState(false);

    const loadFlag = () => {
        (() => {
            if (loadedFlag) {
                const values = form.getState().values;
                if (values['carGradeId']) {
                    setGradeIds();
                }
            }
        })();
    }

    useEffect(loadFlag, [loadedFlag])

    const getValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.target) {
            return e.target.value
        }
    }

    const onCarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterCarModel({carId: Number(getValue(e))});
        setFilterCarColor({carGradeId: 0});
        form.change('carModelId', undefined);
        form.change('carGradeId', undefined);
        form.change('carColorId', undefined);
    }

    const onCarModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCarFilterCarGrade({carModelId: Number(getValue(e))});
        setFilterCarColor({carGradeId: 0});
        form.change('carGradeId', undefined);
        form.change('carColorId', undefined);
    }

    const setGradeIds = () => {
        const carGradeIds = form.getState().values['carGradeId'] as number;
        props.setSelectedCarGradesIds(carGradeIds);
        setFilterCarColor({carGradeId: carGradeIds});
    }

    const onCarGradeChange = () => {
        setGradeIds();
        form.change('carColorId', undefined);
    }

    return (
        <>
            <ReferenceInput
                label="車種"
                reference="cars"
                source="carId"
                validate={[required()]}
                sort={{field: 'position', order: 'ASC'}}
                onChange={onCarChange}
                className={'w-100'}
                perPage={MAX_FETCH_DATA_SIZE}
            >
                <SelectInput optionText={'name'}/>
            </ReferenceInput>
            <ReferenceInput
                label="モデル"
                reference="carModels"
                source="carModelId"
                validate={[required()]}
                filter={filterCarModel}
                onChange={onCarModelChange}
                sort={{field: 'position', order: 'ASC'}}
                className={'w-100'}
                perPage={MAX_FETCH_DATA_SIZE}
            >
                <SelectInput optionText='name'/>
            </ReferenceInput>
            <ReferenceInput
                label="デフォルトグレード"
                reference="carGrades"
                source="carGradeId"
                validate={[required()]}
                onChange={onCarGradeChange}
                filter={filterCarGrade}
                sort={{field: 'position', order: 'ASC'}}
                className={'w-100'}
                perPage={MAX_FETCH_DATA_SIZE}
            >
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput
                label="カラー"
                source="carColorId"
                reference="carColors"
                sort={{field: 'position', order: 'ASC'}}
                allowEmpty
                filter={filterCarColor}
            >
                <SelectInput source="name" fullWidth={true}/>
            </ReferenceInput>
        </>
    );
}

export default CarDetailField
