import React, {Dispatch, SetStateAction} from "react";
import {ResourceNames} from "../../config/const";
import SortableImageList from "../../common/image/SortableImageList";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {DataProviderProxy} from "react-admin";
import {TempFileData} from "../../modules/temporaryFileModule";
import {useCarColorSuspensionImageHooks} from "./CarColorSuspensionImageHooks";

export interface CarColorSuspensionImageProps {
    images: ListPhotosType[] | undefined
    dataProvider: DataProviderProxy;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData: TempFileData[];
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    carColorId: number | undefined;
    required: boolean;
}

const CarColorSuspensionImageComponent: React.FC<CarColorSuspensionImageProps> = (props) => {
    useCarColorSuspensionImageHooks(props);
    return (
        <>
            <SortableImageList imageArray={props.images}
                               dataProvider={props.dataProvider}
                               resource={ResourceNames.CAR_COLOR_SUSPENSION_IMAGE_RESOURCE}
                               isUpdating={!!props.carColorId}
                               setPathData={props.setPathData}
                               pathData={props.pathData}
                               setImages={props.setImages}
                               imageSourceName={ResourceNames.CAR_COLOR_SUSPENSION_IMAGE_RESOURCE}
                               maxFileCount={1}
                               required={props.required}
            />
        </>
    )
}
export default CarColorSuspensionImageComponent;
