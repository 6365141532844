import React, {Dispatch, forwardRef, SetStateAction} from "react";
import {Box, Grid, Paper, Tab, Tabs} from "@material-ui/core";
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {useRecommendedCarDefaultVariantItemHooks} from "./RecommendedCarVariantHooks";

export interface RecommendedCarDefaultVariantItemFunctions {
    save: (
        recommendedCarId: number,
        defaultProductIds: number[],
        defaultVariantItemIds: number[],
    ) => void;
}

export interface RecommendedCarDefaultVariantItemProps {
    carGradeIds?: number[];
    recommendedCarId?: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    variantItemInitialValues: number[] | undefined
    setVariantItemInitialValues: Dispatch<SetStateAction<number[] | undefined>>;
    defaultVariantItemIds: number[] | undefined;
    setDefaultVariantItemIds: Dispatch<SetStateAction<number[] | undefined>>;
    defaultProductIds: number[] | undefined;
    setSelectAffectPriceVariantItemIds: Dispatch<SetStateAction<number[] | undefined>>;
    selectAffectPriceVariantItemIds: number[] | undefined;
    setJNumberOfFrontWheel: Dispatch<SetStateAction<string | undefined>>;
    setInsetOfFrontWheel: Dispatch<SetStateAction<string | undefined>>;
    setJNumberOfRearWheel: Dispatch<SetStateAction<string | undefined>>;
    setInsetOfRearWheel: Dispatch<SetStateAction<string | undefined>>;
    displayAutoJNumber: boolean;
    setDisplayAutoJNumber: Dispatch<SetStateAction<boolean>>;
}

export const RecommendedCarDefaultVariantItemComponent: React.ForwardRefRenderFunction<RecommendedCarDefaultVariantItemFunctions, RecommendedCarDefaultVariantItemProps> = (props, ref) => {

    const {
        classes,
        globalClasses,
        tabValue,
        handleTabChange,
        productVariants,
        variantClassVariantItems,
        onChangeCheckBox
    } = useRecommendedCarDefaultVariantItemHooks({
        ref: ref,
        carGradeIds: props.carGradeIds,
        recommendedCarId: props.recommendedCarId,
        variantItemInitialValues: props.variantItemInitialValues,
        setVariantItemInitialValues: props.setVariantItemInitialValues,
        defaultVariantItemIds: props.defaultVariantItemIds,
        setDefaultVariantItemIds: props.setDefaultVariantItemIds,
        defaultProductIds: props.defaultProductIds,
        open: props.open,
        setOpen: props.setOpen,
        setSelectAffectPriceVariantItemIds: props.setSelectAffectPriceVariantItemIds,
        selectAffectPriceVariantItemIds: props.selectAffectPriceVariantItemIds,
        setJNumberOfFrontWheel: props.setJNumberOfFrontWheel,
        setInsetOfFrontWheel: props.setInsetOfFrontWheel,
        setJNumberOfRearWheel: props.setJNumberOfRearWheel,
        setInsetOfRearWheel: props.setInsetOfRearWheel,
        displayAutoJNumber: props.displayAutoJNumber,
        setDisplayAutoJNumber: props.setDisplayAutoJNumber,
    });

    return (
        <>
            <div className={classes.root}>
                <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                    <div>デフォルト規格</div>
                </Grid>
                <Paper className={globalClasses.scrollableTab}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        variant={"scrollable"}
                        scrollButtons="auto"
                    >
                        {productVariants?.data?.map((productVariant) => (
                            <Tab
                                label={productVariant.variant?.name}
                                value={productVariant.variant?.id}
                            />
                        ))}
                    </Tabs>
                    {variantClassVariantItems?.map((variantClassVariantItem) => (
                        <div>
                            <Box ml={2}>
                                <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11}
                                      className={'mt-20'}>
                                    {variantClassVariantItem.name}
                                </Grid>

                                <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <FormControl>
                                        <RadioGroup
                                            name="controlled-radio-buttons-group"
                                            defaultValue={props.variantItemInitialValues}
                                            onChange={onChangeCheckBox}>
                                            {variantClassVariantItem.carGrades?.map((carGrade) => {
                                                return (
                                                    carGrade.variantItem?.map((variantItem) => {
                                                        return (
                                                            <FormControlLabel value={variantItem.id} control={
                                                                <Radio checked={
                                                                    props.defaultVariantItemIds?.some(variantItemId => variantItemId == variantItem.id)
                                                                }/>}
                                                                              label={variantItem.name}/>
                                                        )
                                                    })
                                                )
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Box>
                        </div>
                    ))}
                </Paper>
            </div>
        </>
    )
}

export const RecommendedCarDefaultVariantItem = forwardRef(RecommendedCarDefaultVariantItemComponent);
