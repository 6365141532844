import React, {Dispatch, SetStateAction, useState} from "react";
import {ApiVariantClass, ApiVariantItemProductPrice, ApiVariantItemProductPriceUpdate} from "../../openapi";
import Papa, {ParseResult, unparse as convertToCSV} from "papaparse";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import {useDataProvider, useNotify, useRedirect, useRefresh} from "react-admin";
import {FormApi} from "final-form";

export interface VariantItemProductPriceProps {
    productId: number;
    variantClass?: ApiVariantClass[];
    data?: ApiVariantItemProductPrice[];
    form?: FormApi;
    setImportCsv?: Dispatch<SetStateAction<boolean>>;
    setError?: Dispatch<SetStateAction<Array<string>>>;
}

export const useExportHooks = (props: VariantItemProductPriceProps) => {
    const [exportCsv, setExportCsv] = useState(false);
    const [error, setError] = useState<Array<string>>([]);
    const additionalHeader = ['売価', '原価', '部品売価', '定価'];
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const redirect = useRedirect();


    const exportData = (variantClass: ApiVariantClass[], data: ApiVariantItemProductPrice[]) => {
        if (!props.productId) {
            return;
        }
        const heads = header(variantClass);
        const body = dataBody(variantClass, data);

        const csvVariantProductPrice = convertToCSV({
            data: body,
            fields: heads,
        });

        const blobVariantProductPrice = new Blob(["\uFEFF" + csvVariantProductPrice], {
            type: 'text/csv; charset=utf-18'
        });
        const zip = new JSZip();

        zip.file('variantProductPrice.csv', blobVariantProductPrice);
        zip.generateAsync({type: "blob"})
            .then(function (content) {
                saveAs(content, "variantProductPriceCSV.zip");
            });

    }

    const header = (variantClass: ApiVariantClass[]) => {
        const head = new Array<string>();
        let count = 1;
        variantClass!!.map((row: ApiVariantClass, i, variantClass) => {

            {
                row.affectPrice &&
                head.push('規格項目名' + count);
                head.push('規格項目ID' + count);
                count++;
            }

        })
        head.push(...additionalHeader);
        return head;
    }

    const dataBody = (dataVariantClasses: ApiVariantClass[], data: ApiVariantItemProductPrice[]) => {
        let body = new Array<string>();
        const mainBody = new Array<string>();
        data!!.map((row, i, data) => {
            dataVariantClasses.map((variantClass) => {
                let existing = false
                row.variantItem?.map((variantItem, index) => {
                    if (variantClass.id === variantItem.variantClassId) {
                        existing = true;
                        body.push(variantItem.name);
                        body.push(variantItem.id!.toString());
                    } else if (!existing && ((row.variantItem?.length ?? 1) - 1 === index)) {
                        body.push('');
                        body.push('');
                    }
                })
            })
            body.push(row?.price!.toString());
            body.push(row?.cost!.toString());
            body.push(row?.priceForPartsOnly!.toString());
            body.push(row?.originalPrice!.toString());
            // @ts-ignore
            mainBody.push(body);
            body = [];
        })
        return mainBody;
    }

    const handleUpload = async (file: File) => {
        if (props.setError) {
            props.setError([]);
        }
        let importItems: ApiVariantItemProductPriceUpdate[] = [];
        const errors: Array<string> = [];

        // @ts-ignore
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            async complete(results: ParseResult<any>, file?: File) {
                results.data.map((data, index) => {
                    let ids = [];
                    let errorFlag = false;

                    for (let key in data) {
                        let value = data[key];
                        let matches = key.match(/(\d+)/);
                        if (value && matches && key.slice(0, key.length - matches![0].length) === '規格項目ID') {
                            ids.push(Number(value));
                        }
                    }

                    const importItem: ApiVariantItemProductPriceUpdate = {
                        cost: Number(data[staticColumn.cost]),
                        priceForPartsOnly: Number(data[staticColumn.priceForPartsOnly]),
                        price: Number(data[staticColumn.price]),
                        originalPrice: Number(data[staticColumn.originalPrice]),
                        variantItemIds: ids,
                    }

                    if (data[staticColumn.originalPrice] === undefined || data[staticColumn.originalPrice] === '' || isNaN(data[staticColumn.originalPrice])) {
                        errorFlag = true;
                    }
                    if (data[staticColumn.cost] === undefined || data[staticColumn.cost] === '' || isNaN(data[staticColumn.cost])) {
                        errorFlag = true;
                    }
                    if (data[staticColumn.priceForPartsOnly] === undefined || data[staticColumn.priceForPartsOnly] === '' || isNaN(data[staticColumn.priceForPartsOnly])) {
                        errorFlag = true;
                    }
                    if (data[staticColumn.price] === undefined || data[staticColumn.price] === '' || isNaN(data[staticColumn.price])) {
                        errorFlag = true;
                    }

                    if (errorFlag) {
                        errors.push('行'+(index + 2)+': 数字以外が入力されています')
                    }

                    importItems.push(importItem);
                });

                props.form?.change('files', undefined);

                if (props.setImportCsv) {
                    props.setImportCsv(false);
                }

                if (errors.length === 0) {
                    await handleSave(importItems, props.productId);

                } else if (props.setError) {
                    props.setError(errors);
                }
            }
        })
    }

    const handleSave = async (filesToSave: Array<ApiVariantItemProductPriceUpdate>, id: number) => {

        await dataProvider.createMany('variantItemProductPrices/' + Number(id), {
            data: filesToSave,
        })

        notify('notification.saved');
        redirect('/products/' + id);
        redirect('/variantItemProductPrice/' + id);
        refresh();
    }

    enum staticColumn {
        cost = '原価',
        priceForPartsOnly = '部品売価',
        originalPrice = '定価',
        price = '売価',
    }

    return {
        exportCsv,
        setExportCsv,
        error,
        setError,
        exportData,
        handleUpload,
    }
}