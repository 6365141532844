export const japaneseImportMessages = {
    csv: {
        buttonMain: {
            label: "インポート",
            tooltip: "'.csv' または '.tsv'ファイルのみインポート可能です",
            emptyResource:
                "'resource': The 'resource' property was empty, did you pass in the {...props} to the ImportButton?",
        },
        parsing: {
            collidingIds: '"id "フィールドが複数存在します',
            failedValidateRow: '不正なデータが存在します',
            invalidCsv: '不正なCSVファイルです',
        },
        dialogCommon: {
            subtitle: '%{count}行を%{fileName}から"商品"へインポートします',
            conflictCount:
                "以下の<strong>商品</strong>がデータベースに存在します。<br/><strong>%{conflictingCount}</strong>",
            buttons: {
                cancel: "キャンセル",
            }
        },
        dialogImport: {
            alertClose: '"%{fname}を"インポートしました',
            title: '"商品"にインポート中です',
            buttons: {
                replaceAllConflicts: "更新",
                skipAllConflicts: "キャンセル",
                letmeDecide: "各行毎に置き換えかスキップを選択する",
            },
        },
        dialogDecide: {
            title: 'id %{id}を"商品"にインポート中です',
            buttons: {
                replaceRow: "id=%{id}行を上書き",
                addAsNewRow: "新しい行として追加する（上書きしない）",
                skipDontReplace: "この行をスキップする（上書きしない）",
            },
        },
        loading: "ローディング中です",
    },
};
