import {
    Create,
    Edit,
    maxLength,
    minValue,
    number,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    useGetOne,
    useRecordContext,
    useRedirect,
    useRefresh
} from "react-admin";
import React from "react";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";
import {Box} from "@material-ui/core";
import GoBack from "../../common/GoBackComponent";
import {ApiVariantClass} from "../../openapi";

const VariantItemForm: React.FC<any> = (props) => {
    const record = useRecordContext();
    const validateName = [required(), maxLength(50)];
    const validateExplanation = [maxLength(1000)];
    const validateNotRequiredNumber = [number(), minValue(0)];
    const validateNumber = [required(), number(), minValue(0)];
    const variantClassData = useGetOne("variantClasses", record.variantClassId);
    const variantClass = variantClassData.data as ApiVariantClass;

    return (
        <SimpleForm
            {...props}
            submitOnEnter={false}
            toolbar={<ActionToolbar/>}
        >
            <TextInputForJapanese source="name" validate={validateName} label={'規格項目名'}/>
            <TextInputForJapanese source="explanation"
                                  validate={validateExplanation}
                                  label={'説明'}
                                  multiline={true}
                                  rows={10}/>
            {variantClass?.suspension &&
                <NumberInput source="lowdown" label={'ローダウン量'}/>
            }
            {variantClass?.wheelSize &&
                <NumberInput source="inches" validate={validateNotRequiredNumber} label={'サイズ'}/>
            }
            {variantClass?.wheelSize &&
                <NumberInput source="tireRatio" validate={validateNotRequiredNumber} label={'タイヤ比率'}/>
            }
            <NumberInput source="price" validate={validateNumber} label={'売価'}/>
            <NumberInput source="cost" validate={validateNumber} label={'原価'}/>
            <NumberInput source="priceForPartsOnly" validate={validateNumber} label={'部品売価'}/>
            <NumberInput source="originalPrice" validate={validateNumber} label={'定価'}/>
            <NumberInput source="position" validate={validateNumber} label={'表示順序'}/>
            <TextInput source="variantClassId"
                       defaultValue={`${record['variantClassId']}`}
                       label={''}
                       disabled={true}
                       fullWidth
                       className={'displayNone'}
            />
        </SimpleForm>
    )
};

const ActionTopToolbar = () => (
    <Box mb={2}>
        <GoBack/>
    </Box>
);

interface CreateProps {
    location?: any;
}

export const VariantItemCreate: React.FC<CreateProps> = ({location, ...props}) => {
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        redirect(`/variantClasses/${location.state.record['variantClassId']}/show`);
        refresh();
    };

    return (
        <Create actions={<ActionTopToolbar/>} {...props} onSuccess={onSuccess}>
            <VariantItemForm type="create"/>
        </Create>
    );
};

export const VariantItemEdit: React.FC = (props: any) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const onSuccess = (response: any) => {
        redirect(`/variantClasses/${response.data.variantClassId}/show`);
        refresh();
    }

    return (
        <Edit actions={<ActionTopToolbar/>} {...props}
              onSuccess={onSuccess}
              undoable={false}
        >
            <VariantItemForm type="edit"/>
        </Edit>
    );
};

