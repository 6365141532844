import React, {useState} from "react";
import {
    ApiVariantClass,
    ApiVariantItemProductPrice,
    ApiVariantItemProductPriceToUpdate,
    ApiVariantItemProductPriceUpdate
} from "../../openapi";
import {useDataProvider, useNotify, useRefresh} from "react-admin";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

interface VariantItemProductPricesHookProps {
    productId: number;
}

const useVariantItemProductPriceHooks = (props: VariantItemProductPricesHookProps) => {
    const dataProvider = useDataProvider()
    const refresh = useRefresh();
    const notify = useNotify();
    const [dataVariantClasses, setDataVariantClasses] = useState(new Array<ApiVariantClass>());
    const [data, setData] = useState(new Array<ApiVariantItemProductPrice>());
    const [showExportImportButton, setShowExportImportButton] = useState(false);

    const getVariantClasses = async () => {
        const variantClasses = await dataProvider.getList('variantClasses',
            {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'id', order: 'asc'},
                filter: {affectPrice: true},
            });
        const array: React.SetStateAction<ApiVariantClass[]> = [];
        variantClasses.data.map((data) => {
            array.push(data as ApiVariantClass);
        });
        return array;
    }

    const fetchApiData = async () => {

        await dataProvider.getManyNoParams('variantItemProductPrices/' + Number(props.productId));

        const variantItemProductPriceCombinations = await dataProvider.getManyNoParams('variantItemProductPriceCombinations/' + Number(props.productId))

        const variantClasses = await getVariantClasses()
        if (variantClasses.length !== 0) {
            setDataVariantClasses(variantClasses.sort((a, b) => a.id!! > b.id!! ? 1 : -1));
        }

        const array: React.SetStateAction<ApiVariantItemProductPrice[]> = [];

        if (variantItemProductPriceCombinations.data != null) {
            variantItemProductPriceCombinations.data?.map((data: ApiVariantItemProductPrice) => {
                array.push(data)
            });
        }
        if (array.length !== 0)
            setShowExportImportButton(true);
        
        setData(array)
    }

    const save = async (e: React.FormEvent<HTMLFormElement>) => {
        const apiVariantItemProductPriceUpdateList: ApiVariantItemProductPriceUpdate[] = []

        data.forEach((apiVariantItemProductPrice) => {
            const variantItemIds: number[] = []
            apiVariantItemProductPrice.variantItem?.map((variantItem) => {
                if (variantItem.id != null) {
                    variantItemIds.push(variantItem.id)
                }
            })
            const apiVariantItemProductPriceUpdate: ApiVariantItemProductPriceUpdate = {
                variantItemIds: variantItemIds,
                price: apiVariantItemProductPrice.price ?? 0,
                cost: apiVariantItemProductPrice.cost ?? 0,
                priceForPartsOnly: apiVariantItemProductPrice.priceForPartsOnly ?? 0,
                originalPrice: apiVariantItemProductPrice.originalPrice ?? 0,
            }

            apiVariantItemProductPriceUpdateList.push(apiVariantItemProductPriceUpdate)
        })
        const apiVariantItemProductPriceToUpdate: ApiVariantItemProductPriceToUpdate = {
            data: apiVariantItemProductPriceUpdateList
        }

        await dataProvider.createMany('variantItemProductPrices/' + props.productId, {
            data: apiVariantItemProductPriceToUpdate.data,
        })
        notify('notification.saved');
        refresh();

    }

    const inputChanged = (value: { value: number | undefined }, id: { id: number | undefined }, property : {property : string | undefined}) => {
        const updatedData = data
        updatedData.map((apiVariantItemProductPrice) => {
            if (apiVariantItemProductPrice.variantItemProductPriceId === id.id) {
                switch (property.property) {
                    case 'price':
                        apiVariantItemProductPrice.price = value.value
                        console.log("ID :", id, "price :",apiVariantItemProductPrice.price)
                        break;
                    case 'cost':
                        apiVariantItemProductPrice.cost = value.value
                        console.log("ID :", id, "cost :",apiVariantItemProductPrice.cost)
                        break;
                    case 'priceForPartsOnly':
                        apiVariantItemProductPrice.priceForPartsOnly = value.value
                        console.log("ID :", id, "priceForPartsOnly :",apiVariantItemProductPrice.priceForPartsOnly)
                        break;
                    case 'originalPrice':
                        apiVariantItemProductPrice.originalPrice = value.value
                        console.log("ID :", id, "originalPrice :",apiVariantItemProductPrice.originalPrice)
                        break;
                    default :
                        apiVariantItemProductPrice.price = value.value
                        break;
                }
            }
        })

        setData(updatedData)
    }

    return {
        dataVariantClasses,
        data,
        fetchApiData,
        save,
        inputChanged,
        showExportImportButton,
    }
}

export default useVariantItemProductPriceHooks;
