/* tslint:disable */
/* eslint-disable */
/**
 * kus_server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, {AxiosInstance, AxiosPromise} from 'axios';
import {Configuration} from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    toPathString
} from '../common';
// @ts-ignore
import {BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequestArgs, RequiredError} from '../base';
// @ts-ignore
// @ts-ignore
// @ts-ignore
import {ApiSetting, ApiSettings, ApiSettingSingle} from '../model';

/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Returns setting
         * @param {string} [sort] sorting column
         * @param {string} [order] ASC / DESC
         * @param {number} [start] Start position of retrieved data
         * @param {number} [end] End position of retrieved data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSetting: async (sort?: string, order?: string, start?: number, end?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['_order'] = order;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['_end'] = end;
            }


            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single setting
         * @summary Find Setting by ID
         * @param {number} settingId ID of setting to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingById: async (settingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('getSettingById', 'settingId', settingId)
            const localVarPath = `/setting/{settingId}`
                .replace(`{${"settingId"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update an existing setting
         * @param {number} settingId ID of genre to return
         * @param {ApiSetting} apiSetting setting object that needs to be added to the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting: async (settingId: number, apiSetting: ApiSetting, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('updateSetting', 'settingId', settingId)
            // verify required parameter 'apiSetting' is not null or undefined
            assertParamExists('updateSetting', 'apiSetting', apiSetting)
            const localVarPath = `/setting/{settingId}`
                .replace(`{${"settingId"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Returns setting
         * @param {string} [sort] sorting column
         * @param {string} [order] ASC / DESC
         * @param {number} [start] Start position of retrieved data
         * @param {number} [end] End position of retrieved data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSetting(sort?: string, order?: string, start?: number, end?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSetting(sort, order, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a single setting
         * @summary Find Setting by ID
         * @param {number} settingId ID of setting to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingById(settingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSettingSingle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingById(settingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update an existing setting
         * @param {number} settingId ID of genre to return
         * @param {ApiSetting} apiSetting setting object that needs to be added to the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSetting(settingId: number, apiSetting: ApiSetting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSettingSingle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSetting(settingId, apiSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         *
         * @summary Returns setting
         * @param {string} [sort] sorting column
         * @param {string} [order] ASC / DESC
         * @param {number} [start] Start position of retrieved data
         * @param {number} [end] End position of retrieved data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSetting(sort?: string, order?: string, start?: number, end?: number, options?: any): AxiosPromise<ApiSettings> {
            return localVarFp.getAllSetting(sort, order, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a single setting
         * @summary Find Setting by ID
         * @param {number} settingId ID of setting to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingById(settingId: number, options?: any): AxiosPromise<ApiSettingSingle> {
            return localVarFp.getSettingById(settingId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update an existing setting
         * @param {number} settingId ID of genre to return
         * @param {ApiSetting} apiSetting setting object that needs to be added to the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting(settingId: number, apiSetting: ApiSetting, options?: any): AxiosPromise<ApiSettingSingle> {
            return localVarFp.updateSetting(settingId, apiSetting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     *
     * @summary Returns setting
     * @param {string} [sort] sorting column
     * @param {string} [order] ASC / DESC
     * @param {number} [start] Start position of retrieved data
     * @param {number} [end] End position of retrieved data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public getAllSetting(sort?: string, order?: string, start?: number, end?: number, options?: any) {
        return SettingApiFp(this.configuration).getAllSetting(sort, order, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a single setting
     * @summary Find Setting by ID
     * @param {number} settingId ID of setting to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public getSettingById(settingId: number, options?: any) {
        return SettingApiFp(this.configuration).getSettingById(settingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update an existing setting
     * @param {number} settingId ID of genre to return
     * @param {ApiSetting} apiSetting setting object that needs to be added to the store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public updateSetting(settingId: number, apiSetting: ApiSetting, options?: any) {
        return SettingApiFp(this.configuration).updateSetting(settingId, apiSetting, options).then((request) => request(this.axios, this.basePath));
    }
}
