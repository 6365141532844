import React from 'react';
import {EditButton, Show, SimpleShowLayout, Toolbar, useRecordContext} from 'react-admin';
import {Box, Card, CardContent, Grid} from "@material-ui/core";

const ActionToolbar = ({...props}) => (
    <Toolbar className={'actionButtons'}>
        <EditButton basePath={props.basePath} record={props.data}/>
    </Toolbar>
);

const SettingShow: React.FC = (props: any) => {
    return (
        <Show {...props} actions={<ActionToolbar/>}>
            <SimpleShowLayout>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item={true} xs={4}>
                                <h4> 中古車走行距離1 </h4>
                            </Grid>
                            <Grid item={true} xs={4}>
                                <h4> 中古車走行距離2 </h4>
                            </Grid>
                            <Grid item={true} xs={4}>
                                <h4> 中古車走行距離3 </h4>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item={true} xs={4}>
                                <TextFieldBody source={"usedMileage1"}/>
                            </Grid>
                            <Grid item={true} xs={4}>
                                <TextFieldBody source={"usedMileage2"}/>
                            </Grid>
                            <Grid item={true} xs={4}>
                                <TextFieldBody source={"usedMileage3"}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Box mt={2}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item={true}>
                                    <h4> ご注文同意事項(車両も購入(HTMLタグ)) </h4>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item={true}>
                                    <TextFieldBody source={"vehiclePurchaseAgreement"}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={2}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item={true}>
                                    <h4>ご注文同意事項(部品のみ購入(HTMLタグ)) </h4>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item={true}>
                                    <TextFieldBody source={"partsOnlyPurchaseAgreement"}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </SimpleShowLayout>
        </Show>
    );
};

const TextFieldBody = ({...source}) => {
    const record = useRecordContext();
    return (<span dangerouslySetInnerHTML={{__html: `${record && record[source.source] ? record[source.source] : ''}`}}/>);
};

export default SettingShow;
