import React from "react";

interface PlusMinusLabelNumberProps {
    value?: number;
}

export const PlusMinusLabelNumber: React.FC<PlusMinusLabelNumberProps> = (props) => {
    return (
        <>
            {props.value !== undefined && props.value > 0 &&
                <>+</>
            }
            {props.value !== undefined && props.value.toLocaleString()}
        </>
    );
}
