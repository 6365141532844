import React, {Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState} from "react";
import {Box, Grid, Paper, Tab, Tabs} from "@material-ui/core";
import {CheckboxGroupInput, useDataProvider} from "react-admin";
import {
    ApiProductCarGradeMountSettingWithOtherData,
    ApiProductCarGradeMountSettingWithOtherDataCategoryParentCategories
} from "../../openapi";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {IdName} from "../../common/IdName";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {useStyles} from "../../common/styles";
import {useForm} from "react-final-form";

export interface AvailableProductFunctions {
    save: (
        recommendedCarId: number,
        availableProducts: number[],
    ) => void;
}

interface AvailableProductProps {
    carGradeIds?: number;
    recommendedCarId?: number;
    availableProductInitialValues: number[] | undefined
    setAvailableProductInitialValues: Dispatch<SetStateAction<number[] | undefined>>;
    setSelectedCarGradeId: Dispatch<SetStateAction<number | undefined>>;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const useLocalStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

interface CategoryProducts extends ApiProductCarGradeMountSettingWithOtherDataCategoryParentCategories {
    choices?: Array<IdName>
}

export const RecommendedCarsAvailableProductsList: React.ForwardRefRenderFunction<AvailableProductFunctions, AvailableProductProps> = (props, ref) => {
    const classes = useLocalStyles();
    const dataProvider = useDataProvider();
    const [productCarGradeMountSettingWithOtherData, setProductCarGradeMountSettingWithOtherData] = useState<ApiProductCarGradeMountSettingWithOtherData>()
    const [tabValue, setTabValue] = React.useState(0);
    const [categories, setCategories] = useState<CategoryProducts[]>()
    const form = useForm();
    const savedValues = form.getState().values;
    props.setSelectedCarGradeId(savedValues['carGradeId'])

    useImperativeHandle(ref, () => ({
        async save(recommendedCarId: number, availableProducts: number[]) {
            const dataArray = new Array<{ productId: number, recommendedCarId: number }>()
            const selectedValues = availableProducts;
            if (!selectedValues || !selectedValues.length) {
                return;
            }
            selectedValues.map((value) => {
                dataArray.push(
                    {
                        recommendedCarId: recommendedCarId,
                        productId: value,
                    }
                )
            })
            await dataProvider.create('recommendedCarAvailableProducts/' + recommendedCarId, {
                data: {
                    data: dataArray,
                    id: 1,
                }
            })

        },
    }));

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const loadTabValues = () => {
        (() => {
            if (tabValue) {
                const data = productCarGradeMountSettingWithOtherData?.data?.find((d) => d.categoryParent?.id === tabValue)?.categoryParent?.categories as Array<CategoryProducts>;
                data?.map((category) => {
                    const choices = new Array<IdName>();
                    category.carGrades?.map((carGrade) => {
                        carGrade.products?.map((product) => {
                            if (choices.find((choice) => choice.id === product.id) === undefined) {
                                choices.push({
                                    id: product.id!!,
                                    name: product.name,
                                });
                            }
                        })
                    })
                    category.choices = choices;
                })
                setCategories(data);
            }
        })();
    }

    const loadData = () => {
        (async () => {
            if (props.carGradeIds === undefined)
                return;
            props.setOpen(true);
            console.log(props)

            const data = await dataProvider.getList('recommendedCarAvailableProducts', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'createdAt', order: 'ASC'},
                filter: {
                    carGradeIds: [props.carGradeIds],
                    recommendedCarId: props.recommendedCarId
                },
            });

            let fetched = data as ApiProductCarGradeMountSettingWithOtherData
            setProductCarGradeMountSettingWithOtherData(fetched);

            const initialValues = new Array<number>();
            fetched.data?.map((parent) => parent.categoryParent?.categories?.map((category) => category.carGrades?.map((carGrade) => carGrade.products?.map((product) => {
                if (product.checked)
                    initialValues.push(product.id!!);
            }))));
            props.setAvailableProductInitialValues(initialValues);
            props.setOpen(false);
        })();
    }

    useEffect(loadData, [props.carGradeIds, props.recommendedCarId])
    useEffect(loadTabValues, [tabValue, props.carGradeIds])

    const globalClasses = useStyles();

    return (
        <div className={classes.root}>

            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                <h3>追加装着可能商品</h3>
            </Grid>

            <Paper className={globalClasses.scrollableTab}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant={"scrollable"}
                    scrollButtons="auto"
                >
                    {productCarGradeMountSettingWithOtherData?.data?.map((completeCarDefaultProduct) => (
                        <Tab
                            label={completeCarDefaultProduct.categoryParent?.name}
                            value={completeCarDefaultProduct.categoryParent?.id}/>
                    ))}
                </Tabs>

                {categories?.map((category) => (
                    <>
                        <Box ml={2}>
                            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                                {category.name}
                            </Grid>

                            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11}>
                                <CheckboxGroupInput
                                    source={"availableProducts"}
                                    label={''}
                                    choices={category.choices}
                                    initialValue={props.availableProductInitialValues}
                                />
                            </Grid>
                        </Box>
                    </>
                ))}

            </Paper>
        </div>
    )
}

export default forwardRef(RecommendedCarsAvailableProductsList);
