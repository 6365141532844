import {Identifier} from "react-admin";
import {ApiCostCalculationType} from "../openapi";

export enum hosts {
    locals = 'http://localhost:8080',
}

export const maxFileSize = 5000000;

export const circularSize = 100;

export interface CreateManyParams {
    data: any[];
}

export interface UpdateManyArrayParams {
    ids: Identifier[];
    data: any[];
}

export interface ProductExportItem {
    商品ID: number;
    商品カテゴリID: number;
    商品カテゴリ名: string;
    基幹商品コード: string;
    商品名: string;
    車種購入のみ: number;
    売価: number;
    部品売価: number;
    原価: number;
    定価: number;
    構造変更手続費用: number;
    アライメント調整費用: number;
    セット品: number;
    新車: number;
    中古: number;
    表示順序: number;
    公開: number;
}

export const ProductExportField = [
    '商品ID',
    '商品カテゴリID',
    '商品カテゴリ名',
    '基幹商品コード',
    '商品名',
    '車種購入のみ',
    '売価',
    '部品売価',
    '原価',
    '定価',
    '構造変更手続費用',
    'アライメント調整費用',
    'セット品',
    '新車',
    '中古',
    '表示順序',
    '公開'
]

export enum ImageDirectory {
    CAR_IMAGE_CLASS_1 = '/carImages/1/',
    CAR_IMAGE_CLASS_2 = '/carImages/2/',
    COMPLETE_CAR_IMAGE_CLASS_1 = '/completeCarImages/1/',
    COMPLETE_CAR_IMAGE_CLASS_2 = '/completeCarImages/2/',
    COMPLETE_CAR_IMAGE_CLASS_3 = '/completeCarImages/3/',
    CAR_IMAGE_CLASS_3 = '/carImages/3/',
    CAR_COLOR_IMAGE_CLASS_1 = '/carColorImages/1/',
    CAR_COLOR_IMAGE_CLASS_2 = '/carColorImages/2/',
    CAR_COLOR_SUSPENSION_IMAGE = '/carColorSuspensionImages/',
    CAR_MODEL_IMAGE = '/carModelImages/1/',
    COMPLETE_CAR_COLOR_IMAGE = '/completeCarColorImages/',
    J_NUMBER_AND_INSET_RECOMMENDATION_IMAGE_RESOURCE = '/jNumberAndInsetRecommendationImages',
    RECOMMENDED_CAR_IMAGE_CLASS_1 = '/recommendedCarImages/1/',
    RECOMMENDED_CAR_IMAGE_CLASS_2 = '/recommendedCarImages/2/',
    VARIANT_CLASS_IMAGE = '/variantClassImages/',
    PRODUCT_VARIANT_ITEM_IMAGE = '/productVariantItemImages/',
    VARIANT_ITEM_IMAGES = '/variantItemImages/',
}

export enum CarImageClass {
    CAR_IMAGE_CLASS_1 = 1,
    CAR_IMAGE_CLASS_2 = 2,
    CAR_IMAGE_CLASS_3 = 3,
}

export enum CompleteCarImageClass {
    COMPLETE_CAR_IMAGE_CLASS_1 = 1,
    COMPLETE_CAR_IMAGE_CLASS_2 = 2,
    COMPLETE_CAR_IMAGE_CLASS_3 = 3,
}

export enum CarColorImageClass {
    CAR_COLOR_IMAGE_CLASS_1 = 1,
    CAR_COLOR_IMAGE_CLASS_2 = 2,
}

export enum RecommendedCarImageClass {
    RECOMMENDED_CAR_IMAGE_CLASS_1 = 1,
    RECOMMENDED_CAR_IMAGE_CLASS_2 = 2,
}

export enum ResourceNames {
    CAR_IMAGE_RESOURCE = 'carImages',
    CAR_COLOR_IMAGE_RESOURCE = 'carColorImages',
    CAR_COLOR_SUSPENSION_IMAGE_RESOURCE = 'carColorSuspensionImages',
    CAR_MODEL_IMAGE_RESOURCE = 'carModelImages',
    COMPLETE_CAR_IMAGE_RESOURCE = 'completeCarImages',
    COMPLETE_CAR_COLOR_IMAGE_RESOURCE = 'completeCarColorImages',
    J_NUMBER_AND_INSET_RECOMMENDATION_IMAGE_RESOURCE = 'jNumberAndInsetRecommendationImages',
    RECOMMENDED_CAR_IMAGE_RESOURCE = 'recommendedCarImages',
    VARIANT_CLASSES_IMAGE_RESOURCE = 'variantClassImages',
    PRODUCT_VARIANT_ITEM_IMAGES = 'productVariantItemImages',
    VARIANT_ITEM_IMAGES = 'variantItemImages',
}

export const MAX_FETCH_DATA_SIZE = 900000;
export const PAGE_LOCATION = 1;

export const productTranslatedItems = [
    '',
    '商品ID',
    '商品カテゴリID',
    '商品カテゴリ名',
    '基幹商品コード',
    '商品名',
    '車種購入のみ',
    '売価',
    '部品売価',
    '原価',
    '定価',
    '構造変更手続費用',
    'アライメント調整費用',
    'セット品',
    '表示順序',
    '公開'
];

export interface EstimateExportItem {
    見積ID: number | undefined;
    見積番号: string | undefined;
    見積日時: string | undefined;
    フェーズ: string | undefined;
    見積合計金額: number | undefined;
    原価合計: number | undefined;
    値引き合計: number | undefined;
    消費税率: number | undefined;
    車種ID: number | undefined;
    車種名: string | undefined;
    モデルID: number | undefined;
    モデル名: string | undefined;
    コンプリートカーID: number | undefined;
    コンプリートカー名: string | undefined;
    コンプリートカー売価: number | undefined;
    コンプリートカー原価: number | undefined;
    コンプリートカー説明文: string | undefined;
    グレードID: number | undefined;
    グレード名: string | undefined;
    グレード売価: number | undefined;
    グレード原価: number | undefined;
    カラーID: number | undefined;
    カラー名: string | undefined;
    カラー売価: number | undefined;
    カラー原価: number | undefined;
    ナンバー登録地ID: number | undefined;
    ナンバー登録地名: string | undefined;
    納車場所ID: number | undefined;
    納車場所名: string | undefined;
    お届け先: string | undefined;
    お支払い方法: string | undefined;
    自動車税ID: number | undefined;
    自動車税: number | undefined;
    自動車税月数: number | undefined;
    環境性能割ID: number | undefined;
    環境性能割税率: number | undefined;
    環境性能割金額: number | undefined;
    重量税ID: number | undefined;
    重量税: number | undefined;
    自賠責保険料ID: number | undefined;
    自賠責保険料: number | undefined;
    自賠責保険料月数: number | undefined;
    法定費用_検査登録_届出: number | undefined;
    法定費用_車庫証明紙代: number | undefined;
    法定費用_下取車: number | undefined;
    代行費用_検査登録_届出: number | undefined;
    代行費用_車庫証明紙代: number | undefined;
    代行費用_下取車: number | undefined;
    一般希望番号申請費: number | undefined;
    製作車両移動費用: number | undefined;
    印紙代: number | undefined;
    ナンバー代: number | undefined;
    登録手続費用: number | undefined;
    納車費用: number | undefined;
    構造変更手続費用: number | undefined;
    アライメント調整費用合計: number | undefined;
    シュレッダーダスト料金: number | undefined;
    エアバッグ料金: number | undefined;
    フロン類料金: number | undefined;
    情報管理料金: number | undefined;
    リサイクル費用自由項目1タイトル: string | undefined;
    リサイクル費用自由項目1金額: number | undefined;
    リサイクル費用自由項目2タイトル: string | undefined;
    リサイクル費用自由項目2金額: number | undefined;
    リサイクル費用自由項目3タイトル: string | undefined;
    リサイクル費用自由項目3金額: number | undefined;
    リサイクル資金管理料: number | undefined;
    リサイクル預託金合計: number | undefined;
    リサイクル計: number | undefined;
    課税諸費用計: number | undefined;
    非課税諸費用計: number | undefined;
    諸費用合計: number | undefined;
    顧客ID: number | undefined;
    名字: string | undefined;
    名前: string | undefined;
    名字ふりがな: string | undefined;
    名前ふりがな: string | undefined;
    生年月日: string | undefined;
    種別: string | undefined;
    会社名: string | undefined;
    会社名ふりがな: string | undefined;
    代表取締役名字: string | undefined;
    代表取締役名前: string | undefined;
    代表取締役名字ふりがな: string | undefined;
    代表取締役名前ふりがな: string | undefined;
    郵便番号: string | undefined;
    都道府県: string | undefined;
    市区町村: string | undefined;
    番地: string | undefined;
    建物マンション名: string | undefined;
    電話番号個人: string | undefined;
    電話番号法人: string | undefined;
    メールアドレス: string | undefined;
    下取車種名: string | undefined;
    下取グレード名: string | undefined;
    下取年式: string | undefined;
    下取走行距離: number | undefined;
    下取ボディカラー: string | undefined;
    修復歴の有無: boolean | undefined;
    セールスポイント: string | undefined;
    備考欄: string | undefined;
    中古車整備費用: number | undefined;
}

export const EstimateExportField = [
    '見積ID',
    '見積番号',
    '見積日時',
    'フェーズ',
    '見積合計金額',
    '原価合計',
    '値引き合計',
    '消費税率',
    '車種ID',
    '車種名',
    'モデルID',
    'コンプリートカーID',
    'コンプリートカー名',
    'コンプリートカー売価',
    'コンプリートカー原価',
    'コンプリートカー説明文',
    'グレードID',
    'グレード名',
    'グレード売価',
    'グレード原価',
    'カラーID',
    'カラー名',
    'カラー売価',
    'カラー原価',
    'ナンバー登録地ID',
    'ナンバー登録地名',
    '納車場所ID',
    '納車場所名',
    'お届け先',
    'お支払い方法',
    '自動車税ID',
    '自動車税',
    '自動車税月数',
    '環境性能割ID',
    '環境性能割税率',
    '環境性能割金額',
    '重量税ID',
    '重量税',
    '自賠責保険料ID',
    '自賠責保険料',
    '自賠責保険料月数',
    '法定費用_検査登録_届出',
    '法定費用_車庫証明紙代',
    '法定費用_下取車',
    '代行費用_検査登録_届出',
    '代行費用_車庫証明紙代',
    '代行費用_下取車',
    '一般希望番号申請費',
    '製作車両移動費用',
    '印紙代',
    'ナンバー代',
    '登録手続費用',
    '納車費用',
    '構造変更手続費用',
    'アライメント調整費用合計',
    'シュレッダーダスト料金',
    'エアバッグ料金',
    'フロン類料金',
    '情報管理料金',
    'リサイクル費用自由項目1タイトル',
    'リサイクル費用自由項目1金額',
    'リサイクル費用自由項目2タイトル',
    'リサイクル費用自由項目2金額',
    'リサイクル費用自由項目3タイトル',
    'リサイクル費用自由項目3金額',
    'リサイクル資金管理料',
    'リサイクル預託金合計',
    'リサイクル計',
    '課税諸費用計',
    '非課税諸費用計',
    '諸費用合計',
    '顧客ID',
    '名字',
    '名前',
    '名字ふりがな',
    '名前ふりがな',
    '生年月日',
    '種別',
    '会社名',
    '会社名ふりがな',
    '代表取締役名字',
    '代表取締役名前',
    '代表取締役名字ふりがな',
    '代表取締役名前ふりがな',
    '郵便番号',
    '都道府県',
    '市区町村',
    '番地',
    '建物マンション名',
    '電話番号個人',
    '電話番号法人',
    'メールアドレス',
    '下取車種名',
    '下取グレード名',
    '下取年式',
    '下取走行距離',
    '下取ボディカラー',
    '修復歴の有無',
    'セールスポイント',
    '備考欄',
    '中古車整備費用',
]

export interface EstimateProductExportItem {
    見積商品ID: number | undefined;
    見積ID: number | undefined;
    商品ID: number | undefined;
    基幹コード: string | undefined;
    商品名: string | undefined;
    売価: number | undefined;
    原価: number | undefined;
    構造変更手続費用: number | undefined;
    アライメント調整費用: number | undefined;
}

export const EstimateProductExportField = [
    '見積商品ID',
    '見積ID',
    '商品ID',
    '基幹コード',
    '商品名',
    '売価',
    '原価',
    '構造変更手続費用',
    'アライメント調整費用',
]

export const ESTIMATE_PHASE = {
    NOT_ORDER: {
        label: '見積中',
        value: 1,
    },
    ORDERED: {
        label: '受注',
        value: 2,
    }
};

export const AUTH_KEY = 'auth'
export const ROLE_KEY = 'roles'
export const ROLES = {
    ADMIN: 'ADMIN',
    SALES: 'SALES',
}

export const ESTIMATE = {
    SIMULATION_FLOW: {
        COMPLETE_CAR: 1,
        PARTS_ONLY: 2,
        ORIGINAL: 3,
        RECOMMENDED_CAR: 4,
        USED_CAR: 6,
    }
}
export const Customer = {
    type: ['', '個人', '法人']
}

// 1=公開
// 0=非公開
export const IS_PUBLIC = [
    {
        code: 'PRIVATE',
        name: '非公開',
        id: 0
    },
    {
        code: 'PUBLIC',
        name: '公開',
        id: 1
    }
]
// 0=いいえ no
//1=はい yes

export const IS_NEW = [
    {
        code: 'OLD',
        name: 'いいえ',
        id: 0
    },
    {
        code: 'NEW',
        name: 'はい',
        id: 1
    }
]

export const IS_DISPLAY = [
    {
        code: 'notDisplay',
        name: 'いいえ',
        id: 0
    },
    {
        code: 'display',
        name: 'はい',
        id: 1
    }
]

export const DEPOSIT_PAYMENT_METHOD = [
    {id: true, text: 'はい'},
    {id: false, text: 'いいえ'},
]

export const DEPOSIT_PAID_TYPE = [
    {id: true, text: 'はい'},
    {id: false, text: 'いいえ'},
]

export const RECOMMENDED_CAR_COST_CALCULATION_TYPE = [
    {
        id: ApiCostCalculationType.Auto,
        name: '自動'
    },
    {
        id: ApiCostCalculationType.Manual,
        name: '手動'
    }

]

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const VALUE = {
    EMPTY: 0,
}

export interface Record {
    id: Identifier;
    [key: string]: any;
}

export const SETTING = {
    id: 1,
    resource: 'setting',
}