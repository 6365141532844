import React, {useEffect, useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {ApiJNumberAndInsetAdvancedSettings, ApiVariantClass} from '../../openapi';
import {TextInput, useDataProvider, useRefresh} from 'react-admin';
import {ListPhotosType} from "../../common/image/arrayUtil";
import {TempFileData} from "../../modules/temporaryFileModule";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {saveImages} from "../../common/image/databaseImageUtils";
import {ImageDirectory, ResourceNames} from "../../config/const";
import useProductVariantHooks from "./productVariantHooks";
import {useForm} from "react-final-form";
import JNumberAndInsetRecommendationImage from "./JNumberAndInsetRecommendationImagesComponent";

type InputChangedType = (value: { value: string; }, id: { id: string; }, columnName: string) => void;

interface JNumberAndInsetRecommendationRecordProps {
    id: number;
    jNumberAndInsetAdvancedSettings: ApiJNumberAndInsetAdvancedSettings;
    recommendName: string | undefined;
    inputChanged: InputChangedType;
    dataVariantClasses: ApiVariantClass[];
    jNumberOfFrontWheel: number;
    jNumberOfRearWheel: number;
    insetOfFrontWheel: number;
    insetOfRearWheel: number;
    recommendNote: string | undefined;
    productId: number;
}

const JNumberAndInsetRecommendationListRecordComponent: React.FC<JNumberAndInsetRecommendationRecordProps> = (props) => {
    const [pathData, setPathData] = useState(new Array<TempFileData>());
    const [images, setImages] = useState<ListPhotosType[] | undefined>()
    const {
        sortVariantClasses,
        sortJNumberAndInsertAdvancedSettings,
    } = useProductVariantHooks({
        productId: props.productId,
    });
    const dataProvider = useDataProvider();

    const form = useForm();
    const refresh = useRefresh();
    const [uploadedCounter, setUploadedCounter] = useState(0);
    useEffect(() => {
        const fn = async () => {
            await saveImages(dataProvider, ResourceNames.J_NUMBER_AND_INSET_RECOMMENDATION_IMAGE_RESOURCE, {
                jNumberAndInsetRecommendationId: props.id,
                position: images?.length
            }, ImageDirectory.J_NUMBER_AND_INSET_RECOMMENDATION_IMAGE_RESOURCE + props.id + '/', pathData, images)
            setUploadedCounter(uploadedCounter + 1);
            setPathData([]);
            form.change(ResourceNames.J_NUMBER_AND_INSET_RECOMMENDATION_IMAGE_RESOURCE + props.id.toString(), undefined);
            refresh();
        }
        if (pathData.length > 0)
            fn().then();
    }, [pathData])

    return (
        <TableRow key={props.id}>
            <TableCell align="left" key={props.id}>
                <div className={'d-flex'}>
                    {props.jNumberAndInsetAdvancedSettings?.carGrade?.carModel?.carName}<br/>
                    {props.jNumberAndInsetAdvancedSettings.carGrade?.carModel?.name}<br/>
                    {props.jNumberAndInsetAdvancedSettings?.carGrade?.name}
                </div>
            </TableCell>
            {sortVariantClasses(props.dataVariantClasses)
                .map((variantClass: ApiVariantClass, index1) => {
                    let existing = true
                    return (
                        sortJNumberAndInsertAdvancedSettings(props.jNumberAndInsetAdvancedSettings!!.data!!)
                            .map((jNumberAndInsetAdvancedSetting, index2) => {
                                if (variantClass.id!! === jNumberAndInsetAdvancedSetting.variantItem?.variantClassId!!) {
                                    existing = false
                                    return (
                                        <TableCell align="left" key={props.id}>
                                            <div className={'d-flex'}>
                                                {jNumberAndInsetAdvancedSetting.variantItem?.name}
                                            </div>
                                        </TableCell>
                                    );
                                } else if (props.jNumberAndInsetAdvancedSettings!!.total!! - 1 === index2 && existing) {
                                    return (
                                        <TableCell align="left" key={props.id}>
                                            <div className={'d-flex'}>
                                            </div>
                                        </TableCell>
                                    );
                                }
                            })
                    );
                })
            }
            <TableCell className={'fitting-table-cell-right-margin'}>
                <NumberInputCommon
                    defaultValue={props.jNumberOfFrontWheel}
                    className={'w-100 input-filled-basic'}
                    type="number"
                    inputProps={{min: 0, step: 0.1}}
                    variant='filled'
                    onChange={(event) => {
                        props.inputChanged({value: event.target.value}, {id: props.id!!.toString()}, "jNumberOfFrontWheel")
                    }}
                    label={"J数_前輪"}
                    isDouble={true}
                    source={'jNumberOfFrontWheel' + props.id}
                />
                <NumberInputCommon
                    defaultValue={props.jNumberOfRearWheel}
                    className={'w-100 input-filled-basic'}
                    style={{minWidth: "50px"}}
                    type="number"
                    inputProps={{min: 0, step: 0.1}}
                    variant='filled'
                    onChange={(event) => {
                        props.inputChanged({value: event.target.value}, {id: props.id!!.toString()}, "jNumberOfRearWheel")
                    }}
                    label={"J数_後輪"}
                    isDouble={true}
                    source={'jNumberOfRearWheel' + props.id}
                />
                <NumberInputCommon
                    defaultValue={props.insetOfFrontWheel}
                    className={'w-100 input-filled-basic'}
                    type="number"
                    inputProps={{min: 0, step: 1}}
                    variant='filled'
                    onChange={(event) => {
                        props.inputChanged({value: event.target.value}, {id: props.id!!.toString()}, "insetOfFrontWheel")
                    }}
                    label={"インセット_前輪"}
                    isDouble={true}
                    source={'insetOfFrontWheel' + props.id}
                />
                <NumberInputCommon
                    defaultValue={props.insetOfRearWheel}
                    className={'w-100 input-filled-basic'}
                    type="number"
                    inputProps={{min: 0, step: 1}}
                    variant='filled'
                    onChange={(event) => {
                        props.inputChanged({value: event.target.value}, {id: props.id!!.toString()}, "insetOfRearWheel")
                    }}
                    label={"インセット_後輪"}
                    isDouble={true}
                    source={'insetOfRearWheel' + props.id}
                />
            </TableCell>
            <TableCell className={'fitting-table-cell'}>
                <TextInput
                    defaultValue={props.recommendName}
                    className={'w-100'}
                    type="string"
                    inputProps={{min: 0, step: 1}}
                    variant='filled'
                    onChange={(event) => {
                        props.inputChanged({value: event.target.value}, {id: props.id!!.toString()}, "recommendName")
                    }}
                    source={"recommendName" + props.id}
                    label={"お勧め名"}
                    required={false}
                />

                <TextInput
                    defaultValue={props.recommendNote}
                    className={'w-100'}
                    type="string"
                    inputProps={{min: 0, step: 1}}
                    variant='filled'
                    multiline
                    rows={10}
                    onChange={(event) => {
                        props.inputChanged({value: event.target.value}, {id: props.id!!.toString()}, "recommendNote")
                    }}
                    source={"recommendNote" + props.id}
                    label={"お勧め説明文"}
                    required={false}
                />
            </TableCell>
            <TableCell align="left" key={props.id} style={{minWidth: `700px`}}>
                <div className={'d-flex'}>
                    <JNumberAndInsetRecommendationImage
                        dataProvider={dataProvider}
                        jNumberAndInsetRecommendationId={props.id}
                        pathData={pathData}
                        setPathData={setPathData}
                        images={images}
                        setImages={setImages}
                        id={props.id}
                        uploadedCounter={uploadedCounter}
                    />
                </div>
            </TableCell>
        </TableRow>
    );
}

export default JNumberAndInsetRecommendationListRecordComponent
