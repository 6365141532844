import {EditableDatagrid, RowForm} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {List, maxLength, minValue, number, required, TextField, useRecordContext,} from 'react-admin';
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";

const TaxByEnvironmentalPerformancesList: React.FC = (props: any) => {
    return (
        <List
            {...props}
            hasCreate
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <EditableDatagrid
                undoable={true}
                createForm={<DataForm/>}
                editForm={<DataForm/>}
                noDelete={false}
                className={'editableListTable'}
            >
                <TextField source="name" label={'環境性能割名'}/>
                <PercentageTextField source="taxRatio" label={'税率'}/>
                <TextField source="position" label={'表示順序'}/>
            </EditableDatagrid>
        </List>
    );
}

const DataForm: React.FC = (props) => {
    const validateName = [required(), maxLength(50)];
    const validateNumber = [required(), number(), minValue(0)];
    return (
        <RowForm
            {...props}
            submitOnEnter={false}
        >
            <TextInputForJapanese source="name" validate={validateName} label={'環境性能割名'}/>
            <TextInputForJapanese source="taxRatio" validate={validateNumber} label={'税率'}/>
            <TextInputForJapanese source="position" validate={validateNumber} label={'表示順序'}/>
        </RowForm>
    )
};


const PercentageTextField = ({...source}) => {
    const record = useRecordContext();
    return (<span>{`${record && record[source.source]} %`}</span>);
};

export default TaxByEnvironmentalPerformancesList;