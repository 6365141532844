import {useStyles} from "../../common/styles";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {
    RecommendedCarDefaultVariantItemFunctions,
    RecommendedCarDefaultVariantItemProps
} from "../recommendedCars/RecommendedCarDefaultVariantItemComponent";
import {ChangeEvent, ForwardedRef, useEffect, useImperativeHandle, useState} from "react";
import {ApiProduct, ApiProductVariants, ApiProductVariantsVariantVariantClass, ApiVariantItem} from "../../openapi";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {useDataProvider} from "react-admin";
import {IdName} from "../../common/IdName";
import {useForm} from "react-final-form";


const useLocalStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

interface DefaultVariantItemHookProps extends RecommendedCarDefaultVariantItemProps {
    ref: ForwardedRef<RecommendedCarDefaultVariantItemFunctions>
}

interface VariantClassVariantItems extends ApiProductVariantsVariantVariantClass {
    choices?: Array<IdName>
}

export const useRecommendedCarDefaultVariantItemHooks = (props: DefaultVariantItemHookProps) => {
    const [tabValue, setTabValue] = useState(0);
    const classes = useLocalStyles();
    const globalClasses = useStyles();
    const [productVariants, setProductVariants] = useState<ApiProductVariants>()
    const dataProvider = useDataProvider();
    const [variantClassVariantItems, setVariantClassVariantItems] = useState<VariantClassVariantItems[]>()
    const form = useForm();

    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    useImperativeHandle(props.ref, () => ({
        async save(recommendedCarId: number, defaultProductIds: number[], defaultVariantItemIds: number[]) {

            if (defaultProductIds.length === 0 && defaultVariantItemIds === undefined) {
                await dataProvider.create('recommendedCarDefaultVariantItems/' + recommendedCarId, {
                    data: {
                        data: undefined,
                        id: 1,
                    }
                })
                return;
            }
            if (defaultVariantItemIds === undefined) {
                return;
            } else {
                const dataArray = new Array<{ variantItemId: number, recommendedCarId: number }>()
                const selectedValues = defaultVariantItemIds;

                if (!selectedValues || !selectedValues.length) {
                    return;
                }

                selectedValues.map((value) => {
                    dataArray.push(
                        {
                            recommendedCarId: recommendedCarId,
                            variantItemId: value,
                        }
                    )
                })

                await dataProvider.create('recommendedCarDefaultVariantItems/' + recommendedCarId, {
                    data: {
                        data: dataArray,
                        id: 1,
                    }
                })
            }
        },
    }));

    const initialization = () => {
        const awaitFn = async () => {

            const carGradeIds = form.getState().values['carGradeId'] as number
            if (carGradeIds === undefined) {
                return;
            }

            props.setOpen(true);
            setTabValue(0)

            if (props.defaultProductIds?.length !== 0 && props.defaultProductIds !== undefined && carGradeIds != null) {
                const data = await dataProvider.getList('recommendedCarDefaultVariantItems/afterChangingOtherDefault', {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'createdAt', order: 'ASC'},
                    filter: {
                        carGradeId: carGradeIds,
                        defaultProductIds: props.defaultProductIds,
                        recommendedCarId: props.recommendedCarId
                    }
                })
                const fetched = data as ApiProductVariants
                setProductVariants(fetched);

                const initialValues = new Array<number>();
                const initialAffectPriceValues = new Array<number>();

                fetched.data?.map((parent) => parent.variant?.variantClass?.map((variantClass) => variantClass.carGrades?.map((carGrade) => carGrade.variantItem?.map((variantItem) => {
                    if (variantItem.checked) {
                        initialValues.push(variantItem.id!!);
                        if (variantItem.variantClass?.affectPrice) {
                            initialAffectPriceValues.push(variantItem.id!!);
                        }
                    }
                }))));

                props.setSelectAffectPriceVariantItemIds(initialAffectPriceValues);
                props.setVariantItemInitialValues(initialValues);
                props.setDefaultVariantItemIds(initialValues);

            } else {
                setProductVariants(undefined)
                props.setVariantItemInitialValues(undefined)
                props.setDefaultVariantItemIds(undefined)
            }
            props.setOpen(false);
        }
        awaitFn().then();
    }

    const retrieveInset = () => {
        const awaitFn = async () => {
            const carGradeIds = form.getState().values['carGradeId'] as number;
            const variant = props.defaultVariantItemIds;
            const defaultProductIds = props.defaultProductIds;
            if (props.defaultProductIds?.length !== 0 && props.defaultVariantItemIds?.length !== 0 && carGradeIds) {
                if (!props.displayAutoJNumber || variant === undefined || !productVariants) {
                    return;
                }
                const productIdTo = defaultProductIds![props.defaultProductIds!.length - 1]
                const product = await dataProvider.getOne('products', {
                    id: productIdTo,
                });

                const productData = product.data as ApiProduct

                if (!productData.productCategory?.wheel) {
                    return;
                }

                const inset = await dataProvider.create('jNumberAndInsetRecommendations/' + carGradeIds + '/' + productIdTo + "/withData", {
                    data: {
                        variantItemIds: variant
                    }
                });

                props.setInsetOfFrontWheel(inset.data.insetOfFrontWheel);
                props.setJNumberOfFrontWheel(inset.data.jNumberOfFrontWheel);
                props.setJNumberOfRearWheel(inset.data.jNumberOfRearWheel);
                props.setInsetOfRearWheel(inset.data.insetOfRearWheel);
            }
        }
        awaitFn().then();
    }

    useEffect(retrieveInset, [props.defaultVariantItemIds])
    useEffect(initialization, [props.carGradeIds, props.recommendedCarId, props.defaultProductIds])

    const onChangeCheckBox = async (e: any) => {
        props.setDisplayAutoJNumber(true);
        const selectVariantItem = findVariantItemById(e.target.value);
        let variantItemIds: number[] = [];
        let affectPriceVariantItemIds: number[] = [];
        let deletedVariantItemId = 0;
        if (props.defaultVariantItemIds !== undefined)
            variantItemIds = props.defaultVariantItemIds;
        if (props.selectAffectPriceVariantItemIds !== undefined)
            affectPriceVariantItemIds = props.selectAffectPriceVariantItemIds;

        variantClassVariantItems?.map((variantClassVariantItem) => {
            if (variantClassVariantItem.choices !== null) {
                variantClassVariantItem.choices?.map((choice) => {
                    const variantItem = findVariantItemById(choice.id)
                    if (variantItem.variantClassId === selectVariantItem.variantClassId) {
                        const index = variantItemIds.indexOf(choice.id, 0)
                        if (index !== -1) {
                            deletedVariantItemId = choice.id
                        }
                    }
                })
            }
            variantClassVariantItem.choices?.push({
                id: selectVariantItem.id!!,
                name: selectVariantItem.name,
            });
        });

        if (selectVariantItem.id != null) {
            if (selectVariantItem.variantClass?.affectPrice)
                affectPriceVariantItemIds.push(selectVariantItem.id);
            variantItemIds.push(selectVariantItem.id);
            if (selectVariantItem.id !== deletedVariantItemId) {
                const index = variantItemIds.indexOf(deletedVariantItemId, 0);
                if (index !== -1)
                    variantItemIds.splice(index, 1);
                const affectPriceIndex = affectPriceVariantItemIds.indexOf(deletedVariantItemId, 0);
                if (affectPriceIndex !== -1) {
                    affectPriceVariantItemIds.splice(affectPriceIndex, 1);
                }
            }
        }
        variantItemIds = [...new Set(variantItemIds)];
        affectPriceVariantItemIds = [...new Set(affectPriceVariantItemIds)];

        setVariantClassVariantItems(variantClassVariantItems);
        props.setDefaultVariantItemIds(variantItemIds);
        props.setSelectAffectPriceVariantItemIds(affectPriceVariantItemIds);
    }

    const findVariantItemById = (variantItemId: number): ApiVariantItem => {
        let selectVariantItem: ApiVariantItem = {
            cost: 0,
            originalPrice: 0,
            priceForPartsOnly: 0,
            name: '', price: 0, variantClassId: 0, position: 0
        }
        variantClassVariantItems?.map((variantClassVariantItem) =>
            variantClassVariantItem.carGrades?.map((carGrade) =>
                carGrade.variantItem?.forEach((variantItem) => {
                    if (variantItemId == variantItem.id!!) {
                        selectVariantItem = variantItem
                    }
                })
            )
        )
        return selectVariantItem
    }

    const getDataToDisplay = () => {
        if (tabValue === 0) {
            const data = new Array<VariantClassVariantItems>();
            setVariantClassVariantItems(data);
        } else {
            const data = productVariants?.data?.find((d) => d.variant?.id === tabValue)?.variant?.variantClass as Array<VariantClassVariantItems>;
            data?.map((variantClassVariantItem) => {
                const choices = new Array<IdName>();
                variantClassVariantItem?.carGrades?.map((carGrade: any) => {
                    carGrade?.variantItem?.map((variantItem: any) => {
                        if (choices.find((choice) => choice.id === variantItem.id) === undefined) {
                            choices.push({
                                id: variantItem.id!!,
                                name: variantItem.name,
                            });
                        }
                    })
                })
                variantClassVariantItem.choices = choices;
            })
            setVariantClassVariantItems(data);
        }
    }

    useEffect(getDataToDisplay, [tabValue]);

    return {
        classes,
        globalClasses,
        tabValue,
        handleTabChange,
        productVariants,
        variantClassVariantItems,
        onChangeCheckBox
    }
}
