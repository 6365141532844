import {Dispatch, SetStateAction, useEffect} from "react";
import {MAX_FETCH_DATA_SIZE, ResourceNames} from "../../config/const";
import {convertDataForSortableImage} from "../../common/image/databaseImageUtils";
import {DataProviderProxy} from "ra-core";
import {ListPhotosType} from "../../common/image/arrayUtil";
import dataProvider from "../../dataProvider";

interface RecommendedCarHookProps {
    recommendedCarId?: number;
    recommendedCarImageClass1: number;
    recommendedCarImageClass2: number;
    dataProvider: DataProviderProxy;
    setImages1: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    setImages2: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    refresh: number;
}

export const useRecommendedCarImageHooks = (props: RecommendedCarHookProps) => {

    const loadImages = () => {
        (async () => {
            console.log('cyd', props.refresh)
            await getImages1()
            await getImages2()

            // const ads = await dataProvider.get

        })();
    }
    useEffect(loadImages, [props.recommendedCarId, props.refresh])

    const getImages1 = async () => {
        if (props.recommendedCarId) {
            const result = await convertDataForSortableImage(props.dataProvider,
                ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE,
                {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'position', order: 'ASC'},
                    filter: {
                        recommendedCarId: props.recommendedCarId,
                        recommendedCarImageClass: props.recommendedCarImageClass1,
                    }
                }
            )
            props.setImages1(result);
        }
    }

    const getImages2 = async () => {
        if (props.recommendedCarId) {
            const result = await convertDataForSortableImage(props.dataProvider,
                ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE,
                {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'position', order: 'ASC'},
                    filter: {
                        recommendedCarId: props.recommendedCarId,
                        recommendedCarImageClass: props.recommendedCarImageClass2,
                    }
                }
            )
            props.setImages2(result);
        }
    }


}
