import * as React from "react";
import {useRecordContext} from 'react-admin';

const TextFieldWithMultiline = ({...source}) => {
    const record = useRecordContext();
    return (
        <pre> {record[source.source]} </pre>
    );
};

export default TextFieldWithMultiline;