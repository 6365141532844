import {useDataProvider} from "react-admin";
import {useState} from "react";
import {ApiCarColor, ApiCarGrade, ApiCarModel} from "../../openapi";

const useCarColor = () => {
    const dataProviders = useDataProvider();
    const [carGrade, setCarGrade] = useState<ApiCarGrade>()
    const [carModel, setCarModel] = useState<ApiCarModel>()

    const getByCarGradeId = async (carGradeId: number) => {
        const fetchedGradeData = await dataProviders.getOne('carGrades', {id: carGradeId})
        const data = fetchedGradeData.data as ApiCarGrade
        setCarGrade(data);
        const fetchedModelData = await dataProviders.getOne('carModels', {id: data.carModelId!!})
        setCarModel(fetchedModelData.data as ApiCarModel);
    }

    const getByCarColorId = async (carColorId: number) => {
        const fetchedData = await dataProviders.getOne('carColors', {id: carColorId});
        const data = fetchedData.data as ApiCarColor;
        await getByCarGradeId(data.carGradeId);
    }


    return {
        carGrade,
        carModel,
        getByCarGradeId,
        getByCarColorId,
    }
}

export default useCarColor;
