import {CarColorImageProps} from "./CarColorsImagesComponent";
import {useEffect} from "react";
import {MAX_FETCH_DATA_SIZE, ResourceNames} from "../../config/const";
import {convertDataForSortableImage} from "../../common/image/databaseImageUtils";

export const useCarColorImageHooks = (props: CarColorImageProps) => {

    useEffect(() => {
        const awaitFn = async () => {
            await getImages()
        };
        awaitFn().then();
    }, [props.carColorId])

    const getImages = async () => {
        if (props.carColorId) {
            const result = await convertDataForSortableImage(props.dataProvider,
                ResourceNames.CAR_COLOR_IMAGE_RESOURCE,
                {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'position', order: 'ASC'},
                    filter: {
                        carColorId: props.carColorId,
                        carColorImageClass: props.carColorImageClass,
                    }
                }
            )
            props.setImages(result);
        }
    }

}
