import React from "react";
import {
    useRecordContext,
} from 'react-admin';

export const TextFieldWithCommas = ({...source}) => {
    const record = useRecordContext();
    return (
        <span className={'numberRight'}>{`${Number(record && record[source.source]).toLocaleString()}`}</span>
    );
};

export const TextFieldWithCommaUnit = ({...source}) => {
    const record = useRecordContext();
    return (
        <span className={'numberRight'}>{`${Number(record && record[source.source]).toLocaleString()}`}{source.unit}</span>
    );
};
