import {DeleteWithConfirmIconButton,} from '@react-admin/ra-editable-datagrid';
import React, {useEffect} from 'react';
import {
    AppBar,
    Button,
    Datagrid,
    EditButton,
    ReferenceManyField,
    TextField,
    TopToolbar,
    useRecordContext
} from 'react-admin';
import {Link} from "react-router-dom";
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";
import useCarColor from "./carColorHooks";
import {ApiCarGrade, ApiCarModel} from "../../openapi";
import {Box} from "@material-ui/core";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";

const BASE_PATH = '/carColors'

interface ListActionProps {
    carGradeId: number;
}

const ListActions: React.FC<ListActionProps> = (props) => {
    return (
        <TopToolbar>
            <Button
                component={Link}
                to={{
                    pathname: '/carColors/create',
                    state: {record: {carGradeId: props.carGradeId}}
                }}
                label={'作成'}
            />
        </TopToolbar>
    );
}

const CarColorList: React.FC = (props: any) => {
    const {carModel, carGrade, getByCarGradeId} = useCarColor();
    useEffect(() => {
        getByCarGradeId(props.id).then();
    }, [])

    return (
        <div>
            {carModel && carGrade &&
            <>
                <CarColorsLabel carModel={carModel} carGrade={carGrade} isList={true}/>
                <ListActions carGradeId={carGrade.id!!}/>
                <ReferenceManyField
                    reference={'carColors'}
                    target={'carGradeId'}
                    filter={{carGradeId: props.id}}
                    addLabel={false}
                    sort={{field: 'position', order: 'ASC'}}
                    fullWidth
                >
                    <Datagrid>
                        <TextField source="name" label={'カラー'}/>
                        <TextFieldWithCommas source="price" label={'売価'} textAlign={'right'}/>
                        <TextFieldWithCommas source="cost" label={'原価'} textAlign={'right'}/>
                        <TextField source="color1" label={'色1'}/>
                        <TextField source="color2" label={'色2'}/>
                        <TextField className={'numberRight'} source="position" label={'表示順序'} textAlign={'right'}/>
                        <div className={'actionButtons'}>
                            <EditDataBtn {...props} />
                            <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                           className={'ml-30'}/>
                        </div>
                    </Datagrid>
                </ReferenceManyField>
            </>
            }
        </div>
    );
}

const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''} basePath={BASE_PATH}/>);
};

export const CarColorsLabel: React.FC<{ carModel?: ApiCarModel, carGrade?: ApiCarGrade, isList?: boolean }> = (props) => {

    const BackButton = () => {
        if (props.isList) {
            return (
                <Button
                    component={Link}
                    variant="contained"
                    color={'default'}
                    to={{
                        pathname: `/carModels/${props.carModel?.id}/show`
                    }}
                    label={'戻る'}
                />
            );
        } else {
            return (
                <Button
                    component={Link}
                    variant="contained"
                    color={'default'}
                    to={{
                        pathname: `/carGrades/${props.carGrade?.id}/show`
                    }}
                    label={'戻る'}
                />
            );
        }
    }
    return (
        <div>
            <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        carModel={props.carModel}
                        carGrade={props.carGrade}
                    />
                    {'カラー管理'}
                </div>
            </AppBar>
            <Box>
                <BackButton/>
            </Box>
        </div>
    );
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton
        {...source}
        record={record}
        mutationMode={'undoable'}
        resource={'carColors'}
        basePath={BASE_PATH}
        redirect={`/carGrades/${record.carGradeId}/show`}
    />);
};

export default CarColorList;

