/* tslint:disable */
/* eslint-disable */
/**
 * kus_server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @enum {string}
 */

export enum UsedCarType {
    UsedCar1 = 'usedCar1',
    UsedCar2 = 'usedCar2',
    UsedCar3 = 'usedCar3'
}



