import {Box, Button, Grid, TextField,} from '@material-ui/core';
import * as React from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {ListPhotosType} from "./arrayUtil";

const defaultProps = {
    defaultValue: 1,
    index: 0,
    value: 1,
    message: '削除しても宜しいでしょうか。',
};

export interface SortBoxType {
    onBeforeClick(index: number | undefined): void;

    onAfterClick(index: number | undefined): void;

    onChange(index: number | undefined, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

export interface SortDelete {
    onDelete(item: ListPhotosType): void;
}

interface SortBoxProps<T> {
    sortBoxProps: SortBoxType;
    defaultValue?: number;
    index?: number;
    value?: number;
    message?: string;
}

type SortBox<T = any> = React.FC<SortBoxProps<T>>;

const SortBox: SortBox = (props) => {
    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={1} justify='center' flex-direction={'column'}>
                <Grid container={true} justify={'center'}>
                    <Box display={'flex'} my={2}>
                        <Box mr={1}>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => props.sortBoxProps.onBeforeClick(props.index)}
                            >
                                <NavigateBeforeIcon/>
                            </Button>
                        </Box>
                        <Box ml={1}>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => props.sortBoxProps.onAfterClick(props.index)}
                            >
                                <NavigateNextIcon/>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
SortBox.defaultProps = defaultProps;
export default SortBox;
