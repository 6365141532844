import React, {Dispatch, SetStateAction} from "react";
import {DataProviderProxy} from "ra-core";
import SortableImageList from "../../common/image/SortableImageList";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {ResourceNames} from "../../config/const";
import {useCarModelImageHooks} from "./carModelImageHooks";

const defaultProps = {
    showSortBox: true,
};

export interface CarModelImageProps {
    carModelId?: number;
    dataProvider: DataProviderProxy;
    setPathData: Dispatch<SetStateAction<Array<TempFileData>>>;
    pathData: TempFileData[];
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>;
    images: ListPhotosType[] | undefined;
    showSortBox?: boolean;
    maxFileCount?: number;
}

const CarModelImage: React.FC<CarModelImageProps> = (props) => {
    useCarModelImageHooks(props);
    return (
        <>
            <SortableImageList imageArray={props.images}
                               dataProvider={props.dataProvider}
                               resource={ResourceNames.CAR_MODEL_IMAGE_RESOURCE}
                               isUpdating={!!props.carModelId}
                               setPathData={props.setPathData}
                               pathData={props.pathData}
                               setImages={props.setImages}
                               imageSourceName={ResourceNames.CAR_MODEL_IMAGE_RESOURCE}
                               showSortBox={props.showSortBox}
                               maxFileCount={props.maxFileCount}
            />
        </>
    );
}

CarModelImage.defaultProps = defaultProps;

export default CarModelImage
