import {DataProviderProxy} from "ra-core";
import {GetListParams} from "react-admin";
import {firebaseStorage} from "../../config/firebase";
import {TempFileData} from "../../modules/temporaryFileModule";
import {deleteFile, uploadFile} from "../../utils/upload";
import {ListPhotosType, reSort} from "./arrayUtil";
import {Dispatch, SetStateAction} from "react";
import {RecommendedCarImageClass, ResourceNames, VALUE} from "../../config/const";
import {ApiProductVariantItemImageToUpdate, ApiRecommendedCarImage} from "../../openapi";
import {UrlInterface} from "../../components/productVariantItemImages/ProductVariantImageHooks";

export const convertDataForSortableImage = async <T extends ListPhotosType>
(dataProvider: DataProviderProxy, resource: string, params: GetListParams): Promise<ListPhotosType[]> => {
    let listPhotos: ListPhotosType[] = [];
    const images = await dataProvider.getList(resource, params);
    await Promise.all(images.data.map(async (images, index) => {
        try {
            if (images.recommendedCarImageClass === RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_2) {
                const ref1 = await firebaseStorage.ref(images.path);
                const url1 = await ref1.getDownloadURL();
                const ref2 = await firebaseStorage.ref(images.path2);
                const url2 = await ref2.getDownloadURL();
                listPhotos[index] = {
                    position: images.position,
                    path: images.path,
                    url: url1,
                    bigImageUrl: url2,
                    ...images
                };
            } else {
                const ref = await firebaseStorage.ref(images.path);
                const url = await ref.getDownloadURL();
                listPhotos[index] = {
                    position: images.position,
                    path: images.path,
                    url: url,
                    ...images
                };
            }
        } catch (e) {
            listPhotos[index] = {
                position: images.position,
                path: images.path,
                url: 'https://i.imgur.com/qwO9UpL.png',
                ...images
            };
        }
    }))
    return listPhotos;
};

export const SAVE_RESULT = {
    NO_IMAGE: 2,
}

export const saveImages = async <T extends { position: number }>
(dataProvider: DataProviderProxy,
 resource: string,
 params: any,
 directory: string,
 saveTargets: TempFileData[],
 exists: T[] | undefined,
 cropTarget?: TempFileData[],
 isThumbnailUpdating?: boolean,
 isBigImageUpdating?: boolean,
 imageId?: number,
 onUploadFn?: () => void,
 refresh?: () => void,
 savePublicImage?: boolean,
 productVariantItemImage?: UrlInterface,
) => {
    if (saveTargets.length === 0) {
        return SAVE_RESULT.NO_IMAGE;
    }
    let max = 0;
    if (exists) {
        max = Math.max.apply(Math, exists.map((o) => {
            return o.position;
        }));
    }
    max++;
    await Promise.all(saveTargets.map(async (saveTarget, index) => {
        let createData: {};
        if (cropTarget) {
            createData = {
                position: max++,
                path: directory + cropTarget[0].filename,
                path2: directory + saveTarget.filename,
            }
        } else {
            createData = {
                position: max++,
                path: directory + saveTarget.filename,
            }
        }
        if (params.recommendedCarImageClass === RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_2) {
            if ((isThumbnailUpdating || isBigImageUpdating) && imageId && imageId > 0) {
                const recommendedCarImg = await dataProvider.getOne(resource, {id: Number(imageId)});
                let imgData = recommendedCarImg.data as ApiRecommendedCarImage
                let data = {
                    id: imageId, ...params, ...createData
                }
                data.position = imgData.position;
                await dataProvider.update(resource, {
                    id: imageId,
                    data: {...data},
                    previousData: {id: imageId}
                }).then(async () => {
                    await uploadFile(directory, saveTarget.fileBinary, saveTarget.filename, saveTarget.fileType, onUploadFn);
                    if (cropTarget) {
                        await uploadFile(directory, cropTarget[0].fileBinary, cropTarget[0].filename, cropTarget[0].fileType, onUploadFn);
                    }
                });
            } else {
                await dataProvider.create(resource,
                    {
                        data: {
                            ...params, ...createData
                        }
                    }
                ).then(async () => {
                    await uploadFile(directory, saveTarget.fileBinary, saveTarget.filename, saveTarget.fileType, onUploadFn);
                    if (cropTarget) {
                        await uploadFile(directory, cropTarget[0].fileBinary, cropTarget[0].filename, cropTarget[0].fileType, onUploadFn);
                    }
                });
            }
        } else {
            const uploadedResult = await uploadFile(directory, saveTarget.fileBinary, saveTarget.filename, saveTarget.fileType);
            if (cropTarget) {
                await uploadFile(directory, cropTarget[0].fileBinary, cropTarget[0].filename, cropTarget[0].fileType);
            }
            if (savePublicImage) {
                params.publicUrl = await uploadedResult.ref.getDownloadURL();
            }

            if (resource === ResourceNames.PRODUCT_VARIANT_ITEM_IMAGES) {
                await updateVariantItemImage(directory, saveTarget.filename, params.publicUrl, productVariantItemImage!, dataProvider, resource)
            } else {
                await dataProvider.create(resource,
                    {
                        data: {
                            ...params, ...createData
                        }
                    });
            }
        }
    }));
}

const updateVariantItemImage = async (directory: string, fileName: string, publicUrl: string, productVariantItemImage: UrlInterface, dataProvider: DataProviderProxy, resource: string) => {
    const pathAndUrl: ApiProductVariantItemImageToUpdate = {
        path: directory + fileName,
        publicUrl: publicUrl,
        productId: productVariantItemImage.productId
    }


    if (productVariantItemImage.imageId !== VALUE.EMPTY) {
        await dataProvider.update(resource, {
            id: productVariantItemImage.imageId!,
            data: {
                ...pathAndUrl
            },
            previousData: {id: productVariantItemImage.imageId!}
        })

        await deleteFile(productVariantItemImage.pathUrl)
    }
}


export const uploadProductImageToFirebase = async (thumbnailData: Array<TempFileData>, pathData: Array<TempFileData>, data: Record<any, any>) => {
    let thumbnailPath = '';
    let path = '';
    if (thumbnailData.length > 0) {
        const result1 = await uploadFile('/' + thumbnailData[0].dir + '/' + data.id + '/', thumbnailData[0].fileBinary, thumbnailData[0].filename, thumbnailData[0].fileType);
        console.log('metadata: ', result1.metadata);
        let fileData = {
            fileName: result1.metadata.name,
            filePath: result1.metadata.fullPath, fileIndex: 0
        };
        thumbnailPath = `${fileData.filePath}.${result1.metadata.contentType.split('/')[1]}`;
    }
    if (pathData.length > 0) {
        const result2 = await uploadFile('/' + pathData[0].dir + '/' + data.id + '/', pathData[0].fileBinary, pathData[0].filename, pathData[0].fileType);
        console.log('metadata: ', result2.metadata);
        let fileData = {
            fileName: result2.metadata.name,
            filePath: result2.metadata.fullPath, fileIndex: 0
        };
        path = `${fileData.filePath}.${result2.metadata.contentType.split('/')[1]}`;
    }
    return {thumbnailPath: thumbnailPath, path: path};
}


export const uploadBrandsImageToFirebase = async (logoPathData: Array<TempFileData>, logoPathSpData: Array<TempFileData>, pathData: Array<TempFileData>, logoPathSuspensionData: Array<TempFileData>) => {
    let logoPath = '';
    let stampPath = '';
    let logoPathSp = '';
    let logoPathSuspension = '';
    if (logoPathData.length > 0) {
        const result1 = await uploadFile('/' + logoPathData[0].dir + '/' + Date.now().toString(36) + Math.random().toString(36).substr(2) + '/', logoPathData[0].fileBinary, logoPathData[0].filename, logoPathData[0].fileType);
        let fileData = {
            fileName: result1.metadata.name,
            filePath: result1.metadata.fullPath, fileIndex: 0
        };
        logoPath = `${fileData.filePath}.${result1.metadata.contentType.split('/')[1]}`;
    }
    if (pathData.length > 0) {
        const result2 = await uploadFile('/' + pathData[0].dir + '/' + Date.now().toString(36) + Math.random().toString(36).substr(2) + '/', pathData[0].fileBinary, pathData[0].filename, pathData[0].fileType);
        let fileData = {
            fileName: result2.metadata.name,
            filePath: result2.metadata.fullPath, fileIndex: 0
        };
        stampPath = `${fileData.filePath}.${result2.metadata.contentType.split('/')[1]}`;
    }
    if (logoPathSpData.length > 0) {
        const result = await uploadFile('/' + logoPathSpData[0].dir + '/' + Date.now().toString(36) + Math.random().toString(36).substr(2) + '/', logoPathSpData[0].fileBinary, logoPathSpData[0].filename, logoPathSpData[0].fileType);
        let fileData = {
            fileName: result.metadata.name,
            filePath: result.metadata.fullPath, fileIndex: 0
        };
        logoPathSp = `${fileData.filePath}.${result.metadata.contentType.split('/')[1]}`;
    }
    if (logoPathSuspensionData.length > 0) {
        const result = await uploadFile('/' + logoPathSuspensionData[0].dir + '/' + Date.now().toString(36) + Math.random().toString(36).substr(2) + '/', logoPathSuspensionData[0].fileBinary, logoPathSuspensionData[0].filename, logoPathSuspensionData[0].fileType);
        let fileData = {
            fileName: result.metadata.name,
            filePath: result.metadata.fullPath, fileIndex: 0
        };
        logoPathSuspension = `${fileData.filePath}.${result.metadata.contentType.split('/')[1]}`;
    }
    return {logoPath, logoPathSp, stampPath, logoPathSuspension};
}


export const deleteImage = async (
    dataProvider: DataProviderProxy,
    resource: string,
    id: number,
    path: string,
    setImages: Dispatch<SetStateAction<Array<ListPhotosType> | undefined>>,
    images: ListPhotosType[],
) => {
    await dataProvider.delete(resource, {
        id: id,
        previousData: {id: id},
    });
    await deleteFile(path);
    setImages(reSort(images.filter((i) => i.id !== id)));
}
