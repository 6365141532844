import * as React from "react";
import {Admin, fetchUtils, mergeTranslations, Resource, TranslationMessages} from 'react-admin';
import {createBrowserHistory} from 'history';
import japaneseMessages from '@bicstone/ra-language-japanese';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import TaxByEnvironmentalPerformancesList
    from "./components/taxByEnvironmentalPerformances/TaxByEnvironmentalPerformancesListComponent";
import CarShippingPlacesList from "./components/carShippingPlaces/CarShippingPlacesListComponent";
import dataProvider from "./dataProvider";
import MyLayout from "./components/menuBar/MenuLayout";
import ProductCategoriesListComponent from './components/productCategories/ProductCategoriesListComponent';
import GeneralChargesShow from "./components/generalCharges/GeneralChargesShowComponent";
import GeneralChargesEdit from "./components/generalCharges/GeneralChargesEditComponent";
import EcoCarTaxCutsList from "./components/ecoCarTaxCuts/EcoCarTaxCutsListComponent";
import CarGradesList, {CarGradesCreate, CarGradesEdit} from "./components/carGrades/CarGradesListComponent";
import CarInsurancesList from "./components/carInsurances/CarInsurancesListComponent";
import CarsList from "./components/cars/CarsListComponent";
import {CarsCreate, CarsEdit} from "./components/cars/CarsFormComponent";
import CarModelsList from "./components/carModels/CarModelsListComponent";
import {CarModelsCreate, CarModelsEdit} from "./components/carModels/CarModelsFormComponent"
import CarColorList from "./components/carColors/CarColorsListComponent";
import Estimates from "./components/estimates/EstimatesComponent";
import EstimatesId from "./components/estimates/EstimatesDetailsComponent";
import EstimatesCharges from "./components/estimates/EstimatesChargesComponent";
import CarShippingFeesList from "./components/carShippingFees/CarShippingFeesListComponent";
import CustomRoutes from "./common/customRoutes/CustomRoutes";
import ProductList from "./components/products/ProductsListComponent";
import SettingShow from "./components/setting/SettingShowComponent";
import SettingEdit from "./components/setting/SettingEditComponent";
import TemplatesList from "./components/templates/TemplatesListComponent";
import {TemplateCreate, TemplateEdit} from "./components/templates/TemplatesFormComponent";
import {ProductCreate, ProductEdit} from "./components/products/ProductsFormComponent";
import authProvider from "./authProvider";
import Login from "./components/login/LoginComponent";
import {CarColorsCreateForm, CarColorsEditForm} from "./components/carColors/CarColorsFormComponent";
import CompleteCarsList from './components/completeCars/CompleteCarsListComponent';
import {CompleteCarsFormCreate, CompleteCarsFormEdit} from "./components/completeCars/CompleteCarsFormComponent";
import {japaneseDomainMessages} from "./config/japaneseDomainMessages";
import weightTaxesList from "./components/weightTaxes/WeightTaxesListComponent";
import {japaneseImportMessages} from "./config/japaneseImportMessages";
import theme from "./theme";
import ConsumptionTaxesList from "./components/consumptionTaxes/ConsumptionTaxesListComponent";
import AutoMobileTaxesList from "./components/autoMobileTaxes/autoMobileTaxesListComponent";
import RegistrationFeeList from "./components/registrationFee/RegistrationFeeListComponent";
import {AUTH_KEY} from "./config/const";
import AeroSeriesListComponent from "./components/aeroSeries/AeroSeriesListComponent";
import {AeroSeriesCreate, AeroSeriesEdit} from "./components/aeroSeries/AeroSeriesProductsFormComponent";
import UsersListComponent from "./components/users/UsersListComponent";
import ShopsListComponent from "./components/shops/ShopsListComponent";
import JNumberAndInsetRecommendationListComponent
    from "./components/products/JNumberAndInsetRecommendationListComponent";
import {RecommendedCarsCreate, RecommendedCarsEdit} from "./components/recommendedCars/RecommendedCarsFormComponent";
import RecommendedCarsListComponent from "./components/recommendedCars/RecommendedCarsListComponent";
import {
    RecommendedCarNonMasterDefaultProductCreate,
    RecommendedCarNonMasterDefaultProductEdit
} from "./components/recommendedCars/RecommendedCarsNonMasterDefaultProductList";
import VariantClassesListComponent from "./components/variantClasses/VariantClassesListComponent";
import {
    VariantClassesCreateForm,
    VariantClassesEditForm
} from "./components/variantClasses/VariantClassesFormComponent";
import VariantItemsListComponent from "./components/variantItems/VariantItemsListComponent";
import RecommendedCarRelatedCarSettingListComponent
    from "./components/recommendedCarRelatedCarSetting/RecommendedCarRelatedCarSettingListComponent";
import ProductVariantItemImagesList from "./components/productVariantItemImages/ProductVariantItemImagesListComponent";
import {VariantItemCreate, VariantItemEdit} from "./components/variantItems/VariantItemFormComponent";
import {BrandsCreate, BrandsEdit} from './components/brands/BrandsFormComponent';
import BrandsList from "./components/brands/BrandstList";

const customEnglishMessages: TranslationMessages = mergeTranslations(
    japaneseMessages,
    japaneseDomainMessages,
    japaneseImportMessages,
);
const i18nProvider = polyglotI18nProvider((locale: string) => {
    return customEnglishMessages;
}, 'ja', {allowMissing: true});
const history = createBrowserHistory();
const httpClient = (url: any, options: fetchUtils.Options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'})
    }
    if (localStorage.getItem(AUTH_KEY)) {
        const {accessToken} = JSON.parse(localStorage.getItem(AUTH_KEY)!);
        if (accessToken && accessToken !== 'undefined') {
            // @ts-ignore refer https://github.com/azzerative/iot-kurban-web-admin/blob/12031b10bdba6e03b22cb0e01564ad7841723489/src/providers/dataProvider.ts#L11
            options.headers.set('Authorization', `Bearer ${accessToken}`)
        }
    }
    return fetchUtils.fetchJson(url, options)
}
const dataProviders = dataProvider(`${process.env.REACT_APP_API_BASE_PATH}/v1`, httpClient);

export const App: React.FC = () => {

    return (
        <Admin
            authProvider={authProvider}
            dataProvider={dataProviders}
            i18nProvider={i18nProvider}
            history={history}
            layout={MyLayout}
            theme={theme}
            customRoutes={CustomRoutes}
            loginPage={Login}
        >
            <Resource name="estimates" options={{label: '見積管理'}} list={Estimates} show={EstimatesId}/>
            <Resource name="estimatesCharges" options={{label: '見積管理'}} show={EstimatesCharges}/>
            <Resource name="taxByEnvironmentalPerformances" options={{label: '環境性能割'}}
                      list={TaxByEnvironmentalPerformancesList}/>
            <Resource name="generalCharges" options={{label: '諸費用全般設定'}} show={GeneralChargesShow}
                      edit={GeneralChargesEdit}/>
            <Resource name="carShippingPlaces" options={{label: '納車場所管理'}} list={CarShippingPlacesList}/>
            <Resource name="ecoCarTaxCuts" options={{label: 'エコカー減税適用項目管理'}} list={EcoCarTaxCutsList}/>
            <Resource name="templates" options={{label: 'テンプレート管理'}} list={TemplatesList} create={TemplateCreate}
                      edit={TemplateEdit}/>
            <Resource name="carInsurances" options={{label: '自賠責保険料'}} list={CarInsurancesList}/>
            <Resource name="carInsuranceClasses"/>
            <Resource name="carShippingFees" options={{label: '納車費用'}} list={CarShippingFeesList}/>
            <Resource name="products" options={{label: '商品管理'}} list={ProductList} create={ProductCreate}
                      edit={ProductEdit}/>
            <Resource name="productCategories" options={{label: '商品カテゴリ'}} list={ProductCategoriesListComponent}/>
            <Resource name="productCategories" options={{label: '商品カテゴリ'}}/>
            <Resource name="productImages"/>
            <Resource name="productMountableCarModels"/>
            <Resource name="makers"/>
            <Resource name="aeroSeries" options={{label: 'エアロシリーズ管理'}} list={AeroSeriesListComponent}
                      edit={AeroSeriesEdit} create={AeroSeriesCreate}/>
            <Resource name="cars" options={{label: '車種管理'}} list={CarsList} edit={CarsEdit} create={CarsCreate}/>
            <Resource name="carModels" options={{label: 'モデル管理'}} list={CarModelsList} create={CarModelsCreate}
                      edit={CarModelsEdit} show={CarGradesList}/>
            <Resource name="setting" options={{label: '設定'}} show={SettingShow} edit={SettingEdit}/>
            <Resource name="consumptionTaxes" options={{label: '消費税設定'}} list={ConsumptionTaxesList}/>
            <Resource name="weightTaxes" options={{label: '重量税'}} list={weightTaxesList}/>
            <Resource name="weightTaxWeightClasses"/>
            <Resource name="carGrades" options={{label: 'グレード'}} list={CarGradesList} create={CarGradesCreate}
                      edit={CarGradesEdit}
                      show={CarColorList}
            />
            <Resource name="carColors" options={{label: 'カラー'}} list={CarColorList} create={CarColorsCreateForm}
                      edit={CarColorsEditForm}/>
            <Resource name="autoMobileTaxClasses"/>
            <Resource name="completeCars" list={CompleteCarsList} edit={CompleteCarsFormEdit}
                      create={CompleteCarsFormCreate} options={{label: 'コンプリートカー'}}/>
            <Resource name='completeCarSelectableCarGrades'/>
            <Resource name="autoMobileTaxes" options={{label: '自動車税'}} list={AutoMobileTaxesList}/>
            <Resource name="autoMobileTaxClasses"/>
            <Resource name="registrationFees" options={{label: '登録手続費用'}} list={RegistrationFeeList}/>
            {/*<Resource name="estimates" options={{ label: '見積管理' }} list={Estimates} />*/}

            <Resource name="customers"/>
            <Resource name="numberRegistrationLocation"/>
            <Resource name="shops" options={{label: '店舗'}} list={ShopsListComponent}/>
            <Resource name="users" options={{label: 'スタッフ'}} list={UsersListComponent}/>
            <Resource name="productVariants" options={{label: '商品規格管理'}}/>
            <Resource name="jNumberAndInsetRecommendation" options={{label: 'J数・インセット設定管理'}}
                      list={JNumberAndInsetRecommendationListComponent}/>
            <Resource name="estimates"/>
            {/*<Resource name={'users'}/>*/}
            <Resource name={'recommendedCarNonMasterDefaultProducts'}
                      create={RecommendedCarNonMasterDefaultProductCreate} edit={RecommendedCarNonMasterDefaultProductEdit} options={{label: 'マスタ外商品'}}/>
            <Resource name="recommendedCars" options={{label: '特選車管理'}} list={RecommendedCarsListComponent}
                      edit={RecommendedCarsEdit} create={RecommendedCarsCreate}/>
            <Resource name="recommendedCarRelatedCarSettings" options={{label: '関連特選車'}}
                      list={RecommendedCarRelatedCarSettingListComponent}/>
            <Resource name="variants"/>
            <Resource name="variantClasses" options={{label: '規格分類管理'}} list={VariantClassesListComponent}
                      edit={VariantClassesEditForm} create={VariantClassesCreateForm} show={VariantItemsListComponent}/>
            <Resource name="variantItems" list={VariantItemsListComponent} create={VariantItemCreate} edit={VariantItemEdit} options={{label: '規格項目管理'}}/>
            <Resource name="productVariants" options={{label: '商品規格管理'}}/>
            <Resource name="noCombinationVariants"/>
            <Resource name="variantItemProductPrice" options={{label: '商品別規格項目価格設定'}}/>
            <Resource name="productVariantItemImageCombination" options={{label: '規格項目画像管理'}}
                      list={ProductVariantItemImagesList}/>
            <Resource name="brands" options={{label: 'ブランド管理'}} create={BrandsCreate} edit={BrandsEdit}
                      list={BrandsList}/>
        </Admin>
    );
};
export default App;
