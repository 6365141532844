import {firebaseStorage} from "../config/firebase";
import {FileWithDownloadUrl} from "../modules/temporaryFileModule";

export const uploadFile = async (directory: string, dataBase64: string | ArrayBuffer | null | undefined, fileName: string, contentType: string, onSuccess?: () => void) => {
    let storageRef = firebaseStorage.ref(directory);
    let ref = storageRef.child(fileName);
    if (dataBase64 && typeof dataBase64 === "string") {

        let uploadTask = ref.putString(dataBase64, 'data_url', {contentType: contentType});

        return uploadTask.then(
            async (snapshot) => {
                if (onSuccess) {
                    onSuccess();
                }
                return snapshot;
            },
            (failedReason) => {
                console.log('Error:' + failedReason.message)
            });
    }
}

export const listFiles = async (directory: string) => {
    const storageRef = firebaseStorage.ref(directory);
    const list = await storageRef.listAll();
    return list.items;
};

export const listFolders = async (directory: string) => {
    const storageRef = firebaseStorage.ref(directory);
    const list = await storageRef.listAll();
    return list.prefixes;
};

export const showFiles = async (directory: string) => {
    const array = new Array<FileWithDownloadUrl>();
    const list = await listFiles(directory);
    list.map((item) => {
        if (item) {
            item.getDownloadURL().then((imageUrl) => {
                array.push({reference: item.fullPath, url: imageUrl});
            })
        }
    })
};

export const deleteFile = async (path: string) => {
    try {
        const storageRef = firebaseStorage.ref(path)
        await storageRef.delete()
    } catch (e) {
        console.warn(e);
    }
}

export const deleteFileFromUrl = async (url: string) => {
    try {
        const storageRef = firebaseStorage.refFromURL(url)
        await storageRef.delete()
    } catch (e) {
        console.warn(e);
    }
}
