import {downloadCSV, useDataProvider, useNotify, useRefresh} from "react-admin";
import React, {Dispatch, SetStateAction} from "react";
import {ApiNoCombinationVariant, ApiNoCombinationVariantToCsv} from "../../openapi";
import Papa, {ParseResult} from "papaparse";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

interface NoCombinationVariantHookProps {
    productId: number;
    carGradeId: number;
    setImportCsv?: Dispatch<SetStateAction<boolean>>;
    setError?: Dispatch<SetStateAction<Array<string>>>;
}

const useNoCombinationVariantHooks = (props: NoCombinationVariantHookProps) => {

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const csvColumns = [`規格項目ID1`, '規格項目名1', `規格項目ID2`, '規格項目名2', `規格項目ID3`, '規格項目名3', `規格項目ID4`, '規格項目名4', `規格項目ID5`, '規格項目名5',];

    enum csvItems {
        variantItemId1 = '規格項目ID1',
        variantItemId2 = '規格項目ID2',
        variantItemId3 = '規格項目ID3',
        variantItemId4 = '規格項目ID4',
        variantItemId5 = '規格項目ID5',
        variantItemName1 = '規格項目名1',
        variantItemName2 = '規格項目名2',
        variantItemName3 = '規格項目名3',
        variantItemName4 = '規格項目名4',
        variantItemName5 = '規格項目名5',
    }

    const getNoCombinationVariants = async () => {
        const noCombinationVariants = await dataProvider.getList('noCombinationVariants',
            {
                pagination: {page: 1, perPage: 100000},
                sort: {field: 'id', order: 'asc'},
                filter: {productId: props.productId, carGradeId: props.carGradeId},
            });
        const array: React.SetStateAction<ApiNoCombinationVariant[]> = [];
        noCombinationVariants.data.map((data) => {
            array.push(data as ApiNoCombinationVariant);
        });
        return array
    }

    const exportNoCombinationVariantCsv = async () => {
        const noCombinationVariants = await getNoCombinationVariants();
        const body = getCsvData(noCombinationVariants)
        const csv = Papa.unparse(await body);
        const blob = new Blob(["\uFEFF" + csv], {
            type: 'text/csv; charset=utf-18'
        });
        downloadCSV(blob, 'noCombinationVariant');
    }

    const getCsvData = async (noCombinationVariants: ApiNoCombinationVariant[]) => {
        type CsvColumnValues = typeof csvColumns[number];
        type CsvColumnTypes = Record<CsvColumnValues, any>;
        const result: Array<CsvColumnTypes> = [];
        noCombinationVariants.map(async (noCombinationVariant) => {
            const value: CsvColumnTypes = {}
            value[csvItems.variantItemId1] = noCombinationVariant.variantItemId1;
            value[csvItems.variantItemName1] = noCombinationVariant.variantItemName1;
            value[csvItems.variantItemId2] = noCombinationVariant.variantItemId2;
            value[csvItems.variantItemName2] = noCombinationVariant.variantItemName2;
            value[csvItems.variantItemId3] = noCombinationVariant.variantItemId3;
            value[csvItems.variantItemName3] = noCombinationVariant.variantItemName3;
            value[csvItems.variantItemId4] = noCombinationVariant.variantItemId4;
            value[csvItems.variantItemName4] = noCombinationVariant.variantItemName4;
            value[csvItems.variantItemId5] = noCombinationVariant.variantItemId5;
            value[csvItems.variantItemName5] = noCombinationVariant.variantItemName5;

            result.push(value);
        })
        return result;
    }

    const handleUpload = async (file: File) => {
        if (props.setError) {
            props.setError([]);
        }
        type CsvColumnValues = typeof csvColumns[number];
        type CsvColumnTypes = Record<CsvColumnValues, any>;
        const importItems: Array<ApiNoCombinationVariantToCsv> = [];
        const errors: Array<string> = [];

        // @ts-ignore
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            async complete(results: ParseResult<CsvColumnTypes>, file?: File) {
                results.data.map((data, index) => {
                    if (data === undefined || data === null || data.toString().trim() === '') {
                        return;
                    }
                    const importItem: ApiNoCombinationVariantToCsv = {
                        variantItemId1: data[csvItems.variantItemId1],
                        variantItemId2: data[csvItems.variantItemId2],
                        variantItemId3: data[csvItems.variantItemId3],
                        variantItemId4: data[csvItems.variantItemId4],
                        variantItemId5: data[csvItems.variantItemId5],
                    }
                    if (!importItem.variantItemId1) {
                        return; // ignore empty line
                    }
                    importItems.push(importItem);
                })

                if (props.setImportCsv) {
                    props.setImportCsv(false)
                }
                if (errors.length === 0)
                    await handleSave(importItems);
                else if (props.setError) {
                    props.setError(errors)
                }
            }
        })
    }

    const handleSave = async (dataNoCombinationVariantToCsv: Array<ApiNoCombinationVariantToCsv>, counter = 0) => {
        if (counter > 5) {
            notify('notification.error', 'warning');
            return;
        }
        await Promise.all(dataNoCombinationVariantToCsv.map(async (dataNoCombinationVariant, index) => {
            const data = {
                carGradeId: props.carGradeId,
                productId: props.productId,
                variantItemId1: dataNoCombinationVariant.variantItemId1,
                variantItemId2: dataNoCombinationVariant.variantItemId2,
                variantItemId3: dataNoCombinationVariant.variantItemId3,
                variantItemId4: dataNoCombinationVariant.variantItemId4,
                variantItemId5: dataNoCombinationVariant.variantItemId5,
            }
            if (index === 0) {
                await dataProvider.create('noCombinationVariants',
                    {data: {...data, isCsvImport: true}}
                );

            } else {
                await dataProvider.create('noCombinationVariants', {
                    data: data,
                });
            }
        }));
        const results = await dataProvider.getList(`noCombinationVariants`, {
            pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
            sort: {field: 'id', order: 'asc'},
            filter: {
                carGradeId: props.carGradeId,
                productId: props.productId,
            },

        });
        if (results.data.length != dataNoCombinationVariantToCsv.length) {
            console.warn("retrying to save noCombinationVariants");
            await handleSave(dataNoCombinationVariantToCsv, counter + 1);
        }
        notify('notification.saved');
        refresh();
    }

    return {
        getNoCombinationVariants,
        exportNoCombinationVariantCsv,
        handleUpload,
        handleSave,
    }
}

export default useNoCombinationVariantHooks;
