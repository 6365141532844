import React, {useState} from "react";
import {useStore} from "react-redux";
import {useForm} from "react-final-form";
import {Grid} from "@material-ui/core";
import {Confirm, ReferenceInput, SelectInput} from "react-admin";
import {ApiTemplate} from "../../openapi";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";


const CarFormSelectTemplate: React.FC = () => {
    const store = useStore();
    const form = useForm();
    const templates = store.getState()['admin']['resources']['templates']['data'];
    const [selectedTemplate, setSelectedTemplate] = useState<ApiTemplate>();

    const [openConfirm, setOpenConfirm] = useState(false);

    const onChangeTemplate = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const template = templates[Number(e.target.value)];
        await setSelectedTemplate(template);
        const note = form.getState().values['note'];
        if (note && form.getState().values['note'] !== '') {
            setOpenConfirm(true);
        } else {
            updateForm(template.body);
        }
    }

    const updateForm = (value: string) => {
        form.change('note', value);
    }
    const updateNote = () => {
        setOpenConfirm(false);
        if (selectedTemplate) {
            updateForm(selectedTemplate.body);
        }
    }

    const onClose = () => {
        setOpenConfirm(false);
        form.change('templateId', undefined);
    }
    return (
        <Grid container={true}>
            <Confirm
                onConfirm={updateNote}
                title={'確認'}
                onClose={onClose}
                isOpen={openConfirm}
                content={'備考を上書きしますか？'}
            />
            <ReferenceInput
                label="登録済みテンプレート選択"
                source="templateId"
                reference="templates"
                sort={{field: 'id', order: 'ASC'}}
                perPage={MAX_FETCH_DATA_SIZE}
                onChange={onChangeTemplate}
            >
                <SelectInput source="name" fullWidth={true}/>
            </ReferenceInput>
        </Grid>
    )
}

export default CarFormSelectTemplate;
