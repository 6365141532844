import React from 'react';
import {Container, Grid} from '@material-ui/core';
import GoBack from "../../common/GoBackComponent";
import {AppBar} from "react-admin";
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import useEstimate from "./estimateHook";

const EstimateChargeLabel = (props: { estimateId: number, chargeName?: string }) => {
    return (
        <div>
            <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        estimateId={props.estimateId}
                    />
                    - &gt;
                    {props.chargeName}
                </div>
            </AppBar>
        </div>
    );
};

const EstimatesCharges: React.FC = (props: any) => {
    const {estimate} = useEstimate(props.id);
    return (
        <Container maxWidth="xl">
            <div className={'w-100 mt-30 mb-30'}>
                <EstimateChargeLabel estimateId={props.id} chargeName={'諸費用'}/>
                <GoBack className={'float-right mr-30'}/>
            </div>
            {estimate && <Grid container={true} spacing={3} className={'w-100 ml-10'}>
                <Grid item={true} xs={12} sm={6} md={4} lg={4} className={'mb-30'}>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div className={'font-18 font-bold mb-50'}>非課税諸費用計</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div
                                className={'text-right font-18 font-bold'}>{Number(estimate.taxableExpenses).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>自動車税（
                                {Number(estimate.autoMobileTaxStartMonth)}
                                月より納車）
                            </div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div className={'text-right'}>{Number(estimate.autoMobileTaxFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>環境性能割</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.taxByEnvironmentalPerformancesFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>重量税</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.weightTaxFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>印紙税（均一価格）</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.stampDuty).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>自賠責保険料（37ヵ月）</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.carInsuranceFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={3} sm={3} md={3} lg={3}>
                            <div>法定費用</div>
                        </Grid>
                        <Grid item={true} xs={7} sm={7} md={7} lg={7}>
                            <div>法定費用</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.legalFeeInspectionRegistration).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={3} sm={3} md={3} lg={3}>
                            <div>&nbsp;</div>
                        </Grid>
                        <Grid item={true} xs={7} sm={7} md={7} lg={7}>
                            <div>車庫証明証紙代（均一価格）</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.legalFeeGarageCertificatePaperFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={3} sm={3} md={3} lg={3}>
                            <div>&nbsp;</div>
                        </Grid>
                        <Grid item={true} xs={7} sm={7} md={7} lg={7}>
                            <div>下取車</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.legalFeeTradeIn).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>ナンバー代（均一価格）</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.numberFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>リサイクル預託金合計</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.recyclingDepositFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                            <div>&nbsp;</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100 mt-30'}>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100 mt-30'}>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={4} className={'mb-30'}>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div className={'font-18 font-bold mb-50'}>課税諸費用計</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div
                                className={'text-right font-18 font-bold'}>{Number(estimate.taxExemptExpenses).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={3} sm={3} md={3} lg={3}>
                            <div>代行費用</div>
                        </Grid>
                        <Grid item={true} xs={7} sm={7} md={7} lg={7}>
                            <div>検査登録（届出）（均一価格）</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.agentFeeInspectionRegistration).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={3} sm={3} md={3} lg={3}>
                            <div>&nbsp;</div>
                        </Grid>
                        <Grid item={true} xs={7} sm={7} md={7} lg={7}>
                            <div>車庫証明（均一価格）</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.agentFeeGarageCertificatePaperFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={3} sm={3} md={3} lg={3}>
                            <div>&nbsp;</div>
                        </Grid>
                        <Grid item={true} xs={7} sm={7} md={7} lg={7}>
                            <div>下取車（均一価格）</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.agentFeeTradeIn).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>リサイクル資金管理料金</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div
                                className={'text-right'}>{Number(estimate.recyclingFundManagementFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>一般希望番号申請費</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div className={'text-right'}>{Number(estimate.selectiveNumberFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>製作車両移動費用</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div className={'text-right'}>{Number(estimate.carShippingFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>登録都道府県</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div className={'text-right'}>{estimate.numberRegistrationLocationName}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>登録手続費用</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div className={'text-right'}>{Number(estimate.registrationFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>納車費用</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div className={'text-right'}>{Number(estimate.shippingFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>構造変更手続費用</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div
                                className={'text-right'}>{Number(estimate.totalStructuralProcedureFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>アライメント調整費用</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div
                                className={'text-right'}>{Number(estimate.totalAlignmentAdjustingFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>中古車整備費用</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div
                                className={'text-right'}>{Number(estimate.usedCarMaintenanceFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100 mt-30'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                            <div>&nbsp;</div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item={true} xs={12} sm={6} md={4} lg={4} className={'mb-30'}>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div className={'font-18 font-bold mb-50'}>リサイクル計</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2}>
                            <div
                                className={'text-right font-18 font-bold'}>{Number(estimate.recyclingTotalFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>シュレッダーダスト料金</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.shredderDustFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>エアバック類料金</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.airbagsFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>フロン類料金</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.freonsFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>情報管理料金</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.informationManagementFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>{estimate.freeRecyclingTitle1 ? estimate.freeRecyclingTitle1 : ' '}</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{estimate.freeRecyclingFee1 ? Number(estimate.freeRecyclingFee1).toLocaleString() : ' '}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>{estimate.freeRecyclingTitle2 ? estimate.freeRecyclingTitle2 : ' '}</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{estimate.freeRecyclingFee2 ? Number(estimate.freeRecyclingFee2).toLocaleString() : ' '}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>{estimate.freeRecyclingTitle3 ? estimate.freeRecyclingTitle3 : ' '}</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{estimate.freeRecyclingFee3 ? Number(estimate.freeRecyclingFee3).toLocaleString() : ' '}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={3} sm={3} md={3} lg={3}>
                            <div>&nbsp;</div>
                        </Grid>
                        <Grid item={true} xs={7} sm={7} md={7} lg={7}>
                            <div>リサイクル預託金合計</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.recyclingDepositFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={3} sm={3} md={3} lg={3}>
                            <div>&nbsp;</div>
                        </Grid>
                        <Grid item={true} xs={7} sm={7} md={7} lg={7}>
                            <div>リサイクル資金管理料</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>{Number(estimate.recyclingFundManagementFee).toLocaleString()}</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={10} sm={10} md={10} lg={10}>
                            <div>&nbsp;</div>
                        </Grid>
                        <Grid item={true} xs={2} sm={2} md={2} lg={2} className={'text-right'}>
                            <div>&nbsp;</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}/>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                            <div>&nbsp;</div>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} className={'w-100'}>
                        <Grid item={true} xs={4} className={'font-18 font-bold'}>
                            <div>諸費用合計</div>
                        </Grid>
                        <Grid item={true} xs={8} className={'font-18 font-bold text-right'}>
                            {Number(estimate.totalExpenses).toLocaleString()}円
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>}

        </Container>

    )
};

export default EstimatesCharges;
