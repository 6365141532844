import React from "react";
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import {BooleanField, List, maxLength, minValue, NullableBooleanInput, number, required, TextField,} from "react-admin";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";

const ShopsListComponent: React.FC = (props: any) => {
    return (
        <List
            {...props}
            hasCreate
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <EditableDatagrid
                undoable={true}
                createForm={<DataForm/>}
                editForm={<DataForm/>}
                noDelete={false}
                className={'editableListTable'}
            >
                <TextField source="name" label={'名称'}/>
                <BooleanField source="available" label={'利用可能'}/>
                <TextField source="position" label={'表示順序'}/>
            </EditableDatagrid>
        </List>
    )
}

const DataForm: React.FC = (props) => {
    const validateName = [required(), maxLength(50)];
    const validateNumber = [required(), number(), minValue(0)];
    return (
        <RowForm
            {...props}
            submitOnEnter={false}
        >
            <TextInputForJapanese source="name" validate={validateName} label={'名称'}/>
            <NullableBooleanInput source="available" validate={required()} label={'利用可能'}/>
            <TextInputForJapanese source="position" validate={validateNumber} label={'表示順序'}/>
        </RowForm>
    )
};

export default ShopsListComponent;