import {Button, FileField, FileInput} from "react-admin";
import React, {useState} from "react";
import useProductVariantHooks from "./productVariantHooks";
import {useForm} from "react-final-form";
import Alert from '@material-ui/lab/Alert';


interface ProductVariantCsvImportComponentProps {
    productId: number;
}

const ProductVariantCsvImportComponent: React.FC<ProductVariantCsvImportComponentProps> = (props) => {
    const form = useForm();

    const [importCsv, setImportCsv] = useState(false);
    const [error, setError] = useState<Array<string>>([])
    const {
        handleUpload,
    } = useProductVariantHooks({
        productId: props.productId,
        form: form,
        setImportCsv: setImportCsv,
        setError: setError,
    });
    return (
        <>
            {!importCsv &&
                <Button variant="contained" color="default" className={'ml-30'}
                        onClick={() => setImportCsv(true)} label={'CSVインポート'}/>
            }
            {importCsv &&
                <FileInput source="files" label="CSVインポート" accept="text/csv" onChange={handleUpload}>
                    <FileField source="src" title="CSVインポート"/>
                </FileInput>
            }
            {error && error.length > 0 &&
                <Alert severity="error">
                    <ul>
                        {error.map((value) => (
                            <li>{value}</li>
                        ))}
                    </ul>
                </Alert>
            }
        </>
    );
}

export default ProductVariantCsvImportComponent;
