import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import {AppBar, Button, useGetOne} from 'react-admin';
import {Form} from 'react-final-form';
import BreadcrumbTrail from "../../common/BreadcrumbTrailComponent";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {ApiProduct, ApiVariantClass} from "../../openapi";
import TableBody from "@material-ui/core/TableBody";
import {useParams} from "react-router-dom";
import useVariantItemProductPriceHooks from "./variantItemProductPricesHooks";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import GoBack from "../../common/GoBackComponent";
import {Box} from "@material-ui/core";
import {useExportHooks} from "./exportHooks";
import VariantItemProductPriceCsvImportComponent from "./VariantItemProductPriceCsvImportComponent";


interface ScreenNameProps {
    product: ApiProduct;
}

const VariantItemProductPriceComponent: React.FC = () => {
    const {productId} = useParams<{ productId: string }>();
    const product = useGetOne('products', productId).data as ApiProduct;

    const {
        dataVariantClasses,
        data,
        fetchApiData,
        save,
        inputChanged,
        showExportImportButton,
    } = useVariantItemProductPriceHooks({
        productId: Number(productId)
    })

    const {
        exportData,
    } = useExportHooks({
        productId: Number(productId),
    });

    const exportMe = () => {
        exportData(dataVariantClasses, data);
    }

    const fetchInitializeData = () => {
        const fn = async () => {
            await fetchApiData();
        }
        fn().then();
    }

    useEffect(fetchInitializeData, []);

    const ScreenName: React.FC<ScreenNameProps> = (props) => {
        return (
            <AppBar {...props} title={'foo'}>
                <div className={'app-bar-title'}>
                    <BreadcrumbTrail
                        product={props.product}
                        isVariantItemProductPrice={true}
                    />
                </div>
            </AppBar>
        );
    }

    return (
        <Form onSubmit={save} render={({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
                <ScreenName product={product}/>
                <Grid container={true} className={'d-flex mt-15'}>
                    <Grid>
                        <Box mb={2}>
                            <GoBack/>
                            <Button label={'保存'} type={"submit"} variant="contained" color={"primary"}
                                    className={'ml-10'}/>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            {showExportImportButton &&
                                <>
                                    <Button variant="contained"
                                            onClick={exportMe}
                                            color={'default'}
                                            label={'CSVエクスポート'}/>
                                    <VariantItemProductPriceCsvImportComponent
                                        productId={Number(productId)}
                                    />
                                </>
                            }
                            &nbsp;
                        </Box>
                    </Grid>
                </Grid>
                <Paper className={'mt-15 b-shadow'}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {dataVariantClasses!!.map((row: ApiVariantClass) => {
                                        return (
                                            <>
                                                {row.affectPrice &&
                                                    <TableCell align="left" key={row.id}> {row.name} </TableCell>
                                                }
                                            </>
                                        );
                                    })}
                                    {/*金額*/}
                                    <TableCell align="left">売価</TableCell>
                                    <TableCell align="left">原価</TableCell>
                                    <TableCell align="left">部品売価</TableCell>
                                    <TableCell align="left">定価</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data!!.map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            {dataVariantClasses.map((variantClass) => {
                                                    let existing = false
                                                    return (
                                                        row.variantItem?.map((variantItem, index) => {
                                                            if (variantClass.id === variantItem.variantClassId) {
                                                                existing = true
                                                                return (
                                                                    <TableCell align="left" key={index}>
                                                                        <div className={'d-flex'}>
                                                                            {variantItem.name}
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            } else if (!existing && ((row.variantItem?.length ?? 1) - 1 === index)) {
                                                                return (
                                                                    <TableCell align="left" key={index}>
                                                                        <div className={'d-flex'}>
                                                                        </div>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        })
                                                    )
                                                }
                                            )
                                            }
                                            <TableCell align="left" key={index}>
                                                <NumberInputCommon
                                                    defaultValue={row.price ?? 0}
                                                    className={'w-70 input-filled-basic'}
                                                    type="number"
                                                    inputProps={{min: 0, step: 1}}
                                                    variant='filled'
                                                    label={"売価"}
                                                    onChange={(event) => {
                                                        inputChanged({value: event.target.value}, {id: row.variantItemProductPriceId}, {property: 'price'})
                                                    }}
                                                    source={"price" + row.variantItemProductPriceId!!.toString()}
                                                />
                                            </TableCell>
                                            <TableCell align="left" key={index}>
                                                <NumberInputCommon
                                                    defaultValue={row.cost ?? 0}
                                                    className={'w-70 input-filled-basic'}
                                                    type="number"
                                                    inputProps={{min: 0, step: 1}}
                                                    variant='filled'
                                                    label={"原価"}
                                                    onChange={(event) => {
                                                        inputChanged({value: event.target.value}, {id: row.variantItemProductPriceId}, {property: 'cost'})
                                                    }}
                                                    source={"cost" + row.variantItemProductPriceId!!.toString()}
                                                />
                                            </TableCell>
                                            <TableCell align="left" key={index}>
                                                <NumberInputCommon
                                                    defaultValue={row.priceForPartsOnly ?? 0}
                                                    className={'w-70 input-filled-basic'}
                                                    type="number"
                                                    inputProps={{min: 0, step: 1}}
                                                    variant='filled'
                                                    label={"部品売価"}
                                                    onChange={(event) => {
                                                        inputChanged({value: event.target.value}, {id: row.variantItemProductPriceId}, {property: 'priceForPartsOnly'})
                                                    }}
                                                    source={"priceForPartsOnly" + row.variantItemProductPriceId!!.toString()}
                                                />
                                            </TableCell>
                                            <TableCell align="left" key={index}>
                                                <NumberInputCommon
                                                    defaultValue={row.originalPrice ?? 0}
                                                    className={'w-70 input-filled-basic'}
                                                    type="number"
                                                    inputProps={{min: 0, step: 1}}
                                                    variant='filled'
                                                    label={"定価"}
                                                    onChange={(event) => {
                                                        inputChanged({value: event.target.value}, {id: row.variantItemProductPriceId}, {property: 'originalPrice'})
                                                    }}
                                                    source={"originalPrice" + row.variantItemProductPriceId!!.toString()}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Grid container={true} className={'d-flex mt-15'} justify={'flex-end'}>
                    <Grid>
                        <Button label={'保存'} type={"submit"} variant="contained" color="primary"/>
                    </Grid>
                </Grid>
            </form>
        )}/>
    );
}

export default VariantItemProductPriceComponent;
