import React, {Dispatch, SetStateAction} from "react";
import {
    ApiCar,
    ApiJNumberAndInsetAdvancedSetting,
    ApiMaker,
    ApiProductMountableCarModel,
    ApiProductVariantsAdmin,
    ApiVariantClass,
    ApiVariantItem
} from "../../openapi";
import Papa, {ParseResult} from "papaparse";
import {downloadCSV, useDataProvider, useNotify, useRefresh} from "react-admin";
import {FormApi} from "final-form";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

interface ProductVariantHookProps {
    productId: number;
    form?: FormApi;
    setImportCsv?: Dispatch<SetStateAction<boolean>>;
    setError?: Dispatch<SetStateAction<Array<string>>>;
}


const useProductVariantHooks = (props: ProductVariantHookProps) => {

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const getProductVariantsAdmin = async (carGradeId: number | undefined) => {
        const productVariantsAdmins = await dataProvider.getList('productVariants/admin',
            {
                pagination: {page: 1, perPage: 100000},
                sort: {field: 'id', order: 'asc'},
                filter: {productId: props.productId, carGradeId: carGradeId},
            });
        const array: React.SetStateAction<ApiProductVariantsAdmin[]> = [];
        productVariantsAdmins.data.map((data) => {
            array.push(data as ApiProductVariantsAdmin);
        });
        return array
    }

    const getVariantClasses = async () => {
        const variantClasses = await dataProvider.getList('variantClasses',
            {
                pagination: {page: 1, perPage: 10000},
                sort: {field: 'id', order: 'asc'},
                filter: {affectJNumberAndInset: true},
            });
        const array: React.SetStateAction<ApiVariantClass[]> = [];
        variantClasses.data.map((data) => {
            array.push(data as ApiVariantClass);
        });
        return array;
    }

    const getSuspensionVariantClasses = async () => {
        const variantClasses = await dataProvider.getList('variantClasses',
            {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'position', order: 'asc'},
                filter: {suspension: true},
            });
        const array: React.SetStateAction<ApiVariantClass[]> = [];
        variantClasses.data.map((data) => {
            array.push(data as ApiVariantClass);
        });
        return array;
    }

    const getSuspensionVariantClassItems = async () => {
        const variantClasses = await getSuspensionVariantClasses();
        const variantItems = await dataProvider.getList('variantItems',{
            pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
            sort: {field: 'position', order: 'asc'},
            filter: {variantClassId: variantClasses[0].id}
        });
        const array: React.SetStateAction<ApiVariantItem[]> = [];
        variantItems.data.map((data) => {
            array.push(data as ApiVariantItem);
        });
        return array;
    }

    const exportJNumberAndInsetRecommendationCsv = async (carGradeId: number | undefined) => {
        const productVariantsAdmins = await getProductVariantsAdmin(carGradeId);
        let variantClasses = await getVariantClasses();
        variantClasses = sortVariantClasses(variantClasses);
        const body = getCsvData(productVariantsAdmins, variantClasses);
        const csv = Papa.unparse(await body);
        const blob = new Blob(["\uFEFF" + csv], {
            type: 'text/csv; charset=utf-18'
        });
        downloadCSV(blob, 'productVariant');
    }

    const sortVariantClasses = (variantClasses: ApiVariantClass[]) => {
        return variantClasses.sort((a, b) => a.id!! > b.id!! ? 1 : -1)
    }

    const sortJNumberAndInsertAdvancedSettings = (jNumberAndInsetAdvancedSettings: Array<ApiJNumberAndInsetAdvancedSetting>) => {
        return jNumberAndInsetAdvancedSettings!!.sort((a, b) => a.variantItem?.variantClassId!! > b.variantItem?.variantClassId!! ? 1 : -1)
    }

    const getCarModels = async () => {
        const carModels = await dataProvider.getList('productMountableCarModels/' + props.productId + '/ForProductVariant',
            {
                pagination: {page: 1, perPage: 10000},
                sort: {field: 'id', order: 'asc'},
                filter: {productId: props.productId},
            });
        const fetchedCarModel = carModels.data as ApiProductMountableCarModel[]

        return fetchedCarModel
    }

    const getCars = async () => {
        const cars = await dataProvider.getList('cars',
            {
                filter: {},
                pagination: {page: 1, perPage: 10000},
                sort: {field: 'id', order: 'asc'}
            });
        const fetchedCar = cars.data as ApiCar[]

        return fetchedCar
    }
    const getMakers = async () => {
        const makers = await dataProvider.getList('makers',
            {
                filter: {},
                pagination: {page: 1, perPage: 10000},
                sort: {field: 'id', order: 'asc'}
            });
        const fetchedMaker = makers.data as ApiMaker[]

        return fetchedMaker
    }

    function getVariantClassName(variantClasses: ApiVariantClass[], jNumberAndInsetAdvancedSetting: ApiJNumberAndInsetAdvancedSetting) {
        return variantClasses.filter((variantClass) => variantClass.id === jNumberAndInsetAdvancedSetting.variantItem?.variantClassId!)[0].name
    }

    async function getCsvColumns(variantClasses: ApiVariantClass[]) {
        const csvColumns = ['id', 'グレード名'];
        variantClasses.map((variantClass) => {
            csvColumns.push(variantClass.name);
        });
        [csvItems.jNumberOfFrontWheel, csvItems.jNumberOfRearWheel, csvItems.insetOfFrontWheel, csvItems.insetOfRearWheel, csvItems.recommendName, csvItems.recommendNote]
            .map((value) => csvColumns.push(value));
        return csvColumns;
    }

    enum csvItems {
        gradeName = 'グレード名',
        jNumberOfFrontWheel = 'J数前輪',
        jNumberOfRearWheel = 'J数後輪',
        insetOfFrontWheel = 'インセット前輪',
        insetOfRearWheel = 'インセット後輪',
        recommendName = 'お勧め名',
        recommendNote = 'お勧め説明',
    }

    const getCsvData = async (productVariantsAdmins: ApiProductVariantsAdmin[], variantClasses: ApiVariantClass[]) => {
        const csvColumns = await getCsvColumns(variantClasses);
        type CsvColumnValues = typeof csvColumns[number];
        type CsvColumnTypes = Record<CsvColumnValues, any>;
        const result: Array<CsvColumnTypes> = [];
        productVariantsAdmins.map((productVariantsAdmin => {
            const carGrade = productVariantsAdmin.jNumberAndInsetAdvancedSettings?.carGrade!!
            const value: CsvColumnTypes =
                {
                    id: productVariantsAdmin.id,
                    [csvItems.gradeName]: `${carGrade.carModel?.car?.name} ${carGrade.carModel?.name} ${carGrade.name}`
                };
            productVariantsAdmin.jNumberAndInsetAdvancedSettings?.data?.map((jNumberAndInsetAdvancedSetting) => {
                value[getVariantClassName(variantClasses, jNumberAndInsetAdvancedSetting)] = jNumberAndInsetAdvancedSetting.variantItem?.name
            });
            value[csvItems.jNumberOfFrontWheel] = productVariantsAdmin.jNumberOfFrontWheel;
            value[csvItems.jNumberOfRearWheel] = productVariantsAdmin.jNumberOfRearWheel;
            value[csvItems.insetOfFrontWheel] = productVariantsAdmin.insetOfFrontWheel;
            value[csvItems.insetOfRearWheel] = productVariantsAdmin.insetOfRearWheel;
            value[csvItems.recommendName] = productVariantsAdmin.recommendName;
            value[csvItems.recommendNote] = productVariantsAdmin.recommendNote;
            result.push(value);
        }))
        return result;
    }

    const handleUpload = async (file: File) => {
        if (props.setError) {
            props.setError([]);
        }
        const variantClasses = await getVariantClasses();
        const csvColumns = await getCsvColumns(variantClasses);
        type CsvColumnValues = typeof csvColumns[number];
        type CsvColumnTypes = Record<CsvColumnValues, any>;
        const importItems: Array<ApiProductVariantsAdmin> = [];
        const errors: Array<string> = [];
        // @ts-ignore
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            async complete(results: ParseResult<CsvColumnTypes>, file?: File) {
                results.data.map((data, index) => {
                    // Condition to ignore row with invalid data, typically a valid data will be represented by an ID
                    // so if ID is not present, we will ignore the row
                    if(data.id === undefined || data.id === null || data.id.toString().trim() === ''){
                        return;
                    }
                    const importItem: ApiProductVariantsAdmin = {
                        id: data.id,
                        jNumberOfFrontWheel: data[csvItems.jNumberOfFrontWheel],
                        jNumberOfRearWheel: data[csvItems.jNumberOfRearWheel],
                        insetOfFrontWheel: data[csvItems.insetOfFrontWheel],
                        insetOfRearWheel: data[csvItems.insetOfRearWheel],
                        recommendName: data[csvItems.recommendName],
                        recommendNote: data[csvItems.recommendNote],
                    }
                    if (!importItem.jNumberOfFrontWheel || !isFinite(importItem.jNumberOfFrontWheel)) {
                        errors.push(`${index + 2}行目の${csvItems.jNumberOfFrontWheel}の値が不正です。`);
                    }
                    if (!importItem.jNumberOfRearWheel || !isFinite(importItem.jNumberOfRearWheel)) {
                        errors.push(`${index + 2}行目の${csvItems.jNumberOfRearWheel}の値が不正です。`);
                    }
                    if (!importItem.insetOfFrontWheel || !isFinite(importItem.insetOfFrontWheel)) {
                        errors.push(`${index + 2}行目の${csvItems.insetOfFrontWheel}の値が不正です。`);
                    }
                    if (!importItem.insetOfRearWheel || !isFinite(importItem.insetOfRearWheel)) {
                        errors.push(`${index + 2}行目の${csvItems.insetOfRearWheel}の値が不正です。`);
                    }
                    importItems.push(importItem);
                })
                props.form?.change('files', undefined);
                if (props.setImportCsv) {
                    props.setImportCsv(false)
                }
                if (errors.length === 0)
                    await handleSave(importItems, undefined);
                else if (props.setError) {
                    props.setError(errors);
                }
            }
        });

    }

    const handleSave = async (dataProductVariantAdmins: Array<ApiProductVariantsAdmin>, carGradeId: number | undefined) => {
        for (const productVariant of await getProductVariantsAdmin(carGradeId)) {
            for (const dataProductVariantAdmin of dataProductVariantAdmins!!) {
                if (productVariant.id === dataProductVariantAdmin.id) {
                    if (JSON.stringify(Object.entries(productVariant).sort()) !== JSON.stringify(Object.entries(dataProductVariantAdmin).sort())) {
                        await dataProvider.create('productVariants/mountableByInset/' + dataProductVariantAdmin.id!!.toString(), {
                            data: {
                                recommendName: dataProductVariantAdmin.recommendName,
                                jNumberOfFrontWheel: dataProductVariantAdmin.jNumberOfFrontWheel,
                                jNumberOfRearWheel: dataProductVariantAdmin.jNumberOfRearWheel,
                                insetOfFrontWheel: dataProductVariantAdmin.insetOfFrontWheel,
                                insetOfRearWheel: dataProductVariantAdmin.insetOfRearWheel,
                                recommendNote: dataProductVariantAdmin.recommendNote
                            }
                        });
                    }
                }
            }
        }
        notify('notification.saved');
        refresh();
    }


    return {
        getProductVariantsAdmin,
        getVariantClasses,
        exportJNumberAndInsetRecommendationCsv,
        sortVariantClasses,
        sortJNumberAndInsertAdvancedSettings,
        handleUpload,
        handleSave,
        getCarModels,
        getMakers,
        getCars,
        getSuspensionVariantClassItems
    }
}
export default useProductVariantHooks;
