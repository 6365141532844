import React, {Dispatch, forwardRef, SetStateAction} from "react";
import {Box, Grid, Paper, Tab, Tabs} from "@material-ui/core";
import {useDefaultVariantItemHooks} from "./defaultVariantHook";
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';

export interface DefaultVariantItemFunctions {
    save: (
        completeCarId: number,
        defaultProductIds: number[],
        defaultVariantItemIds: number[],
    ) => void;
}

export interface DefaultVariantItemProps {
    carGradeIds?: number[];
    completeCarId?: number;
    setCalcPrice: Dispatch<SetStateAction<number>>;
    calcPrice: number;
    variantItemInitialValues: number[] | undefined
    setVariantItemInitialValues: Dispatch<SetStateAction<number[] | undefined>>;
    defaultVariantItemIds: number[] | undefined;
    setDefaultVariantItemIds: Dispatch<SetStateAction<number[] | undefined>>;
    selectAffectPriceVariantItemIds: number[] | undefined;
    setSelectAffectPriceVariantItemIds: Dispatch<SetStateAction<number[] | undefined>>;
    defaultProductIds: number[] | undefined;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modifiedProducts: boolean;
}

export const DefaultVariantItemComponent: React.ForwardRefRenderFunction<DefaultVariantItemFunctions, DefaultVariantItemProps> = (props, ref) => {
    const {
        tabValue,
        handleTabChange,
        classes,
        globalClasses,
        productVariants,
        variantClassVariantItems,
        onChangeCheckBox
    } = useDefaultVariantItemHooks({
        ref: ref,
        carGradeIds: props.carGradeIds,
        completeCarId: props.completeCarId,
        setCalcPrice: props.setCalcPrice,
        calcPrice: props.calcPrice,
        variantItemInitialValues: props.variantItemInitialValues,
        setVariantItemInitialValues: props.setVariantItemInitialValues,
        defaultVariantItemIds: props.defaultVariantItemIds,
        setDefaultVariantItemIds: props.setDefaultVariantItemIds,
        selectAffectPriceVariantItemIds: props.selectAffectPriceVariantItemIds,
        setSelectAffectPriceVariantItemIds: props.setSelectAffectPriceVariantItemIds,
        defaultProductIds: props.defaultProductIds,
        open: props.open,
        setOpen: props.setOpen,
        modifiedProducts: props.modifiedProducts,
    });
    
    return (
        <div className={classes.root}>

            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11} className={'mt-20'}>
                <div>デフォルト規格</div>
            </Grid>

            <Paper className={globalClasses.scrollableTab}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant={"scrollable"}
                    scrollButtons="auto"
                >
                    {productVariants?.data?.map((productVariant) => (
                        <Tab
                            label={productVariant.variant?.name}
                            value={productVariant.variant?.id}
                        />
                    ))}
                </Tabs>
                {variantClassVariantItems?.map((variantClassVariantItem) => (
                    <div>
                        <Box ml={2}>
                            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11}
                                  className={'mt-20'}>
                                {variantClassVariantItem.name}
                            </Grid>

                            <Grid item spacing={0} xs={11} sm={11} md={11} lg={11} xl={11}>
                                <FormControl>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        defaultValue={props.variantItemInitialValues}
                                        onChange={onChangeCheckBox}>
                                        {variantClassVariantItem.carGrades?.map((carGrade) => {
                                            return (
                                                carGrade.variantItem?.map((variantItem) => {
                                                    return (
                                                        <FormControlLabel value={variantItem.id} control={
                                                            <Radio
                                                                checked={props.defaultVariantItemIds?.some(variantItemId => variantItemId == variantItem.id)}
                                                            />}
                                                                          label={variantItem.name}/>
                                                    )
                                                })
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Box>
                    </div>
                ))}
            </Paper>
        </div>
    )
}

export const DefaultVariantItem = forwardRef(DefaultVariantItemComponent);
