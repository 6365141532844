import React from 'react';
import {
    CreateButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    NullableBooleanInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useRecordContext,
} from 'react-admin';
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {Box, Grid} from '@material-ui/core';
import {DeleteWithConfirmIconButton} from '@react-admin/ra-editable-datagrid';
import {TextFieldWithCommas} from "../../common/TextFieldWithCommas";
import {makeStyles} from "@material-ui/core/styles";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";

const CompleteCarsList: React.FC = (props: any) => {
    return (
        <List
            {...props}
            hasCreate
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
            filters={<SearchFilterForm/>}
            actions={<ListActions {...props}/>}
        >
            <Datagrid rowClick={rowClick} optimized>
                <TextField source={'name'} label={'名称'}/>
                <ReferenceField reference={'carModels'} source={'carModelId'} label={'車種・モデル'} link={false}>
                    <CarNameModelName source={'name'}/>
                </ReferenceField>
                <TextFieldWithCommas source={'discountPrice'} label={'値引'} textAlign={'right'}/>
                <WhetherPublicField source={'whetherPublic'} label={'公開/非公開'}/>
                <TextFieldWithCommas source={'position'} label={'表示順序'} textAlign={'right'}/>
                <div className={'actionButtons'}>
                    <EditDataBtn {...props} />
                    <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                   className={'ml-30'}/>
                </div>
            </Datagrid>
        </List>
    )
};

const rowClick = () => {
    return 'edit';
};

const useStyles = makeStyles({
    toolbar: {
        marginTop: '100px !important'
    }
});
const ListActions: React.FC = (props: any) => {
    const classes = useStyles();
    return (
        <TopToolbar className={`mt-195-toolbar ${classes.toolbar}`}>
            <CreateButton {...props} />
        </TopToolbar>
    );
};

const WhetherPublicField = ({...record}) => {
    return (
        JSON.parse(`${record && record.record && record.record.whetherPublic}`) > 0 ? <span>公開</span> : <span>非公開</span>
    )
}

// const CarNameModelName = ({...record}) => {
//     return(
//         <span>{`${record && record.record.carName}`}・{`${record && record.record.name}`}</span>
//     )
// }

const CarNameModelName = ({...record}) => {
    const CarName = () => {
        return (
            <ReferenceField reference={'cars'} source={'carId'} label={'車種・モデル'} link={false}>
                <TextField source={'name'}/>
            </ReferenceField>
        );
    }
    return (
        <span><CarName/>{'・'}<span>{`${record && record.record.name}`}</span></span>
    )
}

const Choice = ({...record}) => {
    return (
        <span>{`${record && record.record.carName}`}・{`${record && record.record.name}`}</span>
    )
}


const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''}/>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};


const SearchFilterForm: React.FC = (props) => {
    return (
        <Filter {...props}>
            {/*// @ts-ignore*/}
            <Box bgcolor="none" p={5} id='box' className={'filterBox'} mt={2} alwaysOn>
                <Grid container={true} spacing={3}>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="name" label={'名称'} fullWidth alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput
                            source='whetherPublic'
                            label={'公開'}
                            fullWidth
                            nullLabel={''}
                            falseLabel="非公開"
                            className={'w-100'}
                            trueLabel="公開"/>
                    </Grid>
                    <Grid item={true} lg={3}>
                        <ReferenceInput
                            label="車種・モデル"
                            source="carModelId"
                            reference="carModels"
                            fullWidth
                            alwaysOn
                            perPage={MAX_FETCH_DATA_SIZE}
                            allowEmpty={true}>
                            <SelectInput optionText={<Choice/>}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInput source="discountPriceFrom" label={'値引き～'} fullWidth alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInput source="discountPriceTo" label={'～値引き'} fullWidth alwaysOn/>
                    </Grid>
                </Grid>
            </Box>
        </Filter>
    )
};

export default CompleteCarsList;
