import {useEffect, useState} from "react";
import {useDataProvider} from "react-admin";
import {
    ESTIMATE_PHASE,
    EstimateExportField,
    EstimateExportItem,
    EstimateProductExportField,
    EstimateProductExportItem
} from "../../config/const";
import {unparse as convertToCSV} from "papaparse";
import JSZip from "jszip";
import {saveAs} from 'file-saver';
import PaymentMethod from "../../config/PaymentMethod";
import {ApiEstimate, ApiEstimateProduct, ApiRecommendedCar, ApiUser, UsedCarType} from "../../openapi";
import moment from "moment";
import {priceWithTax} from "../../common/priceUtil";
import {checkPlusLabelNumber} from "../../utils/numberUtil";
import useSetting from "../setting/SettingHooks";


const useEstimate = (estimateId: number) => {
    const dataProvider = useDataProvider();
    const [estimate, setEstimate] = useState<ApiEstimate>()
    const [recommendedCar, setRecommendedCar] = useState<ApiRecommendedCar>();
    const {setting} = useSetting() ?? {};
    const [staff, setStaff] = useState<ApiUser>();

    useEffect(() => {
        const awaitFn = async () => {
            if (estimateId) {
                const fetchedEstimate = (await getEstimate()) as ApiEstimate;
                if (fetchedEstimate.recommendedCarId) {
                    const fetchedRecommendedCar = await getRecommendedCar(fetchedEstimate.recommendedCarId);
                    setRecommendedCar(fetchedRecommendedCar);
                }
                if(fetchedEstimate.userId) {
                    const fetchedStaff = await getStaff(fetchedEstimate.userId);
                    setStaff(fetchedStaff);
                }
                setEstimate(fetchedEstimate);
            }
        }
        awaitFn().then();

    }, [estimateId])

    const getEstimate = async () => {
        const data = await dataProvider.getOne('estimates', {
            id: Number(estimateId)
        });
        return data.data;
    }

    const doExport = (estimate: ApiEstimate) => {
        return !(estimate.totalExpenses === null) && !estimate.downloadFlag;
    }
    const saveDownloadFlag = async (data: ApiEstimate) => {
        if (doExport(data)) {
            const copied = {...data}
            copied.downloadFlag = true;
            delete copied.estimateProducts;
            const params = {
                id: copied.id!!,
                data: {...copied},
                previousData: {id: copied.id!!}
            }
            params.data.estimateVariantItemProductPrices = undefined
            params.data.estimateVariantItems = undefined
            await dataProvider.update('estimates', params)
        }
    }
    const handleExportData = async (item: ApiEstimate) => {
        let products: any = [];
        let estimatesForExport: any = [];
        let exportItem: EstimateExportItem = {
            見積ID: 0,
            見積番号: '',
            見積日時: '',
            フェーズ: '',
            見積合計金額: 0,
            原価合計: 0,
            値引き合計: 0,
            消費税率: 0,
            車種ID: 0,
            車種名: '',
            モデルID: 0,
            モデル名: '',
            コンプリートカーID: 0,
            コンプリートカー名: '',
            コンプリートカー売価: 0,
            コンプリートカー原価: 0,
            コンプリートカー説明文: '',
            グレードID: 0,
            グレード名: '',
            グレード売価: 0,
            グレード原価: 0,
            カラーID: 0,
            カラー名: '',
            カラー売価: 0,
            カラー原価: 0,
            ナンバー登録地ID: 0,
            ナンバー登録地名: '',
            納車場所ID: 0,
            納車場所名: '',
            お届け先: '',
            お支払い方法: '',
            自動車税ID: 0,
            自動車税: 0,
            自動車税月数: 0,
            環境性能割ID: 0,
            環境性能割税率: 0,
            環境性能割金額: 0,
            重量税ID: 0,
            重量税: 0,
            自賠責保険料ID: 0,
            自賠責保険料: 0,
            自賠責保険料月数: 0,
            法定費用_検査登録_届出: 0,
            法定費用_車庫証明紙代: 0,
            法定費用_下取車: 0,
            代行費用_検査登録_届出: 0,
            代行費用_車庫証明紙代: 0,
            代行費用_下取車: 0,
            一般希望番号申請費: 0,
            製作車両移動費用: 0,
            印紙代: 0,
            ナンバー代: 0,
            登録手続費用: 0,
            納車費用: 0,
            構造変更手続費用: 0,
            アライメント調整費用合計: 0,
            シュレッダーダスト料金: 0,
            エアバッグ料金: 0,
            フロン類料金: 0,
            情報管理料金: 0,
            リサイクル費用自由項目1タイトル: '',
            リサイクル費用自由項目1金額: 0,
            リサイクル費用自由項目2タイトル: '',
            リサイクル費用自由項目2金額: 0,
            リサイクル費用自由項目3タイトル: '',
            リサイクル費用自由項目3金額: 0,
            リサイクル資金管理料: 0,
            リサイクル預託金合計: 0,
            リサイクル計: 0,
            課税諸費用計: 0,
            非課税諸費用計: 0,
            諸費用合計: 0,
            顧客ID: 0,
            名字: '',
            名前: '',
            名字ふりがな: '',
            名前ふりがな: '',
            生年月日: '',
            種別: '',
            会社名: '',
            会社名ふりがな: '',
            代表取締役名字: '',
            代表取締役名前: '',
            代表取締役名字ふりがな: '',
            代表取締役名前ふりがな: '',
            郵便番号: '',
            都道府県: '',
            市区町村: '',
            番地: '',
            建物マンション名: '',
            電話番号個人: '',
            電話番号法人: '',
            メールアドレス: '',
            下取車種名: '',
            下取グレード名: '',
            下取年式: '',
            下取走行距離: 0,
            下取ボディカラー: '',
            修復歴の有無: false,
            セールスポイント: '',
            備考欄: '',
            中古車整備費用: 0,
        };
        if (doExport(item)) {
            products.push(item.estimateProducts!!);
            exportItem.見積ID = item.id;
            exportItem.見積番号 = item.estimateCode;
            exportItem.見積日時 = moment(item.estimateDate).format("YYYY/MM/DD HH:mm:ss");
            exportItem.フェーズ = item.phase === ESTIMATE_PHASE.ORDERED.value ? ESTIMATE_PHASE.ORDERED.label : ESTIMATE_PHASE.NOT_ORDER.label;
            exportItem.見積合計金額 = item.totalFee;
            exportItem.原価合計 = item.totalCost;
            exportItem.値引き合計 = (item.discountPriceFromOriginalPrice || 0) - (estimate?.specialDiscount || 0);
            exportItem.消費税率 = item.consumptionTax;
            exportItem.車種ID = item.carId;
            exportItem.車種名 = item.carName1;
            exportItem.モデルID = item.carModelId;
            exportItem.モデル名 = item.carModelName;
            exportItem.コンプリートカーID = item.completeCarId;
            exportItem.コンプリートカー名 = item.completeCarName;
            exportItem.コンプリートカー売価 = item.completeCarPrice;
            exportItem.コンプリートカー原価 = item.completeCarCost;
            exportItem.コンプリートカー説明文 = item.completeCarExplanation;
            exportItem.グレードID = item.carGradeId;
            exportItem.グレード名 = item.usedCarType ? `${getUsedMileageByUsedCarType(estimate)}` : item.carGradeName
            exportItem.グレード売価 = item.carGradePriceWithTax;
            exportItem.グレード原価 = item.carGradeCostWithTax;
            exportItem.カラーID = item.carColorId;
            exportItem.カラー名 = item.carColorName;
            exportItem.カラー売価 = item.carColorPriceWithTax;
            exportItem.カラー原価 = item.carColorCostWithTax;
            exportItem.ナンバー登録地ID = item.numberRegistrationLocationId;
            exportItem.ナンバー登録地名 = item.numberRegistrationLocationName;
            exportItem.納車場所ID = item.carShippingPlaceId;
            exportItem.納車場所名 = item.carShippingPlaceName;
            exportItem.お届け先 = `${item.addressee1} ${item.addressee2} ${item.addressee3} ${item.addressee4}`;
            if (item.completeCarId) {
                if (item.paymentMethod === 1) {
                    exportItem.お支払い方法 = `${PaymentMethod.cash.labelWithCar}`;
                } else if (item.paymentMethod === 2) {
                    exportItem.お支払い方法 = `${PaymentMethod.fullLoan.labelWithCar}`;
                } else if (item.paymentMethod === 3) {
                    exportItem.お支払い方法 = `${PaymentMethod.someLoan.labelWithCar}`;
                } else if (item.paymentMethod === 4) {
                    exportItem.お支払い方法 = `${PaymentMethod.lease.labelWithCar}`;
                }
            } else {
                if (item.paymentMethod == 1) {
                    exportItem.お支払い方法 = `${PaymentMethod.cash.labelPartsOnly}`;
                } else if (item.paymentMethod == 2) {
                    exportItem.お支払い方法 = `${PaymentMethod.fullLoan.labelPartsOnly}`;
                } else if (item.paymentMethod == 3) {
                    exportItem.お支払い方法 = `${PaymentMethod.someLoan.labelPartsOnly}`;
                } else if (item.paymentMethod == 4) {
                    exportItem.お支払い方法 = `${PaymentMethod.lease.labelPartsOnly}`;
                }
            }
            exportItem.自動車税ID = item.autoMobileTaxId;
            exportItem.自動車税 = item.autoMobileTaxFee;
            exportItem.自動車税月数 = item.autoMobileTaxMonths;
            exportItem.環境性能割ID = item.taxByEnvironmentalPerformancesId;
            exportItem.環境性能割税率 = item.taxByEnvironmentalPerformancesTaxRatio;
            exportItem.環境性能割金額 = item.taxByEnvironmentalPerformancesFee;
            exportItem.重量税ID = item.weightTaxId;
            exportItem.重量税 = item.weightTaxFee;
            exportItem.自賠責保険料ID = item.carInsuranceId;
            exportItem.自賠責保険料 = item.carInsuranceFee;
            exportItem.自賠責保険料月数 = item.carInsuranceMonths;
            exportItem.法定費用_検査登録_届出 = item.legalFeeInspectionRegistration;
            exportItem.法定費用_車庫証明紙代 = item.legalFeeGarageCertificatePaperFee;
            exportItem.法定費用_下取車 = item.legalFeeTradeIn;
            exportItem.代行費用_検査登録_届出 = item.agentFeeInspectionRegistration;
            exportItem.代行費用_車庫証明紙代 = item.agentFeeGarageCertificatePaperFee;
            exportItem.代行費用_下取車 = item.agentFeeTradeIn;
            exportItem.一般希望番号申請費 = item.selectiveNumberFee;
            exportItem.製作車両移動費用 = item.carShippingFee;
            exportItem.印紙代 = item.stampDuty;
            exportItem.ナンバー代 = item.numberFee;
            exportItem.登録手続費用 = item.registrationFee;
            exportItem.納車費用 = item.shippingFee;
            exportItem.構造変更手続費用 = item.totalStructuralProcedureFee;
            exportItem.アライメント調整費用合計 = item.totalAlignmentAdjustingFee;
            exportItem.シュレッダーダスト料金 = item.shredderDustFee;
            exportItem.エアバッグ料金 = item.airbagsFee;
            exportItem.フロン類料金 = item.freonsFee;
            exportItem.情報管理料金 = item.informationManagementFee;
            exportItem.リサイクル費用自由項目1タイトル = item.freeRecyclingTitle1;
            exportItem.リサイクル費用自由項目1金額 = item.freeRecyclingFee1;
            exportItem.リサイクル費用自由項目2タイトル = item.freeRecyclingTitle2;
            exportItem.リサイクル費用自由項目2金額 = item.freeRecyclingFee2;
            exportItem.リサイクル費用自由項目3タイトル = item.freeRecyclingTitle3;
            exportItem.リサイクル費用自由項目3金額 = item.freeRecyclingFee3;
            exportItem.リサイクル資金管理料 = item.recyclingFundManagementFee;
            exportItem.リサイクル預託金合計 = item.recyclingDepositFee;
            exportItem.リサイクル計 = item.recyclingTotalFee;
            exportItem.課税諸費用計 = item.taxableExpenses;
            exportItem.非課税諸費用計 = item.taxExemptExpenses;
            exportItem.諸費用合計 = item.totalExpenses;
            exportItem.顧客ID = item.customer?.id;
            exportItem.名字 = item.customer?.lastName;
            exportItem.名前 = item.customer?.firstName;
            exportItem.名字ふりがな = item.customer?.lastNameRuby;
            exportItem.名前ふりがな = item.customer?.firstNameRuby;
            exportItem.生年月日 = moment(item.customer?.birthday).format("YYYY/MM/DD");
            exportItem.種別 = item.customer?.customerType === 1 ? '個人' : '法人';
            exportItem.会社名 = item.customer?.companyName;
            exportItem.会社名ふりがな = item.customer?.companyNameRuby;
            exportItem.代表取締役名字 = item.customer?.ceoLastName;
            exportItem.代表取締役名前 = item.customer?.ceoFirstName;
            exportItem.代表取締役名字ふりがな = item.customer?.ceoLastNameRuby;
            exportItem.代表取締役名前ふりがな = item.customer?.ceoFirstNameRuby;
            exportItem.郵便番号 = item.customer?.postalCode;
            exportItem.都道府県 = item.customer?.address1;
            exportItem.市区町村 = item.customer?.address2;
            exportItem.番地 = item.customer?.address3;
            exportItem.建物マンション名 = item.customer?.address4;
            exportItem.電話番号個人 = item.customer?.phoneNumber1;
            exportItem.電話番号法人 = item.customer?.phoneNumber2;
            exportItem.メールアドレス = item.customer?.email !== '' ?  item.customer?.email :  item.customer?.emailOld;
            exportItem.下取車種名 = item.tradeInCar;
            exportItem.下取グレード名 = item.tradeInCarGrade;
            exportItem.下取年式 = item.tradeInModelYear;
            exportItem.下取走行距離 = item.tradeInMileage;
            exportItem.下取ボディカラー = item.tradeInCarColor;
            exportItem.修復歴の有無 = item.tradeInHistoryOfRepairs;
            exportItem.セールスポイント = item.salesPoint;
            exportItem.備考欄 = item.memo;
            exportItem.中古車整備費用 = item.usedCarMaintenanceFee;
            estimatesForExport.push(exportItem);
        }
        let estimateProductsForExport: any = [];
        const specialDiscountItems: EstimateProductExportItem[] = [];
        let specialDiscountItem: EstimateProductExportItem | null = null;
        let targetEstimateId: number | undefined = 0;
        products.map((product: any) => {
            product.map((productItem: ApiEstimateProduct) => {
                let exportItem: EstimateProductExportItem = {
                    見積商品ID: 0,
                    見積ID: 0,
                    商品ID: 0,
                    基幹コード: '',
                    商品名: '',
                    売価: 0,
                    原価: 0,
                    構造変更手続費用: 0,
                    アライメント調整費用: 0,
                };
                exportItem.見積商品ID = productItem.id;
                exportItem.見積ID = productItem.estimateId;
                targetEstimateId = productItem.estimateId !== null ? productItem.estimateId : undefined;
                exportItem.商品ID = productItem.productId;
                exportItem.基幹コード = productItem.backboneCoreSystemCode;


                let productName = productItem.productName;
                let productPrice = productItem.free ? 0 : priceWithTax((productItem.originalPrice || 0), item.consumptionTax!!);
                let productCost = productItem.free ? 0 : productItem.productCost || 0;
                if (productItem.wheel) {
                    estimate?.estimateVariantItems?.map((variantItem) => {
                        const wheelCount = variantItem.variantItemSelectionMethod ? 2 : 4;
                        productName += ` ${variantItem.variantItemName}`
                        productPrice += priceWithTax((variantItem.originalPrice || 0) * wheelCount || 0, item.consumptionTax!!);
                        productCost += (variantItem.variantItemCost || 0) * wheelCount;
                    });
                    if (estimate?.jNumberOfFrontWheel) {
                        productName += ` ${estimate.jNumberOfFrontWheel}J${checkPlusLabelNumber(estimate.insetOfFrontWheel)} ${estimate.jNumberOfRearWheel}J${checkPlusLabelNumber(estimate.insetOfRearWheel)}`;
                    }
                    let wheelCount: number;

                    if (estimate?.estimateVariantItemProductPrices?.length === 2) {
                        wheelCount = 2;
                    } else if (estimate?.estimateVariantItemProductPrices?.length === 4) {
                        wheelCount = 1;
                    } else if (estimate?.estimateVariantItemProductPrices?.length === 1) {
                        wheelCount = 4;
                    } else {
                        wheelCount = 1;
                    }
                    estimate?.estimateVariantItemProductPrices?.map((variantItemProductPrice) => {
                        productPrice += priceWithTax((variantItemProductPrice.variantItemProductOriginalPrice || 0) * wheelCount, item.consumptionTax!!);
                        productCost += (variantItemProductPrice.variantItemProductCost || 0) * wheelCount;
                    });

                }
                exportItem.商品名 = productName;
                exportItem.売価 = productPrice;
                exportItem.原価 = productCost;

                exportItem.構造変更手続費用 = productItem.free ? 0 : priceWithTax(productItem.structuralProcedureFee || 0, item.consumptionTax!!);
                exportItem.アライメント調整費用 = productItem.free ? 0 : priceWithTax(productItem.alignmentAdjustingFee || 0, item.consumptionTax!!);
                estimateProductsForExport.push(exportItem);
                return exportItem;
            });
        });
        if (estimate?.specialDiscount) {
            specialDiscountItem = {
                見積商品ID: undefined,
                見積ID: targetEstimateId,
                商品ID: undefined,
                基幹コード: '',
                商品名: `特別値引`,
                売価: estimate?.specialDiscount * -1, // Assuming the special discount is a negative value
                原価: estimate?.specialDiscount * -1,
                構造変更手続費用: 0,
                アライメント調整費用: 0,
            };
        }
        if (specialDiscountItem) {
            specialDiscountItems.push(specialDiscountItem);
        }

        const allExportItems = [...estimateProductsForExport, ...specialDiscountItems];

        const csvEstimates = convertToCSV({
            data: estimatesForExport,
            fields: EstimateExportField,
        });
        const csvEstimateProducts = convertToCSV({
            data: allExportItems,
            fields: EstimateProductExportField,
        });
        const blobEstimates = new Blob(["\uFEFF" + csvEstimates], {
            type: 'text/csv; charset=utf-18'
        });
        const blobEstimateProducts = new Blob(["\uFEFF" + csvEstimateProducts], {
            type: 'text/csv; charset=utf-18'
        });
        const zip = new JSZip();
        zip.file('estimate.csv', blobEstimates)
        zip.file('products.csv', blobEstimateProducts)
        zip.generateAsync({type: "blob"})
            .then(function (content) {
                saveAs(content, "estimatesCSV.kzip");
            });
        await saveDownloadFlag(item);
        window.location.reload();
    };

    const handleCopyEstimate = async () => {
        const codeData = estimate?.estimateCode?.split('-') || '';
        const temData = {id: null, estimateCode: ((codeData[0] ?? 0) + "-" + (parseInt(codeData[1]) + 1 ?? 1))};
        delete estimate?.estimateCode;
        const data = {...temData, ...estimate}
        const responseEstimate = await dataProvider.create('estimates', {data: data});
        estimate?.estimateProducts?.map(async (estProduct: any) => {
            delete estProduct.estimateId;
            await dataProvider.create('estimateProducts', {data: {estimateId: responseEstimate.data.id, ...estProduct}});
        });

    };

    const getRecommendedCar = async (recommendedCarId: number) => {
        const data = await dataProvider.getOne('recommendedCars', {
            id: recommendedCarId,
        })
        return data.data as ApiRecommendedCar;
    };

    const getUsedMileageByUsedCarType = (record: any) => {
        if (record.usedCarType && record.usedCarType === UsedCarType.UsedCar1) {
            return record.carGradeName + ` (${setting?.data?.usedMileage1 ?? ''})`;
        }
        if (record.usedCarType && record.usedCarType === UsedCarType.UsedCar2) {
            return record.carGradeName + ` (${setting?.data?.usedMileage2 ?? ''})`;
        }
        if (record.usedCarType && record.usedCarType === UsedCarType.UsedCar3) {
            return record.carGradeName + ` (${setting?.data?.usedMileage3 ?? ''})`;
        }
        return record.carGradeName;
    }

    const getStaff = async (staffId: number) => {
        const data = await dataProvider.getOne('users', {
            id: staffId,
        })
        return  data.data as ApiUser;
    }

    return {
        estimateId,
        estimate,
        handleExportData,
        handleCopyEstimate,
        doExport,
        recommendedCar,
        getUsedMileageByUsedCarType,
        staff,
    }
}

export default useEstimate;
