import React, {Dispatch, RefObject, SetStateAction, useEffect, useState} from "react";
import {ReferenceInput, required, SelectInput} from "react-admin";
import {useForm} from "react-final-form";
import SelectableCarGrade, {SelectableCarGradeFunctions} from "./SelectableCarGradeComponent";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

export interface CarDetailFieldProps {
    selectedCarGradesIds: number[] | undefined;
    setSelectedCarGradesIds: Dispatch<SetStateAction<number[] | undefined>>;
    setCalcPrice: Dispatch<SetStateAction<number>>;
    calcPrice: number;
    selectableCarGradeRef: RefObject<SelectableCarGradeFunctions>;
}

const CarDetailField: React.FC<CarDetailFieldProps> = (props) => {

    const form = useForm();

    const savedValues = form.getState().values;
    let completeCarId: number | undefined = undefined;
    if (savedValues['id']) {
        completeCarId = Number(savedValues['id']);
    }

    let savedCarId = 0;
    let savedCarModelId = 0;
    let savedCarGradeId = 0;
    if (savedValues['carId']) {
        savedCarId = savedValues['carId'];
    }
    if (savedValues['carModelId']) {
        savedCarModelId = savedValues['carModelId'];
    }
    if (savedValues['defaultCarGradeId']) {
        savedCarGradeId = savedValues['defaultCarGradeId'];
    }

    const [filterCarModel, setFilterCarModel] = useState({carId: savedCarId});
    const [filterCarGrade, setCarFilterCarGrade] = useState({carModelId: savedCarModelId});
    const [filterCarColor, setCarFilterCarColor] = useState({carGradeId: savedCarGradeId});
    const [loadedFlag, setLoadedFlag] = useState(false);

    useEffect(() => {
        if (loadedFlag) {
            const values = form.getState().values;
            if (values['defaultCarGradeId']) {
                setGradeIds();
            }
        }
    }, [loadedFlag])

    const getValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.target) {
            return e.target.value
        }
    }

    const onCarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterCarModel({carId: Number(getValue(e))});
        form.change('carModelId', undefined);
        form.change('defaultCarGradeId', undefined);
    }

    const onCarModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCarFilterCarGrade({carModelId: Number(getValue(e))});
        form.change('defaultCarGradeId', undefined);
    }

    const setGradeIds = () => {
        const carGradeIds = new Array<number>();
        carGradeIds.push(form.getState().values['defaultCarGradeId'] as number);
        const selectableCarGradeIds = form.getState().values['selectableCarGrades'] as number[];
        selectableCarGradeIds && selectableCarGradeIds.map((id) => {
            carGradeIds.push(id);
        })
        console.log(carGradeIds);
        props.setSelectedCarGradesIds(carGradeIds);
    }
    const onCarGradeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCarFilterCarColor({carGradeId: Number(getValue(e))});
        setGradeIds();
        form.change('defaultCarColorId', undefined);
        fireCalcPrice();
    }

    const onCarColorChange = () => {
        fireCalcPrice();
    }

    const fireCalcPrice = () => {
        props.setCalcPrice(props.calcPrice + 1);
    }

    return (
        <>
            <ReferenceInput
                label="車種"
                reference="cars"
                source="carId"
                validate={[required()]}
                sort={{field: 'position', order: 'ASC'}}
                onChange={onCarChange}
                className={'w-100'}
                perPage={MAX_FETCH_DATA_SIZE}
            >
                <SelectInput optionText={'name'}/>
            </ReferenceInput>
            <ReferenceInput
                label="モデル"
                reference="carModels"
                source="carModelId"
                validate={[required()]}
                filter={filterCarModel}
                onChange={onCarModelChange}
                sort={{field: 'position', order: 'ASC'}}
                className={'w-100'}
                perPage={MAX_FETCH_DATA_SIZE}
            >
                <SelectInput optionText='name'/>
            </ReferenceInput>
            <ReferenceInput
                label="デフォルトグレード"
                reference="carGrades"
                source="defaultCarGradeId"
                validate={[required()]}
                onChange={onCarGradeChange}
                filter={filterCarGrade}
                sort={{field: 'position', order: 'ASC'}}
                className={'w-100'}
                perPage={MAX_FETCH_DATA_SIZE}
            >
                <SelectInput optionText="name"/>
            </ReferenceInput>

            <SelectableCarGrade
                filterCarGrade={filterCarGrade}
                completeCarId={completeCarId}
                ref={props.selectableCarGradeRef}
                setGradeIds={setGradeIds}
                setLoadedFlag={setLoadedFlag}
            />
            <ReferenceInput
                label="デフォルトカラー"
                reference="carColors"
                source="defaultCarColorId"
                validate={[required()]}
                filter={filterCarColor}
                onChange={onCarColorChange}
                sort={{field: 'position', order: 'ASC'}}
                perPage={MAX_FETCH_DATA_SIZE}
                className={'w-100'}
            >
                <SelectInput source="name"/>
            </ReferenceInput>

        </>
    );
}

export default CarDetailField
