import React from "react";
import {ApiEstimate, ApiVariantItem} from "../../openapi";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Box} from "@material-ui/core";
import {PlusMinusLabelNumber} from "../../common/PlusMinusLabelNumber";

interface VariantProps {
    estimate: ApiEstimate;
}

export const variantItemSelectionMethods = [
    {id: '', name: ''},
    {id: 'left', name: '左'},
    {id: 'right', name: '右'},
    {id: 'front', name: '前'},
    {id: 'rear', name: '後'},
];

const Variant: React.FC<VariantProps> = (props) => {
    return (
        <>
            <Box ml={2}>
                {
                    <>
                        <h4>規格項目内容</h4>
                        <TableContainer>
                            <Table>
                                {props.estimate.estimateVariantItems?.sort(
                                    (a, b) =>
                                        a.variantClassPosition === b.variantClassPosition ?
                                            (a.variantClassPosition || 0) - (b.variantClassPosition || 0)
                                            :
                                            (a.variantItemPosition || 0) - (b.variantItemPosition || 0)
                                ).map((estimateVariantItem) => (
                                    <>
                                        <TableRow>
                                            <TableCell> {estimateVariantItem.variantClassName}
                                                {estimateVariantItem?.variantItemSelectionMethod !== null ?
                                                    <span> {`${variantItemSelectionMethods[variantItemSelectionMethods.findIndex((item) => item.id === estimateVariantItem?.variantItemSelectionMethod)]?.name.toString()}`}</span>
                                                    :
                                                    <span/>
                                                }
                                            </TableCell>
                                            <TableCell>{estimateVariantItem.variantItemName}</TableCell>
                                            <TableCell className={"text-right"}>
                                                {estimateVariantItem.variantItemPrice?.toLocaleString()}
                                            </TableCell>
                                            <TableCell className={"text-right"}>
                                                {estimateVariantItem.variantItemCost?.toLocaleString()}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))
                                }
                                <h4>商品規格項目価格</h4>
                                {props.estimate.estimateVariantItemProductPrices?.map((estimateVariantItemProductPrice) => {
                                        return (
                                            props.estimate.estimateVariantItemProductPriceCombination?.map((estimateVariantItemProductPriceCombination) => (
                                                    estimateVariantItemProductPrice.variantItemProductPriceId === estimateVariantItemProductPriceCombination.variantItemProductPriceId &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell>{estimateVariantItemProductPriceCombination.product?.productCategory?.name}</TableCell>
                                                            <TableCell>{estimateVariantItemProductPriceCombination.product?.name}</TableCell>
                                                            <TableCell className={"text-right"}>
                                                                {estimateVariantItemProductPrice.variantItemProductPrice?.toLocaleString()}
                                                            </TableCell>
                                                            <TableCell className={"text-right"}>
                                                                {estimateVariantItemProductPrice.variantItemProductCost?.toLocaleString()}
                                                            </TableCell>

                                                        </TableRow>
                                                        {estimateVariantItemProductPriceCombination.variantItems?.sort((a, b) =>
                                                            a.variantClass?.position === b.variantClass?.position ?
                                                                (a.variantClass?.position || 0) - (b.variantClass?.position || 0)
                                                                :
                                                                (a.position || 0) - (b.position || 0)
                                                        ).map((estimateVariantItem: ApiVariantItem) => (
                                                            <TableRow>
                                                                <TableCell>{estimateVariantItem.variantClass?.name}</TableCell>
                                                                <TableCell>{estimateVariantItem.name}</TableCell>
                                                            </TableRow>
                                                        ))
                                                        }
                                                    </>
                                                )
                                            )
                                        )
                                    }
                                )}
                                {props.estimate.jNumberOfFrontWheel &&
                                    <TableRow>
                                        <TableCell>J数・インセット 前輪</TableCell>
                                        <TableCell>
                                            {props.estimate.jNumberOfFrontWheel}J
                                            <PlusMinusLabelNumber
                                                value={props.estimate.insetOfFrontWheel}/>
                                        </TableCell>
                                        <TableCell>J数・インセット 後輪</TableCell>
                                        <TableCell>
                                            {props.estimate.jNumberOfRearWheel}J
                                            <PlusMinusLabelNumber
                                                value={props.estimate.insetOfRearWheel}/>
                                        </TableCell>
                                    </TableRow>
                                }
                            </Table>
                        </TableContainer>
                    </>
                }
            </Box>
        </>
    );
}
export default Variant;
