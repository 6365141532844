import {createBrowserHistory} from "history";
import {Button, ButtonProps} from "react-admin";
import React from "react";
import {Link} from "react-router-dom";

interface GoBackProps {
    url?: string;
    className?: string;
}

const GoBack = (props: GoBackProps) => {
    const history = createBrowserHistory();
    const goToPreviousPath = () => {
        history.goBack()
    };
    const buttonProps: ButtonProps = {
        ...props,
        label: '戻る',
        variant: "contained",
        color: 'default',
    }

    return (
        <>
            {!props.url &&
                <Button
                    {...buttonProps}
                    onClick={goToPreviousPath}
                />
            }
            {props.url &&
                <Button
                    {...buttonProps}
                    component={Link}
                    to={{pathname: props.url}}
                />
            }
        </>
    );
};
export default GoBack;
