import {makeStyles, Theme} from "@material-ui/core/styles";
import {useDataProvider} from "react-admin";
import {ChangeEvent, ForwardedRef, useEffect, useImperativeHandle, useState} from "react";
import {ApiProductVariants, ApiProductVariantsVariantVariantClass, ApiVariantItem} from "../../openapi";
import {IdName} from "../../common/IdName";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import {useStyles} from "../../common/styles";
import {DefaultVariantItemFunctions, DefaultVariantItemProps} from "./DefaultVariantItemComponent";

const useLocalStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

interface VariantClassVariantItems extends ApiProductVariantsVariantVariantClass {
    choices?: Array<IdName>
}

interface DefaultVariantItemHookProps extends DefaultVariantItemProps {
    ref: ForwardedRef<DefaultVariantItemFunctions>
}

export const useDefaultVariantItemHooks = (props: DefaultVariantItemHookProps) => {
    const classes = useLocalStyles();
    const dataProvider = useDataProvider();
    const [productVariants, setProductVariants] = useState<ApiProductVariants>()
    const [tabValue, setTabValue] = useState(0);

    const [variantClassVariantItems, setVariantClassVariantItems] = useState<VariantClassVariantItems[]>()

    useImperativeHandle(props.ref, () => ({
        async save(completeCarId: number, defaultProductIds: number[], defaultVariantItemIds: number[]) {
            console.log("adad0 " + defaultProductIds + ' | ' + defaultVariantItemIds)
            if (defaultProductIds.length === 0 && defaultVariantItemIds === undefined) {
                console.log("adad1")
                await dataProvider.create('completeCarDefaultVariantItems/' + completeCarId, {
                    data: {
                        data: undefined,
                        id: 1,
                    }
                })
                return;
            }
            if (defaultVariantItemIds === undefined) {
                console.log("adad2")
                return;
            } else {
                console.log("adad3")
                const dataArray = new Array<{ variantItemId: number, completeCarId: number }>()
                const selectedValues = defaultVariantItemIds;
                if (!selectedValues || !selectedValues.length) {
                    return;
                }
                selectedValues.map((value) => {
                    dataArray.push(
                        {
                            completeCarId: completeCarId,
                            variantItemId: value,
                        }
                    )
                })
                await dataProvider.create('completeCarDefaultVariantItems/' + completeCarId, {
                    data: {
                        data: dataArray,
                        id: 1,
                    }
                })
            }
        },
    }));

    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const getDataToDisplay = () => {
        if (tabValue === 0) {
            const data = new Array<VariantClassVariantItems>();
            setVariantClassVariantItems(data);
        } else {
            const data = productVariants?.data?.find((d) => d.variant?.id === tabValue)?.variant?.variantClass as Array<VariantClassVariantItems>;
            data?.map((variantClassVariantItem) => {
                const choices = new Array<IdName>();
                variantClassVariantItem?.carGrades?.map((carGrade: any) => {
                    carGrade?.variantItem?.map((variantItem: any) => {
                        if (choices.find((choice) => choice.id === variantItem.id) === undefined) {
                            choices.push({
                                id: variantItem.id!!,
                                name: variantItem.name,
                            });
                        }
                    })
                })
                variantClassVariantItem.choices = choices;
            })
            setVariantClassVariantItems(data);
        }
    }

    useEffect(getDataToDisplay, [tabValue]);

    const initialization = () => {
        const awaitFn = async () => {
            console.log(props.carGradeIds)
            if (props.carGradeIds === undefined) {
                return;
            }

            props.setOpen(true);
            setTabValue(0)
            if (props.defaultProductIds?.length !== 0 && props.defaultProductIds !== undefined && props.carGradeIds != null) {
                const data = await dataProvider.getList('completeCarDefaultVariantItems/afterChangingOtherDefault', {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'createdAt', order: 'ASC'},
                    filter: {
                        carGradeId: props.carGradeIds[0],
                        defaultProductIds: props.defaultProductIds,
                        completeCarId: props.completeCarId
                    }
                })
                const fetched = data as ApiProductVariants
                setProductVariants(fetched);

                const initialValues = new Array<number>();
                const initialAffectPriceValues = new Array<number>();

                fetched.data?.map((parent) => parent.variant?.variantClass?.map((variantClass) => variantClass.carGrades?.map((carGrade) => carGrade.variantItem?.map((variantItem) => {
                    if (variantItem.checked) {
                        initialValues.push(variantItem.id!!);
                        if (variantItem.variantClass?.affectPrice) {
                            initialAffectPriceValues.push(variantItem.id!!);
                        }
                    }
                }))));
                props.setVariantItemInitialValues(initialValues);
                props.setDefaultVariantItemIds(initialValues);
                props.setSelectAffectPriceVariantItemIds(initialAffectPriceValues);
            } else {
                setProductVariants(undefined)
                props.setVariantItemInitialValues(undefined)
                props.setDefaultVariantItemIds(undefined)
                props.setSelectAffectPriceVariantItemIds(undefined)
            }
            props.setOpen(false);
        }
        awaitFn().then();
    }

    useEffect(initialization, [props.modifiedProducts, props.carGradeIds, props.completeCarId, props.defaultProductIds])

    const findVariantItemById = (variantItemId: number): ApiVariantItem => {
        let selectVariantItem: ApiVariantItem = {
            cost: 0,
            originalPrice: 0,
            priceForPartsOnly: 0,
            name: '', price: 0, variantClassId: 0, position: 0
        }
        variantClassVariantItems?.map((variantClassVariantItem) =>
            variantClassVariantItem.carGrades?.map((carGrade) =>
                carGrade.variantItem?.forEach((variantItem) => {
                    if (variantItemId == variantItem.id!!) {
                        selectVariantItem = variantItem
                    }
                })
            )
        )
        return selectVariantItem
    }

    const onChangeCheckBox = async (e: any) => {
        const selectVariantItem = findVariantItemById(e.target.value)
        let variantItemIds: number[] = []
        let affectPriceVariantItemIds: number[] = []
        let deletedVariantItemId = 0
        if (props.defaultVariantItemIds !== undefined)
            variantItemIds = props.defaultVariantItemIds
        if (props.selectAffectPriceVariantItemIds !== undefined)
            affectPriceVariantItemIds = props.selectAffectPriceVariantItemIds

        variantClassVariantItems?.map((variantClassVariantItem) => {
            if (variantClassVariantItem.choices !== null) {
                variantClassVariantItem.choices?.map((choice) => {
                    const variantItem = findVariantItemById(choice.id)
                    if (variantItem.variantClassId === selectVariantItem.variantClassId) {
                        const index = variantItemIds.indexOf(choice.id, 0)
                        if (index !== -1) {
                            deletedVariantItemId = choice.id
                        }
                    }
                })
            }
            variantClassVariantItem.choices?.push({
                id: selectVariantItem.id!!,
                name: selectVariantItem.name,
            });
        })

        if (selectVariantItem.id != null) {
            if (selectVariantItem.variantClass?.affectPrice)
                affectPriceVariantItemIds.push(selectVariantItem.id)
            variantItemIds.push(selectVariantItem.id)
            if (selectVariantItem.id !== deletedVariantItemId) {
                const index = variantItemIds.indexOf(deletedVariantItemId, 0)
                if (index !== -1)
                    variantItemIds.splice(index, 1)
                const affectPriceIndex = affectPriceVariantItemIds.indexOf(deletedVariantItemId, 0)
                if (affectPriceIndex !== -1) {
                    affectPriceVariantItemIds.splice(affectPriceIndex, 1)
                }
            }
        }
        variantItemIds = [...new Set(variantItemIds)]
        affectPriceVariantItemIds = [...new Set(affectPriceVariantItemIds)]

        setVariantClassVariantItems(variantClassVariantItems)
        props.setDefaultVariantItemIds(variantItemIds)
        props.setSelectAffectPriceVariantItemIds(affectPriceVariantItemIds)
        props.setCalcPrice(props.calcPrice + 1);
    }

    const globalClasses = useStyles();

    return {
        classes,
        globalClasses,
        tabValue,
        handleTabChange,
        productVariants,
        variantClassVariantItems,
        onChangeCheckBox
    }
}
