import React from 'react';
import {
    AutocompleteInput,
    DateField,
    DateInput,
    Filter,
    FormDataConsumer,
    FunctionField,
    List,
    ReferenceInput,
    SelectInput,
    ShowButton,
    TextField,
    usePermissions,
    useRecordContext,
} from 'react-admin';
import {Box, Grid} from '@material-ui/core';
import {DeleteWithConfirmIconButton, EditableDatagrid} from "@react-admin/ra-editable-datagrid";
import {MyPagination, PER_PAGE} from "../../common/MyPagination";
import {ESTIMATE_PHASE, MAX_FETCH_DATA_SIZE, ROLES} from "../../config/const";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import useSetting from '../setting/SettingHooks';
import useEstimate from './estimateHook';

const Estimates: React.FC = (props: any) => {
    const {permissions} = usePermissions();
    const {getUsedMileageByUsedCarType} = useEstimate(props.id);
    return (
        <List
            filters={<SearchFilterForm/>}
            {...props}
            hasCreate={false}
            empty={false}
            sort={{field: 'estimateDate', order: 'DESC'}}
            exporter={false}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
        >
            <EditableDatagrid
                undoable={true}
                editForm={<SearchFilterForm/>}
                noDelete={false}
                className={'listTable'}
            >
                <FunctionField label={'見積番号'} noWrap={true} fullWidth={true} render={(record: any) => {
                    let className = "";
                    if (record.phase === ESTIMATE_PHASE.ORDERED.value && record.downloadFlag !== true) {
                        className = "secondary";
                    }
                    return <span className={className}>{record.estimateCode}</span>
                }}/>
                <DateField source="estimateDate" label={'見積日時'} noWrap fullWidth locales={'ja-JP'} showTime={true}/>
                <FunctionField source="phase" label={'フェーズ'} noWrap fullWidth render={(record: any) => {
                    return record.phase === ESTIMATE_PHASE.ORDERED.value ? ESTIMATE_PHASE.ORDERED.label : ESTIMATE_PHASE.NOT_ORDER.label
                }}/>
                <FunctionField
                    noWrap
                    fullWidth
                    label={'顧客名'}
                    render={(record: any) => {
                        if (record.customer == undefined) {
                            return "";
                        } else {
                            return `${record.customer?.lastName} ${record.customer?.firstName}`;
                        }
                    }}
                />
                <FunctionField
                    fullWidth
                    label={'車種・モデル'}
                    render={(record: any) => {
                        record.carName1 = record.carName1 ?? "";
                        record.carModelName = record.carModelName ?? "";
                        return `${record.carName1} ${record.carModelName}`;
                    }}
                />
                <TextField source="completeCarName" label={'コンプリートカー'} noWrap fullWidth/>
                <FunctionField
                    label='グレード'
                    noWrap 
                    fullWidth
                    render={(record: any) => {
                       return getUsedMileageByUsedCarType(record)
                    }}
                />
                <TextField source="carColorName" label={'カラー'} noWrap fullWidth/>
                <FunctionField noWrap fullWidth
                               label={'見積金額'}
                               textAlign={"right"}
                               render={(record: any) => {
                                   if (!isNaN(Number(`${record.totalFee}`))) {
                                       return Number(`${record.totalFee}`).toLocaleString()
                                   }
                                   return ""
                               }}
                />
                <FunctionField noWrap fullWidth
                               label={'原価'}
                               textAlign={"right"}
                               render={(record: any) => {
                                   if (!isNaN(Number(`${record.totalCost}`))) {
                                       return Number(`${record.totalCost}`).toLocaleString()
                                   }
                                   return ""
                               }}
                />
                <FunctionField noWrap fullWidth
                               label={'諸費用'}
                               textAlign={"right"}
                               render={(record: any) => {
                                   if (!isNaN(Number(`${record.totalExpenses}`))) {
                                       return Number(`${record.totalExpenses}`).toLocaleString()
                                   }
                                   return ""
                               }}
                />
                <FunctionField
                    noWrap
                    fullWidth
                    label={'粗利'}
                    textAlign={"right"}
                    render={(record: any) => {
                        if (!isNaN(Number(`${record.totalFee - record.totalCost}`))) {
                            return Number(`${record.totalFee - record.totalCost}`).toLocaleString()
                        }
                        return ""
                    }}
                />
                <TextField source="numberRegistrationLocationName" label={'登録地'} noWrap fullWidth/>
                <TextField source="carShippingPlaceName" label={'納車地'} noWrap fullWidth/>
                <div className={'actionButtons flex-start'}>
                    <CustomShowButton {...props} label={'詳細'} icon={null}/>
                    {permissions === ROLES.ADMIN &&
                    <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                   className={'ml-30 mr-30'}/>
                    }
                </div>
            </EditableDatagrid>
        </List>
    )
};

const SearchFilterForm: React.FC = ({...props}) => {
    const modelRenderer = (choice: Record<any, any>) => choice.name ? `${choice.carName} ${choice.name}` : '';
    const choiceRenderer = (choice: Record<any, any>) => choice.name ? `${choice.name}` : '';
    return (
        <Filter {...props}>
            {/*// @ts-ignore*/}
            <Box bgcolor={"white"} className={'searchForm-sizeLarge filterBox'} id='box' mt={2} p={2} alwaysOn>
                <Grid container={true} spacing={1}>
                    <Grid item={true} md={1}>
                        <TextInputForJapanese source="estimateCode" label={'見積番号'} alwaysOn
                                              className={'p-display-none'}/>
                    </Grid>
                    <Grid item={true} md={1}>
                        <ReferenceInput
                            label="車種・モデル"
                            source="carModelId"
                            reference="carModels"
                            alwaysOn
                            allowEmpty
                            className={'p-display-none'}
                            perPage={MAX_FETCH_DATA_SIZE}
                            sort={{field: 'position', order: 'ASC'}}
                        >
                            <AutocompleteInput optionText={modelRenderer} alwaysOn/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item={true} md={1}>
                        <TextInputForJapanese source="customerLastName" label={'顧客名 名字'} className={'p-display-none'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} md={2}>
                        <Grid container={true} spacing={1}>
                            <Grid xs={6} item={true}>
                                <TextInputForJapanese type={'number'} className={'p-display-none'}
                                                      source="totalCostFrom"
                                                      label={'原価～'} alwaysOn/>
                            </Grid>
                            <Grid xs={6} item={true}>
                                <TextInputForJapanese type={'number'} className={'p-display-none'} source="totalCostTo"
                                                      label={'～原価'} alwaysOn/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} md={1}>
                        <ReferenceInput
                            label="登録地"
                            source="numberRegistrationLocationId"
                            reference="numberRegistrationLocation"
                            alwaysOn
                            allowEmpty
                            className={'p-display-none'}
                            perPage={MAX_FETCH_DATA_SIZE}
                            sort={{field: 'position', order: 'ASC'}}
                        >
                            <AutocompleteInput optionText={choiceRenderer} alwaysOn/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item={true} md={1}>
                        <SelectInput
                            fullWidth={true}
                            source="depositPaymentMethod"
                            label={'手付金支払い方法'}
                            optionValue={'id'}
                            className={'p-display-none'}
                            choices={[
                                {id: 'creditCard', name: 'クレジットカード'},
                                {id: 'cash', name: '現金'},
                            ]}
                            alwaysOn
                            allowEmpty
                        />
                    </Grid>
                    <Grid item={true} md={5}>
                    </Grid>
                    <Grid item={true} md={1}>
                        <DateInput fullWidth={true} className={'p-display-none'} source="estimateDateFrom"
                                   label={'見積日時～'} alwaysOn/>
                    </Grid>
                    <Grid item={true} md={1}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => !(formData.carModelId == null) &&
                                <ReferenceInput
                                    label="グレード"
                                    source="carGradeId"
                                    reference="carGrades"
                                    allowEmpty
                                    className={'p-display-none'}
                                    perPage={MAX_FETCH_DATA_SIZE}
                                    sort={{field: 'position', order: 'ASC'}}
                                    {...rest}
                                >
                                    <AutocompleteInput optionText={choiceRenderer}/>
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item={true} md={1}>
                        <TextInputForJapanese source="customerFirstName" className={'p-display-none'} label={'顧客名 名前'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} md={2}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={6}>
                                <TextInputForJapanese type={'number'} className={'p-display-none'}
                                                      source="totalExpensesFrom"
                                                      label={'諸費用～'}
                                                      alwaysOn/>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <TextInputForJapanese type={'number'} className={'p-display-none'}
                                                      source="totalExpensesTo"
                                                      label={'～諸費用'} alwaysOn/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} md={1}>
                        <ReferenceInput
                            label="納車地"
                            source="carShippingPlaceId"
                            reference="carShippingPlaces"
                            alwaysOn
                            allowEmpty
                            className={'p-display-none'}
                            perPage={MAX_FETCH_DATA_SIZE}
                            sort={{field: 'position', order: 'ASC'}}
                        >
                            <AutocompleteInput optionText={choiceRenderer} alwaysOn/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item={true} md={1}>
                        <SelectInput
                            fullWidth={true}
                            source="depositPaid"
                            label={'手付金支払い済'}
                            optionValue={'id'}
                            className={'p-display-none'}
                            choices={[
                                {id: true, name: 'はい'},
                                {id: false, name: 'いいえ'},
                            ]}
                            alwaysOn
                            allowEmpty
                        />
                    </Grid>
                    <Grid item={true} md={5}>
                    </Grid>
                    <Grid item={true} md={1}>
                        <DateInput fullWidth={true} source="estimateDateTo" className={'p-display-none'} label={'～見積日時'}
                                   alwaysOn/>
                    </Grid>
                    <Grid item={true} md={1}>
                        <FormDataConsumer>
                            {({
                                  formData,
                                  ...rest
                              }) => !(formData.carGradeId == null || formData.carModelId == null) &&
                                <ReferenceInput
                                    label="カラー"
                                    source="carColorId"
                                    reference="carColors"
                                    allowEmpty
                                    className={'p-display-none'}
                                    perPage={MAX_FETCH_DATA_SIZE}
                                    sort={{field: 'position', order: 'ASC'}}
                                    {...rest}
                                >
                                    <AutocompleteInput optionText={choiceRenderer}/>
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item={true} md={1}>
                        <TextInputForJapanese source="customerLastNameRuby" className={'p-display-none'}
                                              label={'ふりがな 名字'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} md={2}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={6}>
                                <TextInputForJapanese type={'number'} className={'p-display-none'}
                                                      source="totalGrossProfitFrom"
                                                      label={'粗利～'}
                                                      alwaysOn/>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <TextInputForJapanese type={'number'} className={'p-display-none'}
                                                      source="totalGrossProfitTo"
                                                      label={'～粗利'}
                                                      alwaysOn/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} md={1}/>
                    <Grid item={true} md={6}>
                    </Grid>
                    <Grid item={true} md={1}>
                        <SelectInput
                            fullWidth={true}
                            source="phase"
                            label={'フェーズ'}
                            optionValue={'id'}
                            className={'p-display-none'}
                            choices={[
                                {id: ESTIMATE_PHASE.NOT_ORDER.value, name: ESTIMATE_PHASE.NOT_ORDER.label},
                                {id: ESTIMATE_PHASE.ORDERED.value, name: ESTIMATE_PHASE.ORDERED.label},
                            ]}

                            alwaysOn
                            allowEmpty
                        />
                    </Grid>
                    <Grid item={true} md={1}>
                        <FormDataConsumer>
                            {({formData}) =>
                                <ReferenceInput
                                    label="コンプリートカー"
                                    source="completeCarId"
                                    reference="completeCars"
                                    filter={{carModelId: formData.carModelId}}
                                    alwaysOn
                                    allowEmpty
                                    className={'p-display-none'}
                                    perPage={MAX_FETCH_DATA_SIZE}
                                    sort={{field: 'position', order: 'ASC'}}
                                >
                                    <AutocompleteInput optionText={choiceRenderer} alwaysOn/>
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item={true} md={1}>
                        <TextInputForJapanese source="customerFirstNameRuby" className={'p-display-none'}
                                              label={'ふりがな 名前'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} md={2}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={6}>
                                <TextInputForJapanese type={'number'} className={'p-display-none'} source="totalFeeFrom"
                                                      label={'見積金額～'} alwaysOn/>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <TextInputForJapanese type={'number'} className={'p-display-none'} source="totalFeeTo"
                                                      label={'～見積金額'} alwaysOn/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} md={1}>
                        <SelectInput
                            fullWidth={true}
                            source="downloadFlag"
                            label={'対応済み'}
                            optionValue={'id'}
                            className={'p-display-none'}
                            choices={[
                                {id: true, name: '対応済み'},
                                {id: false, name: '未対応'},
                            ]}

                            alwaysOn
                            allowEmpty
                        />
                    </Grid>
                    <Grid item={true} md={6}>
                    </Grid>
                </Grid>
            </Box>
        </Filter>
    )
};

const CustomShowButton = ({...source}) => {
    const record = useRecordContext();
    return (<ShowButton {...source} record={record}/>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};

export default Estimates;
