import React, {forwardRef, useImperativeHandle, useState} from "react";
import {DataProviderProxy} from "ra-core";
import SortableImageList from "../../common/image/SortableImageList";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import {ImageDirectory, ResourceNames} from "../../config/const";
import {useCompleteCarColorImageHooks} from "./useCompleteCarColorImageHooks";
import {saveImages} from "../../common/image/databaseImageUtils";

export interface CompleteCarColorImageProps {
    completeCarId?: number;
    carColorId?: number;
    dataProvider: DataProviderProxy;
}

export interface CompleteCarColorImageFunctions {
    save: (completeCarId: number) => Promise<void>;
}

const CompleteCarColorImage: React.ForwardRefRenderFunction<CompleteCarColorImageFunctions, CompleteCarColorImageProps> = (props, ref) => {
    const [pathData, setPathData] = useState(new Array<TempFileData>());
    const [images, setImages] = useState<ListPhotosType[] | undefined>();
    useCompleteCarColorImageHooks({...props, setImages});

    useImperativeHandle(ref, () => ({
        async save(completeCarId: number) {
            if (pathData.length) {
                await saveImages(props.dataProvider,
                    ResourceNames.COMPLETE_CAR_COLOR_IMAGE_RESOURCE,
                    {
                        completeCarId: completeCarId,
                        carColorId: props.carColorId,
                    },
                    `${ImageDirectory.COMPLETE_CAR_COLOR_IMAGE}${props.completeCarId}/${props.carColorId}/`,
                    pathData,
                    images,
                )
            }
        }
    }));
    return (
        <>
            <SortableImageList imageArray={images}
                               dataProvider={props.dataProvider}
                               resource={ResourceNames.COMPLETE_CAR_COLOR_IMAGE_RESOURCE}
                               isUpdating={!!props.completeCarId}
                               setPathData={setPathData}
                               pathData={pathData}
                               setImages={setImages}
                               imageSourceName={ResourceNames.COMPLETE_CAR_COLOR_IMAGE_RESOURCE + props.carColorId?.toString()}
                               required={false}
            />
        </>
    );
}

export default forwardRef(CompleteCarColorImage);
