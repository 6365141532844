import {Dispatch, SetStateAction} from "react";
import {TempFileData} from "../modules/temporaryFileModule";

export const readFileAsDataURL = async (file: File) => {
    return await new Promise<string | ArrayBuffer | null | undefined>((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });
}

interface WithRawFile {
    rawFile: File;
}

export const convertFilesToTempFileData = async (setPathData: Dispatch<SetStateAction<Array<TempFileData>>>, files: File[] | WithRawFile[], setFlag?: Dispatch<SetStateAction<boolean>>) => {
    if (files.length) {
        if (setFlag) setFlag(true);
        let results: TempFileData[] = [];
        await Promise.all(files.map(async (file) => {
            if (!file.hasOwnProperty('rawFile')) {
                await readFile(file as File, results);
            } else {
                const targetFile = file as WithRawFile
                await readFile(targetFile.rawFile, results);
            }
        }));
        setPathData(results);
    }
}

const readFile = async (targetFile: File, results: TempFileData[]) => {
    const data = await readFileAsDataURL(targetFile);
    results.push({
        dir: '',
        fileBinary: data,
        filename: targetFile.name,
        fileType: targetFile.type
    })
}
