import React, {useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import {
    Button,
    Create,
    Edit,
    maxLength,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useRedirect,
    useRefresh,
} from "react-admin";
import {Grid} from "@material-ui/core";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";
import AeroSeriesProductList from "./AeroSeriesProductListComponent";

const AeroSeriesForm: React.FC = (props: any) => {
    const redirect = useRedirect();
    const numberOrEnter = (message = '入力項目に誤りがあります') =>
        (value: string) => value && isNaN(Number(value.replace(/\r?\n/g, ''))) ? message : undefined;
    const validateAeroSeriesProductIds = [numberOrEnter()];
    const validateName = [required(), maxLength(50)];
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [opens, setOpens] = React.useState(false);
    let {id} = useParams<{ id: string }>();
    const [productIds, setProductIds] = React.useState("");
    const [name, setName] = React.useState("");
    const [position, setPosition] = React.useState(0);
    const [completeCarId, setCompleteCarId] = React.useState(0);
    const handleSave = async ({...values}) => {
        setOpens(true);
        if (props.type === 'create') {
            values.name = name;
            values.position = position;
            values.completeCarId = completeCarId === 0 ? 1 : completeCarId;
            const aeroSeries = await dataProvider.create('aeroSeries', {data: {...values}});
            id = aeroSeries.data.id.toString()
            if (productIds !== "") {
                if (!isNaN(Number(productIds.replace(/\r?\n/g, '')))) {
                    await handleSaveAeroSeriesProduct({aeroSeriesId: 0, productIds: ""})
                } else {
                    await dataProvider.delete('aeroSeries', {
                        id: +id,
                        previousData: {id: values.id}
                    }).then();
                }
            } else {
                redirect('/aeroSeries/');
            }
        } else {
            delete values.set;

            await dataProvider.update('aeroSeries', {
                id: values.id,
                data: {...values},
                previousData: {id: values.id}
            }).then();
            if (productIds !== "") {
                if (!isNaN(Number(productIds.replace(/\r?\n/g, '')))) {
                    await handleSaveAeroSeriesProduct({aeroSeriesId: 0, productIds: ""})
                }
            } else {
                redirect('/aeroSeries/');
            }
        }
    }

    const handleSaveAeroSeriesProduct = async ({...values}) => {
        if (props.type === 'create' && id === undefined) {
            await handleSave({name: "", position: 0, completeCarId: 0})
        } else {
            values.aeroSeriesId = Number(id)
            values.productIds = productIds.replace(/\r?\n/g, ',')
                .replace(', ', '')
                .split(',')
                .map(Number)
                .filter((item: number) => item !== 0);

            await dataProvider.create('aeroSeriesProducts', {data: {...values}}).then();

            redirect('/aeroSeries/' + id.toString());
            window.location.reload()
        }
        refresh();
    }

    return (
        <>
            <SimpleForm
                {...props}
                save={handleSave}
                submitOnEnter={false}
                toolbar={<AeroSeriesToolbar/>}
                className={'productForm'}
            >
                <Grid container spacing={3} className={'w-100'}>
                    <Grid item md={12} lg={3}>
                        <TextInput source="name" label={'名称'} validate={validateName} fullWidth={true}
                                   onChange={event => {
                                       setName(event.target.value)
                                   }}/>
                        <NumberInputCommon className={'input-filled-basic'} source="position" label={'表示順序'}
                                           isRequired={true}
                                           onChange={event => {
                                               setPosition(event.target.value)
                                           }}/>
                        <ReferenceInput source="completeCarId" label="対応コンプリートカー" reference="completeCars"
                                        perPage={MAX_FETCH_DATA_SIZE} sort={{field: 'id', order: 'ASC'}}
                                        onChange={event => {
                                            setCompleteCarId(event.target.value)
                                        }}>
                            <SelectInput source="name" fullWidth={true}/>
                        </ReferenceInput>
                        <SimpleForm
                            {...props}
                            submitOnEnter={false}
                            save={handleSaveAeroSeriesProduct}
                            toolbar={
                                <AeroSeriesProductsToolbar/>
                            }
                            className={'productIdsInput'}
                        >
                            <TextInput multiline source="productIds" label={'シリーズ商品'}
                                       validate={validateAeroSeriesProductIds}
                                       placeholder={'1\n2\n3\n4'} fullWidth={true} rows={20} isRequired={true}
                                       onChange={event => {
                                           setProductIds(event.target.value)
                                       }}
                            />
                        </SimpleForm>
                    </Grid>
                    <Grid item md={8} lg={8} xl={8}>
                        <Grid container spacing={3} className={'ml-80'}>
                            <Grid item lg={12}>
                                <Grid container>
                                    <AeroSeriesProductList aeroSeriesId={id ? Number(id) : 0}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleForm>
        </>
    );
};

const ActionTopToolbar: React.FC = () => (
    <div className={'mb-30'}>
        <Button
            component={Link}
            label={'戻る'}
            variant="contained"
            color={'default'}
            to={{pathname: "/aeroSeries"}}
        />
    </div>
);

const AeroSeriesToolbar: React.FC = (props) => {
    const [scrollY, setScrollY] = useState(0)

    useEffect(() => {
        document.addEventListener('scroll', function () {
            setScrollY(window.scrollY)
        });
    }, [scrollY])

    return (
        <Toolbar {...props} className={'bg-none'}>
            <div
                style={{top: (57 + (-scrollY)) + 'px'}}
                className={`app-bar-save-button`}>
                <SaveButton {...props} />
            </div>
        </Toolbar>
    )
}

const AeroSeriesProductsToolbar: React.FC = (props) => {
    return (
        <Toolbar {...props} className={'bg-none'}>
            <SaveButton {...props} label={'追加'}/>
        </Toolbar>
    )
}


export const AeroSeriesCreate: React.FC = (props: any) => {
    return (
        <>
            <Create {...props} actions={<ActionTopToolbar/>}>
                <AeroSeriesForm {...props} type={'create'}/>
            </Create>
        </>
    )
}


export const AeroSeriesEdit: React.FC = (props: any) => {
    return (
        <>
            <Edit {...props} mutationMode={'optimistic'} actions={<ActionTopToolbar/>}>
                <AeroSeriesForm {...props} type={'update'}/>
            </Edit>
        </>
    )
}

export default AeroSeriesForm;
