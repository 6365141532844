import React, {useState} from "react";
import {
    Create,
    Edit,
    maxLength,
    required,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRedirect,
    useRefresh
} from 'react-admin';
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import GoBack from "../../common/GoBackComponent";
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import CarModelImage from "./CarModelImageComponent";
import {circularSize, ImageDirectory, ResourceNames} from "../../config/const";
import {saveImages} from "../../common/image/databaseImageUtils";
import {useStyles} from "../../common/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Box, Grid, Typography} from "@material-ui/core";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";

const ActionTopToolbar: React.FC = () => (
    <Box mb={2}>
        <GoBack/>
    </Box>
);

const CarModelsForm: React.FC = (props: any) => {

    const validateName = [required(), maxLength(50)];
    const record = useRecordContext();
    const carId = Number(record['carId']);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [pathData, setPathData] = useState(new Array<TempFileData>());
    const [images, setImages] = useState<ListPhotosType[] | undefined>()
    const [open, setOpen] = useState(false);

    const handleSave = async ({...values}) => {
        setOpen(true);
        let id = values.id
        if (id) {
            await dataProvider.update('carModels', {
                id: values.id,
                data: {...values},
                previousData: {id: values.id},
            })
        } else {
            const result = await dataProvider.create('carModels', {
                data: {...values},
            })
            id = result.data.id;
        }
        console.log('id', id);
        await saveImages(dataProvider, ResourceNames.CAR_MODEL_IMAGE_RESOURCE, {
            carModelId: id,
        }, ImageDirectory.CAR_MODEL_IMAGE + id + '/', pathData, images);
        console.log('saved images')
        notify('保存しました');
        redirect(`/carModels/list/${values.carId}`);
        refresh();
    }

    const ShowProgress: React.FC = () => {
        const classes = useStyles();
        return (
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" size={circularSize}/>
            </Backdrop>
        );
    }

    return (
        <>
            <ShowProgress/>
            <SimpleForm {...props}
                        save={handleSave}
                        toolbar={<ActionToolbar/>}
            >
                <div className={"w-100"}>
                    <Grid container={true} spacing={1}>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Grid container spacing={1}>
                                <Grid item md>
                                    <TextInputForJapanese source="name" validate={validateName} label={'モデル名'} fullWidth={true}/>
                                </Grid>
                            </Grid>
                            <NumberInputCommon source="position" label={'表示順序'} isRequired={true}/>
                            <TextInput
                                source="carId"
                                label={''}
                                defaultValue={`${carId}`}
                                disabled={true}
                                className="displayNone"
                            />
                            <hr />
                            <Typography variant="h5">シミュレーション座標</Typography>
                            <Typography variant="h6">
                                車体 {/* car body*/}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationCarBodyX"
                                                       label={'X'} isRequired={true} defaultValue={0}/>
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationCarBodyY"
                                                       label={'Y'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationCarBodyWidth"
                                                       label={'幅'} isRequired={true} defaultValue={0}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h6">
                                前輪 {/* front-wheel*/}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationFrontWheelX"
                                                       label={'X'} isRequired={true} defaultValue={0}/>
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationFrontWheelY"
                                                       label={'Y'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h6">
                                後輪 {/* rear-wheel*/}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationRearWheelX"
                                                       label={'X'} isRequired={true} defaultValue={0}/>
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationRearWheelY"
                                                       label={'Y'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h6">
                                前輪ベース画像 {/* base front-wheel*/}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationBaseFrontWheelX"
                                                       label={'X'} isRequired={true} defaultValue={0}/>
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationBaseFrontWheelY"
                                                       label={'Y'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h6">
                                後輪ベース画像{/* base rear-wheel*/}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationBaseRearWheelX"
                                                       label={'X'} isRequired={true} defaultValue={0}/>
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationBaseRearWheelY"
                                                       label={'Y'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h6">
                                前輪背景画像{/* front black image*/}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationFrontBlackX"
                                                       label={'X'} isRequired={true} defaultValue={0}/>
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationFrontBlackY"
                                                       label={'Y'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h6">
                                後輪背景画像{/* rear black image*/}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationRearBlackX"
                                                       label={'X'} isRequired={true} defaultValue={0}/>
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationRearBlackY"
                                                       label={'Y'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h6">
                                下部影{/* simulation shadow background image*/}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationShadowX"
                                                       label={'X'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationShadowY"
                                                       label={'Y'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <NumberInputCommon className={'input-filled-basic'} source="simulationShadowWidth"
                                                       label={'幅'} isRequired={true} defaultValue={0}
                                                       allowNegative={true}
                                                       max={750}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                            <Typography variant={'h6'}>(2枚)</Typography>
                            <CarModelImage
                                dataProvider={dataProvider}
                                setPathData={setPathData}
                                pathData={pathData}
                                setImages={setImages}
                                images={images}
                                carModelId={props.id}
                                maxFileCount={2}
                            />
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </>
    )
};

export const CarModelsCreate: React.FC = (props: any) => {

    return (
        <Create
            {...props}
            actions={<ActionTopToolbar/>}
        >
            <CarModelsForm {...props}/>
        </Create>
    )
};

export const CarModelsEdit: React.FC = (props: any) => {

    return (
        <Edit
            {...props}
            mutationMode={'optimistic'}
            actions={<ActionTopToolbar/>}
            // https://stackoverflow.com/a/63168730
            undoable={false}
        >
            <CarModelsForm {...props} />
        </Edit>
    )
};

export default CarModelsForm
