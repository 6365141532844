import React, {useEffect, useState} from 'react';
import {SaveButton, Toolbar} from 'react-admin';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {circularSize} from "../config/const";
import {useStyles} from "./styles";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";

interface ActionToolbarProps {
    isTemplates?: boolean;
    isGeneralCharges?: boolean;
    isSettings?: boolean;
    opens?: any;
    disableSave?: boolean;
    floatingRight?: boolean;
}

export const ActionToolbar: React.FC<ActionToolbarProps> = (props) => {
    const classes = useStyles();
    const [scrollY, setScrollY] = useState(0)

    useEffect(() => {
        document.addEventListener('scroll', function () {
            setScrollY(window.scrollY)
        });
    }, [scrollY])

    return (
        <Toolbar {...props}>
            <div
                style={
                    props.floatingRight ? {right: 16, left: '80%'} : {top: (57 + (-scrollY)) + 'px'}
                }
                className={`app-bar-save-button ${(props.isGeneralCharges || props.isTemplates || props.isSettings) && ' ml-9'}`}>
                <SaveButton disabled={props.disableSave} {...props} />
            </div>
            <SaveButton disabled={props.disableSave} {...props} />
            {props.isTemplates &&
                <Button className={'ml-10 secondary'} href={'/templates'}> <CancelIcon className={'f-20'}/> キャンセル
                </Button>}
            <Backdrop className={classes.backdrop} open={props.opens}>
                <CircularProgress color="inherit" size={circularSize}/>
            </Backdrop>
        </Toolbar>
    );
}
