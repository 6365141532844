import React, {useEffect, useState} from "react";
import {uploadProductImageToFirebase} from "../../common/image/databaseImageUtils";
import {downloadCSV, useDataProvider, useRedirect, useRefresh} from "react-admin";
import {TempFileData} from "../../modules/temporaryFileModule";
import {MAX_FETCH_DATA_SIZE, ProductExportField, ProductExportItem, productTranslatedItems} from "../../config/const";
import {useStore} from "react-redux";
import {ApiNoCombination, ApiProduct} from "../../openapi";
import {unparse as convertToCSV} from "papaparse";
import {deleteFileFromUrl, listFiles} from "../../utils/upload";

export const useProductsHooks = ({...props}) => {
    const store = useStore();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [productImgId, setProductImgId] = useState(0);
    const [fieldDisabled, setFieldDisabled] = useState<boolean | number | null>(null);
    const [fieldSelected, setFieldSelected] = useState(0);
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [pathUrl, setPathUrl] = useState('');
    const [productImgThumbPath, setProductImgThumbPath] = useState('');
    const [productImgPathPath, setProductImgPathPath] = useState('');
    const [opens, setOpens] = React.useState(false);
    const [thumbnailData, setThumbnailData] = useState<Array<TempFileData>>([]);
    const [pathData, setPathData] = useState<Array<TempFileData>>([]);
    const [modelsData, setModelsData] = useState<Record<any, any>>([]);
    const [selectedModelsData, setSelectedModelsData] = useState<Array<string>>([]);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [finalErrorMessages, setFinalErrorMessages] = useState<string[]>([]);
    const [productImgPaths, setProductImgPaths] = useState('');
    const [noCombinationList, setNoCombinationList] = useState<ApiNoCombination[]>([])
    const [addedCombination, setAddedCombination] = useState(0);
    const [targetProductId, setTargetProductId] = useState(0);
    const [refreshList, setRefreshList] = useState(true);
    const [selectedSuspensionVariantItems, setSelectedSuspensionVariantItems] = useState<Array<string>>([]);
    const [isSuspensionCategory, setIsSuspensionCategory] = useState(false);
    const [savedSuspensionVariantItems, setSavedSuspensionVariantItems] = useState<Array<string>>();
    const [isCreate, setIsCreate] = React.useState(false);
    useEffect(() => {
        const awaitFn = async () => {
            if (props.type === 'update') {
                const {data} = await dataProvider.getOne('products', {id: props.id});
                console.log("Product ", data);
                if (data.productCategory) {
                    if (data.productCategory.suspension) {
                        setIsSuspensionCategory(true);
                    } else {
                        if (data.productCategory.parent) {
                            if (data.productCategory.parent.suspension) {
                                setIsSuspensionCategory(true);
                            }
                        }
                    }
                }
                const currentSuspensionVariantItems = await dataProvider.getList('productSuspensionSettings', {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'createdAt', order: 'ASC'},
                    filter: {productId: parseInt(props.id)},
                });
                if (currentSuspensionVariantItems.data.length > 0) {
                    const array = new Array<string>();
                    currentSuspensionVariantItems.data.map((item) => {
                        array.push(item.variantItem.id.toString());
                    });
                    setSavedSuspensionVariantItems([...array]);
                    setSelectedSuspensionVariantItems([...array]);
                } else {
                    setSavedSuspensionVariantItems([]);
                }
                const productImages = await dataProvider.getList('productImages', {
                    pagination: {page: 1, perPage: 10000},
                    sort: {field: 'createdAt', order: 'DESC'},
                    filter: {productId: props.id},
                });
                productImages.data.map(async (image: Record<any, any>) => {
                    setProductImgId(Number(image.id));
                    setProductImgThumbPath(image.thumbnailPath);
                    setProductImgPathPath(image.path);
                    const directory = image.thumbnailPath !== '' ? image.thumbnailPath.split('/')[0] + '/' + image.thumbnailPath.split('/')[1] :
                        image.path.split('/')[0] + '/' + image.path.split('/')[1];

                    setProductImgPaths(directory);
                    const list = await listFiles(directory);
                    list.map(async (item: any) => {
                        if (item) {
                            const imageUrl = await item.getDownloadURL();
                            if (item.fullPath.split('/')[2] === 'thumbnail') {
                                image.thumbnailPath !== '' && setThumbnailUrl(imageUrl);
                            } else {
                                image.path !== '' && setPathUrl(imageUrl);
                            }
                        }
                    })
                });
                const productMountableCarModels = await dataProvider.getList('productMountableCarModels', {
                    pagination: {page: 1, perPage: 10000},
                    sort: {field: 'createdAt', order: 'ASC'},
                    filter: {productId: props.id},
                });
                const array: Array<string> = [];
                productMountableCarModels.data.map(({...data}) => {
                    setModelsData(data.makers);
                    data.makers.map(({...maker}) => {
                        maker.cars.map(({...car}) => {
                            car.carModels.map(({...carModel}) => {
                                if (carModel.checked) {
                                    array.push(`${car.id}~${carModel.id}`);
                                }
                            });
                        });
                    });
                })
                setSelectedModelsData(array);
            } else if (props.type === 'create') {
                const productMountableCarModels = await dataProvider.getList('productMountableCarModels', {
                    pagination: {page: 1, perPage: 10000},
                    sort: {field: 'createdAt', order: 'DESC'},
                    filter: {},
                });
                productMountableCarModels.data.map(({...data}) => {
                    setModelsData(data.makers)
                });
                setIsCreate(true);
                setSavedSuspensionVariantItems([]);
            }
        }
        awaitFn().then();
    }, [props.type]);

    const handleIsSuspensionCategory = (productCategoryId: any) => {
        const getData = async () => {
            const {data} = await dataProvider.getOne('productCategories', {id: productCategoryId});
            if (data.suspension) {
                setIsSuspensionCategory(true);
            }
            if (!data.suspension && data.parent) {
                if (data.parent.suspension) {
                    setIsSuspensionCategory(true);
                } else {
                    setIsSuspensionCategory(false);
                }
            }
            if (!data.suspension) {
                setIsSuspensionCategory(false);
            }
        }
        getData().then();
    }

    useEffect(() => {
        // @ts-ignore
        const uniqueArray = [...new Map([...finalErrorMessages, ...errorMessages].map((o) => [o, o])).values()];
        setFinalErrorMessages(uniqueArray);
    }, [errorMessages])
    const handleSave = async ({...values}) => {
        if (selectedModelsData.length) {
            setOpens(true);
        }
        if (props.type === 'create') {
            console.log('create');
            const products = await dataProvider.create('products', {data: {...values}});
            const uploadedImage = await uploadProductImageToFirebase(thumbnailData, pathData, products.data);
            await dataProvider.create('productImages', {
                data: {
                    productId: products.data.id,
                    thumbnailPath: uploadedImage.thumbnailPath,
                    path: uploadedImage.path
                }
            });
            selectedModelsData.map((model: string) => {
                dataProvider.create('productMountableCarModels', {
                    data: {
                        productId: products.data.id,
                        carModelId: Number(model.split('~')[1])
                    }
                });
            });
            if (selectedSuspensionVariantItems.length > 0 || (savedSuspensionVariantItems?.length || 0) > 0) {
                const rest = await dataProvider.create(`productSuspensionSettings/${products.data.id}/saveMany`, {
                    data: {
                        variantItemIds: selectedSuspensionVariantItems
                    }
                });
                console.log('rest', rest);
            }
            redirect('/products');
            refresh();
        } else {
            delete values.set;
            const products = await dataProvider.update('products', {
                id: values.id,
                data: {...values},
                previousData: {id: values.id}
            });
            if (selectedModelsData.length === 0) {
                try {
                    await dataProvider.update('productMountableCarModels', {
                        id: values.id,
                        data: {},
                        previousData: {id: values.id}
                    });
                } catch (e) {
                    console.warn(e);
                }
            }
            await Promise.all(selectedModelsData.map(async (ids: string, index: number) => {
                if (index === 0) { // first PUT delete all data for the product
                    console.log('modelDeleteNumber', ids)
                    try {
                        await dataProvider.update('productMountableCarModels', {
                            id: values.id,
                            data: {productId: values.id, carModelId: Number(ids.split('~')[1])},
                            previousData: {id: values.id}
                        });
                    } catch (e) {
                        console.warn(e);
                    }
                }
            }));
            await Promise.all(selectedModelsData.map(async (models: string) => {
                console.log('modelCreateNumber', models)
                await dataProvider.create('productMountableCarModels', {
                    data: {
                        productId: values.id,
                        carModelId: Number(models.split('~')[1])
                    }
                });
            }));

            const uploadedImage = await uploadProductImageToFirebase(thumbnailData, pathData, products.data);
            if (productImgId > 0) {
                if (uploadedImage.thumbnailPath !== '') {
                    await dataProvider.update('productImages', {
                        id: productImgId,
                        data: {
                            productId: products.data.id,
                            thumbnailPath: uploadedImage.thumbnailPath,
                            path: productImgPathPath
                        },
                        previousData: {id: productImgId}
                    });
                }
                if (uploadedImage.path !== '') {
                    await dataProvider.update('productImages', {
                        id: productImgId,
                        data: {
                            productId: products.data.id,
                            thumbnailPath: productImgThumbPath,
                            path: uploadedImage.path
                        },
                        previousData: {id: productImgId}
                    });
                }
            } else {
                await dataProvider.create('productImages', {
                    data: {
                        productId: products.data.id,
                        thumbnailPath: uploadedImage.thumbnailPath,
                        path: uploadedImage.path
                    }
                });
            }

            if (selectedSuspensionVariantItems.length > 0 || (savedSuspensionVariantItems?.length || 0) > 0) {
                const rest = await dataProvider.create(`productSuspensionSettings/${values.id}/saveMany`, {
                    data: {
                        variantItemIds: selectedSuspensionVariantItems
                    }
                });
                console.log('rest', rest);
            }

            redirect('/products');
            refresh();
        }
    };


    const handleUpload = async (acceptedFiles: File, field: string) => {
        let directory = '';
        if (acceptedFiles) {
            const reader = new FileReader();
            let fileBinary: string | ArrayBuffer | null | undefined = null;
            reader.onload = async (item) => {
                fileBinary = item.target?.result;
                if (directory === '' || directory === undefined) {
                    directory = `productImages/`;
                }
                field === 'thumbnailPath' ? setThumbnailData(new Array({
                        dir: directory,
                        fileBinary: fileBinary,
                        filename: 'thumbnail',
                        fileType: acceptedFiles.type
                    })) :
                    setPathData(new Array({
                        dir: directory,
                        fileBinary: fileBinary,
                        filename: 'path',
                        fileType: acceptedFiles.type
                    }));
            };
            reader.readAsDataURL(acceptedFiles);
        }
    }
    const handleSelectAllMakersCarModel = (cars: Array<Record<any, any>>) => {
        const array: Array<string> = [];
        cars.map((car: Record<any, any>) => {
            const carId = car.id;
            car.carModels.map((el: Record<any, any>) => {
                array.push(`${carId}~${el.id}`)
            });
        })
        // @ts-ignore
        const uniqueArray = [...new Map([...selectedModelsData, ...array].map((o) => [o, o])).values()];
        setSelectedModelsData(uniqueArray);
    }
    const handleSelectAllCarModel = (id: string, carModels: Array<Record<any, any>>) => {
        const array: Array<string> = [];
        carModels.map((el: Record<any, any>) => {
            array.push(`${id}~${el.id}`)
        });
        // @ts-ignore
        const uniqueArray = [...new Map([...selectedModelsData, ...array].map((o) => [o, o])).values()];
        setSelectedModelsData(uniqueArray);
    }

    const handleSelectedSuspensionVariantItems = (id: string) => {
        if (selectedSuspensionVariantItems.includes(id)) {
            setSelectedSuspensionVariantItems(selectedSuspensionVariantItems.filter(item => item !== id));
        } else {
            setSelectedSuspensionVariantItems([...selectedSuspensionVariantItems, id]);
        }
    }

    const parentRenderer = (choice: Record<any, any>) => {
        if (choice) {
            return choice.parentName && choice.name ?
                `${choice.parentName}/${choice.name}` : choice.name;
        }
    }
    const openImage = (imageURL: string) => {
        window.open(imageURL);
    }
    const handleTransformRows = async (values: ProductExportItem[]) => {
        return values.map(({...item}) => {
            let importItem: ApiProduct = {
                id: 0,
                productCategoryId: 0,
                productCategoryNames: new Array<string>(),
                backboneCoreSystemCode: '',
                name: '',
                onlyWithCar: false,
                price: 0,
                priceForPartsOnly: 0,
                cost: 0,
                originalPrice: 0,
                structuralProcedureFee: 0,
                alignmentAdjustingFee: 0,
                isSet: false,
                forNew: false,
                forUsed: false,
                whetherPublic: true,
                position: 0,
            };
            importItem.id = item.商品ID;
            importItem.productCategoryId = item.商品カテゴリID;
            importItem.productCategoryNames = !(item.商品カテゴリ名 && item.商品カテゴリ名.length > 0) ? [] : item.商品カテゴリ名.split('/');
            importItem.backboneCoreSystemCode = item.基幹商品コード;
            importItem.name = item.商品名;
            importItem.onlyWithCar = item.車種購入のみ > 0;
            importItem.price = item.売価;
            importItem.priceForPartsOnly = item.部品売価;
            importItem.cost = item.原価;
            importItem.originalPrice = item.定価;
            importItem.structuralProcedureFee = item.構造変更手続費用;
            importItem.alignmentAdjustingFee = item.アライメント調整費用;
            importItem.isSet = item.セット品 > 0;
            importItem.forNew = item.新車 > 0;
            importItem.forUsed = item.中古 > 0;
            importItem.position = item.表示順序;
            importItem.whetherPublic = item.公開 > 0;

            return importItem;
        });
    }
    const handleValidate = async (values: any, row: number) => {
        let col = 0;
        const categories = store.getState()['admin']['resources']['productCategories']['data'];
        Object.keys(values).map(async (key, index) => {
            if ((index !== 0) && (values[key] === null || values[key] === '')) {
                col = index + 1;
                return true;
            } else if (key === 'productCategoryNames' && values[key].length === 0) {
                col = index + 1;
                return true;
            } else if (key === 'productCategoryId' && values[key] !== '') {
                const categoryExits = Object.keys(categories).find((d: string) => categories[d].id === values[key]);
                if (categoryExits && categoryExits.length > 0) {
                    return false;
                } else {
                    col = index + 1;
                    return true;
                }
            } else {
                if ((index < 2 || index > 5) && index !== 0 && index !== 12 && index !== 13 && index !== 14 && index !== 15 && (typeof values[key] !== 'number' || (Number(values[key]) < 0))) {
                    col = index + 1;
                    return true;
                }
                return false;
            }
        });
        if (col > 0) {
            return `${row + 1}行目の${productTranslatedItems[col]}が無効です。`
        } else {
            const exist = await dataProvider.searchById('products', {id: Number(values.id)});
            if (!exist.data) {
                values.id = 0;
            }
            return values;
        }
    }
    const handleExportData = (data: ApiProduct[]) => {
        const productsForExport = data.map((item: ApiProduct) => {
            let exportItem: ProductExportItem = {
                商品ID: 0,
                商品カテゴリID: 0,
                商品カテゴリ名: '',
                基幹商品コード: '',
                商品名: '',
                車種購入のみ: 0,
                売価: 0,
                部品売価: 0,
                原価: 0,
                定価: 0,
                構造変更手続費用: 0,
                アライメント調整費用: 0,
                セット品: 0,
                新車: 0,
                中古: 0,
                表示順序: 0,
                公開: 1,
            };
            exportItem.商品ID = item.id ? item.id : 0;
            exportItem.商品カテゴリID = item.productCategoryId;
            exportItem.商品カテゴリ名 = !(item.productCategoryNames && item.productCategoryNames.length > 0) ? '' : item.productCategoryNames.join('/');
            exportItem.基幹商品コード = item.backboneCoreSystemCode;
            exportItem.商品名 = item.name;
            exportItem.車種購入のみ = item.onlyWithCar ? 1 : 0;
            exportItem.売価 = item.price;
            exportItem.部品売価 = item.priceForPartsOnly;
            exportItem.原価 = item.cost;
            exportItem.定価 = item.originalPrice;
            exportItem.構造変更手続費用 = item.structuralProcedureFee;
            exportItem.アライメント調整費用 = item.alignmentAdjustingFee;
            exportItem.セット品 = item.isSet ? 1 : 0;
            exportItem.表示順序 = item.position;
            exportItem.公開 = item.whetherPublic ? 1 : 0;
            exportItem.新車 = item.forNew ? 1 : 0;
            exportItem.中古 = item.forUsed ? 1 : 0;

            return exportItem;
        });
        const csv = convertToCSV({
            data: productsForExport,
            fields: ProductExportField,
        });
        const blob = new Blob(["\uFEFF" + csv], {
            type: 'text/csv; charset=utf-18'
        });
        downloadCSV(blob, 'products');
    };
    const closeModal = () => {
        setErrorMessages([]);
        setFinalErrorMessages([]);
        refresh();
    }
    const handleSearchOnce = (data: number) => {
        if (!isNaN(data)) {
            setFieldSelected(data)
            setFieldDisabled(!data ? null : data);
        } else {
            setFieldSelected(0)
            setFieldDisabled(null);
        }
    }

    const modelRenderer = (choice: Record<any, any>) => choice.name ? `${choice.carName} ${choice.name}` : '';

    const deleteImage = async (
        id: number,
        path: string,
        imgDir: string,
        productId: number,
        thumbPath: string,
        target: string
    ) => {
        target.includes('thumbnail') ?
            path !== '' ?
                await updateImgData(id, imgDir, productId, path, '') : await deleteImgData(id, imgDir) :
            thumbPath !== '' ?
                await updateImgData(id, imgDir, productId, '', thumbPath) : await deleteImgData(id, imgDir);
    }

    const deleteImgData = async (id: number, imgDir: string) => {
        await dataProvider.delete('productImages', {
            id: id,
            previousData: {id: id},
        });
        const list = await listFiles(imgDir);
        await Promise.all(list.map(async (item: any) => {
            if (item) {
                const imageUrl = await item.getDownloadURL();
                imageUrl && await deleteFileFromUrl(imageUrl);
            }
        })).then(() => window.location.reload());
    }

    const updateImgData = async (
        id: number,
        imgDir: string,
        productId: number,
        path: string,
        thumbPath: string
    ) => {
        await dataProvider.update('productImages', {
            id: id,
            data: {
                productId: productId,
                thumbnailPath: thumbPath,
                path: path
            },
            previousData: {id: id},
        });
        window.location.reload();
    }

    const addNoCombination = async (id: number) => {
        let addFlag = true;

        const availableProduct = await dataProvider.getOne('products', {id: addedCombination}).then(() => {
            return true;
        }).catch(() => {
            return false;
        })

        await Promise.all(
            noCombinationList.map((item) => {
                if (item.notAllowedProductId === addedCombination || item.productId === addedCombination) {
                    addFlag = false;
                }
            })
        )

        if (!addFlag) {
            alert('既にデータが登録されています');
            return 'data added already';
        }
        if (!availableProduct) {
            alert('該当データが存在しません')
            return 'data does not exist';
        }

        if (addedCombination !== 0) {
            console.log(id)
            await dataProvider.create('noCombinations', {
                data: {
                    productId: targetProductId,
                    notAllowedProductId: addedCombination,
                }
            }).then(e => {
                setRefreshList(false);
            });
        }
        setAddedCombination(0);
        setRefreshList(true);
        return 'success';
    }

    const deleteCombination = async (id: number) => {
        if (window.confirm('削除しても宜しいでしょうか。')) {
            await dataProvider.delete('noCombinations', {
                id: id,
                previousData: {id: id},
            }).then(e => {
                console.log(e)
                setRefreshList(false);
            });
            setAddedCombination(0);
            setRefreshList(true)
        }
    }

    useEffect(() => {
        const awaitFn = async () => {
            const editingId = props.id ?? 0
            const noCombinationsList = await dataProvider.getList('noCombinations', {
                pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                sort: {field: 'id', order: 'ASC'},
                filter: {
                    bothProductId: editingId
                },
            });

            const arrayNoCombinationList: Array<ApiNoCombination> = [];
            noCombinationsList.data.map((item) => {
                if (item.id) {
                    arrayNoCombinationList.push(item as ApiNoCombination);
                }
            })

            setNoCombinationList(arrayNoCombinationList)
        }
        awaitFn().then(() => {
        });

    }, [refreshList]);

    return {
        parentRenderer,
        handleSelectAllCarModel,
        handleSelectAllMakersCarModel,
        handleUpload,
        handleSave,
        opens,
        pathUrl,
        thumbnailUrl,
        selectedModelsData,
        modelsData,
        setSelectedModelsData,
        openImage,
        handleValidate,
        closeModal,
        setErrorMessages,
        finalErrorMessages,
        handleTransformRows,
        handleExportData,
        fieldDisabled,
        fieldSelected,
        handleSearchOnce,
        modelRenderer,
        deleteImage,
        productImgId,
        productImgThumbPath,
        productImgPathPath,
        productImgPaths,
        noCombinationList,
        addedCombination,
        setAddedCombination,
        addNoCombination,
        setTargetProductId,
        deleteCombination,
        handleSelectedSuspensionVariantItems,
        isSuspensionCategory,
        handleIsSuspensionCategory,
        savedSuspensionVariantItems,
        isCreate
    }
}
