import React, {useCallback} from "react";
import {
    BooleanInput,
    Button,
    Create,
    Edit,
    ImageField,
    ImageInput,
    maxLength,
    NullableBooleanInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
} from "react-admin";
import {MAX_FETCH_DATA_SIZE, maxFileSize} from "../../config/const";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Link, useParams} from "react-router-dom";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useProductsHooks} from "./productsHooks";
import ProductsImages from "./ProductsImages";
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {Box, Grid, TableCell, TableRow, Typography} from "@material-ui/core";
import {useForm} from 'react-final-form';
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import {ActionToolbar} from "../../common/ActionToolbar";
import ProductSuspensionVariantItems from "./ProductSuspensionVariantItems";
import kusCoreSystemUtil from "../../utils/kusCoreSystemUtil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        buttonDanger: {
            backgroundColor: '#ff0000 !important'
        }
    }),
);

const ProductForm = ({...props}) => {
    const {
        parentRenderer,
        handleSelectAllCarModel,
        handleSelectAllMakersCarModel,
        handleUpload,
        handleSave,
        opens,
        pathUrl,
        thumbnailUrl,
        selectedModelsData,
        modelsData,
        setSelectedModelsData,
        openImage,
        deleteImage,
        productImgId,
        productImgThumbPath,
        productImgPathPath,
        productImgPaths,
        noCombinationList,
        setAddedCombination,
        addNoCombination,
        setTargetProductId,
        deleteCombination,
        handleSelectedSuspensionVariantItems,
        isSuspensionCategory,
        handleIsSuspensionCategory,
        savedSuspensionVariantItems,
        isCreate
    } = useProductsHooks({...props});

    const AddNoCombinationWithClearButton = ({...props}) => {
        const form = useForm();
        const handleClick = useCallback(() => {
            addNoCombination(props.id).then(e => {
                console.log(e)
            })
            form.change('idForCombination', undefined);
        }, [form]);

        return (
            <Button onClick={handleClick} {...props}/>
        );
    };

    const DeleteNoCombinationButton = ({...props}) => {
        const form = useForm();
        const classes = useStyles();
        const handleClick = useCallback(() => {
            deleteCombination(props.id).then();
        }, [form]);

        return (
            <Button onClick={handleClick} className={classes.buttonDanger} {...props} />
        );
    }

    const onChangeGetCategoryId = async (e: React.ChangeEvent<HTMLInputElement>) => {
        handleIsSuspensionCategory(e.target.value);
    }


    return (
        <SimpleForm
            {...props}
            save={handleSave}
            submitOnEnter={false}
            toolbar={
                <ActionToolbar
                    opens={opens}
                />
            }
            className={'productForm'}
        >
            <div className={'grid-container'}>
                <div>
                    <label>商品編集</label>
                    <div className={'grid-container'}>
                        <div>
                            <ReferenceInput
                                label="カテゴリ名"
                                autoWidth={true}
                                source="productCategoryId"
                                reference="productCategories"
                                validate={[required()]}
                                perPage={MAX_FETCH_DATA_SIZE}
                                sort={{field: 'position', order: 'ASC'}}
                                onChange={onChangeGetCategoryId}
                            >
                                <SelectInput optionText={parentRenderer}/>
                            </ReferenceInput>
                            <TextInputForJapanese source="backboneCoreSystemCode" validate={[required(), maxLength(50)]}
                                                  label={'基幹コード'}/>
                            <TextInputForJapanese source="name" validate={[required(), maxLength(250)]}
                                                  label={'商品名'}/>
                            <NullableBooleanInput source="onlyWithCar" className={'w-100'} label={'車両購入のみ'}
                                                  validate={[required()]}/>
                            <NumberInputCommon source="price" label={'売価'}/>
                            <NumberInputCommon source="priceForPartsOnly" label={'部品売価'}/>
                            <NumberInputCommon source="cost" label={'原価'}/>
                            <NumberInputCommon source="originalPrice" label={'定価'}/>
                        </div>
                        <div>
                            <NumberInputCommon source="structuralProcedureFee" label={'構造変更手続費用'}/>
                            <NumberInputCommon source="alignmentAdjustingFee" label={'アライメント調整費用'}/>
                            <NullableBooleanInput source="isSet" className={'w-100'} label={'セット品'}
                                                  validate={[required()]}/>
                            <Box display={'flex'} className={'mt-19-px'}>
                                <BooleanInput
                                    source="forNew"
                                    defaultValue={props.type === 'create' ? true : props.record.forNew}
                                    label="新車"
                                    parse={(value: boolean) => value}
                                />
                                <BooleanInput
                                    source="forUsed"
                                    defaultValue={props.type === 'create' ? true : props.record.forUsed}
                                    label="中古"
                                    parse={(value: boolean) => value}
                                />
                            </Box>
                            <NumberInputCommon source="position" label={'表示順序'}/>
                            <NullableBooleanInput source="whetherPublic" className={'w-100'} label={'公開'}
                                                  validate={[required()]}/>
                        </div>
                    </div>
                    <div>
                        <Grid container={true} spacing={2}>
                            <Grid item={true}>
                                <NumberInputCommon label={'商品ID'} source={''}
                                                   name={'idForCombination'} allowEmpty isRequired={false}
                                                   onChange={(e) => {
                                                       setAddedCombination(Number(e.target.value));
                                                       setTargetProductId(Number(props.id));
                                                   }}/>
                            </Grid>
                            <Grid item={true}>
                                <Box mt={2}>
                                    <AddNoCombinationWithClearButton variant="contained" label={'追加'} id={props.id}
                                                                     disabled={isCreate}/>
                                </Box>
                            </Grid>
                        </Grid>
                        <div>
                            <Grid>
                                <Typography variant="h6" id="tableTitle" component="div">同時選択不可商品</Typography>
                            </Grid>
                            <Grid>
                                <TableRow>
                                    <TableCell align="right" className={'nowrap'}>商品ID</TableCell>
                                    <TableCell align="left">商品名</TableCell>
                                </TableRow>
                                {noCombinationList.map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell
                                                align="right">{Number(props.id) === item.productId ? item.notAllowedProductId : item.productId}</TableCell>
                                            <TableCell
                                                align="left">{Number(props.id) === item.productId ? item.notAllowedProductName : item.productName}</TableCell>
                                            <TableCell>
                                                <DeleteNoCombinationButton variant={"contained"} label={'削除'}
                                                                           id={Number(item.id)}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Grid>
                        </div>
                        {isSuspensionCategory &&
                            <ProductSuspensionVariantItems
                                productId={props.record.id}
                                handleSelectedSuspensionVariantItems={handleSelectedSuspensionVariantItems}
                                savedSuspensionVariantItems={savedSuspensionVariantItems}/>}
                    </div>
                </div>
                <div className={'grid-child'}>
                    <label>画像アップロード</label>
                    <div className={'grid-container mb-30'}>
                        <div className={'grid-child'}>
                            <ImageInput
                                source="thumbnailPath"
                                accept="image/*"
                                label="サムネイル(1枚)"
                                maxSize={maxFileSize}
                                onChange={(files: File) => handleUpload(files, 'thumbnailPath')}
                                validate={props.type === 'update' || thumbnailUrl ? [] : [required()]}
                            >
                                <ImageField source="thumbnailPath" title="thumbnailImg"/>
                            </ImageInput>
                            {thumbnailUrl ?
                                <ProductsImages
                                    imageURL={thumbnailUrl}
                                    openImage={openImage}
                                    deleteImage={deleteImage}
                                    id={productImgId as number}
                                    path={productImgPathPath}
                                    thumbPath={productImgThumbPath}
                                    imgDir={productImgPaths}
                                    productId={props.id}
                                /> : null}
                        </div>
                        <div className={'grid-child'}>
                            <ImageInput
                                source="path"
                                label="拡大(1枚)"
                                accept="image/*"
                                maxSize={maxFileSize}
                                onChange={(files: File) => handleUpload(files, 'path')}
                                validate={props.type === 'update' && pathUrl ? [] : []}
                            >
                                <ImageField source="path" title="pathImg"/>
                            </ImageInput>
                            {pathUrl ?
                                <ProductsImages
                                    imageURL={pathUrl}
                                    openImage={openImage}
                                    deleteImage={deleteImage}
                                    id={productImgId as number}
                                    path={productImgPathPath}
                                    thumbPath={productImgThumbPath}
                                    imgDir={productImgPaths}
                                    productId={props.id}
                                /> : null}
                        </div>
                    </div>
                    <div>
                        {modelsData.map(({...models}) => {
                            return (
                                <div className={'mb-30'}>
                                    <div>
                                        <label>
                                            <strong>{models.name}</strong>
                                        </label>
                                        <Button variant="contained"
                                                size={'small'}
                                                color={'default'}
                                                className={'ml-30'}
                                                onClick={() => handleSelectAllMakersCarModel(models.cars)}
                                                label={'全てチェック'}/>
                                    </div>
                                    <div>
                                        {models.cars ? models.cars.map(({...car}) => {
                                            if (car.carModels) {
                                                return (
                                                    <div className={'car-model-container'}>
                                                        <div className={'car-model-child ml-30 mt-15'}>
                                                            <label>
                                                                {car.name}
                                                            </label>
                                                        </div>
                                                        <div className={'car-model-child mt-05'}>
                                                            <CheckBoxGroup
                                                                choices={car.carModels}
                                                                carId={car.id}
                                                                carName={car.name}
                                                                source={'carModelId'}
                                                                setSelected={setSelectedModelsData}
                                                                selected={selectedModelsData}
                                                            />
                                                        </div>
                                                        <div className={'car-model-child-button mt-10'}>
                                                            <Button
                                                                variant="contained"
                                                                color={'default'}
                                                                onClick={() => handleSelectAllCarModel(car.id, car.carModels)}
                                                                label={'全てチェック'}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }) : null}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>

        </SimpleForm>
    )
}

const CheckBoxGroup = ({...props}) => {
    const onChange = (event: any) => {
        if (event.target.checked) {
            if (!props.selected.includes(event.target.id)) {
                props.setSelected([...props.selected, event.target.id])
            }
        } else {
            props.setSelected(props.selected.filter((d: string) => d !== event.target.id))
        }
    }
    if (!props.choices) {
        return null;
    }
    return props.choices.map((data: Record<any, any>) => {
        return (
            <FormControlLabel
                htmlFor={`${props.carId}~${data.id}`}
                key={data.id}
                className={props.className}
                onChange={onChange}
                control={
                    <Checkbox
                        id={`${props.carId}~${data.id}`}
                        color="primary"
                        checked={!!props.selected.find((v: string) => v === `${props.carId}~${data.id}`)}
                    />
                }
                label={`${data.name}`}
            />
        )
    })
}

const ActionTopToolbar = () => (
    <div className={'mb-30 '}>
        <Button
            component={Link}
            label={'戻る'}
            variant="contained"
            color={'default'}
            to={{pathname: "/products"}}
        />
        <LinkProductVariants/>
        <LinkVariantItemProductPrice/>
        <LinkProductVariantItemImageCombination/>
        <LinkToKusCoreSystemProducts/>
    </div>
);

export const ProductCreate: React.FC = (props) => (
    <Create
        {...props}
        actions={<ActionTopToolbar/>}
        className={'w-125'}
    >
        <ProductForm {...props} type={'create'}/>
    </Create>
);

export const ProductEdit: React.FC = (props) => (
    <Edit
        {...props}
        mutationMode={'optimistic'}
        actions={<ActionTopToolbar/>}
        className={'w-125'}
    >
        <ProductForm {...props} type={'update'}/>
    </Edit>
);

const LinkProductVariants = () => {
    const {id} = useParams<{ id: string }>();
    return (
        <Button
            component={Link}
            label={'商品規格設定'}
            variant="contained"
            color={'secondary'}
            to={{pathname: `/productVariants/${id}`}}
            className={'mr-10'}
            style={{marginLeft: 105}}
        />
    );
}

const LinkVariantItemProductPrice = () => {
    const {id} = useParams<{ id: string }>();
    return (
        <Button
            component={Link}
            label={'規格項目価格設定'}
            variant="contained"
            color={'secondary'}
            to={{pathname: `/variantItemProductPrice/${id}`}}
            className={'mr-30'}
        />
    );
}

const LinkProductVariantItemImageCombination = () => {
    const {id} = useParams<{ id: string }>();
    return (
        <Button variant="contained" label={'商品規格項目画像編集'} color={'secondary'} className={'mr-30'}
                component={Link}
                to={{pathname: `/productVariantItemImageCombination/${id}`}}
        />
    )
}

const LinkToKusCoreSystemProducts = () => {
    const {id} = useParams<{ id: string }>();
    return (
        <a
            href={`${kusCoreSystemUtil().productsUrl()}/${id}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Button
                variant="contained"
                label={'基幹システムへ'}
                color={'default'}
                className={'mr-30'}
            />
        </a>
    )
}
