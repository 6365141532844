import React from "react";
import {AppLocationContext} from "@react-admin/ra-navigation";
import {Layout} from "react-admin";
import MenuBar from "./Menu";

export const MyLayout: React.FC = (props) => {
    return (
        <AppLocationContext>
            <Layout {...props} menu={MenuBar}/>
        </AppLocationContext>
    );
};

export default MyLayout
