export const floatValidation = (value: any, allValues: any) => {
    if (value && value.toString().includes('.')) {
        return `整数で入力してください`;
    }
    return ``;
};

export const lengthNumberValidation = (inputedValue: Array<number>) => {
    if (inputedValue && inputedValue.length > 10) {
        return '10文字以下である必要があります';
    }
    return '';
}
