import {useEffect} from "react";
import {MAX_FETCH_DATA_SIZE, ResourceNames} from "../../config/const";
import {convertDataForSortableImage} from "../../common/image/databaseImageUtils";
import {VariantClassesProps} from "./VariantClassesImagesComponent";

export const useVariantClassesImageHooks = (props: VariantClassesProps) => {

    useEffect(() => {
        const awaitFn = async () => {
            await getImages()
        };
        awaitFn().then();
    }, [props.variantClassesId])

    const getImages = async () => {
        if (props.variantClassesId) {
            const result = await convertDataForSortableImage(props.dataProvider,
                ResourceNames.VARIANT_CLASSES_IMAGE_RESOURCE,
                {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'position', order: 'ASC'},
                    filter: {
                        variantClassId: props.variantClassesId,
                    }
                }
            )
            props.setImages(result);
        }
    }

}
